import { COLORS } from '../../EatClubTheme';

export const modalInputStyle = {
  borderRadius: '24px',
  maxWidth: '640px',
  width: '100%',
};

export const modalStyle = (isBreakpointSmDown, style) => ({
  container: {
    padding: '20px',
  },
  content: {
    padding: isBreakpointSmDown ? '20px' : '32px 40px',
    ...style?.content,
  },
  header: {
    background: COLORS.LINEN,
    padding: '12px 20px',
    position: 'sticky',
    top: 0,
  },
  footer: {
    padding: 0,
  },
  footerInner: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    gap: '8px',
    justifyContent: 'space-between',
    padding: '12px 20px',
  },
});
