import { API } from 'aws-amplify';
import { updateRestaurantTimePeriods } from '../graphql/mutations';
import store from '../store';
import { getAppVersion, isEmpty } from '../utils/helpers';
import { convertTimeslotsToArray } from '../utils/insights/insightHelpers';
import * as type from './types';
import { restaurantDayPeriods, restaurantTimePeriods } from '../graphql/queries';
import { devLog } from '../utils';

/**
 * Fetch the restaurant day periods
 *
 * @param restId
 * @param userId
 * @param userType
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchRestaurantDayPeriodsAction = (restId, userId, userType) => async (dispatch) => {
  dispatch({
    type: type.FETCH_RESTAURANT_DAY_PERIODS_PENDING,
  });

  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'RESTAURANT_DAY_PERIODS',
  });

  try {
    const response = await API.graphql(
      {
        query: restaurantDayPeriods,
        variables: {
          restId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      },
      {
        'user-id': userId,
        'user-type': userType,
        'rest-id': restId,
        'app-version': getAppVersion(),
      },
    ).catch((error) => {
      throw new Error(error.errors[0].message);
    });

    // destructure
    const {
      data: { restaurantDayPeriods: restaurantDayPeriodsData },
    } = response;

    devLog('success', 'restaurantDayPeriods', restaurantDayPeriodsData);

    dispatch({
      type: type.FETCH_RESTAURANT_DAY_PERIODS_SUCCESS,
      payload: restaurantDayPeriodsData,
    });
  } catch (error) {
    devLog('error', 'restaurantDayPeriods', error);

    dispatch({
      type: type.FETCH_RESTAURANT_DAY_PERIODS_FAILURE,
      payload: `Unable to fetch restaurant day periods: ${error}`,
    });
  } finally {
    dispatch({
      type: type.REMOVE_ID_APP_LOADING,
      payload: `RESTAURANT_DAY_PERIODS`,
    });
  }
};

/**
 * Fetch the restaurant Time periods
 *
 * @param restId
 * @param userId
 * @param userType
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchRestaurantTimePeriodsAction = (restId, userId, userType) => async (dispatch) => {
  dispatch({
    type: type.FETCH_RESTAURANT_TIME_PERIODS_PENDING,
  });

  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'RESTAURANT_TIME_PERIODS',
  });

  try {
    const response = await API.graphql(
      {
        query: restaurantTimePeriods,
        variables: {
          restId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      },
      {
        'user-id': userId,
        'user-type': userType,
        'rest-id': restId,
        'app-version': getAppVersion(),
      },
    ).catch((error) => {
      throw new Error(error.errors[0].message);
    });

    // destructure
    const {
      data: { restaurantTimePeriods: restaurantTimePeriodsData },
    } = response;

    devLog('success', 'restaurantTimePeriods', restaurantTimePeriodsData);

    dispatch({
      type: type.FETCH_RESTAURANT_TIME_PERIODS_SUCCESS,
      payload: restaurantTimePeriodsData,
    });
  } catch (error) {
    devLog('error', 'restaurantTimePeriods', error);

    dispatch({
      type: type.FETCH_RESTAURANT_TIME_PERIODS_FAILURE,
      payload: `Unable to fetch restaurant time periods: ${error}`,
    });
  } finally {
    dispatch({
      type: type.REMOVE_ID_APP_LOADING,
      payload: `RESTAURANT_TIME_PERIODS`,
    });
  }
};

/**
 * Fetch the restaurant Time periods
 *
 * @returns {(function(*): Promise<void>)|*}
 * @param newTimePeriods
 */
export const updateRestaurantTimePeriodsAction = (newTimePeriods) => async (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'UPDATE_RESTAURANT_TIME_PERIODS',
  });

  try {
    // Time periods are structured differently on the backend, as a list rather than a nested object
    // Check each time period and update it if different
    const state = store.getState();
    const { restaurantTimePeriods: restaurantTimePeriodsState } = state.restaurantPeriods; // The period data from the api
    const userId = state.user.userInfo.objectId;
    const restId = state.restaurantActive.restaurant.objectId;
    const { userType } = state.user.userInfo;

    // Convert the timeslots to a list of objects for the API
    const newTimesAsArray = convertTimeslotsToArray(newTimePeriods);

    // Get data ready for api
    const changedTimePeriods = newTimesAsArray
      .map((updatedPeriod) => {
        // Find if the API already has a timeslot for this
        const existingTimePeriod = restaurantTimePeriodsState.find(
          (timePeriod) =>
            timePeriod.category === updatedPeriod.category &&
            timePeriod.type === updatedPeriod.type, // note: lateSession type is already converted before here
        );

        // Ignore any that didn't change (currently commented out since api wants all the data)
        if (
          !isEmpty(existingTimePeriod) &&
          updatedPeriod.startTime === existingTimePeriod.startTime &&
          updatedPeriod.endTime === existingTimePeriod.endTime
        ) {
          return null;
        }

        // Update the object with the new times. send through ID as null if item does not yet exist and it will create
        return {
          objectId: existingTimePeriod?.objectId || null,
          category: updatedPeriod.category,
          enabled: true, // TODO this didn't come from anywhere
          type: updatedPeriod.type,
          startTime: updatedPeriod.startTime,
          endTime: updatedPeriod.endTime,
        };
      })
      .filter((item) => item);

    // If we had changes, save them
    if (!isEmpty(changedTimePeriods)) {
      dispatch({
        type: type.UPDATE_RESTAURANT_TIME_PERIODS_PENDING,
      });

      const response = await API.graphql(
        {
          query: updateRestaurantTimePeriods,
          variables: {
            restId,
            input: changedTimePeriods,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const {
        data: { updateRestaurantTimePeriods: updateRestaurantTimePeriodsData },
      } = response;

      devLog('success', 'updateRestaurantTimePeriods', updateRestaurantTimePeriodsData);

      dispatch({
        type: type.UPDATE_RESTAURANT_TIME_PERIODS_SUCCESS,
        payload: updateRestaurantTimePeriodsData,
      });
    }
  } catch (error) {
    devLog('error', 'updateRestaurantTimePeriods', error);

    dispatch({
      type: type.UPDATE_RESTAURANT_TIME_PERIODS_FAILURE,
      payload: `Unable to update restaurant time periods: ${error}`,
    });
  } finally {
    dispatch({
      type: type.REMOVE_ID_APP_LOADING,
      payload: `UPDATE_RESTAURANT_TIME_PERIODS`,
    });
  }
};
