import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.white,
      // border: '8px solid white',
      borderRadius: '32px',
      overflow: 'hidden',
      boxShadow: '0px 4px 4px 0px rgba(218, 218, 218, 0.25)',
    },
    grid: {
      backgroundColor: theme.colors.borderLight,
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius: '32px',
      overflow: 'hidden',
      containerType: 'inline-size',
    },
    gridBlock: {
      flexBasis: '25%',
      maxWidth: '25%',

      // Wow this is cool. if shrunk down, reposition
      '@container (max-width: 900px)': {
        flexBasis: '50%',
        maxWidth: '50%',
      },

      // Wow this is cool. if shrunk down, reposition
      '@container (max-width: 380px)': {
        flexBasis: '100%',
        maxWidth: '100%',
      },
    },
    gridItem: {
      position: 'relative',
      padding: '12px',
      backgroundColor: 'white',
      height: '100%',
      alignItems: 'flex-start',
      gap: '12px',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.colors.hoverLight,
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      alignItems: 'flex-start',
      containerType: 'inline-size',
      width: '100%',
    },
    title: {
      marginTop: '4px',
      display: 'flex',
      fontSize: '14px',
    },
    loading: {
      marginTop: '4px',
      display: 'flex',
      fontSize: '14px',

      color: theme.colors.smoke,
    },
    insightDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      alignItems: 'flex-start',
      containerType: 'inline-size',
      width: '100%',

      // Fade in effects are nicer than the data just popping in
      opacity: (props) => (props.loading ? 0 : 1),
      transition: (props) => (props.loading ? 'none' : 'opacity 0.5s'),
    },
    insightData: {
      fontSize: '28px',
      minHeight: '37px',
    },
    secondaryText: {
      fontSize: '12px',
      color: theme.colors.secondaryText,
      fontWeight: theme.fontWeights.medium,
    },
    iconCircle: {
      width: '32px',
      height: '32px',
      backgroundColor: (props) => props.iconColor,
      borderRadius: '16px',
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
    plusIcon: {
      position: 'absolute',
      right: '16px',
      top: '16px',
      color: theme.colors.secondaryText,
    },

    pill: {
      backgroundColor: (props) =>
        props.variant === 'ghost' ? 'transparent' : theme.colors.dropdownGray,
      fontSize: '12px',
      padding: '4px 8px',
      borderRadius: '20px',
      display: 'inline-flex',
      alignItems: 'stretch',
      fontWeight: theme.fontWeights.medium,

      '& > *': {
        display: 'inline-flex',
        alignItems: 'center',
      },

      // Wow this is cool. if shrunk down, reposition
      '@container (max-width: 156px)': {
        marginLeft: '-44px',
      },
    },
    pillLabel: {
      color: theme.colors.smoke,
    },
    pillStats: {},
    pillContent: {
      color: theme.colors.secondaryText,

      opacity: (props) => (props.loading ? 0 : 1),
      transition: (props) => (props.loading ? 'none' : 'opacity 0.5s'),
    },
  }),
  { name: 'InsightSummary' },
);

export default useStyles;
