import { makeStyles } from '@mui/styles';
import { COLORS, FONT_WEIGHTS } from '../../../../EatClubTheme';
import { drawerWidth } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#313131',
    width: '100%',
    maxWidth: '1600px',
    marginBottom: '150px',
  },
  heading: {
    maxWidth: '900px',
    margin: 0,
    padding: 0,
  },
  description: {
    marginTop: '20px',
  },
  smallHeading: {
    marginBottom: '12px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  strategyContainer: {
    display: 'flex',
    gap: '22px',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  strategyTitle: {
    marginBottom: '10px',
  },
  strategyDescription: {
    color: COLORS.SMOKE,
  },
  startingOfferHeader: {},
  startingOfferDate: {
    paddingTop: '8px',
  },
  startingOffersContainer: {
    marginBottom: '24px',
    display: 'flex',
    gap: '25px',
    lineHeight: '32px',
    alignItems: 'baseline',

    [theme.breakpoints.down('md')]: {
      gap: '8px',
      flexDirection: 'column',
    },
  },

  footerDivider: {
    position: 'fixed',
    bottom: '88px',
    left: '0',
    borderTop: '1px solid #DCDCDC',
    width: '100%',
  },
  footerBar: {
    position: 'fixed',
    bottom: '0',
    height: '88px',
    // width: '100vw',
    padding: '15px 0',
    // paddingLeft: '60px',
    // paddingRight: '20px',
    zIndex: '5',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '18px',
    // marginLeft: '-60px',
    gap: '20px',
    // width: '100%',
    // width: 'inherit',
    width: '100%',
    // maxWidth: '1280px',
    // left: 'auto', // For sidebar
    // left: 'auto', // For sidebar
    maxWidth: 'min(1295px, calc(100% - 320px))', // Adds some side padding
    [theme.breakpoints.down('md')]: {
      // left: 270, // For sidebar
      maxWidth: 'calc(100% - 320px)', // Adds some side padding
      fontSize: '14px',
      gap: '10px',
    },
    [theme.breakpoints.down('md')]: {
      left: '24px', // No sidebar on mobile
      maxWidth: 'calc(100% - 48px)', // Adds some side padding
      fontSize: '14px',
      gap: '10px',
    },
  },
  goalsFooterPadding: {
    display: 'flex',
    maxWidth: '1424px',
    padding: '20px 72px',
    flexWrap: 'wrap',
    gap: '20px',
    rowGap: '12px',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
  },
  goalsFooter: {
    boxSizing: 'unset',
    alignItems: 'center',
    background: '#F5F2ED',
    bottom: '0',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '18px',
    justifyContent: 'center',
    left: drawerWidth,
    position: 'fixed',
    right: '0',
    boxShadow: '0px -4px 4px 0px #D0D0D040',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('md')]: {
      left: '0',
    },
  },
  footerOptions: {
    display: 'inline-flex',
    gap: '12px',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  footerOption: {
    display: 'inline-flex',
    gap: '8px',
    alignItems: 'center',
  },
  labelContainer: (props) => ({
    ...props?.style?.container,
  }),
  label: (props) => ({
    color: '#6E6E6D',
    textTransform: 'uppercase',
    letterSpacing: '0.13em',
    minWidth: '140px',
    ...props?.style?.label,
  }),
  value: (props) => ({
    ...props.style?.value,
  }),
  featureValue: (props) => ({
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: '#F8E4E0',
    marginTop: '5px',
    color: '#E7554B',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {},
    ...props?.style?.featureValue,
  }),
  buttonAndLabel: {
    display: 'flex',
    gap: '20px',
    rowGap: '12px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonContainer: {},
  launchGoal: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
