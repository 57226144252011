import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTheme } from '@mui/material/styles';
import { apiCall } from '../../../actions/actions';
import { restaurantsAOVDatasetStats } from '../../../graphql/queries';
import { formatCurrency, roundToNearest } from '../../../utils/helpers';
import { getComparisonMessage, getTimePeriodMessage } from '../../../utils/insights/insightHelpers';
import { sortAlphaNumeric } from '../../../utils/listHelpers';
import TextDropdown from './Dropdown/TextDropdown';
import MarketComparisons from './MarketComparisons/MarketComparisons';
import useStyles, { getInsightTabColors, INSIGHT_TAB } from './PillarModalStyles';
import { PillarModalRowItem } from './PillarModalRowItem';
import { PillarModalSummary } from './PillarModalSummary';

const AovPillarModalContent = ({ dates, data, setFilter, filter, dateRange, setDateRange }) => {
  const tab = INSIGHT_TAB.AOV;
  const classes = useStyles({ tab });
  const theme = useTheme();

  const [loadingData, setLoadingData] = useState(true);
  const [comparisonData, setComparisonData] = useState({});
  const [comparisonType, setComparisonType] = useState('all');

  const dateRangeOptions = [
    { label: 'Since all time', value: 'allTime' },
    { label: getTimePeriodMessage(dates.startDate, dates.endDate), value: 'dateRange' },
  ];

  const getTotalForComparison = () => {
    switch (comparisonType) {
      case 'dineIn':
        return data.current.dineInAOV;
      case 'takeaway':
        return data.current.takeawayAOV;
      default:
        return data.current.totalAOV;
    }
  };

  // Fetch comparison data
  useEffect(() => {
    const fetchComparisonData = async () => {
      setLoadingData(true);

      const { startDate, endDate } = dates;
      const formatStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formatEndDate = dayjs(endDate).format('YYYY-MM-DD');

      const variables = {
        startDate: formatStartDate,
        endDate: formatEndDate,
        filters: filter !== 'all' ? [filter] : [],
        diningOptionFilter: comparisonType === 'all' ? null : comparisonType,
      };
      const response = await apiCall(restaurantsAOVDatasetStats, variables, true);
      const responseData = response.response.data.restaurantsAOVDatasetStats;

      setLoadingData(false);
      setComparisonData({
        low: roundToNearest(responseData?.low ?? 0),
        mean: roundToNearest(responseData?.mean ?? 0),
        high: roundToNearest(responseData?.high ?? 0),
        dataset: [...(responseData?.dataset ?? [])].sort(sortAlphaNumeric),
        total: getTotalForComparison(), // We set this here so the graph is less janky when loading
      });
    };

    try {
      fetchComparisonData();
    } catch (e) {
      setLoadingData(false);
    }
  }, [dates, filter, comparisonType]);

  return (
    <>
      <Box className={classes.sectionContainer}>
        <PillarModalSummary
          heading='Customers spend more on bigger offers'
          summary='The more you give, the more you get. Our data suggests that the bigger the exclusive offer and savings for the customer, the more likely they are to spend bigger in your venue. The better the value the customer believes they’re getting, the more likely they are to get those pre-dinner cocktails, spend that little bit more on the better wine or get that more expensive meal they would usually hold out on.'
          maxCharacters={175}
        >
          <Box className={classes.sectionBreakdownContainer}>
            {/* <TextDropdown items={dateRangeOptions} value={dateRange} onSelect={setDateRange} /> */}

            <Box className={classes.sectionBreakdownRowItemsContainer}>
              <PillarModalRowItem
                label='Average order value: Dine-in'
                value={formatCurrency(data.current.dineInAOV)}
                variant='outlined'
              />
              <PillarModalRowItem
                label='Average order value: Takeaway'
                value={formatCurrency(data.current.takeawayAOV)}
                variant='outlined'
              />
              <PillarModalRowItem
                label='Average order value'
                value={formatCurrency(data.current.totalAOV)}
                variant='filled'
                color={tab}
              />
            </Box>
          </Box>
        </PillarModalSummary>
      </Box>

      <Divider className={classes.divider} />

      <MarketComparisons
        description={getComparisonMessage(comparisonData.total, comparisonData)}
        comparisonData={comparisonData}
        total={comparisonData.total}
        colors={getInsightTabColors(INSIGHT_TAB.AOV, theme)}
        loading={loadingData}
        format={(value) => formatCurrency(value, false, '', false, true)}
        setFilter={setFilter}
        filter={filter}
        secondaryFilter={[
          { label: 'Dine-in and takeaway', value: 'all' },
          { label: 'Dine-in', value: 'dineIn' },
          { label: 'Takeaway', value: 'takeaway' },
        ]}
        setSecondaryFilter={setComparisonType}
        secondaryFilterValue={comparisonType}
      />
    </>
  );
};

AovPillarModalContent.propTypes = {
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AovPillarModalContent);
