import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableStyles: {
    '& > div': {
      borderRadius: '24px',
      boxShadow: '0px 4px 4px 0px #DADADA40',
      overflow: 'hidden',
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid #F2F2F2',
      lineHeight: 2,
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      borderBottom: '1px solid #E9E9E9',
      lineHeight: 2,
      fontWeight: 400,
      whiteSpace: 'nowrap',
    },
    // fix border causing weird 1px spacing
    '& .MuiTableFooter-root > .MuiTableRow-footer > .MuiTableCell-footer': {
      borderBottom: 0,
    },
    // table scrollbar styles
    '& ::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '& ::-webkit-scrollbar:vertical': {
      width: '12px',
    },
    '& ::-webkit-scrollbar:horizontal': {
      height: '12px',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .25)',
      borderRadius: '10px',
      border: '2px solid #ffffff',
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '#ffffff',
    },
  },
}));

export default useStyles;
