import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    header: {
      padding: '24px',
      fontSize: '20px',
      fontWeight: theme.fontWeights.medium,
    },
    tableStyles: {
      overflowX: 'auto',

      backgroundColor: '#FFFFFF',
      borderRadius: '24px',
      // padding: '24px',

      [theme.breakpoints.down('sm')]: {
        width: 'fit-content',
        margin: '0 20px',
        marginLeft: '20px',
        marginRight: '20px',
        overflowX: 'hidden',
      },

      '& > div': {
        borderRadius: '24px',
        boxShadow: 'none',
        overflowX: 'hidden',
        paddingBottom: '20px',
        color: theme.colors.charcoalTransparent,
      },
      '& .MuiToolbar-root': {
        height: '0',
        minHeight: '0',
      },
      '& .MuiTable-root': {
        borderCollapse: 'separate',
      },
      '& .MuiTableBody-root .MuiTableCell-root': {
        lineHeight: 2,
        height: '56px',
        borderBottom: 0,
        textAlign: 'right',
      },
      '& .MuiTableRow-root > .MuiTableCell-root:first-child': {
        textAlign: 'left',
      },
      '& .MuiTableBody-root .MuiTableRow-root:not(:last-child) .MuiTableCell-root': {
        borderBottom: '1px solid #F2F2F2',
      },
      '& .MuiTableCell-root': {
        paddingRight: '28px',
      },
      '& .MuiTableCell-root:last-child': {
        paddingRight: '38px',
      },
      '& .MuiTableHead-root .MuiTableCell-root': {
        borderBottom: '1px solid #E9E9E9',
        lineHeight: 2,
        whiteSpace: 'nowrap',
        height: '56px',

        // textAlign: 'center',
        textAlign: 'right',
        paddingRight: 0,
      },
      '& .MuiTableHead-root .MuiTableCell-root:last-child': {
        paddingRight: 12,
      },
      '& ::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },
      '& ::-webkit-scrollbar:vertical': {
        width: '12px',
      },
      '& ::-webkit-scrollbar:horizontal': {
        height: '12px',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, .25)',
        borderRadius: '10px',
        border: '2px solid #ffffff',
      },
      '& ::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#ffffff',
      },
    },
  }),
  { name: 'RedemptionsTable' },
);

export default useStyles;
