import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    textDropdownContainer: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      // padding: '4px 8px',
      marginLeft: '-8px', // to offset padding for text buttons
      borderRadius: '6px',
      maxWidth: 'fit-content',
      transition: 'background 0.1s',
      userSelect: 'none',
      '&:hover': {
        cursor: 'pointer',
        background: theme.colors.hoverLight,
      },
    },
  }),
  { name: 'TextDropdown' },
);

export default useStyles;
