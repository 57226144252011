import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { invoicesByRestaurantId } from '../graphql/queries';
import { devLog, sortByDate } from '../utils';

export const fetchInvoicesAction = (restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.FETCH_INVOICES_PENDING,
  });
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'INVOICES',
  });

  const paginatedData = [];
  const fetchLoop = async (pageSize, offset) => {
    try {
      const response = await API.graphql(
        {
          query: invoicesByRestaurantId,
          variables: {
            restId,
            pageSize,
            offset,
          },
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const {
        data: { invoicesByRestaurantId: invoices },
      } = response;

      // congregate data
      if (invoices.length > 0) {
        paginatedData.push(...invoices);
      }

      // But wait, there's more!
      if (invoices.length >= pageSize) {
        fetchLoop(pageSize, offset + pageSize);
        return;
      }

      const invoicesByDate = Array.from(paginatedData).sort((date1, date2) =>
        sortByDate(date1.invoiceCreated, date2.invoiceCreated, ''),
      );

      devLog('success', 'invoices', invoicesByDate);

      // Last call
      dispatch({
        type: type.FETCH_INVOICES_SUCCESS,
        payload: invoicesByDate,
      });
    } catch (error) {
      devLog('error', 'invoices', error);

      dispatch({
        type: type.FETCH_INVOICES_FAILURE,
        payload: `Unable to retrieve invoices: ${error}`,
      });
    }

    // finally
    dispatch({
      type: type.REMOVE_ID_APP_LOADING,
      payload: 'INVOICES',
    });
  };
  fetchLoop(500, 0);
};
