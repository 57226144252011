import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box } from '@eatclub-apps/ec-component-library';

const GraphDot = ({
  cx = 0,
  cy = 0,
  clickHandler = () => {},
  visible = true,
  active = false,
  setPosition,
  payload,
  graphRef,
}) => {
  const theme = useTheme();
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const closedForDay = payload?.closedForDay;

  // When this becomes active, set the position
  // Sorry about all the magic numbers. This was fine-tuned to fit with offsets from the points
  useEffect(() => {
    if (active) {
      const newXPosition = cx + 16;
      const isTooFarRight = newXPosition + 120 > graphRef.current?.offsetWidth;

      if (isTooFarRight) {
        setPosition(cx - 180, cy - 96);
      } else {
        setPosition(newXPosition, cy - 96);
      }
    }
  }, [active]);

  if (payload?.revenue === null) {
    return <></>;
  }

  return (
    <>
      <Box>{payload?.revenue}</Box>
      <circle
        onClick={clickHandler}
        cx={cx}
        cy={cy}
        r={breakpointSmDown ? 4 : 5}
        fill='black'
        style={{ display: visible ? 'auto' : 'none' }}
      />
      <circle
        onClick={clickHandler}
        cx={cx}
        cy={cy}
        r={breakpointSmDown ? 3 : 4}
        fill={closedForDay ? theme.colors.chartDotInvalid : theme.colors.chartDotDefault}
        style={{ display: visible ? 'auto' : 'none' }}
      />
    </>
  );
};

GraphDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  clickHandler: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GraphDot);
