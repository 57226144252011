import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Auth, Hub } from 'aws-amplify';
import {
  Authenticator,
  Greetings,
  SignUp,
  ForgotPassword,
  SignIn,
  VerifyContact,
} from 'aws-amplify-react';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import partnerConfig from '../../partner-aws-exports';
import staffConfig from '../../staff-aws-exports';
import { devLog } from '../../utils';
import CustomSignIn from './CustomSignIn';
import App from '../../App';
import { fetchSsoCredsAction, setLogoutAction } from '../../actions/loginAction';
import { revertObscureString, isEmpty } from '../../utils/helpers';

const AppWithAuth = ({ ssoCreds, fetchSsoCreds, setLogout }) => {
  const partnerSession = localStorage.getItem('mode') === 'partner';
  const staffSession = localStorage.getItem('mode') === 'staff';

  const location = useLocation();
  const staffLogin = location?.pathname === '/staff';

  const params = new URLSearchParams(window.location.search);
  const ssoToken = params.get('ssoToken');

  const [authSignedIn, setAuthSignedIn] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);

  // This is used to prevent redirects cancelling other redirects
  const redirecting = useRef(false);

  const staffSignOut = () => {
    console.log('signing out staff');
    redirecting.current = true;
    window.location.href = `${import.meta.env.VITE_LOGIN_URL}?auth=signOut&callback_url=${
      window.location.origin
    }/staff${params.get('restId') ? `&restId=${params.get('restId')}` : ''}`;
  };

  useEffect(() => {
    if (params.get('origin') === 'hub' && partnerSession) {
      // localStorage.clear(); // TODO don't want to wipe all local storage. Check what Obee FOH is doing
    }

    if (ssoToken) {
      try {
        fetchSsoCreds(ssoToken, staffLogin);
      } catch (e) {
        console.error(e);
      }
    }

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          setAuthSignedIn(true);
          break;
        case 'signOut':
          const mode = localStorage.getItem('mode');
          if (staffSession || mode === 'staff') {
            localStorage.removeItem('mode');
            staffSignOut();
          } else {
            localStorage.removeItem('mode');
            setAuthSignedIn(false);
            setAuthLoading(false);
          }
          break;
        case 'customOAuthState':
          break;
        case 'signIn_failure':
          break;
        default:
          break;
      }
    });
  }, [fetchSsoCreds, ssoToken]);

  const login = async (email, password) => {
    try {
      const user = await Auth.signIn(email.trim(), password);
      params.delete('ssoToken');
      devLog('success', 'user', user);
    } catch (error) {
      devLog('error', 'user', error);
      setLogout();
    }
  };

  // When sso login is complete
  const oldSsoSuccess = useRef(ssoCreds?.success); // Prevents auto-login if the user hasn't refreshed the page yet
  useEffect(() => {
    if (!oldSsoSuccess.current && ssoCreds?.success) {
      const creds = revertObscureString(ssoCreds?.data).split(';');

      // User login. Get creds then log in for that user
      if (!staffLogin) {
        // Assuming we get the email and password
        const email = creds?.[0];
        const password = creds?.[1];

        // failsafe if currently logged in as staff
        if (localStorage.getItem('mode') === 'staff') {
          console.log('have sso but removing staff mode anyway');
          localStorage.removeItem('mode');
        }

        // Remove the ssoToken from the url
        redirecting.current = true;
        window.history.pushState({}, '', window.location.href.split('?')[0]);

        // Log the user in
        devLog('info', 'sso login', 'logging in');
        login(email, password);
        return;
      }

      // If staff, get the login creds
      let keyPrefix = `CognitoIdentityServiceProvider.${import.meta.env.VITE_AWS_STAFF_USER_POOLS_WEB_CLIENT_ID}`;
      const cognitoLastAuthUser = creds[1];

      localStorage.setItem(`${keyPrefix}.LastAuthUser`, cognitoLastAuthUser);

      keyPrefix = `${keyPrefix}.${cognitoLastAuthUser}`;

      localStorage.setItem(`${keyPrefix}.accessToken`, creds[0]);
      localStorage.setItem(`${keyPrefix}.idToken`, creds[2]);
      localStorage.setItem(`${keyPrefix}.refreshToken`, creds[3]);
      localStorage.setItem(`mode`, `${staffLogin}`);

      params.delete('ssoToken');
      params.delete('mode');
      const paramsString = params.toString();

      setAuthSignedIn(true);
      setAuthLoading(false);

      window.history.pushState(
        {},
        '',
        `${window.location.origin}${!isEmpty(paramsString) ? `?${paramsString}` : ''}`,
      );
    }

    oldSsoSuccess.current = ssoCreds?.success;
  }, [ssoCreds?.success]);

  const mapErrorMessages = (message) => {
    if (message === 'Custom auth lambda trigger is not configured for the user pool.') {
      return 'Please enter your password';
    }
    return message;
  };

  const signUpConfig = {
    header: 'Sign Up to EatClub',
    hiddenDefaults: ['phone_number'],
  };

  const customTheme = {
    button: {
      backgroundColor: '#E54439',
      fontFamily: 'Gordita',
      width: '100%',
      borderRadius: '4px',
    },
    a: {
      color: '#E54439',
    },
    navButton: {
      backgroundColor: '#E54439',
      fontFamily: 'Gordita',
    },
    sectionFooter: {
      display: 'block',
    },
    sectionFooterPrimaryContent: {
      display: 'block',
      marginBottom: '12px',
    },
    toast: {
      backgroundColor: '#E54439',
    },
  };

  // On successful login, if a query param is present, redirect to that url
  useEffect(() => {
    const internalRedirect = params.get('redirectTo');
    if (!ssoToken && authSignedIn && !isEmpty(internalRedirect)) {
      devLog('info', 'Redirecting after login', internalRedirect);
      const params = new URLSearchParams(location.search);
      params.delete('redirectTo');

      // Note: This assumes the internal redirect begins with a /
      redirecting.current = true;
      window.history.pushState({}, '', `${import.meta.env.VITE_URL_PREFIX}${internalRedirect}`);
    }
  }, [authSignedIn]);

  const staffRedirect = () => {
    if (!ssoToken && !redirecting.current) {
      redirecting.current = true;
      window.location.href = `${import.meta.env.VITE_LOGIN_URL}?callback_url=${
        window.location.origin
      }/staff${params.get('restId') ? `&restId=${params.get('restId')}` : ''}`;
    }
  };

  if (ssoCreds?.error) {
    if (staffLogin) {
      staffSignOut();
    } else {
      devLog('error', 'failed to switch account');
    }
  }

  return (
    <Authenticator
      onStateChange={(authState) => {
        if (authState === 'signedIn' && !ssoToken) {
          // NOTE: this will trigger when refreshing and logged in
          // show app here to prevent showing both login screen, and app
          setAuthSignedIn(true);
          setAuthLoading(false);
          return;
        }
        if (authState === 'signIn') {
          setAuthLoading(false);
        }
      }}
      hide={[Greetings, SignIn, SignUp, ForgotPassword, VerifyContact]}
      includeGreetings={false}
      amplifyConfig={(staffLogin || staffSession) && !partnerSession ? staffConfig : partnerConfig}
      theme={customTheme}
      signUpConfig={signUpConfig}
      usernameAttributes='email'
      errorMessage={mapErrorMessages}
    >
      <>
        {!ssoToken && authSignedIn && <App />}
        {!ssoToken && !authLoading && !authSignedIn && staffLogin && staffRedirect()}
        {!ssoToken && !authLoading && !authSignedIn && !staffLogin && (
          <CustomSignIn authSignedIn={authSignedIn} />
        )}
      </>
    </Authenticator>
  );
};

AppWithAuth.propTypes = {
  fetchSsoCreds: PropTypes.func.isRequired,
  setLogout: PropTypes.func.isRequired,
  ssoCreds: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    error: PropTypes.string,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  ssoCreds: state.ssoCreds,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLogout: setLogoutAction,
      fetchSsoCreds: fetchSsoCredsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppWithAuth);
