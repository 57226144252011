// Export prop types for easy import in components that use this data
import PropTypes from 'prop-types';

export const transactionDetailPropTypes = PropTypes.shape({
  bookingId: PropTypes.string,
  bookingDate: PropTypes.string,
  discount: PropTypes.string,
  discountEx: PropTypes.number,
  dayOfWeek: PropTypes.number,
  time: PropTypes.string, // 11:50 AM
  partySize: PropTypes.number,
  type: PropTypes.string, // "dinein"
  origTotal: PropTypes.string, // in cents
  unsubsidizedTotal: PropTypes.number, // in cents
  ecFloat: PropTypes.number, // in cents
  ecFees: PropTypes.number, // in cents
  txFees: PropTypes.number, // in cents
  fullFee: PropTypes.number, // in cents
  rating: PropTypes.number, // comes back -1
});

export const transactionDetailsPropTypes = PropTypes.shape({
  transactionDetails: PropTypes.arrayOf(transactionDetailPropTypes),
  compareTransactionDetails: PropTypes.arrayOf(transactionDetailPropTypes),
  pastYearTransactionDetails: PropTypes.arrayOf(transactionDetailPropTypes),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  fetching: PropTypes.bool,
  shouldFetch: PropTypes.bool,
});
