/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import useStyles from './PillarModalStyles';
import { isEmpty } from '../../../utils/helpers';

export const PillarModalSummary = ({
  title = '',
  summary,
  maxCharacters,
  children,
  showDivider = true,
}) => {
  const classes = useStyles();

  const [isHidden, setIsHidden] = useState(false);
  const [isTruncated, setIsTruncated] = useState(true);

  return (
    <Box style={{ paddingBottom: '18px' }}>
      {!isHidden && (
        <>
          {!isEmpty(title) && <Box className={classes.title}>{title}</Box>}

          <Box className={classes.sectionSubHeadingContainer}>
            {isTruncated ? (
              <>
                {summary.slice(0, maxCharacters)}...{' '}
                <span
                  onClick={() => setIsTruncated(false)}
                  style={{ textDecoration: 'underline', cursor: 'pointer', whiteSpace: 'nowrap' }}
                >
                  Learn more
                </span>
              </>
            ) : (
              <>
                <span style={{ whiteSpace: 'pre-line' }}>{summary}</span>
                <Box
                  onClick={() => setIsTruncated(true)}
                  style={{ textDecoration: 'underline', cursor: 'pointer', whiteSpace: 'nowrap' }}
                >
                  Show less
                </Box>
              </>
            )}
          </Box>

          {showDivider && <Divider className={classes.divider} />}

          {children}
        </>
      )}
    </Box>
  );
};

PillarModalSummary.propTypes = {
  summary: PropTypes.string.isRequired,
  maxCharacters: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
  showDivider: PropTypes.bool,
  title: PropTypes.string,
};
