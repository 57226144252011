import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../EatClubTheme';

const useStyles = makeStyles(() => ({
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxWidth: '250px',
  },
  popoverHeader: {
    fontSize: FONT_SIZES.H4,
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  popoverContent: {
    color: COLORS.SMOKE,
    fontSize: '16px',
  },
  icon: {
    color: COLORS.CHARCOAL,
    opacity: 0.8,
    transition: 'opacity 0.1s',
    '&:hover': {
      opacity: 1,
    },
  },
  transactionsContainer: {
    color: COLORS.SMOKE,
    transition: 'color 0.25s',
    '&:hover': {
      color: COLORS.CHARCOAL,
      cursor: 'pointer',
    },
  },
  clickableContainer: {
    alignItems: 'baseline',
    color: COLORS.CHARCOAL,
    display: 'flex',
    gap: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  negativeRevenueLabel: {
    padding: '5px',
    borderRadius: '4px',
    width: 'fit-content',
    background: '#FAE3E2',
    color: '#E7554B',
  },
  positiveRevenueLabel: {
    padding: '5px',
    borderRadius: '4px',
    width: 'fit-content',
    background: '#E3EEE2',
    color: '#487447',
  },
  viewText: {
    textDecoration: 'underline',
  },
  tableStyles: {
    '& > div': {
      borderRadius: '24px',
      boxShadow: '0px 4px 4px 0px #DADADA40',
      overflow: 'hidden',
    },
    '& .MuiTable-root': {
      borderCollapse: 'separate',
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid #F2F2F2',
      lineHeight: 2,
      height: '56px',
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      borderBottom: '1px solid #E9E9E9',
      lineHeight: 2,
      fontWeight: 400,
      whiteSpace: 'nowrap',
      height: '56px',
    },
    '& .MuiTableHead-root .MuiTableCell-root:first-child': {
      width: '100% !important',
    },
    '& ::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '& ::-webkit-scrollbar:vertical': {
      width: '12px',
    },
    '& ::-webkit-scrollbar:horizontal': {
      height: '12px',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .25)',
      borderRadius: '10px',
      border: '2px solid #ffffff',
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '#ffffff',
    },
  },
}));

export default useStyles;
