import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  heading: {},
  description: {
    marginTop: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  modalFooter: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '15px',
    width: '100%',
  },
}));

export default useStyles;
