import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CenteredContent, RadioButtonGroup } from '@eatclub-apps/ec-component-library';
import { useSelector } from 'react-redux';
import { dealPropTypes } from '../../../data/models/Deal';
import { COLORS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, formatCurrency, getOfferTime } from '../../../utils/helpers';
import useStyles from './ModalStyles';
import Disabled from '../../../assets/disabled.svg';
import ModalSectionHeader from './ModalSectionHeader';
import { Modal } from '../../Modal';

const DisableOfferModal = ({ isOpen, offer, onClose, onSave }) => {
  const classes = useStyles();

  const goals = useSelector((state) => state.goals);

  const goalForOffer = goals.data.find((goal) => goal?.objectId === offer?.goalId);

  const [typeToDisable, setTypeToDisable] = useState('dineInAndTakeaway');

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: disable_offer');
    }
  }, [isOpen]);

  const confirm = () => {
    trackEvent('button_click: confirm_disable_offer');
    onSave(null, typeToDisable);
  };

  return (
    <Modal
      confirmLabel='Disable offer'
      heading='Disable offer'
      headingStartIcon={<Disabled height='24px' width='24px' />}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirm}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Box>
          <Box className={classes.inlineText}>
            <Box className={classes.discountLabel}>{offer?.discount}</Box>
            <CenteredContent>
              on {getDayFromInt(offer?.dayOfWeek)} {getOfferTime(offer)}
            </CenteredContent>
          </Box>
        </Box>
        <Box className={classes.radioButtonContainer}>
          <RadioButtonGroup
            items={[
              { label: 'Disable both dine-in and takeaway', value: 'dineInAndTakeaway' },
              { label: 'Disable dine-in only', value: 'dineInOnly' },
              { label: 'Disable takeaway only', value: 'takeawayOnly' },
            ]}
            onSelect={setTypeToDisable}
            value={typeToDisable}
            style={{
              buttonCircleInnerSelected: {
                backgroundColor: COLORS.BLACK,
              },
            }}
          />
        </Box>
        {goalForOffer && offer?.revenueExpected > 0 && (
          <Box>
            <ModalSectionHeader header='Impact' />
            <Box className={classes.impactRow}>
              <Box>Change in weekly revenue</Box>
              <Box className={classes.redLabel}>
                -{formatCurrency(offer?.revenueExpected, false)}
              </Box>
            </Box>
          </Box>
        )}
        <Box>This will be disabled until end of business tonight.</Box>
      </Box>
    </Modal>
  );
};

DisableOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DisableOfferModal;
