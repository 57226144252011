import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { TabBar as ECTabBar } from '@eatclub-apps/ec-component-library';
import { tabBarStyles } from './TabBarStyles';

/**
 * A wrapper for the ec component library tab bar, with clean styles
 * @param tabs
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TabBar = ({ tabs = [], value = null, onSelect = (e) => e, ...props }) => {
  const theme = useTheme();
  return (
    <ECTabBar
      style={tabBarStyles(theme)}
      tabs={tabs}
      value={value}
      onSelect={onSelect}
      {...props}
    />
  );
};

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  value: PropTypes.string,
  onSelect: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TabBar);
