import { Box, Button, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import {
  clearPotentialRevenuesAction,
  fetchPotentialRevenuesAction,
} from '../../../actions/potentialRevenuesAction';
import { potentialRevenuesPropTypes } from '../../../reducers/potentialRevenuesReducer';
import { Text } from '../../Text';
import { trackEvent } from '../../../utils/analytics';
import ChefIcon from '../../../assets/chef_icon.svg';
import { formatCurrency } from '../../../utils/helpers';

const RevenueBanner = ({
  fetchPotentialRevenues,
  clearPotentialRevenues,
  potentialRevenues,
  restId,
  userId,
  userType,
}) => {
  const history = useHistory();

  useEffect(() => {
    clearPotentialRevenues();
  }, [restId]);

  // Fetch potential revenue
  useEffect(() => {
    if (!potentialRevenues?.success) {
      fetchPotentialRevenues(restId, userId, userType);
    }
  }, [potentialRevenues?.success]);

  return (
    <Box
      style={{
        backgroundColor: '#fff',
        maxWidth: '600px',
        borderRadius: '24px',
        boxShadow: '0px 4px 21.2px 0px #9C9C9C40',
        border: '1px solid #E0E0E0',
        padding: '20px',
      }}
    >
      <Box style={{ display: 'flex', gap: '20px' }}>
        {potentialRevenues.fetching ? (
          <Skeleton width='100%' height='32px' />
        ) : (
          <Text size='lg' style={{ lineHeight: '32px' }}>
            Hey, did you know you could earn an extra{' '}
            <span
              style={{
                backgroundColor: '#FBF4BD',
                borderRadius: '100px',
                fontWeight: 500,
                padding: '1px 3px',
              }}
            >
              {formatCurrency(potentialRevenues.data?.maxRevenue?.amount, false)}
            </span>{' '}
            on EatClub using revenue goals
          </Text>
        )}

        <Box>
          <ChefIcon />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          columnGap: '20px',
          rowGap: '12px',
          flexWrap: 'wrap',
          marginTop: '32px',
        }}
      >
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          style={{ letterSpacing: '1.2px' }}
          onClick={() => {
            trackEvent('button_click: create_new_goal_from_banner');
            history.push('/goals/create');
          }}
        >
          GET STARTED
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          style={{ letterSpacing: '1.2px' }}
          onClick={() => {
            trackEvent('button_click: view_previous_goals_from_banner');
            history.push('/goals/previous');
          }}
        >
          VIEW PREVIOUS
        </Button>
      </Box>
    </Box>
  );
};

RevenueBanner.propTypes = {
  fetchPotentialRevenues: PropTypes.func.isRequired,
  clearPotentialRevenues: PropTypes.func.isRequired,
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  potentialRevenues: state.potentialRevenues,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPotentialRevenues: fetchPotentialRevenuesAction,
      clearPotentialRevenues: clearPotentialRevenuesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RevenueBanner);
