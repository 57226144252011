// Prevent showing the "account is disabled" message for prodtest accounts
export const overrideDisabledRestaurants = [
  '6E2E705E-4323-C2C7-FF1B-027FF0248A00', // PRODTEST 1
  '6E2E705E-4323-C2C7-FF1B-027FF0248A02', // PRODTEST 2
  '894EA032-820B-4C03-86D0-0C957605A9A6', // PRODTEST 3
  'D13FB386-D333-44FA-A574-C35FE2950D66', // PRODTEST 4
  'FF2D1A1D-16F4-11EE-A554-0236648A357E', // PRODTEST 5
  '01BA817D-16F5-11EE-A554-0236648A357E', // PRODTEST 6
  '040DDEDC-16F5-11EE-A554-0236648A357E', // PRODTEST 7
  '06A61D5C-16F5-11EE-A554-0236648A357E', // PRODTEST 8
];
