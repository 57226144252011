import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      // border: '1px solid #DCDCDB',
      borderRadius: '32px',
      padding: '24px',
      gap: '16px',
      // padding: '10px',

      backgroundColor: theme.colors.chartBackground,
    },
    graphHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    graphContainer: {
      paddingTop: '16px',
      height: '204px',
      '& .recharts-surface': {
        overflow: 'visible',
      },
      '& .recharts-cartesian-grid-vertical > line': {
        strokeDasharray: 3,
      },
      // margin: '20px -10px',
    },
    performanceDayContainer: {
      display: 'flex',
      paddingTop: '16px',
      paddingLeft: '40px',
      margin: '0 -18px',
      // margin: '0 -12px',
      // justifyContent: 'space-between',
      flexGrow: 0,
      flexShrink: 1,
      borderTop: `1px solid ${theme.colors.chartDivider}`,
      // gap: '24px',
      // padding: '20px 30px',
      // flexWrap: 'wrap',
    },
    performanceDay: {
      width: '100%',
      maxWidth: '60px',
      overflowX: 'hidden',
      flexGrow: 0,
      flexShrink: 1,
      alignSelf: 'start',
      textAlign: 'center',
      padding: '8px 0',
      border: '1px solid transparent',
      borderRadius: '8px',
      // cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        border: '1px solid black',
        backgroundColor: '#F9F7EC !important',
      },
    },
    dayOfferInfo: {
      display: 'flex',
      borderTop: `1px solid ${theme.colors.chartDivider}`,
      paddingTop: '20px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dayName: {
      fontSize: '16px',
      paddingBottom: '2px',
      whiteSpace: 'nowrap',
    },
    monthName: {
      fontSize: '10px',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    },
    dayRevenue: {
      marginBottom: '12px',
      fontSize: '18px',
      fontWeight: 500,
    },
    dayDate: {
      fontSize: '18px',
    },
    title: {
      fontSize: '22px',
      color: '#000000',
      opacity: 0.75, // Lets it blend in to the yellow and looks much nicer than just a grey
      fontWeight: theme.fontWeights.medium,
    },

    // Below graph
    emptyStateContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '57px auto 100px auto',
      gap: '15px',
      fontSize: '16px',
      alignItems: 'center',
    },
    emptyStateHeading: {
      fontWeight: theme.fontWeights.medium,
    },
    tooltipContainer: {
      minWidth: '150px',
      backgroundColor: 'white',
      padding: '12px',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      fontSize: '15px',
      opacity: '0.95',
      boxShadow: '0px 4px 4px 0px rgba(218, 218, 218, 0.25)',
    },
    tooltipDate: {
      fontSize: '12px',
      color: theme.colors.charcoal,
      fontWeight: theme.fontWeights.medium,
      marginBottom: '4px',
    },
  }),
  { name: 'RevenueGraph' },
);

export default useStyles;
