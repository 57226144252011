import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
  },
  loadingContainer: {
    minHeight: '196px',
  },
  heading: {
    fontWeight: 500,
  },
  offersList: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 4px 0px #DADADA40',
    borderRadius: '24px',
    overflow: 'hidden',
  },

  opportunitiesHeaderContainer: {
    display: 'inline-flex',
    gap: '5px',
    marginBottom: '24px',
  },
  opportunitiesHeader: {
    fontWeight: 500,
  },
  oppoTitleDivider: {
    backgroundColor: '#000000',
  },
  oppoDivider: {
    backgroundColor: '#F3F3F3',
  },

  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  mobileOnly: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  viewAllOffers: {
    alignItems: 'center',
    border: '1px solid #DED0BC',
    borderRadius: '24px',
    color: '#313131',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '18px',
    gap: '16px',
    padding: '15px 25px',
    width: '100%',
  },
  offerAmount: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '43px',
    width: '43px',
    backgroundColor: COLORS.CLOUD,
    borderRadius: '100%',
  },
}));

export default useStyles;
