import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { CenteredContent } from '@eatclub-apps/ec-component-library';
import { goalPropTypes } from '../../../data/models/Goal';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, getDateRange } from '../../../utils/helpers';
import GoalStatus from './GoalStatus';
import ChevronRight from '../../../assets/chevron_right.svg';
import useStyles from './GoalAnalyticsStyles';

const MobileGoalCard = ({ goal }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box
      className={classes.card}
      onClick={() => {
        trackEvent('button_click: view_goal');
        history.push(`/goals/${goal?.objectId}`);
      }}
    >
      <Box>
        <Box className={classes.cardInner}>
          <Box className={classes.cardDetails}>
            <Box className={classes.cardTitle}>
              {getDateRange(dayjs(goal?.startDate), dayjs(goal?.endDate))}
            </Box>
            <Box className={classes.cardDate}>{formatCurrency(goal?.revenue, false)}</Box>
            <CenteredContent>
              <GoalStatus status={goal?.status} />
            </CenteredContent>
          </Box>
          <Box className={classes.arrowContainer}>
            <CenteredContent style={{ marginRight: '12px' }}>
              <ChevronRight />
            </CenteredContent>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

MobileGoalCard.propTypes = {
  goal: goalPropTypes.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileGoalCard);
