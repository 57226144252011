import {
  getDineInBookings,
  getOfflineBookings,
  getOfflineRevenueAfterOffer,
  getOnlineBookings,
  getOnlineFeesAfterOffer,
  getOnlineRevenueAfterOffer,
  getOnlineRevenueBeforeOffer,
  getTakeawayBookings,
  getTotalGuests,
} from '../data/models/Analytics';
import { safeDivision } from './helpers';

/**
 * Calculate all of the revenue thingies from the given list.
 *
 * This is helpful when breaking down by day
 * @param bookingsData
 * @param compareBookingsData
 * @param averageOrderValue
 * @param averageBookingValue
 * @returns {{}}
 */
export const calculateRevenue = (
  bookingsData,
  compareBookingsData,
  averageOrderValue,
  averageBookingValue,
  ecPayEnabled = false,
  inAppOrdering = false,
) => {
  const uniqueBookingsData = bookingsData ?? [];
  const uniqueCompareBookingsData = compareBookingsData ?? [];

  // Total guests
  const totalGuests = getTotalGuests(uniqueBookingsData ?? []);
  const totalGuestsCompare = getTotalGuests(uniqueCompareBookingsData ?? []);

  // Deals redeemed
  const dealsRedeemed = uniqueBookingsData.length;
  const dealsRedeemedCompare = uniqueCompareBookingsData.length;

  // Dine-in
  const dineInBookings = getDineInBookings(bookingsData);
  const dineInBookingsCompare = getDineInBookings(compareBookingsData);

  const uniqueDineInBookings = dineInBookings;
  const uniqueCompareDineInBookings = dineInBookingsCompare;

  const uniqueOnlineDineInBookings = getOnlineBookings(dineInBookings);
  const uniqueCompareOnlineDineInBookings = getOnlineBookings(dineInBookingsCompare);
  const uniqueOfflineDineInBookings = getOfflineBookings(uniqueDineInBookings);
  const uniqueCompareOfflineDineInBookings = getOfflineBookings(dineInBookingsCompare);

  const dineInGuests = getTotalGuests(uniqueDineInBookings);

  const onlineDineInBookings = getOnlineBookings(dineInBookings);
  const onlineDineInBookingsCompare = getOnlineBookings(dineInBookingsCompare);

  const dineInOnlineRevenueBeforeOffer = getOnlineRevenueBeforeOffer(onlineDineInBookings);
  const dineInOnlineRevenueBeforeOfferCompare = getOnlineRevenueBeforeOffer(
    onlineDineInBookingsCompare,
  );
  const dineInOnlineRevenueAfterOffer = getOnlineRevenueAfterOffer(onlineDineInBookings);
  const dineInOnlineRevenueAfterOfferCompare = getOnlineRevenueAfterOffer(
    onlineDineInBookingsCompare,
  );

  // dine-in online aov
  const dineInAOV = safeDivision(dineInOnlineRevenueBeforeOffer, uniqueOnlineDineInBookings.length);
  const dineInAOVCompare = safeDivision(
    dineInOnlineRevenueBeforeOfferCompare,
    uniqueCompareOnlineDineInBookings.length,
  );

  const dineInOnlineFees = getOnlineFeesAfterOffer(onlineDineInBookings);
  const dineInOnlineFeesCompare = getOnlineFeesAfterOffer(onlineDineInBookingsCompare);

  // The AOVs to use for a mix of online and offline bookings
  const dineInOnlineAOVForOffline = averageBookingValue || dineInAOV;
  const dineInOnlineAOVForOfflineCompare = averageBookingValue || dineInAOVCompare;

  const dineInRevenueBeforeOffer =
    dineInOnlineRevenueBeforeOffer + uniqueOfflineDineInBookings.length * dineInOnlineAOVForOffline;
  const dineInRevenueBeforeOfferCompare =
    dineInOnlineRevenueBeforeOfferCompare +
    uniqueCompareOfflineDineInBookings.length * dineInOnlineAOVForOfflineCompare;

  const dineInRevenueAfterOffer =
    dineInOnlineRevenueAfterOffer +
    getOfflineRevenueAfterOffer(uniqueOfflineDineInBookings, dineInOnlineAOVForOffline);
  const dineInRevenueAfterOfferCompare =
    dineInOnlineRevenueAfterOfferCompare +
    getOfflineRevenueAfterOffer(
      uniqueCompareOfflineDineInBookings,
      dineInOnlineAOVForOfflineCompare,
    );

  // Offline only stats
  // Note: Revenue is calculated across all bookings, including multi tap, but the AOV is applied to unique bookings
  const dineInOfflineAOV = averageBookingValue;
  const dineInOfflineRevenueBeforeOffer = dineInOfflineAOV * uniqueOnlineDineInBookings.length; // TODO I think this is wrong. Check with allen
  const dineInOfflineRevenueBeforeOfferCompare =
    dineInOfflineAOV * uniqueCompareOnlineDineInBookings.length;
  const dineInOfflineRevenueAfterOffer = getOfflineRevenueAfterOffer(
    uniqueDineInBookings,
    dineInOfflineAOV,
  );
  const dineInOfflineRevenueAfterOfferCompare = getOfflineRevenueAfterOffer(
    uniqueCompareDineInBookings,
    dineInOfflineAOV,
  );

  // Takeaway
  const takeawayBookings = getTakeawayBookings(bookingsData);
  const takeawayBookingsCompare = getTakeawayBookings(compareBookingsData);

  // const uniqueTakeawayBookings = uniqueObjectsByProperty(takeawayBookings, 'dealId');
  // const uniqueCompareTakeawayBookings = uniqueObjectsByProperty(takeawayBookingsCompare, 'dealId');

  const uniqueTakeawayBookings = takeawayBookings;
  const uniqueCompareTakeawayBookings = takeawayBookingsCompare;
  const uniqueOnlineTakeawayBookings = getOnlineBookings(takeawayBookings);
  const uniqueCompareOnlineTakeawayBookings = getOnlineBookings(takeawayBookingsCompare);
  const uniqueOfflineTakeawayBookings = getOfflineBookings(uniqueTakeawayBookings);
  const uniqueCompareOfflineTakeawayBookings = getOfflineBookings(takeawayBookingsCompare);

  const takeawayGuests = getTotalGuests(uniqueTakeawayBookings);

  const onlineTakeawayBookings = getOnlineBookings(takeawayBookings);
  const onlineTakeawayBookingsCompare = getOnlineBookings(takeawayBookingsCompare);

  const takeawayOnlineRevenueBeforeOffer = getOnlineRevenueBeforeOffer(onlineTakeawayBookings);
  const takeawayOnlineRevenueBeforeOfferCompare = getOnlineRevenueBeforeOffer(
    onlineTakeawayBookingsCompare,
  );
  const takeawayOnlineRevenueAfterOffer = getOnlineRevenueAfterOffer(onlineTakeawayBookings);
  const takeawayOnlineRevenueAfterOfferCompare = getOnlineRevenueAfterOffer(
    onlineTakeawayBookingsCompare,
  );

  const takeawayOnlineFees = getOnlineFeesAfterOffer(onlineTakeawayBookings);
  const takeawayOnlineFeesCompare = getOnlineFeesAfterOffer(onlineTakeawayBookingsCompare);

  const takeawayAOV = safeDivision(
    takeawayOnlineRevenueBeforeOffer,
    uniqueOnlineTakeawayBookings.length,
  );
  const takeawayAOVCompare = safeDivision(
    takeawayOnlineRevenueBeforeOfferCompare,
    uniqueCompareOnlineTakeawayBookings.length,
  );

  // The AOVs to use for a mix of online and offline bookings
  const takeawayOnlineAOVForOffline = averageOrderValue || takeawayAOV;
  const takeawayOnlineAOVForOfflineCompare = averageOrderValue || takeawayAOVCompare;

  const takeawayRevenueBeforeOffer =
    takeawayOnlineRevenueBeforeOffer +
    uniqueOfflineTakeawayBookings.length * takeawayOnlineAOVForOffline;
  const takeawayRevenueBeforeOfferCompare =
    takeawayOnlineRevenueBeforeOfferCompare +
    uniqueCompareOfflineTakeawayBookings.length * takeawayOnlineAOVForOfflineCompare;

  const takeawayRevenueAfterOffer =
    takeawayOnlineRevenueAfterOffer +
    getOfflineRevenueAfterOffer(uniqueOfflineTakeawayBookings, takeawayOnlineAOVForOffline);
  const takeawayRevenueAfterOfferCompare =
    takeawayOnlineRevenueAfterOfferCompare +
    getOfflineRevenueAfterOffer(
      uniqueCompareOfflineTakeawayBookings,
      takeawayOnlineAOVForOfflineCompare,
    );

  // Offline only stats
  const takeawayOfflineAOV = averageOrderValue;
  const takeawayOfflineRevenueBeforeOffer = takeawayOfflineAOV * uniqueTakeawayBookings.length;
  const takeawayOfflineRevenueBeforeOfferCompare =
    takeawayOfflineAOV * uniqueCompareTakeawayBookings.length;

  const takeawayOfflineRevenueAfterOffer = getOfflineRevenueAfterOffer(
    uniqueTakeawayBookings,
    takeawayOfflineAOV,
  );
  const takeawayOfflineRevenueAfterOfferCompare = getOfflineRevenueAfterOffer(
    uniqueCompareTakeawayBookings,
    takeawayOfflineAOV,
  );

  // totals
  const totalAOV = safeDivision(
    takeawayOnlineRevenueBeforeOffer + dineInOnlineRevenueBeforeOffer,
    dealsRedeemed,
  );
  const totalAOVCompare = safeDivision(
    takeawayOnlineRevenueBeforeOfferCompare + dineInOnlineRevenueBeforeOfferCompare,
    dealsRedeemedCompare,
  );

  const revenue = bookingsData.reduce((accum, booking) => accum + parseFloat(booking?.revenue), 0);
  const fullFee = bookingsData.reduce((accum, booking) => accum + booking?.fullFee / 100, 0);

  const revenueCompare = compareBookingsData.reduce(
    (accum, booking) => accum + parseFloat(booking?.revenue),
    0,
  );
  const fullFeeCompare = compareBookingsData.reduce(
    (accum, booking) => accum + booking?.fullFee / 100,
    0,
  );

  return {
    // All
    totalGuests,
    totalGuestsCompare,
    // dealsRedeemed,
    // dealsRedeemedCompare,

    // Dine-in
    isDineInOffline: !ecPayEnabled,
    // Dine-in offline stats
    dineInOfflineAOV,
    dineInOfflineRevenueBeforeOffer,
    dineInOfflineRevenueBeforeOfferCompare,
    dineInOfflineRevenueAfterOffer,
    dineInOfflineRevenueAfterOfferCompare,

    // Dine-in online stats (ECPAY)
    dineInRevenueAfterOffer,
    dineInRevenueAfterOfferCompare,
    dineInRevenueBeforeOffer,
    dineInRevenueBeforeOfferCompare,

    dineInAOV,
    dineInAOVCompare,
    dineInGuests,

    // Takeaway
    isTakeawayOffline: !inAppOrdering,
    // Takeaway offline stats
    takeawayOfflineAOV,
    takeawayOfflineRevenueBeforeOffer,
    takeawayOfflineRevenueBeforeOfferCompare,
    takeawayOfflineRevenueAfterOffer,
    takeawayOfflineRevenueAfterOfferCompare,

    // Takeaway online stats (IAO)
    takeawayRevenueAfterOffer,
    takeawayRevenueAfterOfferCompare,
    takeawayRevenueBeforeOffer,
    takeawayRevenueBeforeOfferCompare,

    takeawayAOV,
    takeawayAOVCompare,
    takeawayGuests,

    // Totals
    totalOrders: uniqueBookingsData.length,
    totalAOV,
    totalAOVCompare,
    totalGrossRevenue: takeawayRevenueBeforeOffer + dineInRevenueBeforeOffer,
    totalGrossRevenueCompare: takeawayRevenueBeforeOfferCompare + dineInRevenueBeforeOfferCompare,
    totalFees: dineInOnlineFees + takeawayOnlineFees,

    dineInOnlineFees,
    takeawayOnlineFees,

    totalNetRevenueWithoutFees:
      takeawayRevenueAfterOffer + dineInRevenueAfterOffer - (dineInOnlineFees + takeawayOnlineFees),
    totalNetRevenueCompareWithoutFees:
      takeawayRevenueAfterOfferCompare +
      dineInRevenueAfterOfferCompare -
      (dineInOnlineFeesCompare + takeawayOnlineFeesCompare),

    netRevenue: revenue - fullFee,
    netRevenueCompare: revenueCompare - fullFeeCompare,

    // Totals of all fee values
    ecFees: bookingsData.reduce((accum, booking) => accum + booking?.ecFees, 0) / 100,
    fullFee,
    ecFloat: bookingsData.reduce((accum, booking) => accum + booking?.ecFloat, 0) / 100,
    revenue: bookingsData.reduce((accum, booking) => accum + parseFloat(booking?.revenue), 0),
    unsubsidizedTotal: bookingsData.reduce(
      (accum, booking) => accum + booking?.unsubsidizedTotal,
      0,
    ),
    origTotal: bookingsData.reduce((accum, booking) => accum + parseFloat(booking?.origTotal), 0),

    revenueCompare: compareBookingsData.reduce(
      (accum, booking) => accum + Number.parseFloat(booking?.revenue),
      0,
    ),
  };
};

/**
 * Calculate JUST the net revenue and nothing else.
 * Used in the charts for the daily breakdown.
 *
 * Basically it's a more minimal version of the above function
 *
 * @param bookingsData
 * @param compareBookingsData
 * @param averageOrderValue
 * @param averageBookingValue
 * @returns {{}}
 */
export const calculateRevenueOnly = (
  bookingsData,
  compareBookingsData,
  averageOrderValue,
  averageBookingValue,
) => {
  if (bookingsData.length === 0) {
    return 0;
  }

  // Dine-in
  const dineInBookings = getDineInBookings(bookingsData);
  const uniqueDineInBookings = dineInBookings;

  const uniqueOnlineDineInBookings = getOnlineBookings(dineInBookings);
  const uniqueOfflineDineInBookings = getOfflineBookings(uniqueDineInBookings);

  const onlineDineInBookings = getOnlineBookings(dineInBookings);

  const dineInOnlineRevenueBeforeOffer = getOnlineRevenueBeforeOffer(onlineDineInBookings);
  const dineInOnlineRevenueAfterOffer = getOnlineRevenueAfterOffer(onlineDineInBookings);

  // dine-in online aov
  const dineInAOV = safeDivision(dineInOnlineRevenueBeforeOffer, uniqueOnlineDineInBookings.length);

  // The AOVs to use for a mix of online and offline bookings
  const dineInOnlineAOVForOffline = averageBookingValue || dineInAOV;

  const dineInRevenueAfterOffer =
    dineInOnlineRevenueAfterOffer +
    getOfflineRevenueAfterOffer(uniqueOfflineDineInBookings, dineInOnlineAOVForOffline);

  // Takeaway
  const takeawayBookings = getTakeawayBookings(bookingsData);

  const uniqueTakeawayBookings = takeawayBookings;
  const uniqueOnlineTakeawayBookings = getOnlineBookings(takeawayBookings);
  const uniqueOfflineTakeawayBookings = getOfflineBookings(uniqueTakeawayBookings);

  const onlineTakeawayBookings = getOnlineBookings(takeawayBookings);

  const takeawayOnlineRevenueBeforeOffer = getOnlineRevenueBeforeOffer(onlineTakeawayBookings);
  const takeawayOnlineRevenueAfterOffer = getOnlineRevenueAfterOffer(onlineTakeawayBookings);

  const takeawayAOV = safeDivision(
    takeawayOnlineRevenueBeforeOffer,
    uniqueOnlineTakeawayBookings.length,
  );

  // The AOVs to use for a mix of online and offline bookings
  const takeawayOnlineAOVForOffline = averageOrderValue || takeawayAOV;

  const takeawayRevenueAfterOffer =
    takeawayOnlineRevenueAfterOffer +
    getOfflineRevenueAfterOffer(uniqueOfflineTakeawayBookings, takeawayOnlineAOVForOffline);

  // TODO need to test that offline bookings are included
  const revenue = bookingsData.reduce((accum, booking) => accum + parseFloat(booking?.revenue), 0);
  const fullFee = bookingsData.reduce((accum, booking) => accum + booking?.fullFee / 100, 0);

  return revenue - fullFee;
};
