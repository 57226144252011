import { isVoid } from './helpers';

/**
 * Useful for checking whether a string has any value or is empty
 * Now works with arrays too.
 * Avoids accidentally checking falsy values like 0
 * */
export const isEmpty = (item) => {
  if (item === null || item === undefined) {
    return true;
  }

  // empty object
  if (Object.getPrototypeOf(item) === Object.prototype) {
    return item && Object.keys(item).length === 0;
  }

  // empty array
  if (item.constructor === Array) {
    return item?.length === 0;
  }

  // empty string
  return item === '';
};

/**
 * Sorts two objects by a property
 */
export const sortByProperty = (a, b, property, descending = false) =>
  (a?.[property] >= b?.[property] ? 1 : -1) * (descending ? -1 : 1);

/**
 *
 * @param a
 * @param b
 * @param descending
 * @returns {number}
 */
export const sortValues = (a, b, descending = false) => {
  if (a === b) {
    return 0;
  }

  const descendingModifier = descending ? -1 : 1;

  if (!isVoid(a) && isVoid(b)) {
    return 1 * descendingModifier;
  }

  if (isVoid(a) && !isVoid(b)) {
    return -1 * descendingModifier;
  }

  if (a > b) {
    return 1 * descendingModifier;
  }

  return -1 * descendingModifier;
};

export const sortListByProperty = (list, property, descending = false) => {
  if (isEmpty(list)) {
    return [];
  }

  const newList = [...list];

  return newList.sort((a, b) => sortValues(a?.[property], b?.[property], descending));
};

/**
 * Sort a list alphanumerically. Preserves proper numerical ascension
 * Taken from https://stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array
 * See also https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
 */
export const sortAlphaNumeric = (a, b, descending = false) => {
  const descendingModifier = descending ? -1 : 1;

  // Return early if we're just comparing numbers
  if (!Number.isNaN(a) || !Number.isNaN(b)) {
    return (a > b ? 1 : -1) * descendingModifier;
  }

  if (isVoid(a)) {
    return 1 * descendingModifier;
  }

  if (isVoid(b)) {
    return -1 * descendingModifier;
  }

  const alphabetRegex = /[^a-zA-Z]/g;
  const numericRegex = /[^0-9]/g;

  const aAlpha = a.replace(alphabetRegex, '');
  const bAlpha = b.replace(alphabetRegex, '');

  if (aAlpha === bAlpha) {
    const aNumeric = parseInt(a.replace(numericRegex, ''), 10);
    const bNumeric = parseInt(b.replace(numericRegex, ''), 10);
    // eslint-disable-next-line no-nested-ternary
    return aNumeric === bNumeric ? 0 : (aNumeric > bNumeric ? 1 : -1) * descendingModifier;
  }

  return (aAlpha > bAlpha ? 1 : -1) * descendingModifier;
};

/**
 * Sort a list by a property alphanumerically. Preserves proper numerical ascension
 */
export const sortAlphaNumericByProperty = (a, b, property, descending = false) => {
  return sortAlphaNumeric(a?.[property] || '', b?.[property] || '', descending);
};

export const sortListNaturalByProperty = (list, property, descending) => {
  return [...list]?.sort((a, b) => sortAlphaNumericByProperty(a, b, property, descending));
};
