import { API } from 'aws-amplify';
import {
  editOffer,
  disableOfferForDate,
  deleteOffer,
  reenableOfferForDate,
  deleteAllOffers,
  createOffer,
} from '../graphql/mutations';
import { formatTimeFromInt, getAppVersion } from '../utils/helpers';
import * as type from './types';
import { offersForGoal } from '../graphql/queries';
import { devLog } from '../utils';
import { createAsyncAction } from '../lib/react-redux';
import { makeApiAction } from './actions';

export const fetchOffersForGoalAction = (restaurantId, goalId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `OFFERS_FOR_GOAL_${goalId}`,
  });

  dispatch({
    type: type.FETCH_OFFERS_FOR_GOAL_PENDING,
  });

  (async () => {
    try {
      const offersResponse = await API.graphql(
        {
          query: offersForGoal,
          variables: {
            restId: restaurantId,
            goalId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restaurantId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const {
        data: { offersForGoal: offers },
      } = offersResponse;

      devLog('success', 'offers for goal', offers);

      dispatch({
        type: type.FETCH_OFFERS_FOR_GOAL_SUCCESS,
        payload: { offers },
      });
    } catch (error) {
      devLog('error', 'offers for goal', error);

      dispatch({
        type: type.FETCH_OFFERS_FOR_GOAL_FAILURE,
        payload: `Unable to retrieve offers for goal: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `OFFERS_FOR_GOAL_${goalId}`,
      });
    }
  })();
};

export const editOfferAction =
  (
    dealIds,
    // lightning,
    // discount,
    dineInQty,
    takeawayQty,
    startTime,
    endTime,
    dineIn,
    takeaway,
    goalId,
    goalOfferId,
    reason,
    reasonMessage,
    allOccurrences,
    revenueChange,
    customersLower,
    customersUpper,
    userId,
    userType,
    restaurantId,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.EDIT_OFFER_PENDING,
    });

    (async () => {
      try {
        const offersResponse = await API.graphql(
          {
            query: editOffer,
            variables: {
              dealIds,
              // lightning,
              // discount,
              dineInQty,
              takeawayQty,
              startTime,
              endTime,
              dineIn,
              takeaway,
              goalId,
              goalOfferId,
              reason,
              reasonMessage,
              allOccurrences,
              revenueChange,
              customersLower,
              customersUpper,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restaurantId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { offers },
        } = offersResponse;

        devLog('success', 'offers', offers);

        dispatch({
          type: type.EDIT_OFFER_SUCCESS,
          payload: { offers },
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `EDIT_OFFER_SUCCESS_${new Date().getTime()}`,
            message: `Success, the offer has been updated`,
            severity: 'success',
          },
        });
      } catch (error) {
        devLog('error', 'edit offer', error);

        dispatch({
          type: type.EDIT_OFFER_FAILURE,
          payload: `Unable to edit offer: ${error}`,
        });
      }
    })();
  };

export const disableOfferForDateAction =
  (
    dealIds,
    date,
    goalId,
    goalOfferId,
    reason,
    reasonMessage,
    internalId,
    restaurantId,
    userId,
    userType,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.DISABLE_OFFER_FOR_DATE_PENDING,
    });

    (async () => {
      try {
        const offersResponse = await API.graphql(
          {
            query: disableOfferForDate,
            variables: {
              dealIds,
              date,
              goalId,
              goalOfferId,
              reason,
              reasonMessage,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restaurantId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const { data } = offersResponse;

        devLog('success', 'disable offer for date', data);

        dispatch({
          type: type.DISABLE_OFFER_FOR_DATE_SUCCESS,
          payload: { offerId: internalId, dealIds, changedOffer: data?.disableOfferForDate },
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `DISABLE_OFFER_FOR_DATE_SUCCESS_${new Date().getTime()}`,
            message: `Success, the offer has been disabled`,
            severity: 'success',
          },
        });
      } catch (error) {
        devLog('error', 'disable offer for date', error);

        dispatch({
          type: type.DISABLE_OFFER_FOR_DATE_FAILURE,
          payload: `Unable to disable offer for date: ${error}`,
        });
      }
    })();
  };

export const deleteOfferAction =
  (
    dealIds,
    goalId,
    goalOfferId,
    reason,
    reasonMessage,
    internalId,
    restaurantId,
    userId,
    userType,
    allOccurrences,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.DELETE_OFFER_PENDING,
    });

    (async () => {
      try {
        const offersResponse = await API.graphql(
          {
            query: deleteOffer,
            variables: {
              dealIds,
              goalId,
              goalOfferId,
              reason,
              reasonMessage,
              allOccurrences,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restaurantId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const { data } = offersResponse;

        devLog('success', 'delete offer', data);

        dispatch({
          type: type.DELETE_OFFER_SUCCESS,
          payload: { offerId: internalId },
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `DELETE_OFFER_FOR_DATE_SUCCESS_${new Date().getTime()}`,
            message: `Success, the offer has been deleted`,
            severity: 'success',
          },
        });
      } catch (error) {
        devLog('error', 'delete offer', error);

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `DELETE_OFFER_FOR_DATE_ERROR_${new Date().getTime()}`,
            message: `${error}`,
            severity: 'error',
          },
        });

        dispatch({
          type: type.DELETE_OFFER_FAILURE,
          payload: `Unable to delete offer: ${error}`,
        });
      }
    })();
  };

export const deleteAllOffersAction = () =>
  createAsyncAction(
    'DELETE_ALL_OFFERS',
    deleteAllOffers,
    {},
    'deleteAllOffers',
    'Success, all offers have been deleted',
    true,
    true,
  );

export const reEnableOfferForDateAction =
  (dealIds, date, internalId, restaurantId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.RE_ENABLE_OFFER_FOR_DATE_PENDING,
    });

    (async () => {
      try {
        const offersResponse = await API.graphql(
          {
            query: reenableOfferForDate,
            variables: {
              dealIds,
              date,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restaurantId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const { data } = offersResponse;

        devLog('success', 're-enable offer for date', data);

        dispatch({
          type: type.RE_ENABLE_OFFER_FOR_DATE_SUCCESS,
          payload: { offerId: internalId },
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `RE_ENABLE_OFFER_FOR_DATE_SUCCESS_${new Date().getTime()}`,
            message: `Success, the offer has been enabled`,
            severity: 'success',
          },
        });
      } catch (error) {
        devLog('error', 're-enable offer for date', error);

        dispatch({
          type: type.RE_ENABLE_OFFER_FOR_DATE_FAILURE,
          payload: `Unable to re-enable offer for date: ${error}`,
        });
      }
    })();
  };

export const createOfferAction =
  (
    discount,
    lightning,
    dineIn,
    takeaway,
    drinksOnly,
    startTime,
    endTime,
    startDate,
    recurring,
    recurringDays,
    dineInQty,
    takeawayQty,
  ) =>
  (dispatch) => {
    const action = 'CREATE_OFFER';

    const variables = {
      discount,
      lightning,
      dineIn,
      takeaway,
      drinksOnly,
      startTime,
      endTime,
      startDate,
      recurring,
      recurringDays,
      dineInQty,
      takeawayQty,
    };

    const startTimeReadable = formatTimeFromInt(startTime);
    const endTimeReadable = formatTimeFromInt(endTime);

    dispatch(
      makeApiAction(
        action,
        createOffer,
        variables,
        {},
        'createOffer',
        true,
        dispatch,
        `Success, offer for ${discount} ${
          !lightning ? 'all day' : `at ${startTimeReadable}-${endTimeReadable}`
        } has been created`,
        null,
        false,
        `Unable to create offer for ${discount} ${
          !lightning ? 'all day' : `at ${startTimeReadable}-${endTimeReadable}`
        }`,
      ),
    );
  };
