/**
 * Formats the `minutes` to time. Eg, 600 > 6am.
 *
 * @param minutes Number of minutes
 * @param format Time format, default 'h:mm a'
 */
import dayjs from 'dayjs';

export const minutesToDayjs = (minutes) => {
  if (minutes === null || minutes === undefined) {
    return dayjs().startOf('day');
  }

  return dayjs().startOf('day').add(minutes, 'minutes');
};

/**
 * @deprecated - use timeFromInt
 * @param minutes
 * @param format
 * @returns {string}
 */
export const minutesToTime = (minutes, format = 'h:mm a') => minutesToDayjs(minutes).format(format);
