import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, ListSubheader } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFilterAction } from '../../actions/transactionsAction';
import { trackEvent } from '../../utils/analytics';

const TypePicker = ({ filter, setFilter }) => {
  const types = [
    { value: 'all', label: 'All' },
    { value: 'inAppOrder', label: 'In-App Order' },
    { value: 'dineIn', label: 'Dine-In' },
    { value: 'takeAway', label: 'Takeaway' },
    { value: 'tableOrder', label: 'Table Order' },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = (newValue) => {
    trackEvent('button_click: set_filter');

    setFilter(newValue);
    handleCloseDropdown();
  };

  return (
    <>
      <Button onClick={handleOpenDropdown} color='secondary' variant='outlined'>
        Filter type: {types.find((typeToFind) => typeToFind.value === filter)?.label ?? 'All'}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseDropdown}>
        <ListSubheader
          component='li'
          style={{
            outline: 0,
            lineHeight: 1.5,
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
        >
          Filter Type
        </ListSubheader>
        {types.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleSelectMenu(option.value)}
            selected={filter === option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

TypePicker.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.transactions.filter,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setFilter: setFilterAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TypePicker);
