import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CenteredContent, TextArea, Dropdown } from '@eatclub-apps/ec-component-library';
import { useSelector } from 'react-redux';
import { dealPropTypes } from '../../../data/models/Deal';
import { goalPropTypes } from '../../../data/models/Goal';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, getDayFromInt, minutesToTimeString } from '../../../utils/helpers';
import useStyles from './ModalStyles';
import ModalSectionHeader from './ModalSectionHeader';
import { Modal } from '../../Modal';

const EditOfferModal = ({
  isOpen,
  offer,
  onClose,
  onSave,
  goal = null,
  percentage,
  dayOfWeek,
  period,
  startTime,
  endTime,
  allOccurrences,
  occurrencesLeft,
  submitting,
}) => {
  const classes = useStyles();

  const predict = useSelector((state) => state.dealPredict);

  const { data: prediction } = predict;

  const [reason, setReason] = useState(null);
  const [additionalReason, setAdditionalReason] = useState(null);

  const calculateRevenue = () =>
    allOccurrences ? prediction?.targetVal * occurrencesLeft : prediction?.targetVal;
  const calculateExpectedRevenue = () =>
    allOccurrences ? offer?.revenueExpected * occurrencesLeft : offer?.revenueExpected;
  const changeAmount = calculateRevenue() - calculateExpectedRevenue();

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: edit_offer_modal');
    }
  }, [isOpen]);

  const getRevenueChange = () => {
    let type;
    if (changeAmount >= 0) {
      type = 'Increase';
    } else {
      type = 'Decrease';
    }

    const increase = type === 'Increase';

    return (
      <Box className={classes.impactRow}>
        <Box>{type} in revenue goal</Box>
        <Box className={increase ? classes.greenLabel : classes.redLabel}>
          {increase && '+'}
          {formatCurrency(changeAmount, false)}
        </Box>
      </Box>
    );
  };

  const belongsToGoal = !!offer?.goalId;

  const confirm = () => {
    trackEvent('button_click: confirm_edit_offer');
    onSave(
      reason,
      additionalReason,
      belongsToGoal ? Math.round(prediction?.targetVal - offer?.revenueExpected) : 0,
      belongsToGoal ? prediction?.partySizeLower : 0,
      belongsToGoal ? prediction?.partySizeUpper : 0,
    );
  };

  return (
    <Modal
      confirmLabel='Save changes'
      heading='Confirm changes'
      isLoading={submitting}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirm}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Box>
          <Box className={classes.inlineText}>
            <Box className={classes.discountLabel}>{percentage}</Box>
            <CenteredContent>
              on {getDayFromInt(dayOfWeek)}{' '}
              {period === 'allDay'
                ? 'all day'
                : `from ${minutesToTimeString(startTime)} - ${minutesToTimeString(endTime)}`}
            </CenteredContent>
          </Box>
        </Box>
        {belongsToGoal && (
          <Box>
            <ModalSectionHeader header='Impact' />
            <Box className={classes.radioButtonContainer}>
              {getRevenueChange()}
              <Box className={classes.impactRow}>
                <Box>Adjusted revenue goal</Box>
                <Box className={classes.impactCurrency}>
                  {formatCurrency(goal?.targetRevenue + changeAmount, false)}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box mt='20px' display='flex' flexDirection='column' style={{ gap: '15px' }}>
          <ModalSectionHeader header='Let us know why' />

          <Dropdown
            allowDeselect
            items={[
              { label: 'Restaurant became busy', value: 'busy' },
              { label: 'Staffing issue', value: 'staffIssue' },
              { label: "Time isn't suitable", value: 'badTime' },
              { label: 'Offer percentage too high', value: 'offerTooHigh' },
              { label: 'Other', value: 'other' },
            ]}
            onSelect={setReason}
            placeholder='Select a reason for editing the offer'
            value={reason}
          />

          <TextArea
            onChange={setAdditionalReason}
            placeholder='Additional Reason?'
            value={additionalReason}
          />
        </Box>
      </Box>
    </Modal>
  );
};

EditOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  goal: goalPropTypes,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.string.isRequired,
  dayOfWeek: PropTypes.number.isRequired,
  period: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  allOccurrences: PropTypes.bool.isRequired,
  occurrencesLeft: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default EditOfferModal;
