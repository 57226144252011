import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Skeleton } from '@mui/material';
import { connect } from 'react-redux';
import { Box, Divider, Spacer, Button } from '@eatclub-apps/ec-component-library';
import useStyles from './CreateOfferStyles';
import CustomersSmall from '../../../assets/icon_customers_small.svg';
import RevenueSmall from '../../../assets/icon_revenue_small.svg';
import { formatCurrency, isEmpty, isStaffUser } from '../../../utils/helpers';
import Slider from '../Slider/Slider';
import { newOfferProps } from '../../../data/models/Offer';
import { fetchPredictionsForOffersAction } from '../../../actions/predictionsForOffersAction';
import { restaurantPropTypes } from '../../../data/models/Restaurant';
import { dayjsAsMinutes } from '../../../utils';

const Pill = ({ icon, label, bkgColor }) => {
  return (
    <Spacer
      style={{
        backgroundColor: bkgColor,
        padding: '8px',
        borderRadius: '100px',
        boxShadow: '0px 2px 4px #CBD8C8',
      }}
    >
      {icon}
      <Box style={{ color: '#6a6a6a', fontSize: '16px', fontWeight: 500 }}>{label}</Box>
    </Spacer>
  );
};

const PredictionCard = ({ prediction, selected, onSelect }) => {
  return (
    <Box style={{ userSelect: 'none' }} onClick={() => onSelect(prediction)}>
      <Spacer
        direction='vertical'
        gap='m'
        style={{
          padding: '8px',
          borderRadius: '8px',
          border: '1px solid #AEAEAE',
          width: '120px',
          height: '200px',
          borderColor: selected ? '#313131' : '#aeaeae',
          backgroundColor: selected && '#FAFBEE',
          transition: 'border-color 0.1s, background-color 0.1s',
        }}
      >
        <Spacer direction='vertical'>
          <Box style={{ fontWeight: 900, fontSize: '18px', padding: '4px 4px 0 4px' }}>
            {`${prediction.discount}%`}
          </Box>
          <Divider />
        </Spacer>

        <Spacer direction='vertical'>
          <Pill
            icon={<CustomersSmall />}
            label={`${prediction.partySizeLower}-${prediction.partySizeUpper}`}
            bkgColor='#F9F3DB'
          />
          <Pill
            icon={<RevenueSmall />}
            label={formatCurrency(prediction.revenueExpected, false)}
            bkgColor='#EBF0EA'
          />
        </Spacer>

        <Box
          style={{
            height: '30px',
            backgroundColor: '#ffffff',
            border: '1px solid #AEAEAE',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
            letterSpacing: '2px',
            fontWeight: 500,
            cursor: 'pointer',
          }}
          onClick={() => onSelect(prediction)}
        >
          {selected ? 'SELECTED' : 'SELECT'}
        </Box>
      </Spacer>
    </Box>
  );
};

const OfferAmount = ({
  newOffer,
  setNewOffer,
  fetchPredictionsForOffers,
  restaurant,
  predictionsForOffers,
  user,
}) => {
  const classes = useStyles();
  const [allIncrements, setAllIncrements] = useState(false);

  const handleChange = (prediction) => {
    setNewOffer({
      ...newOffer,
      offerAmount: { ...newOffer.offerAmount, value: prediction },
    });
  };

  const defaultIncrements = [20, 30, 40, 50];
  const completeIncrements = [20, 25, 30, 35, 40, 45, 50];
  const dealIncrements = [
    ...(isStaffUser(user) ? [15] : []),
    ...(allIncrements ? completeIncrements : defaultIncrements),
  ];

  useEffect(() => {
    handleChange(undefined);

    fetchPredictionsForOffers(
      restaurant.objectId,
      dealIncrements,
      newOffer.whatTimes.value.lightning,
      newOffer.service.value.dineIn,
      newOffer.service.value.takeaway,
      newOffer.service.value.drinksOnly,
      newOffer.whatTimes.value.lightning ? dayjsAsMinutes(newOffer.whatTimes.value.startTime) : 0,
      newOffer.whatTimes.value.lightning ? dayjsAsMinutes(newOffer.whatTimes.value.endTime) : 1439,
      newOffer.date.value.startDate.format('YYYY-MM-DD'),
      newOffer.date.value.recurring
        ? JSON.stringify(
            Object.entries(newOffer.date.value.recurringDays)
              .map(([label, value]) => value && label)
              .filter((v) => v),
          )
        : null,
    );
  }, [
    restaurant.objectId,
    newOffer?.whatTimes?.value?.lightning,
    newOffer.service.value.dineIn,
    newOffer.service.value.takeaway,
    newOffer.service.value.drinksOnly,
    newOffer.whatTimes.value.startTime,
    newOffer.whatTimes.value.endTime,
    newOffer.date.value.startDate,
    newOffer.date.value.recurring,
    newOffer.date.value.recurringDays,
    allIncrements,
  ]);

  return (
    <Spacer direction='vertical' style={{ maxWidth: '632px' }}>
      <Spacer>
        {predictionsForOffers.pending &&
          [...Array(5).keys()].map(() => (
            <Skeleton width={120} height={200} variant='rect' style={{ borderRadius: '8px' }} />
          ))}
      </Spacer>

      {predictionsForOffers.success && isEmpty(predictionsForOffers.data) && (
        <Box>Unable to make predictions for offers</Box>
      )}

      {predictionsForOffers.success && !isEmpty(predictionsForOffers.data) && (
        <Slider
          style={{ height: '200px', overflowX: 'scroll' }}
          defaultPositionRight
          items={(predictionsForOffers.data ?? []).map((prediction) => ({
            key: prediction.discount,
            component: (
              <PredictionCard
                prediction={prediction}
                selected={newOffer.offerAmount.value?.discount === prediction.discount}
                onSelect={handleChange}
              />
            ),
          }))}
        />
      )}

      {!allIncrements && (
        <Button
          type='text'
          onClick={() => {
            setAllIncrements(true);
          }}
          style={{
            button: {
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
            },
          }}
        >
          Show all offers
        </Button>
      )}
    </Spacer>
  );
};

OfferAmount.propTypes = {
  newOffer: newOfferProps.isRequired,
  setNewOffer: PropTypes.func.isRequired,
  fetchPredictionsForOffers: PropTypes.func.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  predictionsForOffers: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurantActive.restaurant,
  predictionsForOffers: state.predictionsForOffers,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPredictionsForOffers: fetchPredictionsForOffersAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OfferAmount);
