import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
  },
  overviewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '30px',
  },
  overviewItem: {
    display: 'flex',
    gap: '16px',
  },
  overviewText: {
    fontSize: '20px',
    maxWidth: '205px',
  },
  highlightsSectionItems: {
    display: 'flex',
    columnGap: '14px',
    flexWrap: 'wrap',
    rowGap: '32px',
  },
  highlightItemsHeader: {
    fontSize: '34px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    marginBottom: '63px',
  },
  highlightsSectionContainer: {
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
    rowGap: '50px',
  },
  highlightsSectionHeader: {
    fontSize: FONT_SIZES.H3,
    maxWidth: '260px',
    marginRight: 'auto',
  },
  highlightItemContainer: {
    border: `1px solid ${COLORS.LINEN}`,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    padding: '36px 20px',
    position: 'relative',
  },
  highlightItemIcon: {
    alignItems: 'center',
    backgroundColor: COLORS.LINEN,
    borderRadius: '8px',
    color: 'white',
    display: 'flex',
    height: '50px',
    justifyContent: 'center',
    position: 'absolute',
    top: '-25px',
    width: '50px',
  },
  highlightItemHeader: {
    fontSize: '18px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    marginBottom: '8px',
  },
  highlightItemContent: {
    fontSize: '15px',
    width: '170px',
  },
  requestAccessFooter: {
    alignItems: 'center',
    background: COLORS.WHITE,
    bottom: '0',
    boxShadow: '0px -2px 7px rgba(212, 212, 212, 0.5)',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: FONT_SIZES.H4,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    justifyContent: 'space-between',
    left: '313px',
    padding: '20px 72px',
    position: 'fixed',
    right: '0',
    [theme.breakpoints.down('md')]: {
      fontSize: FONT_SIZES.H5,
    },
    [theme.breakpoints.down('sm')]: {
      left: '0',
    },
  },
  plansContainer: {
    display: 'flex',
    gap: '22px',
    justifyContent: 'center',
    marginBottom: '64px',
    flexWrap: 'wrap',
  },
  planCardContainer: {
    borderRadius: '32px',
    boxShadow: '0px 4px 4px 0px #DADADA40',
    width: '440px',
    background: '#fff',
  },
  planCardHeaderContainer: {
    alignItems: 'center',
    background: '#F5F2ED',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 40px',
    flexWrap: 'wrap',
    rowGap: '12px',
    borderRadius: '12px 12px 0 0',
  },
  planCardHeader: {
    fontSize: '28px',
  },
  planCardHeaderPrice: {
    fontSize: '38px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  planCardContentContainer: {
    padding: '20px',
  },
  planCardContentItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px',
    minHeight: '152px',
  },
  planCardContentItemText: {
    fontSize: FONT_SIZES.H4,
  },
  planCardContentItem: {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
  },
  planCardPlusButton: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    rowGap: '20px',
  },
  introducingText: {
    fontSize: '13px',
    letterSpacing: '0.32em',
    marginBottom: '26px',
  },
  pageTitleHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pageTitleHeader: {
    maxWidth: '330px',
    fontSize: '64px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    lineHeight: '66.5px',
  },
  pageTitleBlurb: {
    maxWidth: '388px',
    fontSize: '20px',
  },
  iconBackground: {
    alignItems: 'center',
    background: 'rgba(232, 162, 99, 0.4)',
    borderRadius: '100%',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    width: '32px',
  },
  modalHeaderContainer: {
    display: 'flex',
    gap: '18px',
    marginBottom: '30px',
    alignItems: 'center',
  },
  modalHeader: {
    fontSize: '24px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  modalFieldContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalFieldLabel: {
    color: COLORS.NIMBUS,
    fontSize: '15px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  modalImageContainer: {
    alignItems: 'center',
    background: COLORS.LINEN,
    borderRadius: '6px',
    display: 'flex',
    height: '250px',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  modalContentContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginBottom: '30px',
    maxWidth: '530px',
    textAlign: 'center',
  },
  modalContentThankYou: {
    fontSize: FONT_SIZES.H3,
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  modalContentRequestReceived: {
    fontSize: FONT_SIZES.H4,
  },
  image: {
    opacity: '0',
  },
  fadeIn: {
    opacity: '1',
    transition: 'opacity 0.25s',
  },
  slugContainer: {
    background: '#EFEFEF',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '12px',
    marginTop: '12px',
  },
  urlHeading: {
    fontSize: '18px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoIcon: {
    color: COLORS.SMOKE,
    transition: '0.1s color',
    '&:hover': {
      cursor: 'pointer',
      color: COLORS.CHARCOAL,
    },
  },
  smallText: {
    color: COLORS.NIMBUS,
    fontSize: '15px',
    marginTop: '12px',
    textAlign: 'center',
  },
  requestAccessFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    fontSize: '15px',
  },
  agreeToTermsContainer: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  link: {
    color: 'inherit',
  },
}));

export default useStyles;
