import React from 'react';
import { Typography, Button, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { usePageViews } from '../../utils/analytics';

const NotFound = () => {
  usePageViews();
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
        We couldn&apos;t find the page you were looking for.
        <br />
        Please check the URL or contact our{' '}
        <MuiLink component={Link} to='/support' color='secondary'>
          Support Team
        </MuiLink>
        .
      </Typography>
      <Button variant='outlined' color='secondary' component={Link} to='/'>
        Go back to the Dashboard
      </Button>
    </div>
  );
};

export default NotFound;
