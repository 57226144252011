import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTheme } from '@mui/material/styles';
import { Box } from '@eatclub-apps/ec-component-library';
import { apiCall } from '../../../actions/actions';
import { restaurantsCustomerDatasetStats } from '../../../graphql/queries';
import { formatNumber } from '../../../utils';
import { formatCompactNumber, roundToNearest } from '../../../utils/helpers';
import { getComparisonMessage, getTimePeriodMessage } from '../../../utils/insights/insightHelpers';
import { sortAlphaNumeric } from '../../../utils/listHelpers';
import TextDropdown from './Dropdown/TextDropdown';
import MarketComparisons from './MarketComparisons/MarketComparisons';
import useStyles, { getInsightTabColors, INSIGHT_TAB } from './PillarModalStyles';
import { PillarModalRowItem } from './PillarModalRowItem';
import { PillarModalSummary } from './PillarModalSummary';

const CustomersPillarModalContent = ({
  data,
  dates,
  setFilter,
  filter,
  dateRange,
  setDateRange,
}) => {
  const tab = INSIGHT_TAB.CUSTOMERS;
  const classes = useStyles({ tab });
  const theme = useTheme();

  const [loadingData, setLoadingData] = useState(true);
  const [comparisonData, setComparisonData] = useState({});

  const totalGuests = data.current.totalGuests;

  const dateRangeOptions = [
    { label: 'Since all time', value: 'allTime' },
    { label: getTimePeriodMessage(dates.startDate, dates.endDate), value: 'dateRange' },
  ];

  // Fetch comparison data
  useEffect(() => {
    const fetchComparisonData = async () => {
      setLoadingData(true);

      const { startDate, endDate } = dates;
      const formatStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formatEndDate = dayjs(endDate).format('YYYY-MM-DD');

      const variables = {
        startDate: formatStartDate,
        endDate: formatEndDate,
        filters: filter !== 'all' ? [filter] : [],
      };
      const response = await apiCall(restaurantsCustomerDatasetStats, variables, true);
      const responseData = response.response.data.restaurantsCustomerDatasetStats;

      setLoadingData(false);
      setComparisonData({
        low: roundToNearest(responseData?.low ?? 0),
        mean: roundToNearest(responseData?.mean ?? 0),
        high: roundToNearest(responseData?.high ?? 0),
        dataset: [...(responseData?.dataset ?? [])].sort(sortAlphaNumeric),
      });
    };

    try {
      fetchComparisonData();
    } catch (e) {
      setLoadingData(false);
    }
  }, [dates, filter]);

  return (
    <>
      <Box className={classes.sectionContainer}>
        <PillarModalSummary
          heading='A vibrant ambience that'
          summary={`Customers are the lifeblood of a great restaurant. Attracting customers at times when you have additional capacity can transform your venue. Filling the early bird session, ensuring window seats are always filled or extending your peak service is a great way to leverage spare capacity, earn revenue and create a vibrant ambience in your venue. 

There are also flow-on benefits to these customers. Filled window seats can further attract walk-ins, a vibrant ambience can increase average order values and steady stream of online positive reviews can connect with new online digital audiences. Tapping into herd mentality can create a powerful ripple effect of benefits for your venue.`}
          maxCharacters={171}
        >
          <Box className={classes.sectionBreakdownContainer}>
            {/* <TextDropdown items={dateRangeOptions} value={dateRange} onSelect={setDateRange} /> */}

            <Box className={classes.sectionBreakdownRowItemsContainer}>
              <PillarModalRowItem
                label='Dine-in customers'
                value={formatNumber(data.current.dineInGuests, 0)}
                variant='outlined'
              />
              <PillarModalRowItem
                label='Takeaway customers'
                value={formatNumber(data.current.takeawayGuests, 0)}
                variant='outlined'
              />
              <PillarModalRowItem
                label='Total Customers'
                value={formatNumber(data.current.totalGuests, 0)}
                variant='filled'
                color={tab}
              />
            </Box>
          </Box>
        </PillarModalSummary>
      </Box>

      <Divider className={classes.divider} />

      <MarketComparisons
        description={getComparisonMessage(totalGuests, comparisonData)}
        comparisonData={comparisonData}
        total={totalGuests}
        colors={getInsightTabColors(INSIGHT_TAB.CUSTOMERS, theme)}
        loading={loadingData}
        format={(value) => formatCompactNumber(value)}
        setFilter={setFilter}
        filter={filter}
      />
    </>
  );
};

CustomersPillarModalContent.propTypes = {
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPillarModalContent);
