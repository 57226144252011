import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteGoalAction } from '../../../actions/goalsAction';
import { trackEvent } from '../../../utils/analytics';
import { Modal } from '../../Modal';

const DeleteGoalModal = ({ isOpen, onClose, onSave = () => {}, goalId }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const save = () => {
    trackEvent('button_click: confirm_delete_goal');
    setSubmitting(true);
    dispatch(deleteGoalAction(goalId))
      .then(onSave)
      .finally(() => setSubmitting(false));
  };

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: delete_goal');
    }
  }, [isOpen]);

  return (
    <Modal
      confirmLabel='Delete goal'
      heading='Delete goal'
      isLoading={submitting}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={save}
    >
      <Box>Are you sure you want to delete this goal?</Box>
    </Modal>
  );
};

DeleteGoalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  goalId: PropTypes.string.isRequired,
};

export default DeleteGoalModal;
