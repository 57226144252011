import PropTypes from 'prop-types';
import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { formatCurrency, pluralise } from '../../../utils/helpers';
import ActionMenu from '../ActionMenu/ActionMenu';
import SmallCheck from '../../../assets/small_check.svg';
import useStyles from './OfferCardStyles';
import { Text } from '../../Text';

/**
 * A more basic version of the other offer card, used only for predictions
 */
const PredictedOfferCard = ({ offer, deleteOffer, editOffer, occurrences = 0 }) => {
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const classes = useStyles();

  const getBookingLabel = () => {
    if (offer?.partySizeLower === offer?.partySizeUpper) {
      return Math.max(1, offer?.partySizeMonthlyLower);
    }
    return `${Math.max(1, offer?.partySizeMonthlyLower)} - ${Math.max(
      2,
      offer?.partySizeUpper * occurrences,
    )}`;
  };

  const actions = [
    {
      name: 'Edit offer',
      type: 'edit',
      onClick: () => editOffer(offer),
      visible: true,
    },
    {
      name: 'Remove offer',
      type: 'delete',
      onClick: () => deleteOffer(offer),
      visible: true,
    },
  ];

  const hasDineIn = () => !offer?.takeawayOnly;
  const hasTakeaway = () => !offer?.dineInOnly;

  const getCustomers = () => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Text size='sm'>Customers</Text>
        <Text size='sm'>
          {getBookingLabel()}{' '}
          <Text size='xs' style={{ color: '#7C7C7C' }}>
            Predicted
          </Text>
        </Text>
      </Box>
    );
  };

  const getRevenue = () => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Text size='sm'>Revenue</Text>
        <Text size='sm'>
          {formatCurrency(Math.round(offer?.targetValMonthly), false)}{' '}
          <Text size='xs' style={{ color: '#7C7C7C' }}>
            Predicted
          </Text>
        </Text>
      </Box>
    );
  };

  const getRepeatText = () => {
    // Don't show count on mobile due to screen width
    if (breakpointXsDown) {
      return 'Repeats';
    }

    return ` Repeats ${
      occurrences > 0 ? `${occurrences} ${pluralise('time', occurrences)}` : 'weekly'
    }`;
  };

  const repeatText = getRepeatText();

  return (
    <Box className={classes.container}>
      <Box className={classes.gridContainer}>
        <Box>
          <Box className={classes.subHeading}>
            {!offer?.lightning
              ? 'All day'
              : `From ${offer?.startTimeReadable} - ${offer?.endTimeReadable}`}
          </Box>

          <Box style={{ marginTop: '8px', display: 'flex', gap: '16px' }}>
            <Box className={classes.detail}>
              <SmallCheck height={12} width={16} />
              <Box>{offer?.discount}% off</Box>
            </Box>

            <Box className={classes.detail}>
              <SmallCheck height={12} width={16} />
              <Box>{repeatText}</Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.detailsContainer}>
          <Box className={classes.details}>
            {hasDineIn() && (
              <Box style={{ display: 'flex' }}>
                <svg
                  width='31'
                  height='31'
                  viewBox='0 0 31 31'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='15.5' cy='15.5' r='15.5' fill='#FBF4BD' />
                  <path
                    d='M14.433 17.1927L9.74178 15.5733C9.54389 15.505 9.57067 15.2171 9.77778 15.1864L13.2185 14.6774L17.4207 14.0557C17.4525 14.051 17.4849 14.0541 17.5153 14.0645L22.934 15.9351L14.433 17.1927Z'
                    fill='#FAEEE4'
                  />
                  <path
                    d='M14.433 17.1927L9.74178 15.5733C9.54389 15.505 9.57067 15.2171 9.77778 15.1864L13.2185 14.6774L17.4207 14.0557C17.4525 14.051 17.4849 14.0541 17.5153 14.0645L22.934 15.9351M14.433 17.1927L22.934 15.9351M14.433 17.1927V25.2214M22.934 15.9351V23.9637'
                    stroke='black'
                    strokeLinecap='round'
                  />
                  <path
                    d='M9.20094 22.4282V11.4786M9.20094 11.4786L8.39113 8.53286C8.18931 7.79875 8.55225 6.9944 9.24134 6.67067C10.6547 6.00667 12.8368 5.18571 15.1217 6.02539C15.837 6.28828 16.3151 6.93687 16.5538 7.66064L17.3478 10.0677M9.20094 11.4786C9.6751 10.538 11.9683 8.93888 17.3478 10.0677M17.3478 10.0677V13.5961'
                    stroke='black'
                    strokeLinecap='round'
                  />
                  <path d='M17.3477 16.8413V21.4966' stroke='black' strokeLinecap='round' />
                </svg>
              </Box>
            )}

            {hasTakeaway() && (
              <Box style={{ display: 'flex' }}>
                <svg
                  width='31'
                  height='31'
                  viewBox='0 0 31 31'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='15.5' cy='15.5' r='15.5' fill='#FBF4BD' />
                  <path
                    d='M18.8712 8.45982C18.8712 8.02113 13.3569 8.82921 9.80207 9.26549C9.32564 9.32396 8.95644 9.70941 8.92075 10.1881L8.20435 19.7965L8.0617 22.0197C8.02726 22.5564 8.42349 23.0242 8.95856 23.0786L19.6667 24.1666C19.557 22.0821 19.6282 19.115 19.484 16.3187C19.2798 12.3566 18.8712 8.7378 18.8712 8.45982Z'
                    fill='#F5F2ED'
                    stroke='#1A1919'
                  />
                  <path
                    d='M19.6666 24.4999L21.0169 21.8066C21.1016 21.6376 21.3195 21.5882 21.4689 21.7039L22.8095 22.7433C22.8799 22.7978 22.9811 22.7402 22.97 22.6518L21.2606 8.97445C21.2289 8.72112 20.9155 8.6206 20.7424 8.80827L20.3982 9.18145L18.8333 8.49992'
                    stroke='#1A1919'
                  />
                  <path
                    d='M13.8333 8.33333C13.893 7.77137 14.0027 7.17067 14.4587 6.53281M19.6666 7.53276C19.3225 5.41152 18.0651 4.85385 16.9718 5.03058'
                    stroke='black'
                    strokeLinecap='round'
                  />
                  <path
                    d='M11.3333 11.6667C11.3331 9.58333 11.644 5 14.4438 5C17.2436 5 17.1663 9.59259 17.1664 11.6667'
                    stroke='black'
                    strokeLinecap='round'
                  />
                </svg>
              </Box>
            )}

            <Box>
              <svg
                width='31'
                height='31'
                viewBox='0 0 31 31'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='15.5' cy='15.5' r='15.5' fill='#FBF4BD' />
                <path
                  d='M13.1134 11.8369C12.9516 12.2416 12.1252 15.2603 12.1252 15.2603C11.7478 18.7625 11.8666 19.6187 11.924 20.2306C12.0102 21.1507 12.3764 22.1934 12.3764 22.1934C12.5265 22.6891 13.0414 23.6877 13.2821 24.0248C13.5227 24.3619 13.9247 24.6174 14.1126 24.7443C14.3727 24.9198 15.1368 25.0408 15.654 24.8074C15.9072 24.6931 16.1646 24.5167 16.3651 24.3168C16.4219 24.26 16.4854 24.2127 16.5519 24.1678C16.6423 24.1065 16.6929 24.0045 16.7738 23.9327C16.8702 23.8472 16.9581 23.7129 17.0294 23.6033C17.1451 23.426 17.2782 23.2633 17.3928 23.084L17.4052 23.0645C17.4847 22.9402 17.5738 22.8006 17.6639 22.6891C17.7624 22.5674 17.8233 22.4218 17.9182 22.2996C18.019 22.1699 18.0891 22.0168 18.1505 21.8623C18.2031 21.73 18.2896 21.5697 18.3634 21.451C18.4468 21.3164 18.5019 21.1672 18.5839 21.0328C18.814 20.6557 18.9928 20.2892 19.1445 19.874C19.2169 19.6755 19.2544 19.5293 19.3546 19.2302C19.4023 19.0672 19.6981 18.2972 19.846 17.8351C20.0184 17.2966 20.208 16.5604 20.2188 16.1007C20.2239 15.8833 20.2889 15.2149 20.2889 14.7738C20.2889 14.2868 20.3123 13.7985 20.3123 13.3074C20.3123 12.8304 20.2889 12.3471 20.2889 11.867C20.2889 11.6262 20.208 10.8717 20.208 10.6882C20.208 10.561 20.079 9.59107 20.0605 9.46452C20.0605 9.46452 20.0258 9.21507 19.997 9.08733C19.9681 8.95989 19.936 8.84521 19.8971 8.72656C19.8401 8.55337 19.8374 8.40432 19.7372 8.19535C19.5977 7.90505 19.4361 7.46401 19.2431 7.12763C19.1766 7.01195 19.1 6.96328 19.0159 6.8748C18.9423 6.79724 18.5766 6.45837 18.2319 6.31473C17.904 6.17815 17.6271 6.12999 17.3409 6.15461C17.0955 6.17569 16.8271 6.31473 16.6263 6.41921C16.4332 6.51975 16.0242 6.73265 15.3607 7.48978C15.1678 7.74382 15.0207 7.8819 14.7873 8.27284C14.519 8.71773 14.0529 9.45564 13.8165 9.97147C13.5802 10.4873 13.2162 11.5798 13.1134 11.8369Z'
                  fill='#F5F2ED'
                  stroke='#313131'
                  strokeLinecap='round'
                />
                <path
                  d='M15.1906 18.8034C14.9065 18.5942 14.7831 18.3017 14.6296 18.0017C14.5793 17.9035 14.5582 17.8006 14.5218 17.6979C14.4791 17.5773 14.4212 17.4567 14.4181 17.3266C14.4125 17.084 14.3617 16.8413 14.3617 16.5984C14.3617 16.3974 14.3617 16.1962 14.3617 15.9951C14.3617 15.569 14.3556 15.141 14.4411 14.7228C14.4841 14.5128 14.5018 14.2917 14.6023 14.099C14.7172 13.8787 14.8451 13.6566 14.9834 13.4506C15.2075 13.1162 15.3873 12.7755 15.6803 12.4892C15.7728 12.3989 15.8701 12.3171 15.963 12.2315C16.0698 12.1329 16.2636 12.0897 16.4004 12.0515C16.6648 11.9777 16.9779 12.1044 17.1309 12.3092C17.2889 12.5205 17.4238 12.7416 17.512 12.9903C17.6189 13.2922 17.6396 13.6034 17.6396 13.9251C17.6396 14.2463 17.6585 14.5706 17.6585 14.8946C17.6585 15.1347 17.6464 15.3715 17.6396 15.6105C17.6341 15.8064 17.5417 16.0017 17.512 16.1955C17.4641 16.5071 17.3125 16.8208 17.1875 17.1058C17.0963 17.3138 16.9868 17.5146 16.8861 17.7174C16.8183 17.8538 16.76 18.0079 16.6789 18.1366C16.6413 18.1964 16.5896 18.2818 16.5281 18.3208C16.4219 18.3879 16.3766 18.5258 16.2643 18.5866C16.0799 18.6867 16.0034 18.8462 15.7746 18.8587C15.6802 18.8638 15.5904 18.8955 15.492 18.8955C15.3714 18.8955 15.2683 18.8219 15.1529 18.8219'
                  stroke='#313131'
                  strokeWidth='0.6'
                  strokeLinecap='round'
                />
                <path
                  d='M15.8171 15.2075L2.8494 12.9036C2.68065 12.9758 2.75236 13.0388 2.80932 13.0612C2.88094 13.0855 3.02807 13.1391 3.04361 13.1595C3.06303 13.1851 3.15104 13.1959 3.10187 13.2363C3.05271 13.2767 2.97502 13.1744 2.81325 13.2243C2.68384 13.2642 2.72311 13.2984 2.75893 13.3105L15.8444 15.5592C15.9903 15.3727 15.887 15.247 15.8171 15.2075Z'
                  fill='#313131'
                  stroke='#313131'
                  strokeWidth='0.3'
                />
                <path
                  d='M1.34361 11.7533C1.57795 11.9179 1.91508 12.4208 2.05434 12.6518C3.2465 12.8875 5.62175 13.3466 5.58542 13.2974L5.40377 13.0515C5.33565 12.9593 5.28115 12.7583 5.10858 12.6519C4.936 12.5455 4.98596 12.3587 4.69986 12.0986C4.41374 11.8386 4.48413 12.1246 4.32744 12.1034C4.17075 12.0821 4.07311 12.0135 4.03677 12.0915C4.00044 12.1696 4.02314 12.2003 3.82786 12.0631C3.63258 11.926 3.3124 11.9378 3.1557 11.9165C2.99902 11.8952 2.722 11.7747 2.56531 11.7534C2.40862 11.7321 1.05069 11.5476 1.34361 11.7533Z'
                  fill='#F0F0F0'
                  stroke='#313131'
                  strokeWidth='0.3'
                />
                <path
                  d='M1.17395 13.7873C1.55029 13.6905 2.09283 13.2628 2.20218 13.1582L5.40399 13.703C5.38865 13.7664 5.42246 14.2711 5.35211 14.3275C5.26417 14.3979 4.92915 14.5128 4.66532 14.7241C4.4015 14.9353 4.50617 14.6981 4.22279 14.5671C3.9394 14.4361 3.89893 14.5708 3.87519 14.5387C3.85146 14.5065 3.78026 14.4102 3.50944 14.4225C3.23863 14.4349 3.23863 14.4349 3.19116 14.3706C3.14369 14.3063 3.4773 13.9369 3.40052 13.8961C3.32374 13.8554 3.08507 14.3533 2.81425 14.3656C2.54343 14.378 1.26888 13.9158 1.17395 13.7873Z'
                  fill='#F0F0F0'
                  stroke='#313131'
                  strokeWidth='0.3'
                />
                <path
                  d='M20.755 15.5718C21.3597 16.4728 21.4572 16.9021 21.9961 17.8454C22.3736 18.5066 22.7156 19.1834 23.1107 19.8337C23.6013 20.641 24.1955 21.3694 24.6882 22.1755C24.8888 22.5039 25.1029 22.8151 25.3222 23.1264C25.5459 23.4439 25.7155 23.8817 25.9713 24.148C26.0808 24.2619 26.2985 24.6562 26.2985 24.6562'
                  stroke='white'
                  strokeWidth='0.5'
                  strokeLinecap='round'
                />
                <path
                  d='M19.4037 25.2288C19.4037 25.1039 18.237 22.8166 18.1171 22.4419L18.237 22.0671C18.597 23.0247 19.9075 25.0539 20.0034 25.3537C20.1234 25.7284 19.4037 25.3537 19.4037 25.2288Z'
                  stroke='#313131'
                  strokeWidth='0.5'
                />
                <path
                  d='M25.7948 24.8142C25.5336 24.5555 20.8526 16.1884 20.2913 15.0628L20.4927 14.5632C22.1768 17.4396 25.9455 23.6638 26.3946 24.5643C26.6345 25.0453 26.0346 25.0516 25.7948 24.8142Z'
                  stroke='#313131'
                  strokeWidth='0.5'
                />
              </svg>
            </Box>
          </Box>

          <Box className={classes.predictionsContainer}>
            <Box style={{ display: 'flex', gap: '40px' }}>
              {getCustomers()}
              {getRevenue()}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        {actions?.length > 0 && (
          <ActionMenu options={actions} disabled={offer?.status === 'cancelled'} />
        )}
      </Box>
    </Box>
  );
};

PredictedOfferCard.propTypes = {
  offer: offerPropTypes.isRequired,
  deleteOffer: PropTypes.func.isRequired,
  editOffer: PropTypes.func.isRequired,
  occurrences: PropTypes.number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PredictedOfferCard);
