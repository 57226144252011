import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goalPropTypes } from '../../../data/models/Goal';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent } from '../../../utils/analytics';
import { formatDate, getDayOccurrences, groupByProperty, isEmpty } from '../../../utils/helpers';
import OfferCard from '../OfferCard/OfferCard';
import DayTab from './DayTab';
import useStyles from './OffersByDayStyles';

const OffersByDay = ({ offers = [], goal = null, endDate = null, restaurantActive }) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = useState(null);
  const [offersByDay, setOffersByDay] = useState([]);
  const [repeatDays, setRepeatDays] = useState({});

  const extractNumberFromString = (str) => {
    return parseInt(str.replace(/^\D+/g, ''), 10);
  };

  const sortOffers = (offersToSort) => {
    const currentlyActiveOffers = offersToSort
      .filter((offer) => offer?.status === 'active')
      .map((offer) => ({ ...offer, discountAsInt: extractNumberFromString(offer?.discount) }))
      .sort((a, b) => a?.startTime - b?.startTime)
      .sort((a, b) => b?.discountAsInt - a?.discountAsInt);

    const offersForDay = offersToSort
      .filter((offer) => offer?.status !== 'active')
      .map((offer) => ({ ...offer, discountAsInt: extractNumberFromString(offer?.discount) }))
      .sort((a, b) => a?.startTime - b?.startTime)
      .sort((a, b) => b?.discountAsInt - a?.discountAsInt);

    return [...currentlyActiveOffers, ...offersForDay];
  };

  useEffect(() => {
    const daysToShow = 7; // How many days in advance, including today, we will show
    const upcomingDays = [...Array.from({ length: daysToShow })].map((_, i) =>
      dayjs().startOf('day').add(i, 'days'),
    );

    const getDayRelativeToToday = (day, daysInAdvance) => {
      if (daysInAdvance === 0) {
        return `${day}: Today`;
      }

      if (daysInAdvance === 1) {
        return `${day}: Tomorrow`;
      }

      return day;
    };

    const newOffersByDay = upcomingDays.map((dayAsDayjs, i) => {
      const dayOfWeek = parseInt(dayAsDayjs?.format('d'), 10);

      const offersForWeek = offers.filter(
        (offer) =>
          !dayjs(offer.startDate).isAfter(dayjs(endDate)) && offer?.dayOfWeek % 7 === dayOfWeek,
      );

      return {
        dayOfWeek,
        tabLabel: dayAsDayjs.format('ddd Do'),
        dayjsDate: dayAsDayjs,
        listLabel: getDayRelativeToToday(dayAsDayjs.format('dddd'), i),
        offers: sortOffers(offersForWeek),
      };
    });

    // Switch back to all if no offers for that day
    if (selectedDay && newOffersByDay[selectedDay].length === 0) {
      setSelectedDay(null);
    }

    setOffersByDay(newOffersByDay);
  }, [offers]);

  const futureOffers = groupByProperty(
    sortOffers(offers.filter((offer) => dayjs(offer?.startDate).isAfter(dayjs(endDate)))),
    'startDate',
    'startDate',
  ); // TODO double check default timezone is working

  // Get remaining day count for offers
  useEffect(() => {
    const newRepeatDays = getDayOccurrences(dayjs(), goal?.endDate);
    setRepeatDays(newRepeatDays);
  }, [goal]);

  return (
    <Box className={classes.container}>
      <Box className={classes.tabBar}>
        <DayTab
          key='tab-All'
          day='All'
          selected={selectedDay === null}
          onClick={() => {
            trackEvent('button_click: offers_by_day_set_all');
            setSelectedDay(null);
          }}
          itemAmount={offers.length}
        />
        {offersByDay
          .filter((day) => day?.offers?.length > 0)
          .map((day) => (
            <DayTab
              key={`tab-${day?.tabLabel}`}
              day={day?.tabLabel}
              selected={selectedDay === day?.dayOfWeek}
              onClick={() => {
                trackEvent(`button_click: offers_by_day_set_day_${day?.dayOfWeek}`);
                setSelectedDay(day?.dayOfWeek);
              }}
              itemAmount={day?.offers?.length}
            />
          ))}
        {!isEmpty(futureOffers) && (
          <DayTab
            key='tab-Future'
            day='Future offers'
            selected={selectedDay === 'future'}
            onClick={() => {
              trackEvent('button_click: offers_by_day_set_future');
              setSelectedDay('future');
            }}
            itemAmount={futureOffers?.length}
          />
        )}
      </Box>
      <Box>
        {offersByDay
          .filter(
            (day) =>
              (selectedDay === null || day?.dayOfWeek === selectedDay) && day?.offers.length > 0,
          )
          .map((day) => (
            <Box key={`offerDay-${day?.tabLabel}`} className={classes.offerDay}>
              <Box className={classes.dayName}>{day?.listLabel}</Box>

              <Box className={classes.offerCard}>
                {day?.offers.map((offer, i) => (
                  <Box
                    key={`offer-${offer?.id}-${offer?.startTime}-${offer?.endTime}-${offer?.dayOfWeek}`}
                  >
                    <OfferCard
                      offer={offer}
                      occurrences={repeatDays?.[offer?.dayOfWeek]}
                      editable={!restaurantActive?.closedEarlyToday}
                    />

                    {i < day.offers.length - 1 && <Divider style={{ borderColor: '#EDEDED' }} />}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}

        {selectedDay === null && !isEmpty(futureOffers) && (
          <Box className={classes.futureOffersHeading}>Future Offers</Box>
        )}

        {(selectedDay === null || selectedDay === 'future') &&
          futureOffers.map((day) => (
            <Box key={`offerDay-${day?.tabLabel}`} className={classes.offerDay}>
              <Box className={classes.dayName}>{formatDate(day?.startDate, 'ddd Do MMM YYYY')}</Box>

              <Box className={classes.offerCard}>
                {day?.items.map((offer, i) => (
                  <Box
                    key={`offer-${offer?.id}-${offer?.startTime}-${offer?.endTime}-${offer?.dayOfWeek}`}
                  >
                    <OfferCard
                      offer={offer}
                      occurrences={repeatDays?.[offer?.dayOfWeek]}
                      editable={!restaurantActive?.closedEarlyToday}
                    />

                    {i < day?.items.length - 1 && <Divider style={{ borderColor: '#EDEDED' }} />}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

OffersByDay.propTypes = {
  offers: PropTypes.arrayOf(offerPropTypes),
  goal: goalPropTypes,
  endDate: PropTypes.string,
};

const mapStateToProps = (state) => ({
  restaurantActive: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OffersByDay);
