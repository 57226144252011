import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spacer, Button, TextField } from '@eatclub-apps/ec-component-library';
import { trackEvent } from '../../../utils/analytics';
import { roundToDecimal } from '../../../utils/helpers';
import useStyles, { INSIGHT_TAB } from './PillarModalStyles';

export const PillarModalRowItemEditable = ({
  label,
  variant = 'outlined',
  color = INSIGHT_TAB.IMPRESSIONS,
  smallText = null,
  editable = false,
  value,
  editableValue,
  setEditableValue,
}) => {
  const classes = useStyles({ variant, tab: color });

  const [editing, setEditing] = useState(false);
  const [textValue, setTextValue] = useState(editableValue);

  useEffect(() => {
    if (editing) {
      setTextValue(editableValue);
    }
  }, [editing]);

  if (editing) {
    return (
      <div className={classes.rowItemContainer}>
        <div className={classes.rowItemInnerContainer}>
          <Spacer
            className={classes.rowItemInnerContainerLeft}
            direction='vertical'
            style={{ width: '100%' }}
          >
            <Spacer>
              <TextField
                value={textValue}
                onChange={setTextValue}
                inputSuffix='%'
                // minNumber={0}
                // maxNumber={100}
                style={{ input: { maxWidth: '80px', minWidth: '80px' } }}
                autofocus
              />
              <div>{label}</div>
            </Spacer>
            <Spacer>
              <Button
                type='secondary'
                onClick={() => {
                  // Validate
                  let parsedTextValue = Math.min(100, Math.max(0, parseFloat(textValue)));
                  if (Number.isNaN(parsedTextValue)) {
                    parsedTextValue = 0;
                  }
                  setTextValue(parsedTextValue);

                  setEditableValue(parsedTextValue);
                  setEditing(false);
                }}
              >
                Save
              </Button>
              <Button
                type='text'
                onClick={() => {
                  setEditing(false);
                }}
              >
                Cancel
              </Button>
            </Spacer>
          </Spacer>

          <div className={classes.rowItemInnerContainerRight}>
            <div>{value}</div>
            {smallText && <div className={classes.smallText}>{smallText}</div>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.rowItemContainer}>
      <div className={classes.rowItemInnerContainer}>
        <Spacer>
          <div>
            {roundToDecimal(editableValue, 2)}%{label}
          </div>

          {editable && (
            <Button
              type='text'
              style={{
                button: {
                  fontSize: '15px',
                  fontWeight: 500,
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                trackEvent('button_click: edit_food_cost');
                setEditing(true);
              }}
            >
              Edit
            </Button>
          )}
        </Spacer>

        <div className={classes.rowItemInnerContainerRight}>
          <div>{value}</div>
          {smallText && <div className={classes.smallText}>{smallText}</div>}
        </div>
      </div>
    </div>
  );
};

PillarModalRowItemEditable.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['outlined', 'filled']),
  color: PropTypes.oneOf([
    INSIGHT_TAB.NET_REVENUE,
    INSIGHT_TAB.AOV,
    INSIGHT_TAB.CUSTOMERS,
    INSIGHT_TAB.IMPRESSIONS,
  ]),
  smallText: PropTypes.string,
  editable: PropTypes.bool,
  editableValue: PropTypes.number.isRequired,
  setEditableValue: PropTypes.func.isRequired,
};
