import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@mui/material';
import { usePageViews } from '../../utils/analytics';
import PlatformPicker from '../platformPicker/PlatformPicker';
import DailySummaryTable from './dailySummaryTable';
import MonthlySummaryTable from './monthlySummaryTable';
import BillingTable from './BillingTable';
import Error from '../error/Error';

const Billing = ({ invoices }) => {
  usePageViews();
  return (
    <>
      <Error error={invoices.invoicesError} message={invoices.invoicesErrorMessage} />

      <Grid container spacing={3} style={{ fontSize: '14px' }}>
        <Grid item xs={12}>
          <div style={{ marginBottom: '2rem' }}>
            <BillingTable />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ marginBottom: '2rem' }}>
              <PlatformPicker />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <DailySummaryTable />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <MonthlySummaryTable />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

Billing.propTypes = {
  invoices: PropTypes.shape({
    invoicesError: PropTypes.bool.isRequired,
    invoicesErrorMessage: PropTypes.string.isRequired,
  }).isRequired,
  activeRestaurant: PropTypes.shape({
    platform: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  invoices: state.invoices,
  activeRestaurant: state.restaurantActive.restaurant,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Billing);
