import React, { useEffect, useMemo } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Info } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './BalanceHistoryStyles';
import {
  fetchBalanceHistoryByRestIdAction,
  fetchNetBalanceByRestIdAction,
} from '../../actions/settlementsAction';
import { formatCurrency, formatDate } from '../../utils/helpers';
import Label, { labelTypes } from '../label/Label';
import { Banner } from '../banner';

const statusTypeMap = {
  failed: labelTypes.RED,
  pending: labelTypes.ORANGE,
  success: labelTypes.GREEN,
};

export const BalanceHistory = () => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const restaurantActive = useSelector((state) => state.restaurantActive);
  const billingHistory = useSelector((state) => state.billingHistory);
  const netBalance = useSelector((state) => state.netBalance);

  useEffect(() => {
    dispatch(fetchBalanceHistoryByRestIdAction());
    dispatch(fetchNetBalanceByRestIdAction());
  }, [dispatch, restaurantActive.restaurant.objectId]);

  const columns = useMemo(
    () => [
      {
        render: (rowData) => {
          if (rowData.status === 'pending' || rowData.status === 'failed') {
            const statusColorMap = {
              pending: '#F5EBD2',
              failed: '#F8E4E0',
            };
            return (
              <div
                style={{
                  height: '56px',
                  width: '4px',
                  backgroundColor: statusColorMap[rowData.status],
                }}
              />
            );
          }

          return null;
        },
        width: '4px',
        cellStyle: {
          padding: 0,
        },
        headerStyle: {
          padding: 0,
        },
        sorting: false,
      },
      {
        title: 'Date',
        field: 'date',
        render: (rowData) => (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate(rowData.date, 'ddd Do MMM YYYY')}
          </span>
        ),
      },
      {
        title: 'Description',
        field: 'description',
        render: (rowData) => {
          const isSettlement = rowData.type === 'settlement';

          return (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                gap: '8px',
                whiteSpace: 'nowrap',
                maxWidth: isSettlement && '320px',
              }}
            >
              {rowData.description}

              {/* show whether its pending/failed */}
              {rowData.status !== 'success' ? ` -  ${rowData.status}` : ''}

              {rowData.failureReason && (
                <Tooltip title={rowData.failureReason}>
                  <Info color='disabled' />
                </Tooltip>
              )}

              {isSettlement && (
                <Button
                  onClick={() => window.open(rowData.pdfLink, '_blank')}
                  variant='outlined'
                  color='secondary'
                  size='small'
                  style={{ marginLeft: 'auto', lineHeight: '20px' }}
                >
                  View
                </Button>
              )}
            </div>
          );
        },
        width: '100%',
      },
      {
        title: 'Amount',
        field: 'amount',
        render: (rowData) => {
          const formattedAmount = formatCurrency(rowData.amount, true, undefined, true);

          const isSettlement = rowData.type === 'settlement';

          const type = rowData.netBalance > 0 ? labelTypes.GREEN : labelTypes.LINEN;

          if (isSettlement) {
            return (
              <Label
                label={formattedAmount}
                style={{
                  fontSize: 'inherit',
                  lineHeight: 'initial',
                  width: 'fit-content',
                  marginLeft: 'auto',
                }}
                type={type}
              />
            );
          }

          return (
            <Label
              label={formattedAmount}
              style={{
                fontSize: 'inherit',
                lineHeight: 'initial',
                width: 'fit-content',
                marginLeft: 'auto',
              }}
              type={statusTypeMap[rowData.status]}
            />
          );
        },
        align: 'right',
      },
      {
        title: 'Balance',
        field: 'netBalance',
        render: (rowData) => {
          const formattedAmount = formatCurrency(rowData.netBalance, true, undefined, true);

          if (rowData.netBalance === 0) {
            return formattedAmount;
          }

          const type = rowData.netBalance > 0 ? labelTypes.GREEN : labelTypes.LINEN;

          return (
            <Label
              label={formattedAmount}
              style={{
                fontSize: 'inherit',
                lineHeight: 'initial',
                width: 'fit-content',
                marginLeft: 'auto',
              }}
              type={type}
            />
          );
        },
        align: 'right',
      },
    ],
    [],
  );

  const outstandingBalance = netBalance.data * -1;

  return (
    <Box>
      {netBalance.success && outstandingBalance > 0 && (
        <Banner
          action={
            <Button
              size='large'
              variant='contained'
              onClick={() => history.push('/settlements/pay-balance')}
            >
              Make a payment
            </Button>
          }
        >
          You have an outstanding balance of{' '}
          <b>{formatCurrency(outstandingBalance, true, undefined, true)}</b>
        </Banner>
      )}

      <div className={classes.tableStyles}>
        <MaterialTable
          isLoading={billingHistory.pending}
          columns={columns}
          data={billingHistory.data?.balancePayments ?? []}
          tableLayout='fixed'
          options={{
            emptyRowsWhenPaging: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            padding: 'dense',
            search: false,
            draggable: false,
            toolbar: false,
            headerStyle: {
              width: '2px',
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No balance history to display',
            },
          }}
        />
      </div>
    </Box>
  );
};
