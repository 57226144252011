import { Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Button, Modal } from '@eatclub-apps/ec-component-library';
import React from 'react';
import IconConfirmedRequestSVG from '../../../assets/icon_confirmed_request.svg';
import useStyles from './BookingsLandingStyles';

const ConfirmedModal = ({
  isOpen,
  onClose,
  heading,
  subHeading,
  onClick,
  ctaLabel,
  image = <IconConfirmedRequestSVG />,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));

  return (
    <Modal
      fullWidth={false}
      style={{
        container: { padding: '20px' },
        modal: { minWidth: breakpointXsDown ? '100px' : '550px' },
        content: { padding: '20px' },
        header: { display: 'none' },
      }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
    >
      <Box className={classes.modalImageContainer}>{image}</Box>
      <Box className={classes.modalContentContainer}>
        <Box className={classes.modalContentThankYou}>{heading}</Box>
        <Box className={classes.modalContentRequestReceived}>{subHeading}</Box>
      </Box>
      <Button onClick={onClick} style={{ button: { width: '100%' } }}>
        {ctaLabel}
      </Button>
    </Modal>
  );
};

ConfirmedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.element,
};

export default ConfirmedModal;
