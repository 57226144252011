/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Typography,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clearFlagMenuAction, flagMenuAction } from '../../actions/menuFlagAction';
import useStyles from './FlagDialogStyles';
import Error from '../error/Error';
import { trackEvent } from '../../utils/analytics';

const FlagDialog = ({
  clearFlagMenuAction,
  flag,
  flagMenuAction,
  handleCloseDialog,
  menu,
  open,
  restId,
  userId,
  userType,
}) => {
  const classes = useStyles();

  const [issue, setIssue] = useState(10);
  const [issueError, setIssueError] = useState('');

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const issueOptions = [
    { value: 10, name: 'Menu prices are incorrect' },
    { value: 20, name: 'Toppings / addons are incorrect' },
    { value: 30, name: 'Menu layout is incorrect' },
    { value: 40, name: 'Menu has changed' },
    { value: 50, name: 'Other' },
  ];

  const validateForm = () => {
    let formError = false;

    if (issue === 50 && description === '') {
      setDescriptionError('Please enter your issue.');
      formError = true;
    } else if (descriptionError) {
      setDescriptionError('');
    }

    return formError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    trackEvent('button_click: send_report_issue');

    const formError = validateForm();

    if (formError) {
      return;
    }

    const selectedOption = issueOptions.find((option) => option.value === issue);

    flagMenuAction(restId, menu.objectId, selectedOption.name, description, userId, userType);
  };

  const handleSelectChange = (event) => {
    setIssue(event.target.value);
  };

  const handleClose = useCallback(() => {
    handleCloseDialog();
  }, [handleCloseDialog]);

  if (menu.issueId && flag.success) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth='xs'
        TransitionProps={{
          onExit: () => {
            clearFlagMenuAction();
          },
        }}
      >
        <DialogTitle>Report Issue - {menu.menuTitle}</DialogTitle>
        <DialogContent dividers>
          <Typography paragraph>
            Your issue has been reported, an EatClub representative will reach out to you soon.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant='outlined' color='secondary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
      TransitionProps={{
        onEnter: () => {
          setIssue(10);
          setIssueError('');

          setDescription('');
          setDescriptionError('');
        },
        onExit: () => {
          clearFlagMenuAction();
        },
      }}
    >
      <DialogTitle>Report Issue - {menu.menuTitle}</DialogTitle>
      <DialogContent>
        <form id='report-issue-form' noValidate>
          <FormControl fullWidth className={classes.formControl} error={Boolean(issueError)}>
            <InputLabel id='issue-select-label'>Select an issue</InputLabel>
            <Select
              labelId='issue-select-label'
              id='issue-select'
              value={issue}
              onChange={handleSelectChange}
            >
              {issueOptions.map((issue) => (
                <MenuItem key={issue.value} value={issue.value}>
                  {issue.name}
                </MenuItem>
              ))}
            </Select>
            {Boolean(issueError) && <FormHelperText>{issueError}</FormHelperText>}
          </FormControl>

          {issue === 50 && (
            <TextField
              fullWidth
              margin='normal'
              label='Tell us about the issue'
              multiline
              minRows={6}
              value={description}
              onBlur={(e) => setDescription(e.target.value.trim())}
              onChange={(e) => setDescription(e.target.value)}
              error={Boolean(descriptionError)}
              helperText={descriptionError}
            />
          )}
        </form>

        <Error error={flag.error} message={flag.errorMessage} style={{ marginTop: '1rem' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant='outlined' color='secondary'>
          Cancel
        </Button>

        <Button type='submit' form='report-issue-form' onClick={handleSubmit}>
          {flag.saving ? <CircularProgress color='primary' size={22} /> : 'Report Issue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  flag: state.menuFlag,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearFlagMenuAction, flagMenuAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FlagDialog);
