import PropTypes from 'prop-types';
import React from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatCurrency } from '../../../utils/helpers';
import useStyles from './CreateGoalStyles';
import { Heading } from '../../Heading';
import { Text } from '../../Text';

const GoalsOverview = ({ customersLower, customersUpper, maxRevenue, onNext }) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const renderMonthly = (heading, content, bgColor) => (
    <Box className={classes.revenueValueContainer}>
      <Box className={classes.revenueLabel}>{heading}</Box>
      <Box className={classes.revenueValue} style={{ background: bgColor }}>
        {content} monthly
      </Box>
    </Box>
  );

  const renderGoalStep = (heading, description) => (
    <Box className={classes.goalStep}>
      <Box className={classes.goalStepText}>
        <Heading size='4'>{heading}</Heading>
        <Box className={classes.goalStepDescription}>{description}</Box>
      </Box>
    </Box>
  );

  return (
    <Box display='flex' flexDirection='column' mb='150px'>
      <Box className={classes.headingContainer}>
        <Heading>
          Welcome to
          <br />
          goals
        </Heading>
        <Text size='lg' style={{ lineHeight: '36px', maxWidth: '560px' }}>
          Get more from the EatClub Marketplace with control and clarity to how many customers you
          want to reach and revenue you want to make.
        </Text>
      </Box>

      <Box className={classes.goalStepContainer}>
        {renderGoalStep(
          'Set your monthly revenue goal',
          'Select how much revenue you want to make each month, after the offer amount given to the customer.',
        )}
        {renderGoalStep(
          'Review AI suggested offers',
          'Our AI has evaluated thousands of data points to suggest your optimal selection of offers to launch to the Marketplace.',
        )}
        {renderGoalStep(
          'Launch and stay on track',
          "Shape your starting offers to suit your needs, access more data to see how you're tracking\n" +
            '            and make changes at anytime.',
        )}
      </Box>

      <Box className={classes.goalsFooter}>
        <Box className={classes.goalsFooterPadding}>
          {!breakpointXsDown && (
            <Box className={classes.extraRevenueContainer}>
              <Heading size='4'>Your potential extra revenue</Heading>
              <Text>Estimated revenue after offer amount</Text>
            </Box>
          )}

          <Box className={classes.revenueLabelContainer}>
            <Box className={classes.footerTargets}>
              {renderMonthly('Customers', `${customersLower}-${customersUpper}`, '#fbf4bd')}
              {renderMonthly('Revenue', formatCurrency(maxRevenue, false), '#fbf4bd')}
            </Box>

            <Button variant='contained' onClick={onNext} size='large'>
              Get started
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

GoalsOverview.propTypes = {
  customersLower: PropTypes.number.isRequired,
  customersUpper: PropTypes.number.isRequired,
  maxRevenue: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default GoalsOverview;
