/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MenuCategory from './MenuCategory';

const MenuCategories = ({ categories }) => {
  const [openItemId, setOpenItemId] = React.useState(false);

  const handleToggleItem = (itemId) => {
    if (itemId === openItemId) {
      setOpenItemId(false);
      return;
    }
    setOpenItemId(itemId);
  };

  return (
    <>
      {categories?.map((category, index) => (
        <MenuCategory
          key={category.objectId}
          index={index}
          categoriesLength={categories.length}
          category={category}
          openItemId={openItemId}
          handleToggleItem={handleToggleItem}
        />
      ))}
    </>
  );
};

MenuCategories.propTypes = {
  categories: PropTypes.array.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuCategories);
