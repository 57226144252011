import React from 'react';
import PropTypes from 'prop-types';
import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDateRangeLabel } from '../../utils/insights/insightHelpers';
import useStyles from './InsightCardStyles';

const InsightCard = ({ title = '', data = [], topPercentage = null, dates }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Spacer className={classes.title} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Box>{title}</Box>
        <Box style={{ fontSize: '14px', fontWeight: '400' }}>{getDateRangeLabel(dates)}</Box>
      </Spacer>
      {data.map((dataPoint) => (
        <Spacer key={dataPoint.label} className={classes.row}>
          <Box className={classes.label}>{dataPoint.label}</Box>
          <Box className={classes.value}>{dataPoint.value}</Box>
        </Spacer>
      ))}
      {topPercentage && <Box>Top {topPercentage}%</Box>}
    </Box>
  );
};

InsightCard.propTypes = {
  title: PropTypes.string,
  topPercentage: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InsightCard);
