import * as type from './types';

export const createDeal = /* GraphQL */ `
  mutation CreateDeal(
    $dineIn: Boolean!
    $discount: String!
    $endTime: Int!
    $lightning: Boolean!
    $quantity: Int!
    $recurring: Boolean!
    $recurringDays: AWSJSON
    $restId: String!
    $startDate: AWSDate!
    $startTime: Int!
    $takeaway: Boolean!
  ) {
    createDeal(
      dineIn: $dineIn
      discount: $discount
      endTime: $endTime
      lightning: $lightning
      quantity: $quantity
      recurring: $recurring
      recurringDays: $recurringDays
      restId: $restId
      startDate: $startDate
      startTime: $startTime
      takeaway: $takeaway
    ) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
    }
  }
`;
export const deleteDeal = /* GraphQL */ `
  mutation DeleteDeal($dealId: String!) {
    deleteDeal(dealId: $dealId)
  }
`;
export const disableDealForDate = /* GraphQL */ `
  mutation DisableDealForDate($date: String, $dealId: String) {
    disableDealForDate(date: $date, dealId: $dealId) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
    }
  }
`;
export const editDeal = /* GraphQL */ `
  mutation EditDeal(
    $dealId: String!
    $discount: String
    $endTime: Int
    $qty: Int
    $startTime: Int
  ) {
    editDeal(
      dealId: $dealId
      discount: $discount
      endTime: $endTime
      qty: $qty
      startTime: $startTime
    ) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
    }
  }
`;
export const flagCustomerIssue = /* GraphQL */ `
  mutation FlagCustomerIssue($bookingId: String!, $comment: String!) {
    flagCustomerIssue(bookingId: $bookingId, comment: $comment) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
export const flagMenuIssue = /* GraphQL */ `
  mutation FlagMenuIssue(
    $comment: String
    $flagReason: String!
    $menuId: String!
    $restId: String!
  ) {
    flagMenuIssue(comment: $comment, flagReason: $flagReason, menuId: $menuId, restId: $restId) {
      enabled
      endTime
      issueId
      menuDescription
      menuIndex
      menuTitle
      objectId
      platform
      restIds
      startTime
      type
    }
  }
`;
export const flagNoShow = /* GraphQL */ `
  mutation FlagNoShow($bookingId: String!) {
    flagNoShow(bookingId: $bookingId) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
export const flagOrderIssue = /* GraphQL */ `
  mutation FlagOrderIssue($bookingId: String!, $comment: String!) {
    flagOrderIssue(bookingId: $bookingId, comment: $comment) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
export const flagPartySize = /* GraphQL */ `
  mutation FlagPartySize($bookingId: String!, $partySize: Int!) {
    flagPartySize(bookingId: $bookingId, partySize: $partySize) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
export const increaseDealPercentage = /* GraphQL */ `
  mutation IncreaseDealPercentage($dealId: String!, $newDiscount: String!) {
    increaseDealPercentage(dealId: $dealId, newDiscount: $newDiscount) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
    }
  }
`;
export const increaseDealQty = /* GraphQL */ `
  mutation IncreaseDealQty($addQty: Int!, $dealId: String!, $todayOnly: Boolean!) {
    increaseDealQty(addQty: $addQty, dealId: $dealId, todayOnly: $todayOnly) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
    }
  }
`;
export const reenableDealForDate = /* GraphQL */ `
  mutation ReenableDealForDate($date: String, $dealId: String) {
    reenableDealForDate(date: $date, dealId: $dealId) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
    }
  }
`;
export const refundAmount = /* GraphQL */ `
  mutation RefundAmount($amount: String!, $orderId: String!) {
    refundAmount(amount: $amount, orderId: $orderId) {
      bookingId
      created
      discount
      items {
        category
        itemOptions {
          choices
          objectId
          optionTitle
          qty
        }
        itemTitle
        objectId
        origPrice
        price
        qty
      }
      name
      objectId
      origTotal
      partySize
      paymentMethod
      pickupTime
      platform
      refundAmount
      refundReason
      refundType
      restId
      tableNum
      total
      transactionId
    }
  }
`;
export const refundEntireOrder = /* GraphQL */ `
  mutation RefundEntireOrder($orderId: String!) {
    refundEntireOrder(orderId: $orderId) {
      bookingId
      created
      discount
      items {
        category
        itemOptions {
          choices
          objectId
          optionTitle
          qty
        }
        itemTitle
        objectId
        origPrice
        price
        qty
      }
      name
      objectId
      origTotal
      partySize
      paymentMethod
      pickupTime
      platform
      refundAmount
      refundReason
      refundType
      restId
      tableNum
      total
      transactionId
    }
  }
`;
export const refundItems = /* GraphQL */ `
  mutation RefundItems($itemIds: [String]!, $orderId: String!) {
    refundItems(itemIds: $itemIds, orderId: $orderId) {
      bookingId
      created
      discount
      items {
        category
        itemOptions {
          choices
          objectId
          optionTitle
          qty
        }
        itemTitle
        objectId
        origPrice
        price
        qty
      }
      name
      objectId
      origTotal
      partySize
      paymentMethod
      pickupTime
      platform
      refundAmount
      refundReason
      refundType
      restId
      tableNum
      total
      transactionId
    }
  }
`;
export const requestHelpForDeal = /* GraphQL */ `
  mutation RequestHelpForDeal($dealId: String!) {
    requestHelpForDeal(dealId: $dealId)
  }
`;
export const saveInvoice = /* GraphQL */ `
  mutation SaveInvoice(
    $invoiceCreated: AWSDateTime
    $invoiceDueDate: AWSDateTime
    $invoiceId: ID
    $invoiceNumber: String
    $invoiceTotal: Float
    $invoiceUrl: String
    $objectId: ID
    $restaurantId: ID
  ) {
    saveInvoice(
      invoiceCreated: $invoiceCreated
      invoiceDueDate: $invoiceDueDate
      invoiceId: $invoiceId
      invoiceNumber: $invoiceNumber
      invoiceTotal: $invoiceTotal
      invoiceUrl: $invoiceUrl
      objectId: $objectId
      restaurantId: $restaurantId
    ) {
      invoiceCreated
      invoiceDueDate
      invoiceId
      invoiceNumber
      invoiceTotal
      invoiceUrl
      objectId
      restId
    }
  }
`;
export const sendPubSubEvent = /* GraphQL */ `
  mutation SendPubSubEvent($bookingId: String!, $restId: String!) {
    sendPubSubEvent(bookingId: $bookingId, restId: $restId) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
export const toggleMenuItemOptionStock = /* GraphQL */ `
  mutation ToggleMenuItemOptionStock(
    $choiceTitle: String!
    $inStock: Boolean!
    $menuItemOptionId: String!
    $restId: String!
  ) {
    toggleMenuItemOptionStock(
      choiceTitle: $choiceTitle
      inStock: $inStock
      menuItemOptionId: $menuItemOptionId
      restId: $restId
    ) {
      choices
      enabled
      mandatory
      maxSelections
      menuItemId
      minSelections
      objectId
      optionDescription
      optionIndex
      optionTitle
    }
  }
`;
export const toggleMenuItemStock = /* GraphQL */ `
  mutation ToggleMenuItemStock($inStock: Boolean!, $menuItemId: String!, $restId: String!) {
    toggleMenuItemStock(inStock: $inStock, menuItemId: $menuItemId, restId: $restId) {
      category
      disableDate
      enabled
      flagReason
      flags
      imageLink
      itemDescription
      itemIndex
      itemTitle
      menuId
      noDiscount
      objectId
      options
      price
    }
  }
`;

export const toggleMenuProductStock = /* GraphQL */ `
  mutation ToggleMenuProductStock($productId: String!, $inStock: Boolean!, $restId: String!) {
    toggleMenuProductStock(productId: $productId, inStock: $inStock, restId: $restId) {
      objectId
      enabled
      productTitle
      productDescription
      productIndex
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      restId
      posId
      options {
        objectId
        enabled
        optionTitle
        optionDescription
        optionIndex
        mandatory
        minSelections
        maxSelections
        restId
        posId
        choices {
          objectId
          enabled
          choiceTitle
          choiceIndex
          price
          disableDate
          disableUntilDate
          restId
          posId
        }
      }
    }
  }
`;
export const toggleMenuChoiceStock = /* GraphQL */ `
  mutation ToggleMenuChoiceStock($choiceId: String!, $inStock: Boolean!, $restId: String!) {
    toggleMenuChoiceStock(choiceId: $choiceId, inStock: $inStock, restId: $restId) {
      objectId
      enabled
      choiceTitle
      choiceIndex
      price
      disableDate
      disableUntilDate
      restId
      posId
    }
  }
`;
export const contactBilling = /* GraphQL */ `
  mutation ContactBilling($message: String, $reason: String!, $restId: String!) {
    contactBilling(message: $message, reason: $reason, restId: $restId)
  }
`;
export const postOppo = /* GraphQL */ `
  mutation PostOppo($oppoId: String!, $discount: String!, $origin: String!) {
    postOppo(oppoId: $oppoId, discount: $discount, origin: $origin) {
      objectId
      created
      lastUpdated
      enabled
      restId
      discount
      customDiscount
      lightning
      takeawayOnly
      iaoOnly
      dineInOnly
      qtyLeft
      dayOfWeek
      recurring
      recurringQty
      startTime
      endTime
      startDate
      endDate
      takeRate
      redeemedLastWeek
      disabledForDate
      origin
    }
  }
`;

export const confirmBooking = /* GraphQL */ `
  mutation ConfirmBooking($bookingId: String!) {
    confirmBooking(bookingId: $bookingId)
  }
`;

export const requestCallForBooking = /* GraphQL */ `
  mutation RequestCallForBooking($bookingId: String!) {
    requestCallForBooking(bookingId: $bookingId)
  }
`;

export const postAllOppos = /* GraphQL */ `
  mutation PostAllOppos($restId: String!, $date: AWSDate!) {
    postAllOppos(restId: $restId, date: $date) {
      objectId
      created
      lastUpdated
      enabled
      restId
      discount
      customDiscount
      lightning
      takeawayOnly
      iaoOnly
      dineInOnly
      qtyLeft
      dayOfWeek
      recurring
      recurringQty
      startTime
      endTime
      startDate
      endDate
      takeRate
      redeemedLastWeek
      disabledForDate
      origin
    }
  }
`;

export const startGoal = /* GraphQL */ `
  mutation StartGoal($goal: GoalInput!, $offers: [GoalOfferInput]) {
    startGoal(goal: $goal, offers: $offers)
  }
`;

export const endGoal = /* GraphQL */ `
  mutation EndGoal($goalId: String!, $reason: String) {
    endGoal(goalId: $goalId, reason: $reason)
  }
`;

export const editGoal = /* GraphQL */ `
  mutation EditGoal($goalId: String!, $duration: String, $autoAdjust: Boolean) {
    editGoal(goalId: $goalId, duration: $duration, autoAdjust: $autoAdjust)
  }
`;

export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal($goalId: String!) {
    deleteGoal(goalId: $goalId)
  }
`;

export const editOffer = /* GraphQL */ `
  mutation EditOffer(
    $dealIds: [String]!
    $lightning: Boolean
    $discount: String
    $dineInQty: Int
    $takeawayQty: Int
    $startTime: Int
    $endTime: Int
    $dineIn: Boolean!
    $takeaway: Boolean!
    $goalId: String
    $goalOfferId: String
    $reason: String
    $reasonMessage: String
    $allOccurrences: Boolean!
    $revenueChange: Int
    $customersLower: Int
    $customersUpper: Int
  ) {
    editOffer(
      dealIds: $dealIds
      lightning: $lightning
      discount: $discount
      dineInQty: $dineInQty
      takeawayQty: $takeawayQty
      startTime: $startTime
      endTime: $endTime
      dineIn: $dineIn
      takeaway: $takeaway
      goalId: $goalId
      goalOfferId: $goalOfferId
      reason: $reason
      reasonMessage: $reasonMessage
      allOccurrences: $allOccurrences
      revenueChange: $revenueChange
      customersLower: $customersLower
      customersUpper: $customersUpper
    ) {
      goalOfferId
      restId
      enabled
      discount
      lightning
      recurring
      startTime
      endTime
      startDate
      endDate
      dayOfWeek
      goalId
      partySizeLower
      partySizeUpper
      revenueExpected
      partySizeActual
      revenueActual
      onPeak
      deals {
        objectId
        created
        lastUpdated
        enabled
        restId
        discount
        customDiscount
        lightning
        takeawayOnly
        iaoOnly
        dineInOnly
        qtyLeft
        dayOfWeek
        recurring
        recurringQty
        startTime
        endTime
        startDate
        endDate
        takeRate
        redeemedLastWeek
        lastBookingDate
        disabledForDate
        origin
        goalId
      }
    }
  }
`;

export const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $dealIds: [String]!
    $goalId: String
    $goalOfferId: String
    $reason: String
    $reasonMessage: String
    $allOccurrences: Boolean!
  ) {
    deleteOffer(
      dealIds: $dealIds
      goalId: $goalId
      goalOfferId: $goalOfferId
      reason: $reason
      reasonMessage: $reasonMessage
      allOccurrences: $allOccurrences
    )
  }
`;

export const deleteAllOffers = /* GraphQL */ `
  mutation DeleteAllOffers($restId: String!) {
    deleteAllOffers(restId: $restId)
  }
`;

export const disableOfferForDate = /* GraphQL */ `
  mutation DisableOfferForDate(
    $dealIds: [String]!
    $date: String!
    $goalId: String
    $goalOfferId: String
    $reason: String!
    $reasonMessage: String
  ) {
    disableOfferForDate(
      dealIds: $dealIds
      date: $date
      goalId: $goalId
      goalOfferId: $goalOfferId
      reason: $reason
      reasonMessage: $reasonMessage
    ) {
      goalOfferId
      restId
      enabled
      discount
      lightning
      recurring
      startTime
      endTime
      startDate
      endDate
      dayOfWeek
      goalId
      partySizeLower
      partySizeUpper
      revenueExpected
      partySizeActual
      revenueActual
      onPeak
      deals {
        objectId
        created
        lastUpdated
        enabled
        restId
        discount
        customDiscount
        lightning
        takeawayOnly
        iaoOnly
        dineInOnly
        qtyLeft
        dayOfWeek
        recurring
        recurringQty
        startTime
        endTime
        startDate
        endDate
        takeRate
        redeemedLastWeek
        lastBookingDate
        disabledForDate
        origin
        goalId
      }
    }
  }
`;

export const disableOfferUntilDateTime = /* GraphQL */ `
  mutation DisableOfferUntilDateTime(
    $dealIds: [String]!
    $dateTime: AWSDateTime!
    $goalId: String
    $goalOfferId: String
    $reason: String!
    $reasonMessage: String
  ) {
    disableOfferUntilDateTime(
      dealIds: $dealIds
      dateTime: $dateTime
      goalId: $goalId
      goalOfferId: $goalOfferId
      reason: $reason
      reasonMessage: $reasonMessage
    ) {
      goalOfferId
      restId
      enabled
      discount
      lightning
      recurring
      startTime
      endTime
      startDate
      endDate
      dayOfWeek
      goalId
      partySizeLower
      partySizeUpper
      revenueExpected
      partySizeActual
      revenueActual
      onPeak
      deals {
        objectId
        created
        lastUpdated
        enabled
        restId
        discount
        customDiscount
        lightning
        takeawayOnly
        iaoOnly
        dineInOnly
        qtyLeft
        dayOfWeek
        recurring
        recurringQty
        startTime
        endTime
        startDate
        endDate
        takeRate
        redeemedLastWeek
        lastBookingDate
        disabledForDate
        origin
        goalId
      }
    }
  }
`;

export const reenableOfferForDate = /* GraphQL */ `
  mutation ReenableOfferForDate($dealIds: [String]!, $date: String!) {
    reenableOfferForDate(dealIds: $dealIds, date: $date) {
      goalOfferId
      restId
      enabled
      discount
      lightning
      recurring
      startTime
      endTime
      startDate
      endDate
      dayOfWeek
      goalId
      partySizeLower
      partySizeUpper
      revenueExpected
      partySizeActual
      revenueActual
      onPeak
      deals {
        objectId
        created
        lastUpdated
        enabled
        restId
        discount
        customDiscount
        lightning
        takeawayOnly
        iaoOnly
        dineInOnly
        qtyLeft
        dayOfWeek
        recurring
        recurringQty
        startTime
        endTime
        startDate
        endDate
        takeRate
        redeemedLastWeek
        lastBookingDate
        disabledForDate
        origin
        goalId
      }
    }
  }
`;

export const requestOfferEdit = /* GraphQL */ `
  mutation RequestOfferEdit(
    $dealIds: [String]!
    $reason: String!
    $reasonMessage: String
    $contact: String
    $phone: String
  ) {
    requestOfferEdit(
      dealIds: $dealIds
      reason: $reason
      reasonMessage: $reasonMessage
      contact: $contact
      phone: $phone
    )
  }
`;

export const requestOfferDelete = /* GraphQL */ `
  mutation RequestOfferDelete(
    $dealIds: [String]!
    $reason: String!
    $reasonMessage: String
    $contact: String
    $phone: String
  ) {
    requestOfferDelete(
      dealIds: $dealIds
      reason: $reason
      reasonMessage: $reasonMessage
      contact: $contact
      phone: $phone
    )
  }
`;

export const requestGoalDelete = /* GraphQL */ `
  mutation RequestGoalDelete(
    $goalId: String!
    $reason: String!
    $reasonMessage: String
    $contact: String
    $phone: String
  ) {
    requestGoalDelete(
      goalId: $goalId
      reason: $reason
      reasonMessage: $reasonMessage
      contact: $contact
      phone: $phone
    )
  }
`;

export const saveRestaurant = /* GraphQL */ `
  mutation SaveRestaurant($restId: String!, $restaurant: RestaurantInput!) {
    saveRestaurant(restId: $restId, restaurant: $restaurant){
      ${type.Restaurant}
    }
  }
`;

export const closeEarly = /* GraphQL */ `
  mutation CloseEarly($restId: String!) {
    closeEarly(restId: $restId) {
      ${type.Restaurant}
    }
  }
`;

export const reopen = /* GraphQL */ `
  mutation Reopen($restId: String!) {
    reopen(restId: $restId) {
      ${type.Restaurant}
    }
  }
`;

export const onboardToObee = /* GraphQL */ `
  mutation OnboardToObee(
    $restId: String!
    $slug: String!
    $operationsEmail: String!
    $premium: Boolean!
  ) {
    onboardToObee(
      restId: $restId
      slug: $slug
      operationsEmail: $operationsEmail
      premium: $premium
    )
  }
`;

export const requestObeeSignup = /* GraphQL */ `
  mutation RequestObeeSignup(
    $restId: String!
    $premium: Boolean!
    $name: String!
    $phone: String
    $email: String
    $comment: String
  ) {
    requestObeeSignup(
      restId: $restId
      premium: $premium
      name: $name
      phone: $phone
      email: $email
      comment: $comment
    )
  }
`;

export const saveAverageBookingValue = /* GraphQL */ `
  mutation SaveAverageBookingValue($restId: String!, $abv: Int!) {
    saveAverageBookingValue(restId: $restId, abv: $abv) {
      averageBookingValue
    }
  }
`;

export const saveAverageOrderValue = /* GraphQL */ `
  mutation SaveAverageOrderValue($restId: String!, $aov: Int!) {
    saveAverageOrderValue(restId: $restId, aov: $aov) {
      averageOrderValue
    }
  }
`;

export const payBalance = /* GraphQL */ `
  mutation PayBalance(
    $restId: String!
    $amount: Int!
    $paymentMethodId: String!
    $paymentMethodName: String!
    $paymentMethodType: String!
  ) {
    payBalance(
      restId: $restId
      amount: $amount
      paymentMethodId: $paymentMethodId
      paymentMethodName: $paymentMethodName
      paymentMethodType: $paymentMethodType
    )
  }
`;

export const createCCSetupIntent = /* GraphQL */ `
  mutation CreateCCSetupIntent($restId: String!) {
    createCCSetupIntent(restId: $restId)
  }
`;

export const savePaymentDetails = /* GraphQL */ `
  mutation savePaymentDetails(
    $restId: String!
    $paymentMethodId: String!
    $paymentMethodName: String!
    $paymentMethodType: String!
  ) {
    savePaymentDetails(
      restId: $restId
      paymentMethodId: $paymentMethodId
      paymentMethodName: $paymentMethodName
      paymentMethodType: $paymentMethodType
    )
  }
`;

export const updateRestaurantTimePeriods = /* GraphQL */ `
  mutation UpdateRestaurantTimePeriods($restId: String!, $input: [RestaurantTimePeriodInput]!) {
    updateRestaurantTimePeriods(restId: $restId, input: $input) {
      ${type.RestaurantTimePeriod}
    }
  }
`;

export const updateRestaurantDayPeriods = /* GraphQL */ `
  mutation UpdateRestaurantDayPeriods($restId: String!, $input: [RestaurantDayPeriodInput]!) {
    updateRestaurantDayPeriods(restId: $restId, input: $input) {
      ${type.RestaurantDayPeriod}
    }
  }
`;

export const saveFoodCostPercent = /* GraphQL */ `
  mutation SaveFoodCostPercent($restId: String!, $foodCostPercent: Int!) {
    saveFoodCostPercent(restId: $restId, foodCostPercent: $foodCostPercent) {
      ${type.Restaurant}
    }
  }
`;

export const createOffer = /* GraphQL */ `
  mutation CreateOffer(
    $restId: String!
    $discount: String!
    $dineIn: Boolean!
    $takeaway: Boolean!
    $drinksOnly: Boolean!
    $startTime: Int!
    $endTime: Int!
    $lightning: Boolean!
    $startDate: AWSDate!
    $recurring: Boolean!
    $recurringDays: AWSJSON
    $dineInQty: Int!
    $takeawayQty: Int!
  ) {
    createOffer(
      restId: $restId
      discount: $discount
      dineIn: $dineIn
      takeaway: $takeaway
      drinksOnly: $drinksOnly
      startTime: $startTime
      endTime: $endTime
      lightning: $lightning
      startDate: $startDate
      recurring: $recurring
      recurringDays: $recurringDays
      dineInQty: $dineInQty
      takeawayQty: $takeawayQty
    ) {
      ${type.Deal}
    }
  }
`;
