import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    backgroundColor: '#EFE8DE',
    borderRadius: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginBottom: '26px',
    padding: '8px',
  },
  contentContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginRight: 'auto',
  },
  iconContainer: {
    alignItems: 'center',
    backgroundColor: '#FCFCCF',
    borderRadius: '18px',
    display: 'flex',
    flexShrink: 0,
    height: '48px',
    justifyContent: 'center',
    width: '48px',
  },
}));

export default useStyles;
