import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';
import { drawerWidth } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  // Overview
  headingContainer: {
    display: 'grid',
    alignItems: 'center',
    flexWrap: 'wrap',
    gridTemplateColumns: '1fr 2fr',
    gap: '20px',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  heading: {
    fontSize: '40px',
    lineHeight: '40px',
    fontWeight: 500,
  },
  revenueTargetHeading: {
    fontSize: '40px',
    lineHeight: '40px',
    fontWeight: 500,
  },
  description: {
    maxWidth: '600px',
  },
  overviewDescription: {
    lineHeight: '36px',
    maxWidth: '600px',
  },
  revenueTargetDescription: {
    marginTop: '20px',
  },
  goalStepContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    marginTop: '150px',
    gap: '20px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
      gridTemplateColumns: '1fr',
    },
  },
  goalStep: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    maxWidth: '350px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: '10px',
    },
  },
  goalStepNumber: {
    color: COLORS.PRIMARY_RED_MATTE,
    fontSize: '14px',
    fontWeight: '700',

    [theme.breakpoints.down('sm')]: {
      marginTop: '3px',
    },
  },
  goalStepText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  goalStepHeading: {
    fontSize: '18px',
    fontWeight: '500',
  },
  goalStepDescription: {
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '8px',
  },
  goalsFooter: {
    boxSizing: 'unset',
    alignItems: 'center',
    background: '#F5F2ED',
    bottom: '0',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '18px',
    justifyContent: 'center',
    left: drawerWidth,
    position: 'fixed',
    right: '0',
    boxShadow: '0px -4px 4px 0px #D0D0D040',
    [theme.breakpoints.down('md')]: {
      left: '0',
      fontSize: '14px',
    },
  },
  footerTargets: {
    gap: '36px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '18px',
    },
  },
  revenueValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  revenueLabel: {
    color: COLORS.SMOKE,
    fontSize: '12px',
    letterSpacing: '1.5px',
  },
  revenueValue: {
    borderRadius: '12px',
    fontSize: '18px',
    padding: '4px 12px',
  },
  goalsFooterPadding: {
    display: 'flex',
    maxWidth: '1424px',
    padding: '20px 72px',
    flexWrap: 'wrap',
    gap: '20px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
  },
  goalsFooterProjection: {
    display: 'flex',
    maxWidth: '1424px',
    padding: '20px 72px',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    rowGap: '15px',
    columnGap: '40px',
    paddingBottom: '13px',
    paddingTop: '13px',

    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
  },
  extraRevenueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    gap: '10px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  extraRevenueHeading: {},
  extraRevenueDescription: {},
  revenueLabelContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '40px',
    rowGap: '20px',
    flexWrap: 'wrap',
  },

  // Revenue Target
  revenueTargetContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#313131',
    marginBottom: '125px',
  },
  sliderTitleContainer: {
    marginBottom: '20px',
    fontSize: '28px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  sliderTitle: {
    fontWeight: 400,
  },
  potentialRevenueContainer: {
    marginTop: '40px',
    display: 'inline-flex',
    gap: '32px',
    alignItems: 'center',
    fontSize: '18px',
    lineHeight: '35px',
    rowGap: '12px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  potentialRevenueRow: {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
  },
  tooltipContainer: {},
  selectButton: {
    border: '1px solid #DCDCDB',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    padding: '8px 16px',
    textTransform: 'uppercase',
    letterSpacing: '0.13em',
    cursor: 'pointer',
    lineHeight: '19px',
  },
  footerEstimateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
  },
  footerEstimate: {
    display: 'flex',
    gap: '4px',
    backgroundColor: '#F8F0E3',
    justifyContent: 'center',
  },

  // Success
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#313131',
    height: '100%',
  },
  successBanner: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '60px',
    width: '100%',
    backgroundColor: COLORS.LINEN,
    borderRadius: '16px',
    padding: '24px 32px',
  },
  successContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
}));

export default useStyles;
