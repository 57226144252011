import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    cardTitle: {
      fontWeight: 500,
    },
    chart: {
      width: ['100%', '!important'],
    },
    chartHeight: {
      height: '80px',
    },
  }),
  { name: 'RedemptionTimesChart' },
);

export default useStyles;
