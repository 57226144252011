/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Snackbar } from '@mui/material';
import { Alert as ECAlert } from '@eatclub-apps/ec-component-library';
import { removeMessageAction } from '../../actions/logMessageAction';

const LogMessages = ({ messages, removeMessage }) => {
  const [open, setOpen] = useState(false);
  const [activeMessage, setActiveMessage] = useState(undefined);

  useEffect(() => {
    if (messages.length && !activeMessage) {
      // Set a new message when there's none active
      setActiveMessage({ ...messages[0] });
      removeMessage(messages[0].id);
      setOpen(true);
    } else if (messages.length && activeMessage && open) {
      // Close an active message when a new one is added
      setOpen(false);
    }
  }, [messages, activeMessage, open, removeMessage]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setActiveMessage(undefined);
  };

  /**
   * Return the duration in ms that the message will stay on the screen
   * @returns {number|null}
   */
  const getDuration = () => {
    if (!activeMessage) {
      return 5000;
    }

    // Error messages stay up to a minute, but not forever
    if (activeMessage.severity === 'error') {
      return 60 * 1000;
    }

    return 5000;
  };

  const styles = {
    snackbar: {
      width: '100%',
      position: 'fixed',
      maxWidth: '900px',
    },
  };

  if (activeMessage) {
    return (
      <Snackbar
        id={activeMessage ? activeMessage.id : undefined}
        open={open}
        autoHideDuration={getDuration()}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={activeMessage ? activeMessage.message : undefined}
        style={styles.snackbar}
      >
        {/* Workaround for a bug in MUI - need to wrap alerts in divs */}
        <div>
          <ECAlert
            message={activeMessage?.message}
            severity={activeMessage?.severity}
            handleClose={handleClose}
          />
        </div>
      </Snackbar>
    );
  }

  return <></>;
};

const mapStateToProps = (state) => ({ messages: state.logMessage.messages });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeMessage: removeMessageAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LogMessages);
