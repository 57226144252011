import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const DayTab = ({ day, itemAmount, onClick, selected = false, disabled = false }) => {
  return (
    <Button
      color='secondary'
      disabled={disabled}
      onClick={onClick}
      variant={selected ? 'contained' : 'outlined'}
    >
      {day} ({itemAmount})
    </Button>
  );
};

DayTab.propTypes = {
  day: PropTypes.string.isRequired,
  itemAmount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DayTab);
