import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { dealsForGoal } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchDealsForGoalAction = (restaurantId, goalId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `DEALS_FOR_GOAL_${goalId}`,
  });

  dispatch({
    type: type.FETCH_DEALS_FOR_GOAL_PENDING,
  });

  (async () => {
    try {
      const dealsResponse = await API.graphql(
        {
          query: dealsForGoal,
          variables: {
            restId: restaurantId,
            goalId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restaurantId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const {
        data: { dealsForGoal: deals },
      } = dealsResponse;

      devLog('success', 'deals for goal', deals);

      // const bookingsResponse = await API.graphql(
      //   graphqlOperation(dealsByDayBookings, {
      //     restId: restaurantId,
      //     day,
      //   }),
      //   {
      //     'user-id': userId,
      //     'rest-id': restId,
      //     'app-version': packageInfo.version,
      //   },
      // ).catch((error) => {
      //   throw new Error(error.errors[0].message);
      // });

      // destructure
      // const {
      //   data: { dealsByDayBookings: bookings },
      // } = bookingsResponse;

      // devLog('success', 'deals by day bookings', bookings);

      dispatch({
        type: type.FETCH_DEALS_FOR_GOAL_SUCCESS,
        payload: { deals },
      });
    } catch (error) {
      devLog('error', 'deals for goal', error);

      dispatch({
        type: type.FETCH_DEALS_FOR_GOAL_FAILURE,
        payload: `Unable to retrieve deals for goal: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `DEALS_FOR_GOAL_${goalId}`,
      });
    }
  })();
};
