import dayjs from 'dayjs';

/**
 * Formats `dayjs object` into minutes `number`.
 *
 * @param time Number of decimal places to round
 */
export const dayjsAsMinutes = (time) => {
  if (!time) {
    return 0;
  }
  if (!dayjs.isDayjs(time)) {
    return time;
  }

  return time.hour() * 60 + time.minute();
};
