import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    restaurantSwitcherContainer: {
      display: 'flex',
      backgroundColor: '#EEEAE3',
      borderRadius: '20px',
      alignItems: 'center',
      padding: '12px',
      color: theme.colors.CHARCOAL,
      gap: '12px',
      cursor: 'pointer',
      userSelect: 'none',
      position: 'relative',
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      paddingTop: '2px',
    },
    restaurantDropdownArrow: {
      marginLeft: 'auto',
      color: theme.colors.BLACK,
      transitionDuration: '0.1s',
      flexShrink: 0,
      marginRight: '12px',
    },
    accountMenuContent: {
      pointerEvents: 'auto',
      backgroundColor: theme.colors.LINEN,
      padding: '12px',
      borderRadius: '10px',
      lineHeight: 'normal',
    },
    navigationLinks: {
      display: 'flex',
      flexDirection: 'column',
    },
    navigationLink: {
      padding: '6px 12px',
      borderRadius: '12px',
      cursor: 'pointer',
      userSelect: 'none',
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      transition: 'all 0.1s',
      lineHeight: '30px',
      marginTop: '4px',

      '&:hover': {
        backgroundColor: '#EEEAE3',
      },
    },
    divider: {
      marginTop: '8px',
      borderColor: '#E9E9E9',
    },
    logoContainer: {
      padding: '6px',
      borderRadius: '14px',
      background: '#FAD14A',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexShrink: 0,
    },
    restaurantAddress: {
      fontSize: '15px',
      color: theme.colors.smoke,
    },
  }),
  { name: 'RestaurantSwitcher' },
);

export default useStyles;
