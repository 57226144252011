import PropTypes from 'prop-types';
import React from 'react';
// import logoEatClub from '../components/login/assets/logoRedEatClub.png';
// import logoCrave from '../components/login/assets/logoRedCrave.png';
import LogoEatClub from '../assets/logoEatClub.svg';
// import CraveLogoImage from '../assets/logoEatClub.svg';

const defaultStyle = {
  color: '#E54439',
};

export const AppLogo = ({ style = {} }) => {
  switch (import.meta.env.VITE_WEBSITE_NAME) {
    case 'Crave':
      // TODO get crave svg
      return <LogoEatClub style={style} />;
    case 'EatClub':
    default:
      return <LogoEatClub style={{ ...defaultStyle, ...style }} />;
  }
};

AppLogo.propTypes = {
  style: PropTypes.shape({}),
};
