import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontWeight: 500,
  },
  card: {
    height: '100%',
  },
  redemptionTableContainer: {
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '308px',
      maxHeight: '308px',
    },
  },
}));

export default useStyles;
