import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Spacer } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateRestaurantTimePeriodsAction } from '../../../actions/restaurantPeriodsAction';
import { CleanDropdown } from '../../../components/Dropdown/CleanDropdown';
import useStyles, { dropdownErrorStyles, dropdownStyles } from './OpeningHoursEditModalStyles';

const OpeningHourDropdown = ({ onSelect, error = null, items, value }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableCell}>
      <Spacer direction='vertical' gap={2}>
        <CleanDropdown
          items={items}
          value={value}
          style={{
            ...dropdownStyles,
            ...(error && dropdownErrorStyles),
          }}
          onSelect={onSelect}
        />
        {error?.error && <Box style={{ fontSize: '10px', color: 'red' }}>{error.error}</Box>}
      </Spacer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  restaurantPeriods: state.restaurantPeriods,
  activeRestaurant: state.restaurantActive.restaurant,
});

OpeningHourDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]).isRequired,
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.shape({ error: PropTypes.string }),
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateRestaurantTimePeriods: updateRestaurantTimePeriodsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpeningHourDropdown);
