export const outlinedTabBarStyles = (theme) => ({
  fontWeight: theme.fontWeights.regular,

  tabContainer: {
    position: 'relative',
  },
  containerInner: {
    gap: '6px',
    flexWrap: 'wrap',
  },
  tab: {
    justifyContent: 'flex-start',
    color: theme.colors.smoke,

    padding: '0 10px',
    borderRadius: '50px',
    fontSize: '15px',
    lineHeight: '15px',
    // minWidth: '100px',
    transition: 'background-color 0.1s, border 0.1s, color 0.1s',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    overflowX: theme.overlay,
    height: '36px',

    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    borderBottom: `1px solid transparent`, // The base component specifically sets the bottom border to be red
  },
  tabActive: {
    paddingBottom: '10px',
    color: theme.colors.black,
    backgroundPositionY: '80%',
    fontWeight: theme.fontWeights.regular,

    backgroundColor: 'transparent',
    padding: '9px 13px',

    borderTop: `1px solid ${theme.colors.black}`,
    borderLeft: `1px solid ${theme.colors.black}`,
    borderRight: `1px solid ${theme.colors.black}`,
    borderBottom: `1px solid ${theme.colors.black}`, // The base component specifically sets the bottom border to be red
  },
  tabHover: {
    backgroundColor: 'transparent',
    color: theme.colors.black,
    borderTop: `1px solid ${theme.colors.black}`,
    borderLeft: `1px solid ${theme.colors.black}`,
    borderRight: `1px solid ${theme.colors.black}`,
    borderBottom: `1px solid ${theme.colors.black}`, // The base component specifically sets the bottom border to be red
    filter: 'none',
  },
});
