import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { activeOffersForRestaurant } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchActiveOffersForRestaurantAction =
  (restaurantId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: `ACTIVE_OFFERS_FOR_RESTAURANT_${restaurantId}`,
    });

    dispatch({
      type: type.FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_PENDING,
    });

    (async () => {
      try {
        const offersResponse = await API.graphql(
          {
            query: activeOffersForRestaurant,
            variables: { restId: restaurantId },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restaurantId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { activeOffersForRestaurant: offers },
        } = offersResponse;

        devLog('success', 'active offers for restaurant', offers);

        dispatch({
          type: type.FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_SUCCESS,
          payload: { offers },
        });
      } catch (error) {
        devLog('error', 'active offers for restaurant', error);

        dispatch({
          type: type.FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_FAILURE,
          payload: `Unable to retrieve active offers for restaurant: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: `ACTIVE_OFFERS_FOR_RESTAURANT_${restaurantId}`,
        });
      }
    })();
  };
