export const regions = () => [
  { label: 'Australian Capital Territory', value: 'ACT' },
  { label: 'New South Wales', value: 'NSW' },
  { label: 'Northern Territory', value: 'NT' },
  { label: 'South Australia', value: 'SA' },
  { label: 'Queensland', value: 'QLD' },
  { label: 'Tasmania', value: 'TAS' },
  { label: 'Victoria', value: 'VIC' },
  { label: 'Western Australia', value: 'WA' },
];

export const countries = () => [{ label: 'Australia', value: 'Australia' }];

export const weekdays = () => [
  { label: 'Monday', value: 'mon' },
  { label: 'Tuesday', value: 'tue' },
  { label: 'Wednesday', value: 'wed' },
  { label: 'Thursday', value: 'thu' },
  { label: 'Friday', value: 'fri' },
  { label: 'Saturday', value: 'sat' },
  { label: 'Sunday', value: 'sun' },
];

// TODO could turn this into a function that generates times at a custom interval and custom format
export const times = [
  { label: '12:00am', value: 0 },
  { label: '12:30am', value: 30 },
  { label: '1:00am', value: 60 },
  { label: '1:30am', value: 90 },
  { label: '2:00am', value: 120 },
  { label: '2:30am', value: 150 },
  { label: '3:00am', value: 180 },
  { label: '3:30am', value: 210 },
  { label: '4:00am', value: 240 },
  { label: '4:30am', value: 270 },
  { label: '5:00am', value: 300 },
  { label: '5:30am', value: 330 },
  { label: '6:00am', value: 360 },
  { label: '6:30am', value: 390 },
  { label: '7:00am', value: 420 },
  { label: '7:30am', value: 450 },
  { label: '8:00am', value: 480 },
  { label: '8:30am', value: 510 },
  { label: '9:00am', value: 540 },
  { label: '9:30am', value: 570 },
  { label: '10:00am', value: 600 },
  { label: '10:30am', value: 630 },
  { label: '11:00am', value: 660 },
  { label: '11:30am', value: 690 },
  { label: '12:00pm', value: 720 },
  { label: '12:30pm', value: 750 },
  { label: '1:00pm', value: 780 },
  { label: '1:30pm', value: 810 },
  { label: '2:00pm', value: 840 },
  { label: '2:30pm', value: 870 },
  { label: '3:00pm', value: 900 },
  { label: '3:30pm', value: 930 },
  { label: '4:00pm', value: 960 },
  { label: '4:30pm', value: 990 },
  { label: '5:00pm', value: 1020 },
  { label: '5:30pm', value: 1050 },
  { label: '6:00pm', value: 1080 },
  { label: '6:30pm', value: 1110 },
  { label: '7:00pm', value: 1140 },
  { label: '7:30pm', value: 1170 },
  { label: '8:00pm', value: 1200 },
  { label: '8:30pm', value: 1230 },
  { label: '9:00pm', value: 1260 },
  { label: '9:30pm', value: 1290 },
  { label: '10:00pm', value: 1320 },
  { label: '10:30pm', value: 1350 },
  { label: '11:00pm', value: 1380 },
  { label: '11:30pm', value: 1410 },
  { label: '12:00am', value: 1440 }, // Since the day finishes at midnight too
];
