import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Checkbox, Spacer } from '@eatclub-apps/ec-component-library';
import CleanDatePopover from './CleanDatePopover';
import { newOfferProps } from '../../../data/models/Offer';
import { capitaliseFirstLetter } from '../../../utils/helpers';

const ToggleButton = ({ selected, label, onChange, disabled }) => {
  return (
    <Box
      style={{
        height: '40px',
        border: '1px solid #AEAEAE',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '80px',
        borderColor: (disabled && '#f0f0f0') || (selected ? '#313131' : '#aeaeae'),
        backgroundColor: (disabled && '#f0f0f0') || (selected && '#FAFBEE'),
        userSelect: 'none',
        cursor: !disabled && 'pointer',
        color: disabled && '#6e6e6d',
      }}
      onClick={() => !disabled && onChange(!selected)}
    >
      {label}
    </Box>
  );
};

const OfferDate = ({ newOffer, setNewOffer, hoursParsed }) => {
  const handleChange = (newValues) => {
    // Invalid if all days are deselected, which we now allow
    const isValid =
      !newValues?.recurringDays || Object.values(newValues?.recurringDays).some((value) => !!value);

    setNewOffer({
      ...newOffer,
      date: { ...newOffer.date, value: { ...newOffer.date.value, ...newValues } },
      offerAmount: {
        ...newOffer.offerAmount,
        enabled: isValid,
      },
      whatTimes: {
        ...newOffer.whatTimes,
        enabled: isValid,
      },
      offerLimit: {
        ...newOffer.offerLimit,
        enabled: isValid,
      },
    });
  };

  const closed = (day) =>
    Object.entries(hoursParsed).some(
      ([label, value]) => label.substring(0, 3) === day && value === -1,
    );

  const items = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => {
    return {
      label: capitaliseFirstLetter(day),
      value: day,
      disabled: closed(day),
    };
  });

  const noOpeningHours = items.every((item) => item.disabled);

  const isValidDate = (date) => {
    const day = dayjs(date).format('ddd').toLowerCase();

    return !items.some((item) => item.value === day && item.disabled);
  };

  return (
    <Spacer direction='vertical' gap='m'>
      {/* Recurring days */}
      {newOffer.date.value.recurring && (
        <Spacer style={{ overflow: 'auto' }}>
          {items.map((item) => (
            <ToggleButton
              selected={newOffer.date.value.recurringDays[item.value]}
              onChange={(selected) => {
                handleChange({
                  recurringDays: {
                    ...newOffer.date.value.recurringDays,
                    [item.value]: selected,
                  },
                });
              }}
              label={item.label}
              disabled={item.disabled}
            />
          ))}
        </Spacer>
      )}

      {!noOpeningHours && (
        <>
          {/* Recurring */}
          <Checkbox
            selected={!newOffer.date.value.recurring}
            onChange={(selected) => handleChange({ recurring: !selected })}
            color='black'
            label='Don’t repeat: one-time only'
          />

          {/* Start date */}
          <CleanDatePopover
            label='Start date'
            value={newOffer.date.value.startDate}
            onSelect={(dayjs) => {
              handleChange({
                startDate: dayjs,
                recurringDays: {
                  ...newOffer.date.value.recurringDays,
                  [dayjs.format('ddd').toLocaleLowerCase()]: true,
                },
              });
            }}
            format='DD/MM/YYYY'
            style={{
              label: { fontSize: '16px', color: '#6E6E6D', width: '78px' },
              value: {
                fontSize: '16px',
                color: '#4F4F4F',
                border: '1px solid #AEAEAE',
                width: '240px',
              },
            }}
            datePickerProps={{ filterDate: isValidDate }}
          />
        </>
      )}
    </Spacer>
  );
};

OfferDate.propTypes = {
  newOffer: newOfferProps.isRequired,
  setNewOffer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hoursParsed: state.restaurantActive.restaurant.hoursParsed,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfferDate);
