import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    container: {
      overflow: 'hidden',
    },
    tabBar: {
      display: 'flex',
      gap: 8,
      maxWidth: '100%',
      overflowX: 'auto',
      paddingBottom: '10px',
      flexWrap: 'wrap',
    },
    offerDay: {
      marginTop: '30px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    dayName: {
      fontWeight: '500',
    },
    offerCard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      boxShadow: '0px 4px 4px 0px #DADADA40',
      borderRadius: '24px',
      overflow: 'hidden',
    },
    futureOffersHeading: {
      paddingTop: '40px',
      fontWeight: '500',
    },
    notificationMessage: {
      backgroundColor: '#EFE8DE',
      width: '100%',
      padding: '16px',
      marginTop: '16px',
      marginBottom: '32px',
      fontSize: '18px',
      borderRadius: '32px',
      lineHeight: '24px',
    },
  },
  { name: 'OffersByDay' },
);

export default useStyles;
