import { Box } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TextArea } from '@eatclub-apps/ec-component-library';
import { useDispatch } from 'react-redux';
import { endGoalAction } from '../../../actions/goalsAction';
import { dealsPropTypes } from '../../../data/models/Deal';
import { goalPropTypes } from '../../../data/models/Goal';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, pluralise } from '../../../utils/helpers';
import useStyles from './ModalStyles';
import { Modal } from '../../Modal';
import { Heading } from '../../Heading';

const EndGoalModal = ({ isOpen, onClose, onSave = () => {}, targetRevenue, goal, deals }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const today = dayjs().startOf('day');
  const end = dayjs(goal?.endDate);
  const daysLeft = Math.max(0, end.diff(today, 'days')); // Max is to make sure we don't go negative

  const [endGoalReason, setEndGoalReason] = useState('');
  const [submitting, setSubmitting] = useState(false);

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: end_goal_modal');
    }
  }, [isOpen]);

  const save = () => {
    setSubmitting(true);
    trackEvent('button_click: confirm_end_goal');

    dispatch(endGoalAction(goal?.objectId, endGoalReason))
      .then(onSave)
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal
      confirmLabel='End my goal'
      heading='End your current goal'
      isLoading={submitting}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={save}
    >
      <Box>This will remove all of your offers from the EatClub Marketplace.</Box>

      <Box mt='20px' className={classes.modalContentSmall}>
        <Box className={classes.impactRowSmall}>
          <Box>Current revenue goal</Box>
          <Box className={classes.impactValue}>{formatCurrency(targetRevenue, false)}</Box>
        </Box>
        <Box className={classes.impactRowSmall}>
          <Box>Total Offers</Box>
          <Box className={classes.impactValue}>{deals?.data?.length}</Box>
        </Box>
        <Box className={classes.impactRowSmall}>
          <Box>Current total revenue</Box>
          <Box className={classes.impactValue}>{formatCurrency(goal?.revenue, false)}</Box>
        </Box>
        <Box className={classes.impactRowSmall}>
          <Box>Days left</Box>
          <Box className={classes.impactValue}>
            {daysLeft} {pluralise('day', daysLeft)}
          </Box>
        </Box>
      </Box>

      <Box mt='20px'>
        <Heading size='4' className={classes.subheading}>
          Let us know why
        </Heading>

        <Box mt='10px'>
          <TextArea
            placeholder="I'm ending my goal because..."
            resizable={false}
            value={endGoalReason}
            onChange={setEndGoalReason}
          />
        </Box>
      </Box>
    </Modal>
  );
};

EndGoalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  targetRevenue: PropTypes.number.isRequired,
  goal: goalPropTypes.isRequired,
  deals: dealsPropTypes.isRequired,
};

export default EndGoalModal;
