import * as type from '../actions/types';

const initialState = {
  platform: 'eatclub',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_PLATFORM:
      return {
        ...state,
        platform: action.payload,
      };

    case type.FETCH_RESTAURANT_SUCCESS: {
      const { restaurant } = action.payload;

      let newPlatform = 'eatclub';

      // NOTE: if current platform exists in the new restaurant, return it, otherwise default to eatclub
      if (restaurant?.platform) {
        const availablePlatforms =
          !restaurant.platform || restaurant.platform === 'both'
            ? ['eatclub', 'whitelabel']
            : [restaurant.platform];

        const holdsCurrentPlatform = availablePlatforms.find(
          (platform) => platform === state.platform,
        );

        newPlatform = holdsCurrentPlatform || restaurant.platform;
      }

      return {
        ...state,
        platform: newPlatform,
      };
    }

    default:
      return state;
  }
};
