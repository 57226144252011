// eslint-disable-next-line
import Symbol_observable from 'symbol-observable'; // Fix for redux polyfill bug

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import './init';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider as RollbarProvider, ErrorBoundary, historyContext } from '@rollbar/react';
import { createBrowserHistory } from 'history';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import minMax from 'dayjs/plugin/minMax';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import CallRequest from './components/bookingConfirmation/CallRequest';
import ConfirmBooking from './components/bookingConfirmation/ConfirmBooking';
import AllOpportunities from './components/opportunity/AllOpportunities';
import configureRollbar from './rollbar';
import store from './store';
import theme from './AppTheme';
import AppWithAuth from './components/auth/AppWithAuth';
import Opportunity from './components/opportunity/Opportunity';
import AppContainer from './AppContainer';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

// NOTE: enable dayjs in window to test in browser
if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === 'development') {
  window.dayjs = dayjs;
}

const { rollbar } = configureRollbar();

const history = createBrowserHistory();
history.listen(historyContext(rollbar, {}));

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <RollbarProvider instance={rollbar}>
    <ErrorBoundary>
      <Provider store={store}>
        <CssBaseline>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AppContainer>
                  <Router>
                    <Switch>
                      <Route exact path='/oppo/:oppoId'>
                        <Opportunity />
                      </Route>
                      <Route exact path='/alloppos/:restId'>
                        <AllOpportunities />
                      </Route>
                      <Route exact path='/booking-confirm/:bookingId'>
                        <ConfirmBooking />
                      </Route>
                      <Route exact path='/booking-call/:bookingId'>
                        <CallRequest />
                      </Route>
                      <Route path='*'>
                        <AppWithAuth />
                      </Route>
                    </Switch>
                  </Router>
                </AppContainer>
              </LocalizationProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </CssBaseline>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>,
);
