import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { menuItemsForMenu } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchMenuItemsAction = (menuId, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'MENU_ITEMS',
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: menuItemsForMenu,
          variables: {
            menuId,
            pageSize: 500,
            offset: 0,
            includeOptions: true,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'menu items', response.data.menuItemsForMenu);

      dispatch({
        type: type.FETCH_MENU_ITEMS_SUCCESS,
        payload: { items: response.data.menuItemsForMenu },
      });
    } catch (error) {
      devLog('success', 'menu items', error);

      dispatch({
        type: type.FETCH_MENU_ITEMS_FAILURE,
        payload: `Unable to retrieve MENU ITEMS: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'MENU_ITEMS',
      });
    }
  })();
};

export const clearMenuItemsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_MENU_ITEMS,
  });
};
