import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontWeight: 500,
  },
  chart: {
    width: ['100%', '!important'],
  },
  chartHeight: {
    height: '300px',
  },
  card: {
    height: '100%',
  },
}));

export default useStyles;
