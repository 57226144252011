import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: ({ style, smoothTransition, diff }) => ({
    transform: smoothTransition && `translateX(${diff * -1}px)`,
    transition: smoothTransition && 'transform 0.1s',
    position: 'relative',
    scrollbarWidth: 'none',
    scrollBehavior: 'smooth',
    width: '100%',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-moz-scrollbar': {
      display: 'none',
    },

    '&:hover': {
      cursor: 'pointer',
    },
    ...style,
  }),
}));

export default useStyles;
