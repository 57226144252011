import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  CenteredContent,
  InlineTextField,
  TextArea,
  Dropdown,
} from '@eatclub-apps/ec-component-library';
import { dealPropTypes } from '../../../data/models/Deal';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, getOfferTime } from '../../../utils/helpers';
import useStyles from './ModalStyles';
import Pencil from '../../../assets/pencil.svg';
import ModalSectionHeader from './ModalSectionHeader';
import { Modal } from '../../Modal';

const RequestEditOfferModal = ({ isOpen, offer, onClose, onSave }) => {
  const classes = useStyles();

  const [editOfferReason, setEditOfferReason] = useState(null);
  const [editOfferAdditionalReason, setEditOfferAdditionalReason] = useState(null);

  const [contactName, setContactName] = useState(null);
  const [contactPhone, setContactPhone] = useState(null);

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: edit_offer_request');
    }
  }, [isOpen]);

  const confirm = () => {
    onSave(editOfferReason, editOfferAdditionalReason, contactName, contactPhone);
  };

  return (
    <Modal
      confirmLabel='Request a call'
      heading='Edit offer'
      headingStartIcon={<Pencil height='24px' width='24px' />}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirm}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Box>
          <Box className={classes.inlineText}>
            <Box>
              <Box className={classes.discountLabel}>{offer?.discount}</Box>
            </Box>
            <CenteredContent>
              on {getDayFromInt(offer?.dayOfWeek)} {getOfferTime(offer)}
              {offer.recurring ? ', repeats weekly' : ''}
            </CenteredContent>
          </Box>
        </Box>

        <Box style={{ maxWidth: '580px' }}>
          This offer was set up by your account manager. Request a call and they’ll be in touch
          shortly to help you make any changes.
        </Box>

        <Box>
          <ModalSectionHeader header='Let us know why' />

          <Box mt='20px' display='flex' flexDirection='column' style={{ gap: '15px' }}>
            <Dropdown
              allowDeselect
              items={[
                { label: 'Restaurant became busy', value: 'busy' },
                { label: 'Staffing issue', value: 'staffIssue' },
                { label: "Time isn't suitable", value: 'badTime' },
                { label: 'Offer percentage too high', value: 'offerTooHigh' },
                { label: 'Other', value: 'other' },
              ]}
              onSelect={setEditOfferReason}
              placeholder='Select a reason for editing the offer'
              value={editOfferReason}
            />

            <TextArea
              placeholder='Additional Reason?'
              resizable={false}
              value={editOfferAdditionalReason}
              onChange={setEditOfferAdditionalReason}
            />
          </Box>
        </Box>

        <Box>
          <ModalSectionHeader header='Contact details' />

          <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
            <Box>Name</Box>
            <InlineTextField
              value={contactName}
              onChange={setContactName}
              style={{
                textFieldInner: { padding: '5px 10px' },
                input: { width: '100%' },
              }}
              placeholder='Empty (optional)'
              maxCharacters={24}
            />
          </Box>

          <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
            <Box>Phone</Box>
            <InlineTextField
              value={contactPhone}
              onChange={setContactPhone}
              style={{
                textFieldInner: { padding: '5px 10px' },
                input: { width: '100%' },
              }}
              placeholder='Empty (optional)'
              maxCharacters={24}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

RequestEditOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default RequestEditOfferModal;
