/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@mui/material/styles';

export const CleanDropdown = ({
  label = '',
  value = '',
  items = [],
  onSelect = () => {},
  onFocus = () => {},
  onBlur = () => {},
  style = {},
  multiSelect = false,
  disabled = false,
  ...other
}) => {
  const theme = useTheme();
  const styles = {
    ...style,
    outerContainer: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      height: '40px',
      ...style?.outerContainer,
    },
    disabled: {
      color: theme.colors.nimbus,
      ...style?.disabled,
    },
    label: {
      color: theme.colors.smoke,
      marginBottom: '0',
      width: '100px',
      ...style?.label,
    },
    dropdown: {
      border: `1px solid ${theme.colors.dropdownOutline}`,
      borderBottom: `1px solid ${theme.colors.dropdownOutline}`,
      backgroundColor: theme.colors.pageBackground,
      width: '100%',
      color: theme.colors.charcoal,
      borderRadius: '8px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '16px',
      paddingRight: '40px',
      minWidth: '250px',
      lineHeight: '18px',
      transition: 'background-color 0.1s',
      ...style?.dropdown,
    },
    dropdownHover: {
      backgroundColor: theme.colors.white,
      filter: 'none',
      ...style?.dropdownHover,
    },
    dropdownOpen: {
      border: `1px solid #aeaeae`,
      // borderBottom: `1px solid ${theme.colors.charcoal}`,
      borderBottom: '1px solid transparent',
      backgroundColor: theme.colors.dropdownGray,
      borderRadius: '6px 6px 0 0',
      ...style?.dropdownOpen,
    },
    menu: {
      fontSize: '16px',
      top: '36px',
      maxHeight: '400px',
      ...style?.menu,
    },
    dropdownContent: {
      maxWidth: '200px',
      ...style?.dropdownContent,
    },
    dropdownContentInner: {
      maxWidth: '100%',
      padding: '10px 14px',
      lineHeight: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...style?.dropdownContentInner,
    },
    inputHover: {
      backgroundColor: theme.colors.white,
      ...style?.inputHover,
    },
    placeholder: {
      color: theme.colors.nimbus,
      ...style?.placeholder,
    },
    menuItem: {
      padding: '16px 14px',
    },
  };

  return (
    <Dropdown
      label={label}
      value={value}
      items={items}
      type='text'
      onSelect={onSelect}
      placeholder='Empty'
      onFocus={onFocus}
      onBlur={onBlur}
      style={styles}
      disabled={disabled}
      {...other}
    />
  );
};

CleanDropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.shape({}),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  multiSelect: PropTypes.bool,
  disabled: PropTypes.bool,
};
