import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dropdown, Button, Checkbox } from '@eatclub-apps/ec-component-library';
import { fetchDealsForGoalAction } from '../../../actions/dealsForGoalAction';
import { fetchGoalsAction, editGoalAction } from '../../../actions/goalsAction';
import { dealsPropTypes } from '../../../data/models/Deal';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';
import { goalsPropTypes } from '../../../data/models/Goal';
import { trackEvent } from '../../../utils/analytics';
import { canEditAll, isSet, formatCurrency, isStaffUser } from '../../../utils/helpers';

import EndGoalModal from '../Modals/EndGoalModal';
import RevenueGoalModal from '../Modals/RevenueGoalModal';
import DeleteGoalModal from '../Modals/DeleteGoalModal';
import useStyles from './EditGoalStyles';
import RequestEndGoalModal from '../Modals/RequestEndGoalModal';
import { requestGoalDeleteAction } from '../../../actions/requestCallAction';

const EditGoalForm = ({
  restId,
  fetchGoals,
  goals,
  fetchDealsForGoal,
  deals,
  editGoal,
  userId,
  userType,
  requestGoalDelete,
  user,
}) => {
  const history = useHistory();
  const { goalId } = useParams();
  const classes = useStyles();

  const [goal, setGoal] = useState(null);
  const [amount, setAmount] = useState(null);
  const [duration, setDuration] = useState(null);
  const [targeting, setTargeting] = useState(null);
  const [priceAdjustment, setPriceAdjustment] = useState(null);
  const [errors, setErrors] = useState({});
  const [showRevenueModal, setShowRevenueModal] = useState(false);
  const [showEndGoalModal, setShowEndGoalModal] = useState(false);
  const [showRequestEndGoalModal, setShowRequestEndGoalModal] = useState(false);
  const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);

  // Fetch goals if we haven't yet
  useEffect(() => {
    fetchGoals(restId);
  }, [fetchGoals, restId, goalId]);

  useEffect(() => {
    fetchDealsForGoal(restId, goalId);
  }, [fetchDealsForGoal, restId, goalId]);

  // Find the goal we are trying to edit
  useEffect(() => {
    const goalEdited = goals?.data?.find((someGoal) => `${someGoal?.objectId}` === `${goalId}`);
    // Set default field values TODO fix render thrashing
    setAmount(goalEdited?.targetRevenue);
    setDuration(goalEdited?.duration);
    // setTargeting(goalEdited?.amount);
    setPriceAdjustment(goalEdited?.autoAdjust);

    setGoal(goalEdited);
  }, [goalId, goals]);

  const save = () => {
    editGoal(restId, userId, userType, goalId, duration, priceAdjustment);

    // TODO redirect on success. Create a new reducer to handle editing state
  };

  if (goals.pending) {
    return <CircularProgress />;
  }

  if (!isSet(goal)) {
    return <Box>Goal could not be found</Box>;
  }

  const labelStyles = {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '10px',
  };

  // TODO move this to theme when EC Components can be overridden
  const componentStyles = {
    dropdown: {
      label: labelStyles,
    },
    radioCardGroup: {
      label: labelStyles,
    },
    checkbox: {
      container: {
        fontSize: '14px',
      },
      checkboxBox: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
      },
      checkMark: { top: '2px', left: '2px' },
    },
    segmentedControl: {
      label: labelStyles,
      groupContainer: {},
      option: {
        width: '170px',
      },
    },
    textField: {
      label: labelStyles,
    },
    toggleGroup: {
      label: labelStyles,
      option: {
        flexGrow: 1,
        textAlign: 'left',
        borderColor: COLORS.GRAY_BORDER,
        borderRadius: '4px',
        padding: '10px',
        fontSize: '18px',
        width: '135px',
      },
      selectedOption: {
        backgroundColor: COLORS.OLIVE,
        borderColor: COLORS.OLIVE,
      },
    },
    textSelect: {
      label: labelStyles,
    },
  };

  const requestEndGoal = (reason, reasonMessage, contact, phone) => {
    trackEvent('button_click: confirm_end_goal_request');

    requestGoalDelete(restId, userId, userType, goalId, reason, reasonMessage, contact, phone);
    setShowRequestEndGoalModal(false);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.form}>
        {/* Fake text field */}
        <Box>
          <Box style={labelStyles} mb='10px'>
            Revenue goal amount
          </Box>
          <Box className={classes.fakeTextField}>
            <Box>{formatCurrency(amount, false)}</Box>
            <Button type='text' onClick={() => setShowRevenueModal(true)}>
              Change
            </Button>
          </Box>
        </Box>
        <Dropdown
          label='Duration'
          value={duration}
          items={[
            { label: '1 month', value: 'monthly' },
            { label: 'Ongoing', value: 'ongoing' },
          ]}
          placeholder='Please select a duration'
          onSelect={setDuration}
          style={{ label: labelStyles }}
          error={errors?.dropdown}
        />
        {/* <RadioCardGroup */}
        {/*  label='Customer targeting' */}
        {/*  items={[ */}
        {/*    { label: '+10% for new customers', value: 'new' }, */}
        {/*    { label: '+10% for new churned', value: 'churned' }, */}
        {/*  ]} */}
        {/*  value={targeting} */}
        {/*  onSelect={setTargeting} */}
        {/*  style={styles.radioCardGroup} */}
        {/* /> */}
        <Box>
          <Box style={labelStyles} mb='10px'>
            Intelligent pricing
          </Box>
          <Checkbox
            selected={priceAdjustment}
            onChange={setPriceAdjustment}
            style={componentStyles.checkbox}
            color='#313131'
            label='Enable offers to slightly adjust in market to help you reach your goal.'
          />
        </Box>

        <Button
          type='text'
          style={{ fontWeight: FONT_WEIGHTS.MEDIUM, marginTop: '12px' }}
          onClick={() =>
            isStaffUser(user) ? setShowEndGoalModal(true) : setShowRequestEndGoalModal(true)
          }
        >
          End my goal
        </Button>
        {canEditAll(user) && (
          <Button
            type='text'
            style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}
            onClick={() => setShowDeleteGoalModal(true)}
          >
            Delete my goal
          </Button>
        )}
      </Box>

      <Box className={classes.formFooter}>
        <Box className={classes.footerButtonContainer}>
          <Button disabled={!duration} onClick={save}>
            Save changes
          </Button>
        </Box>
      </Box>

      <RevenueGoalModal
        isOpen={showRevenueModal}
        targetRevenue={goal?.targetRevenue}
        onClose={() => setShowRevenueModal(false)}
        onSave={() => {
          trackEvent('button_click: confirm_update_revenue_goal');
          history.push('/goals/create');
        }}
      />

      <EndGoalModal
        isOpen={showEndGoalModal}
        targetRevenue={goal?.targetRevenue}
        goal={goal}
        deals={deals}
        onClose={() => setShowEndGoalModal(false)}
        onSave={() => history.push('/offers')}
      />

      <RequestEndGoalModal
        isOpen={showRequestEndGoalModal}
        targetRevenue={goal?.targetRevenue}
        goal={goal}
        deals={deals}
        onClose={() => setShowRequestEndGoalModal(false)}
        onSave={requestEndGoal}
      />

      <DeleteGoalModal
        isOpen={showDeleteGoalModal}
        goalId={goal?.objectId}
        onClose={() => setShowDeleteGoalModal(false)}
        onSave={() => history.push('/offers')}
      />

      {/* {dealUpdate.error && <Error error={dealUpdate.error} message={dealUpdate.errorMessage} />} */}
    </Box>
  );
};

EditGoalForm.propTypes = {
  goals: goalsPropTypes.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  fetchGoals: PropTypes.func.isRequired,
  fetchDealsForGoal: PropTypes.func.isRequired,
  editGoal: PropTypes.func.isRequired,
  requestGoalDelete: PropTypes.func.isRequired,
  deals: dealsPropTypes.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  goals: state.goals,
  deals: state.dealsForGoal,
  restId: state.restaurantActive.restaurant.objectId,
  user: state.user,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGoals: fetchGoalsAction,
      fetchDealsForGoal: fetchDealsForGoalAction,
      editGoal: editGoalAction,
      requestGoalDelete: requestGoalDeleteAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditGoalForm);
