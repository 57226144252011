import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLogoutAction } from '../../actions/loginAction';

const AppWithAuth = ({ setLogout }) => {
  useEffect(() => {
    setLogout();
  }, []);

  return <Box>Logging out...</Box>;
};

AppWithAuth.propTypes = {
  setLogout: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setLogout: setLogoutAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppWithAuth);
