import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ChevronRight from '../../../assets/chevron_right.svg';
import Swipe from '../Swipe/Swipe';
import useStyles from './SliderStyles';

/**
 * A generic Slider for showing a list of items horizontally.
 */
const Slider = ({ items, style, defaultPositionRight = false }) => {
  const classes = useStyles({ style });
  const theme = useTheme();
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const wrapperRef = useRef(null);
  const scrollRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(defaultPositionRight ? 999999 : 0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const scrollAmount = 300;

  const listener = () => setScrollLeft(wrapperRef.current.scrollLeft);

  useEffect(() => {
    wrapperRef.current.addEventListener('scroll', listener);

    return () => {
      wrapperRef?.current?.removeEventListener('scroll', listener);
    };
  }, [wrapperRef]);

  // If items change, scroll to default
  useEffect(() => {
    const newScrollLeft = defaultPositionRight ? 999999 : 0;
    setScrollLeft(newScrollLeft);
    wrapperRef.current.scrollLeft = newScrollLeft;
  }, [items.length]);

  useEffect(() => {
    setScrollWidth(scrollRef.current.scrollWidth);
    setClientWidth(wrapperRef.current.clientWidth);
  }, [scrollRef, wrapperRef, items.length]);

  const next = () => {
    setScrollLeft((wrapperRef.current.scrollLeft += scrollAmount));
  };

  const prev = () => {
    setScrollLeft((wrapperRef.current.scrollLeft -= scrollAmount));
  };

  const itemsToShow = 5;

  return (
    <Spacer direction='vertical' gap='m'>
      <Spacer style={{ position: 'relative' }}>
        {!breakpointSmDown && scrollLeft > 0 && items.length > itemsToShow && (
          <Box className={classes.button} style={{ left: '-38px' }} onClick={prev}>
            <ChevronRight style={{ transform: 'rotate(180deg)' }} />
          </Box>
        )}
        <Swipe
          ref={wrapperRef}
          onNext={next}
          onPrev={prev}
          style={style}
          smoothTransition={false}
          trackTouch={false}
        >
          <Box ref={scrollRef} className={classes.items}>
            {items.map((item) => (
              <Box key={item.key}>{item.component}</Box>
            ))}
          </Box>
        </Swipe>
        {!breakpointSmDown && scrollLeft < scrollWidth - clientWidth && (
          <Box className={classes.button} style={{ right: '-38px' }} onClick={next}>
            <ChevronRight />
          </Box>
        )}
      </Spacer>

      {/* Mobile buttons */}
      {breakpointSmDown && (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Left */}
          <Box>
            {scrollLeft > 0 && (
              <Box
                className={classes.button}
                style={{ position: 'relative', height: '40px', width: '80px' }}
                onClick={prev}
              >
                <ChevronRight style={{ transform: 'rotate(180deg)' }} />
              </Box>
            )}
          </Box>

          {/* Right */}
          <Box>
            {scrollLeft < scrollWidth - clientWidth && (
              <Box
                className={classes.button}
                style={{ position: 'relative', height: '40px', width: '80px' }}
                onClick={next}
              >
                <ChevronRight />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Spacer>
  );
};

Slider.defaultProps = {
  style: {},
  items: [],
};

Slider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  style: PropTypes.shape({}),
  defaultPositionRight: PropTypes.bool,
};

export default Slider;
