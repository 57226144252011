/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ComparePercent from '../comparePercent/ComparePercent';
import { getWhitelabelData, getCompareWhitelabelData } from '../../../reducers/analyticsHelpers';
import { formatNumber } from '../../../utils';
import { getChange, getPercent } from '../../../data/models/Analytics';

const AverageOrderValue = ({ averageOrder, compareAverageOrder }) => {
  const revenue = averageOrder;
  const revenueCompare = compareAverageOrder;
  const revenuePercent = getPercent(revenue, revenueCompare);
  const revenueChange = getChange(revenue, revenueCompare);

  const renderCurrency = (num) => {
    if (!Number.isFinite(revenue)) {
      return '-';
    }

    return `$${formatNumber(num, 0)}`;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} sm={12} xs={12}>
          <Typography style={{ fontWeight: 500 }}>Average Order Value</Typography>
        </Grid>

        <Grid item lg={12} sm={12} xs={12}>
          <Typography variant='h5' style={{ fontWeight: 500 }}>
            {renderCurrency(revenue)}
          </Typography>
          <ComparePercent percent={revenuePercent} change={revenueChange} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  averageOrder: getWhitelabelData(state.analytics.bookingsData, 'averageOrder'),
  compareAverageOrder: getCompareWhitelabelData(
    state.analytics.compareBookingsData,
    'compareAverageOrder',
  ),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AverageOrderValue);
