/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import MenuOptions from './MenuOptions';
import useStyles from './MenusStyles';
import MenuItemStock from './MenuItemStock';
import { formatNumber } from '../../../utils';

const MenuItem = ({ handleToggleItem, openItem, product }) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('sm'));
  const options = product.options ? product.options : [];

  return (
    <>
      <ListItem
        button={options.length > 0}
        onClick={handleToggleItem}
        style={{ paddingRight: breakpointMdDown ? '180px' : '270px', borderRadius: '14px' }}
      >
        <ListItemText
          primary={product.productTitle}
          secondary={product.productDescription ? product.productDescription : null}
        />

        <ListItemSecondaryAction
          style={{
            paddingRight: options.length === 0 ? '30px' : '',
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
          }}
        >
          <Typography
            display='inline'
            color='secondary'
            variant='subtitle1'
            style={{ padding: '0 1rem' }}
          >
            ${formatNumber(product.price)}
          </Typography>
          <MenuItemStock item={product} />
          {options.length > 0 && (
            <IconButton size='small' onClick={() => handleToggleItem(product.objectId)}>
              <ExpandMore className={`${classes.collapseHandle} ${openItem ? `expanded` : ``}`} />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {options.length > 0 && <MenuOptions options={options} openItem={openItem} />}
    </>
  );
};

MenuItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default MenuItem;
