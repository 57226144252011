import React from 'react';
import PropTypes from 'prop-types';
import { Box, Spacer, Checkbox } from '@eatclub-apps/ec-component-library';
import useStyles from './CreateOfferStyles';
import Bag from '../../../assets/icon_bag_square.svg';
import Chair from '../../../assets/icon_chair_square.svg';
import Drink from '../../../assets/icon_drink_square.svg';
import { newOfferProps } from '../../../data/models/Offer';

const Card = ({ checked, onChange, heading, description, icon }) => {
  const classes = useStyles({ checked });

  return (
    <Box onClick={() => onChange(!checked)} style={{ userSelect: 'none', cursor: 'pointer' }}>
      <Spacer className={classes.cardContainer} gap='m'>
        <Checkbox selected={checked} onChange={onChange} color='black' />

        <Spacer direction='vertical'>
          <Box className={classes.cardHeading}>{heading}</Box>
          <Box className={classes.cardDescription}>{description}</Box>
        </Spacer>

        {icon}
      </Spacer>
    </Box>
  );
};

const OfferService = ({ newOffer, setNewOffer, drinksOnly }) => {
  const handleChange = (newValues) => {
    const value = { ...newOffer.service.value, ...newValues };

    const valid = Object.entries(value).some(([k, v]) => v);

    const dateValid = !newOffer.date.error;

    const whatTimesValid =
      !newOffer.whatTimes.value.lightning ||
      (newOffer.whatTimes.value.lightning &&
        newOffer.whatTimes.value.startTime < newOffer.whatTimes.value.endTime);

    setNewOffer({
      ...newOffer,
      service: {
        ...newOffer.service,
        value,
        // error: valid ? '' : 'Please select one to continue.',
      },
      date: { ...newOffer.date, enabled: valid },
      whatTimes: { ...newOffer.whatTimes, enabled: valid && dateValid },
      offerAmount: { ...newOffer.offerAmount, enabled: valid && dateValid && whatTimesValid },
      offerLimit: { ...newOffer.offerLimit, enabled: valid && dateValid && whatTimesValid },
    });
  };

  return (
    <Spacer style={{ flexWrap: 'wrap' }}>
      {!drinksOnly && (
        <>
          <Card
            heading='Dine-in'
            description='Fill tables and build your dream ambience.'
            icon={<Chair style={{ flexShrink: 0 }} />}
            checked={newOffer.service.value.dineIn}
            onChange={(checked) => handleChange({ dineIn: checked })}
          />
          <Card
            heading='Takeaway'
            description='Create demand and pickup orders for your kitchen.'
            icon={<Bag style={{ flexShrink: 0 }} />}
            checked={newOffer.service.value.takeaway}
            onChange={(checked) => handleChange({ takeaway: checked })}
          />
        </>
      )}

      {drinksOnly && (
        <Card
          heading='Boost orders at the bar'
          description='Attract customers into your bar and maximise spending.'
          icon={<Drink style={{ flexShrink: 0 }} />}
          checked={newOffer.service.value.drinksOnly}
          onChange={(checked) => handleChange({ drinksOnly: checked })}
        />
      )}
    </Spacer>
  );
};

OfferService.propTypes = {
  newOffer: newOfferProps.isRequired,
  setNewOffer: PropTypes.func.isRequired,
};

export default OfferService;
