import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spacer, ToggleGroup, TextSelect } from '@eatclub-apps/ec-component-library';
import { newOfferProps } from '../../../data/models/Offer';
import { COLORS } from '../../../EatClubTheme';

const OfferLimit = ({ newOffer, setNewOffer, drinksOnly }) => {
  const labelStyles = {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
    color: COLORS.CHARCOAL,
  };

  const styles = {
    toggleGroup: {
      groupContainer: {
        gap: '12px',
      },
      label: labelStyles,
      option: {
        textAlign: 'left',
        borderColor: COLORS.GRAY_BORDER,
        borderRadius: '4px',
        padding: '10px',
        fontSize: '18px',
        width: '154px',
      },
      selectedOption: {
        borderColor: COLORS.OLIVE,
      },
    },
    textSelect: {
      label: labelStyles,
      textFieldContainer: { width: '158px' },
      textFieldRoot: { width: '100%' },
    },
  };

  const handleChange = (newValues) => {
    const value = { ...newOffer.offerLimit.value, ...newValues };

    setNewOffer({
      ...newOffer,
      offerLimit: { ...newOffer.offerLimit, value },
    });
  };

  useEffect(() => {
    handleChange({
      limit: [
        ...(newOffer.service.value.dineIn && newOffer.offerLimit.value.limit.includes('dineIn')
          ? ['dineIn']
          : []),
        ...(newOffer.service.value.takeaway && newOffer.offerLimit.value.limit.includes('takeaway')
          ? ['takeaway']
          : []),
      ],
    });
  }, [newOffer.service.value.dineIn, newOffer.service.value.takeaway]);

  return (
    <>
      <ToggleGroup
        value={newOffer.offerLimit.value.limit}
        onSelect={(value) => handleChange({ limit: value })}
        singleChoice={false}
        items={[
          ...(newOffer.service.value.dineIn ? [{ label: 'Dine-in', value: 'dineIn' }] : []),
          ...(newOffer.service.value.takeaway ? [{ label: 'Takeaway', value: 'takeaway' }] : []),
        ]}
        style={styles.toggleGroup}
      />

      <Spacer gap={20} style={{ flexWrap: 'wrap' }}>
        {newOffer.service.value.dineIn && newOffer.offerLimit.value.limit.includes('dineIn') && (
          <TextSelect
            label='Dine-in quantity'
            value={newOffer.offerLimit.value.dineInQuantity}
            onChange={(value) => handleChange({ dineInQuantity: value })}
            style={styles.textSelect}
            minNumber={1}
            increment
            fullWidth
          />
        )}

        {newOffer.service.value.takeaway &&
          newOffer.offerLimit.value.limit.includes('takeaway') && (
            <TextSelect
              label='Takeaway quantity'
              value={newOffer.offerLimit.value.takeawayQuantity}
              onChange={(value) => handleChange({ takeawayQuantity: value })}
              style={styles.textSelect}
              minNumber={1}
              increment
              fullWidth
            />
          )}
      </Spacer>
    </>
  );
};

OfferLimit.propTypes = {
  newOffer: newOfferProps.isRequired,
  setNewOffer: PropTypes.func.isRequired,
};

export default OfferLimit;
