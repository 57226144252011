import { useState } from 'react';

/**
 * Modified from https://dev.to/rakumairu/how-to-handle-swipe-event-on-react-carousel-24ab
 *
 * Handles swipe events on touch and mouse devices
 * @param {*} onNext callback for next swipe
 * @param {*} onPrev callback for previous swipe
 * @param {*} swipeDuration how long in pixels till the callbacks are triggered
 * @returns {[handleSwipeStart, handleSwipeMove, handleSwipeEnd, diff]}
 */
export const useSwipe = (onNext = () => {}, onPrev = () => {}, swipeDuration = 25) => {
  const [touchPosition, setTouchPosition] = useState(null);
  const [diff, setDiff] = useState(0);

  const onEnd = () => {
    setTouchPosition(null);
    setDiff(0);
  };

  const handleSwipeStart = (e) => {
    const touchDown = e.touches?.[0]?.clientX || e.clientX;
    setTouchPosition(touchDown);
  };

  const handleSwipeMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches?.[0]?.clientX || e.clientX;
    setDiff(touchDown - currentTouch);

    if (diff > swipeDuration) {
      onNext();
      onEnd();
    }

    if (diff < -swipeDuration) {
      onPrev();
      onEnd();
    }
  };

  const handleSwipeEnd = () => {
    onEnd();
  };

  return [handleSwipeStart, handleSwipeMove, handleSwipeEnd, diff];
};
