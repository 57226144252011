import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1,
    padding: '40px',
    backgroundColor: '#EFE8DE',
    borderRadius: '15px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    },
  },
  content: {},
  options: {
    display: 'inline-flex',
    lineHeight: '40px',
    flexWrap: 'wrap',
    gap: '10px',
  },
  option: {
    display: 'inline-flex',
    gap: '10px',
  },
  predictions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '800px',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
    gap: '12px',
  },
  launchButton: {
    width: '100%',
    maxWidth: '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useStyles;
