import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextSelect, SegmentedControl, ToggleGroup } from '@eatclub-apps/ec-component-library';
import { clearPostDailyOppoAction } from '../../../../actions/dealDailyOpposPostAction';
import { COLORS } from '../../../../EatClubTheme';
import { potentialRevenuesPropTypes } from '../../../../reducers/potentialRevenuesReducer';
import { trackEvent } from '../../../../utils/analytics';
import { roundToNearest } from '../../../../utils/helpers';
import useStyles from './EditParametersModalStyles';
import { Modal } from '../../../Modal';

const EditParametersModal = ({
  isOpen,
  onClose,
  onSave,
  revenueTarget,
  strategy,
  types,
  potentialRevenues,
  dineInMax,
}) => {
  const classes = useStyles();
  const [newRevenueTarget, setNewRevenueTarget] = useState(revenueTarget);
  const [newStrategy, setNewStrategy] = useState(strategy);
  const [newTypes, setNewTypes] = useState(types);
  const [selectionValues, setSelectionValues] = useState([]);
  const [newDineInMax, setNewDineInMax] = useState(dineInMax);

  // Refresh everything on open
  useEffect(() => {
    if (isOpen) {
      setNewRevenueTarget(revenueTarget);
      setNewStrategy(strategy);
      setNewTypes(types);
      setNewDineInMax(dineInMax);
    }
  }, [isOpen]);

  const getMaxRevenueForStrategy = () => {
    const revenueStrategy =
      newStrategy === 'lowerOffers' ? 'lowerOffersOptimized' : 'offPeakOptimized';
    return potentialRevenues.data[revenueStrategy];
  };

  // Set the selection values for the text select
  useEffect(() => {
    const createValueArray = (maxValue) => {
      const values = [
        // eslint-disable-next-line prefer-spread
        ...Array.apply(null, { length: 10 }).map(
          (_, num) => roundToNearest((maxValue / 10) * num, 50), // Rounded to nearest 10
        ),
        maxValue,
      ];

      const valueObject = values.map((value) => ({ label: `${value}`, value }));
      return valueObject;
    };

    setSelectionValues(createValueArray(getMaxRevenueForStrategy()));
  }, [newStrategy]);

  const save = () => {
    trackEvent('button_click: save_edit_parameters');
    onSave(newRevenueTarget, newStrategy, newTypes, newDineInMax);
    onClose();
  };

  // TODO move this to theme
  const labelStyles = {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '500',
  };

  const toggleGroupStyles = {
    container: {
      // flexGrow: 1,
    },
    toggleGroup: {
      // width: '100%',
    },
    groupOptionContainer: {
      flexGrow: 1,
    },
    label: labelStyles,
    option: {
      flexGrow: 1,
      textAlign: 'left',
      borderColor: COLORS.GRAY_BORDER,
      borderRadius: '4px',
      padding: '10px',
      fontSize: '18px',
      width: '150px',
    },
    selectedOption: {
      borderColor: COLORS.OLIVE,
    },
  };

  return (
    <Modal
      confirmLabel='Save changes'
      disabled={newTypes.length <= 0}
      heading='Edit parameters'
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={save}
      style={{
        heading: {
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <Box className={classes.container}>
        <Box>Changes will re-calculate your starting schedule of offers.</Box>

        <Box mt='30px'>
          <Box className={classes.optionsContainer}>
            <TextSelect
              onChange={(value) => setNewRevenueTarget(parseInt(value, 10))}
              validation='numbers_only'
              prefix='$'
              minNumber={0}
              maxNumber={getMaxRevenueForStrategy()}
              items={selectionValues}
              label='Monthly revenue target'
              value={newRevenueTarget}
              style={{
                label: labelStyles,
              }}
              fullWidth
            />
            <SegmentedControl
              label='Optimisation strategy'
              items={[
                { label: 'Offpeak', value: 'offPeak' },
                { label: 'Lower offers', value: 'lowerOffers' },
              ]}
              onSelect={setNewStrategy}
              value={newStrategy}
              style={{
                label: labelStyles,
                option: {
                  fontSize: '18px',
                },
                groupContainer: {
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                },
              }}
            />
            <ToggleGroup
              label='Available for'
              onSelect={setNewTypes}
              items={[
                { label: 'Dine-in', value: 'dineIn' },
                { label: 'Takeaway', value: 'takeaway' },
              ]}
              style={toggleGroupStyles}
              value={newTypes}
              singleChoice={false}
            />
            {/* <Box style={{ flexGrow: 1 }}> */}
            {/*  <TextSelect */}
            {/*    onChange={setNewDineInMax} */}
            {/*    validation='numbers_only' */}
            {/*    minNumber={0} */}
            {/*    increment */}
            {/*    maxNumber={10} */}
            {/*    label='Set a dine-in maximum' */}
            {/*    value={newDineInMax} */}
            {/*    style={{ */}
            {/*      label: labelStyles, */}
            {/*      textField: { width: '150px' }, */}
            {/*    }} */}
            {/*  /> */}
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

EditParametersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  revenueTarget: PropTypes.number.isRequired,
  strategy: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
  dineInMax: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  dealDailyOppoPost: state.dealDailyOppoPost,
  dailyOppos: state.dealDailyOppos,
  potentialRevenues: state.potentialRevenues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearPostDailyOppo: clearPostDailyOppoAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditParametersModal);
