import { safeParse } from '../utils/helpers';
import { apiCall, makeApiAction } from './actions';
import {
  balanceHistoryByRestId,
  settlementsByRestId,
  netBalanceByRestId,
  lookupPaymentMethodName,
  paymentMethods,
} from '../graphql/queries';
import { createCCSetupIntent, payBalance, savePaymentDetails } from '../graphql/mutations';
import { createAsyncAction } from '../lib/react-redux';

export const fetchSettlementsByRestIdAction =
  (pageSize = 500, page = 0) =>
  (dispatch) => {
    dispatch(
      makeApiAction(
        'FETCH_SETTLEMENTS',
        settlementsByRestId,
        { pageSize, page },
        {},
        'settlementsByRestId',
        true,
        dispatch,
      ),
    );
  };

export const fetchBalanceHistoryByRestIdAction = () => (dispatch) => {
  dispatch(
    makeApiAction(
      'FETCH_BILLING_HISTORY',
      balanceHistoryByRestId,
      {},
      {},
      'balanceHistoryByRestId',
      true,
      dispatch,
    ),
  );
};

export const fetchNetBalanceByRestIdAction = () => (dispatch) => {
  dispatch(
    makeApiAction(
      'FETCH_NET_BALANCE',
      netBalanceByRestId,
      {},
      {},
      'netBalanceByRestId',
      true,
      dispatch,
    ),
  );
};

export const fetchPaymentMethodsAction = () => (dispatch) => {
  dispatch(
    makeApiAction(
      'FETCH_PAYMENT_METHODS',
      paymentMethods,
      {},
      {},
      'paymentMethods',
      true,
      dispatch,
    ),
  );
};

export const payBalanceAction =
  (amount, paymentMethodId, paymentMethodName, paymentMethodType) => (dispatch) => {
    return dispatch(
      makeApiAction(
        'PAY_BALANCE',
        payBalance,
        { amount, paymentMethodId, paymentMethodName, paymentMethodType },
        {},
        'payBalance',
        true,
        dispatch,
        'Payment made.',
        null,
        false,
        null,
        false,
        null,
        (response) => {
          const data = safeParse(response?.data?.payBalance);
          if (data?.status === 'failure') {
            return data?.paymentIntentStatus;
          }

          return true;
        },
      ),
    );
  };

export const createCCSetupIntentAction = () => {
  return apiCall(createCCSetupIntent, {}, true);
};

export const savePaymentDetailsAction = (paymentMethodId, paymentMethodName, paymentMethodType) =>
  createAsyncAction(
    'SAVE_PAYMENT_DETAILS',
    savePaymentDetails,
    { paymentMethodId, paymentMethodName, paymentMethodType },
    'savePaymentDetails',
    'Payment details saved.',
    false,
    true,
  );

export const lookupPaymentMethodNameAction = (paymentMethodId) => {
  return apiCall(lookupPaymentMethodName, { paymentMethodId }, true);
};
