import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, CardContent, Card } from '@mui/material';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spacer, Box } from '@eatclub-apps/ec-component-library';
import {
  fetchAnalyticBookingsAction,
  fetchImpressionsAction,
  fetchPopularMenuItemsAction,
  fetchPastYearAnalyticsAction,
  fetchTransactionDetailsAction,
  fetchHistoricalOffersAction,
  fetchPastYearTransactionDetailsAction,
} from '../../actions/analyticsAction';
import {
  fetchRestaurantDayPeriodsAction,
  fetchRestaurantTimePeriodsAction,
} from '../../actions/restaurantPeriodsAction';
import AverageOrderValue from '../../components/analytics/averageOrderValue/AverageOrderValue';
import RedemptionTimesChart from '../../components/analytics/redemptionTimesChart/RedemptionTimesChart';
import RedemptionTrendsChart from '../../components/analytics/redemptionTrendsChart/RedemptionTrendsChart';
import TotalRevenue from '../../components/analytics/totalRevenue/TotalRevenue';
import { transactionDetailsPropTypes } from '../../data/models/TransactionDetails';
import { formatNumber } from '../../utils';
import { calculateRevenue } from '../../utils/calculateRevenue';
import { getMonthRange } from '../../utils/dateHelpers';
import { formatCompactNumber, formatCurrency } from '../../utils/helpers';
import InsightCard from './InsightCard';
import InsightSummary from './InsightSummary';
import useStyles from './InsightStyles';
import Error from '../../components/error/Error';
import { platformMap } from '../../constants';
import MostPopularItemsTable from './PopularItemsTable/MostPopularItemsTable';
import HorizontalScroll from './RevenueByTime/HorizontalScroll';
import RevenueByTime from './RevenueByTime/RevenueByTime';
import RevenueDistribution from './RevenueDistribution/RevenueDistribution';
import RevenueGraph from './RevenueGraph';
import RedemptionsTable from './RedemptionsTable/RedemptionsTable';

const Insight = ({
  activeRestaurant,
  fetchAnalyticBookings,
  fetchImpressions,
  fetchPopularMenuItems,
  fetchTransactionDetails,
  fetchPastYearTransactionDetails,
  dates,
  analytics,
  platform,
  userId,
  userType,
  fetchRestaurantDayPeriods,
  fetchRestaurantTimePeriods,
  fetchHistoricalOffers,
  transactionDetails,
}) => {
  const classes = useStyles();

  const { startDate, endDate } = dates;
  const { impressions } = analytics;

  const beforeImpressions = startDate.isBefore('2024-06-01');

  // usePageViews();

  // Set this to no longer be new
  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem(`viewedInsights`, 'true');
    }, 10000);
  });

  // Fetch analytics data
  useEffect(() => {
    if (!analytics.shouldFetch) {
      return;
    }

    const formatStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formatEndDate = dayjs(endDate).format('YYYY-MM-DD');

    const rangeOfDays = Math.floor(dayjs(endDate).diff(startDate, 'days', '[]'));

    // compare dates also depend on whether you're viewing per month or per day
    const getCompareDates = () => {
      if (dates.dateUnit === 'month') {
        const monthRange = getMonthRange(dates.dateRange, dayjs(startDate));

        return {
          start: monthRange.startDate.format('YYYY-MM-DD'),
          end: monthRange.endDate.format('YYYY-MM-DD'),
        };
      }

      return {
        start: dayjs(startDate)
          .subtract(rangeOfDays + 1, 'days')
          .format('YYYY-MM-DD'),
        end: dayjs(endDate) // Adding a day fixes off-by-one errors in calculations
          .subtract(rangeOfDays + 1, 'days')
          .format('YYYY-MM-DD'),
      };
    };

    const compareDates = getCompareDates();

    fetchTransactionDetails(
      activeRestaurant.objectId,
      formatStartDate,
      formatEndDate,
      compareDates.start,
      compareDates.end,
      platform,
      userId,
      userType,
    );

    fetchPastYearTransactionDetails(
      activeRestaurant.objectId,
      formatStartDate,
      formatEndDate,
      compareDates.start,
      compareDates.end,
      platform,
      userId,
      userType,
    );

    // fetchAnalyticBookings(
    //   activeRestaurant.objectId,
    //   activeRestaurant.region,
    //   formatStartDate,
    //   formatEndDate,
    //   compareDates.start,
    //   compareDates.end,
    //   platform,
    //   userId,
    //   userType,
    // );

    fetchImpressions(
      activeRestaurant.objectId,
      activeRestaurant.region,
      formatStartDate,
      formatEndDate,
      compareDates.start,
      compareDates.end,
      userId,
      userType,
    );

    fetchPopularMenuItems(
      activeRestaurant.objectId,
      activeRestaurant.region,
      formatStartDate,
      formatEndDate,
      compareDates.start,
      compareDates.end,
      userId,
      userType,
    );

    fetchRestaurantDayPeriods(activeRestaurant.objectId, userId, userType);
    fetchRestaurantTimePeriods(activeRestaurant.objectId, userId, userType);

    fetchHistoricalOffers(formatStartDate, formatEndDate);
  }, [
    activeRestaurant.objectId,
    activeRestaurant.region,
    analytics.shouldFetch,
    endDate,
    fetchAnalyticBookings,
    platform,
    startDate,
    userId,
    userType,
  ]);

  // // Fetch past 12 months analytics data.
  // // Used in the insight summaries for 12 months data
  // useEffect(() => {
  //   if (!analytics.shouldFetch) {
  //     return;
  //   }
  //
  //   fetchPastYearAnalytics(
  //     activeRestaurant.objectId,
  //     activeRestaurant.region,
  //     platform,
  //     userId,
  //     userType,
  //   );
  // }, [activeRestaurant.objectId, activeRestaurant.region, platform, userId, userType]);

  // Quick maths
  const transactionsData = useMemo(() => {
    if (platform === 'whitelabel') {
      return { startTime: null, endTime: null, current: {}, pastYear: {} };
    }

    if (transactionDetails.fetching || transactionDetails.shouldFetch) {
      return { startTime: null, endTime: null, current: {}, pastYear: {} };
    }

    const currentRevenue = calculateRevenue(
      transactionDetails.transactionDetails,
      transactionDetails.compareTransactionDetails,
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
      activeRestaurant.ecPayEnabled,
      activeRestaurant.inAppOrdering,
    );

    const pastRevenue = calculateRevenue(
      transactionDetails.pastYearTransactionDetails,
      [],
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
      activeRestaurant.ecPayEnabled,
      activeRestaurant.inAppOrdering,
    );

    return {
      current: currentRevenue, // TODO current makes it seem like we're using a ref. Rename this
      pastYear: pastRevenue,
      impressions,
    };
  }, [
    activeRestaurant.averageOrderValue,
    activeRestaurant.averageBookingValue,
    activeRestaurant.ecPayEnabled,
    activeRestaurant.inAppOrdering,
    analytics.fetching,
    analytics.shouldFetch,
    transactionDetails.transactionDetails,
    transactionDetails.compareTransactionDetails,
    transactionDetails.pastYearTransactionDetails,
    platform,
    impressions,
  ]);

  return (
    <>
      {analytics.error && <Error error={analytics.error} message={analytics.errorMessage} />}
      {transactionDetails.error && (
        <Error error={transactionDetails.error} message={transactionDetails.errorMessage} />
      )}

      {platform === platformMap.eatclub.value && (
        <Spacer gap={24} direction='vertical' className={classes.mobilePaddingBreak}>
          <Spacer gap={24} direction='vertical' className={classes.mobilePadding}>
            {beforeImpressions && (
              <Box style={{ fontSize: '14px', fontWeight: '400' }}>
                We’re unable to show some impressions data for the selected date range.
              </Box>
            )}

            <InsightSummary data={transactionsData} impressions={impressions} />

            <RevenueGraph />

            <Spacer gap={16} className={classes.cardContainer}>
              <InsightCard
                title='Dine-in'
                data={[
                  {
                    label: 'Customers',
                    value: formatCompactNumber(transactionsData.current.dineInGuests),
                  },
                  {
                    label: 'AOV',
                    value: formatCurrency(transactionsData.current.dineInAOV, false),
                  },
                  // { label: 'Avg rating', value: 4.1 },
                ]}
              />
              <InsightCard
                title='Takeaway'
                data={[
                  {
                    label: 'Customers',
                    value: formatNumber(transactionsData.current.takeawayGuests, 0),
                  },
                  {
                    label: 'AOV',
                    value: formatCurrency(transactionsData.current.takeawayAOV, false),
                  },
                  // { label: 'Avg rating', value: 4.1 },
                ]}
              />
            </Spacer>
          </Spacer>

          <HorizontalScroll>
            <RevenueByTime />
          </HorizontalScroll>

          <Spacer gap={24} direction='vertical' className={classes.mobilePadding}>
            <RevenueDistribution />
          </Spacer>

          <HorizontalScroll>
            <RedemptionsTable
              // redemptions={bookingsData}
              redemptions={transactionDetails.transactionDetails}
            />
          </HorizontalScroll>

          <HorizontalScroll>
            <MostPopularItemsTable />
          </HorizontalScroll>
        </Spacer>
      )}

      {platform === platformMap.whitelabel.value && (
        <Box>
          <Grid container spacing={2} className={classes.mb2}>
            <Grid item lg={6} sm={6} xs={12}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <AverageOrderValue />
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <TotalRevenue />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.mb2}>
            <Grid item md={12} xs={12}>
              <RedemptionTrendsChart
                platform={platform}
                redemptions={transactionDetails.transactionDetails}
                fetchingOrShouldFetch={
                  transactionDetails.fetching || transactionDetails.shouldFetch
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.mb2}>
            <Grid item xs={12}>
              <RedemptionTimesChart
                redemptions={transactionDetails.transactionDetails}
                platform={platform}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

Insight.propTypes = {
  activeRestaurant: PropTypes.shape({
    objectId: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  analytics: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    success: PropTypes.bool,
    shouldFetch: PropTypes.bool,
  }).isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
  fetchAnalyticBookings: PropTypes.func.isRequired,
  fetchPastYearAnalytics: PropTypes.func.isRequired,
  fetchPopularMenuItems: PropTypes.func.isRequired,
  fetchImpressions: PropTypes.func.isRequired,
  fetchRestaurantDayPeriods: PropTypes.func.isRequired,
  fetchRestaurantTimePeriods: PropTypes.func.isRequired,
  fetchTransactionDetails: PropTypes.func.isRequired,
  fetchPastYearTransactionDetails: PropTypes.func.isRequired,
  fetchHistoricalOffers: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  transactionDetails: transactionDetailsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  dates: state.dates,
  platform: state.platform.platform,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  activeRestaurant: state.restaurantActive.restaurant,
  transactionDetails: state.transactionDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAnalyticBookings: fetchAnalyticBookingsAction,
      fetchTransactionDetails: fetchTransactionDetailsAction,
      fetchPastYearTransactionDetails: fetchPastYearTransactionDetailsAction,
      fetchPastYearAnalytics: fetchPastYearAnalyticsAction,
      fetchImpressions: fetchImpressionsAction,
      fetchPopularMenuItems: fetchPopularMenuItemsAction,
      fetchRestaurantDayPeriods: fetchRestaurantDayPeriodsAction,
      fetchRestaurantTimePeriods: fetchRestaurantTimePeriodsAction,
      fetchHistoricalOffers: fetchHistoricalOffersAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Insight);
