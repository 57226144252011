import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { opportunitiesPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { pluralise } from '../../../utils/helpers';
import TopOpportunity from '../Opportunity/TopOpportunity';
import useStyles from './OffersStyles';
import { Heading } from '../../Heading';

const TopOpportunities = ({ opportunities }) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.opportunitiesHeaderContainer}>
        <Heading size='3'>
          Daily {pluralise('opportunity', opportunities?.length, 'opportunities')}
        </Heading>
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {opportunities?.map((opportunity, index) => (
          <TopOpportunity
            key={opportunity?.objectId}
            opportunity={opportunity}
            number={index + 1}
          />
        ))}
      </Box>
    </Box>
  );
};

TopOpportunities.propTypes = {
  opportunities: PropTypes.arrayOf(opportunitiesPropTypes).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopOpportunities);
