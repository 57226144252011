/* eslint-disable react/prop-types */
import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuChoiceStock from './MenuChoiceStock';
import { formatNumber } from '../../../utils';

const MenuChoices = ({ choice, index, optionId }) => {
  const theme = useTheme();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('sm'));

  const renderPrice = (num) => {
    if (!num) {
      return '-';
    }
    return `$${formatNumber(num)}`;
  };

  return (
    <ListItem key={index} style={{ paddingRight: breakpointMdDown ? '150px' : '270px' }}>
      <ListItemText style={{ paddingLeft: '56px' }} secondary={choice.choiceTitle} />
      <ListItemSecondaryAction
        style={{ paddingRight: '30px', alignItems: 'center', display: 'flex', gap: '8px' }}
      >
        <Typography
          display='inline'
          color='secondary'
          variant='subtitle1'
          style={{ padding: '0 1rem' }}
        >
          {renderPrice(choice.price)}
        </Typography>
        <MenuChoiceStock choice={choice} optionId={optionId} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default MenuChoices;
