const getColor = (context) => {
  switch (context) {
    case 'success':
      return 'lawngreen';
    case 'tracking':
      return 'violet';
    case 'info':
      return 'dodgerblue';
    default:
      return 'hotpink';
  }
};

/**
 * Log a `msg` to console only in dev/demo mode.
 * TODO move context to the end
 *
 * @param context Success, Tracking or Danger
 * @param name Name of console
 * @param msg Message to log
 */
export const devLog = (context = 'success', name, ...msg) => {
  if (!import.meta.env.VITE_MODE || import.meta.env.VITE_MODE === 'development') {
    // eslint-disable-next-line no-console
    console.log(`%c${context.toUpperCase()} ${name}: `, `color: ${getColor(context)}`, ...msg);
  }
};
