import { combineReducers } from 'redux';
import activeDealsForRestaurantReducer from './activeDealsForRestaurantReducer';
import activeOffersForRestaurantReducer from './activeOffersForRestaurantReducer';
import dealDailyOpposPostAllReducer from './dealDailyOpposPostAllReducer';
import analyticsReducer from './analyticsReducer';
import breadcrumbsReducer from './breadcrumbsReducer';
import categoriesReducer from './categoriesReducer';
import confirmBookingReducer from './confirmBookingReducer';
import contactBdmReducer from './contactBdmReducer';
import contactBillingReducer from './contactBillingReducer';
import datePickerReducer from './datePickerReducer';
import dealDailyOppoPostReducer from './dealDailyOppoPostReducer';
import dealDailyOpposReducer from './dealDailyOpposReducer';
import dealDailyOppoUpdateReducer from './dealDailyOppoUpdateReducer';
import dealDeleteReducer from './dealDeleteReducer';
import dealDisableReducer from './dealDisableReducer';
import dealHelpReducer from './dealHelpReducer';
import dealIncreaseReducer from './dealIncreaseReducer';
import dealPredictReducer from './dealPredictReducer';
import dealReducer from './dealReducer';
import dealsByDayReducer from './dealsByDayReducer';
import dealsByIdsReducer from './dealsByIdsReducer';
import dealsForGoalReducer from './dealsForGoalReducer';
import dealsReducer from './dealsReducer';
import dealUpdateReducer from './dealUpdateReducer';
import averageBookingValueReducer from './averageBookingValueReducer';
import emailSendReducer from './emailSendReducer';
import errorReducer from './errorReducer';
import generatedOffersReducer from './generatedOffersReducer';
import goalsReducer from './goalsReducer';
import goalStartReducer from './goalStartReducer';
import goalEndReducer from './goalEndReducer';
import invoicesReducer from './invoicesReducer';
import loadReducer from './loadReducer';
import logMessageReducer from './logMessageReducer';
import loginReducer from './loginReducer';
import menuChoiceStockReducer from './menuChoiceStockReducer';
import menuFlagReducer from './menuFlagReducer';
import menuItemsReducer from './menuItemsReducer';
import menuItemStockReducer from './menuItemStockReducer';
import menusReducer from './menusReducer';
import offerEditReducer from './offerEditReducer';
import offersForGoalReducer from './offersForGoalReducer';
import orderFlagReducer from './orderFlagReducer';
import ordersReducer from './ordersReducer';
import dailyOrderSummaryReducer from './dailyOrderSummaryReducer';
import monthlyOrderSummaryReducer from './monthlyOrderSummaryReducer';
import payoutsReducer from './payoutsReducer';
import payoutsWhiteLabelReducer from './payoutsWhiteLabelReducer';
import platformReducer from './platformReducer';
import potentialRevenuesReducer from './potentialRevenuesReducer';
import refundReducer from './refundReducer';
import requestCallReducer from './requestCallReducer';
import restaurantActiveReducer from './restaurantActiveReducer';
import restaurantFavReducer from './restaurantFavReducer';
import restaurantPeriodsReducer from './restaurantPeriodsReducer';
import restaurantReducer from './restaurantReducer';
import subEventsReducer from './subEventsReducer';
import averageOrderValueReducer from './averageOrderValueReducer';
import transactionDetailsReducer from './transactionDetailsReducer';
import transactionsReducer from './transactionsReducer';
import potentialTakeawayRevenuesReducer from './potentialTakeawayRevenuesReducer';
import potentialDineInRevenuesReducer from './potentialDineInRevenuesReducer';
import requestOfferEditReducer from './requestOfferEditReducer';
import requestOfferDeleteReducer from './requestOfferDeleteReducer';
import requestGoalDeleteReducer from './requestGoalDeleteReducer';
import restaurantsReducer from './restaurantsReducer';
import ssoCredsReducer from './ssoCredsReducer';
import settlementsReducer from './settlementsReducer';
// TODO remove all of these
import obeeOnboardReducer from './obeeOnboardReducer';
import obeePricingReducer from './obeePricingReducer';
import obeeRequestReducer from './obeeRequestReducer';
import obeeUpgradeReducer from './obeeUpgradeReducer';
import apiRequestsReducer from './apiRequestsReducer';
import affiliatedRestaurantsReducer from './affiliatedRestaurantsReducer';
import billingHistoryReducer from './billingHistoryReducer';
import netBalanceReducer from './netBalanceReducer';
import paymentMethodsReducer from './paymentMethodsReducer';
import savePaymentDetailsReducer from './savePaymentDetailsReducer';
import payBalanceReducer from './payBalanceReducer';
import whiteLabelOrdersReducer from './whiteLabelOrdersReducer';
import offerCreateReducer from './offerCreateReducer';
import predictionsForOffersReducer from './predictionsForOffersReducer';
// import storage from "redux-persist/lib/storage";

export const reducers = {
  activeDealsForRestaurant: activeDealsForRestaurantReducer,
  activeOffersForRestaurant: activeOffersForRestaurantReducer,
  affiliatedRestaurants: affiliatedRestaurantsReducer,
  analytics: analyticsReducer,
  apiRequests: apiRequestsReducer,
  billingHistory: billingHistoryReducer,
  breadcrumbs: breadcrumbsReducer,
  categories: categoriesReducer,
  confirmBooking: confirmBookingReducer,
  contactBdm: contactBdmReducer,
  contactBilling: contactBillingReducer,
  dailyOrderSummaries: dailyOrderSummaryReducer,
  dates: datePickerReducer,
  deal: dealReducer,
  dealDailyOppoPost: dealDailyOppoPostReducer,
  dealDailyOppoPostAll: dealDailyOpposPostAllReducer,
  dealDailyOppoUpdate: dealDailyOppoUpdateReducer,
  dealDailyOppos: dealDailyOpposReducer,
  dealDelete: dealDeleteReducer,
  dealDisable: dealDisableReducer,
  dealHelp: dealHelpReducer,
  dealIncrease: dealIncreaseReducer,
  dealPredict: dealPredictReducer,
  dealUpdate: dealUpdateReducer,
  deals: dealsReducer,
  dealsByDay: dealsByDayReducer,
  dealsByIds: dealsByIdsReducer,
  dealsForGoal: dealsForGoalReducer,
  averageBookingValue: averageBookingValueReducer,
  emailSend: emailSendReducer,
  error: errorReducer,
  generatedOffers: generatedOffersReducer,
  goalEnd: goalEndReducer,
  goalStart: goalStartReducer,
  goals: goalsReducer,
  invoices: invoicesReducer,
  loading: loadReducer,
  logMessage: logMessageReducer,
  menuChoiceStock: menuChoiceStockReducer,
  menuFlag: menuFlagReducer,
  menuItemStock: menuItemStockReducer,
  menuItems: menuItemsReducer,
  menus: menusReducer,
  monthlyOrderSummaries: monthlyOrderSummaryReducer,
  netBalance: netBalanceReducer,
  offerCreate: offerCreateReducer,
  offerEdit: offerEditReducer,
  offersForGoal: offersForGoalReducer,
  order: ordersReducer,
  orderFlag: orderFlagReducer,
  payBalance: payBalanceReducer,
  paymentMethods: paymentMethodsReducer,
  payouts: payoutsReducer,
  payoutsWhitelabel: payoutsWhiteLabelReducer,
  platform: platformReducer,
  potentialDineInRevenues: potentialDineInRevenuesReducer,
  potentialRevenues: potentialRevenuesReducer,
  potentialTakeawayRevenues: potentialTakeawayRevenuesReducer,
  predictionsForOffers: predictionsForOffersReducer,
  refund: refundReducer,
  requestCall: requestCallReducer,
  requestGoalDelete: requestGoalDeleteReducer,
  requestOfferDelete: requestOfferDeleteReducer,
  requestOfferEdit: requestOfferEditReducer,
  restaurant: restaurantReducer,
  restaurantActive: restaurantActiveReducer,
  restaurantFav: restaurantFavReducer,
  restaurants: restaurantsReducer,
  restaurantPeriods: restaurantPeriodsReducer,
  savePaymentDetails: savePaymentDetailsReducer,
  settlements: settlementsReducer,
  ssoCreds: ssoCredsReducer,
  subEvents: subEventsReducer,
  averageOrderValue: averageOrderValueReducer,
  transactions: transactionsReducer,
  transactionDetails: transactionDetailsReducer,
  user: loginReducer,
  whiteLabelOrders: whiteLabelOrdersReducer,

  // Bookings - remove these
  obeeOnboard: obeeOnboardReducer,
  obeePricing: obeePricingReducer,
  obeeRequest: obeeRequestReducer,
  obeeUpgrade: obeeUpgradeReducer,
};

// App's top-level reducers
const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  //  Clear Redux state on logout
  if (action.type === 'SET_LOGOUT') {
    // storage.removeItem("persist:root"); // Redux Persist: Reset state,

    // eslint-disable-next-line no-param-reassign
    state = {};
  }

  // Clear majority of the Redux state for when switching between venues with staff login
  if (action.type === 'SET_ACTIVE_RESTAURANT') {
    const { restaurant, restaurantActive, user } = state;

    // eslint-disable-next-line no-param-reassign
    state = { restaurant, restaurantActive, user };
  }
  return appReducer(state, action);
};

export default rootReducer;
