export const tabBarStyles = (theme) => ({
  fontWeight: theme.fontWeights.regular,
  containerInner: {
    gap: '12px',
  },
  tab: {
    padding: '0 13px',
    borderRadius: '100px',
    fontSize: '16px',
    lineHeight: '20px',
    transition: 'background-color 0.1s',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    border: '1px solid transparent',
  },
  tabActive: {
    fontWeight: theme.fontWeights.regular,
    backgroundColor: 'white',
    padding: '9px 13px',
    paddingBottom: '9px',
    color: theme.colors.charcoal,
    border: `1px solid ${theme.colors.charcoal}`,
    borderBottom: `1px solid ${theme.colors.charcoal}`, // defaults to red
  },
  tabHover: {
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.white}`,
    color: theme.colors.black,
    filter: 'none',
  },
});
