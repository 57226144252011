import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { roundToNearest } from '../../../../utils/helpers';
import { getCurveData } from '../../../../utils/insights/insightHelpers';
import { calculatePercentage } from '../../../../utils/math';
import useStyles from './ComparisonBarStyles';

/**
 *
 * @param comparisonData
 * @param value
 * @param colors
 * @param format
 * @param percentile
 * @param lowCutoffPercentage
 * @param highCutoffPercentage
 * @returns {Element}
 * @constructor
 */
const ComparisonBar = ({
  comparisonData,
  value,
  colors,
  format = (item) => item,
  percentile = 100,
  lowCutoffPercentage = 5,
  highCutoffPercentage = 5,
}) => {
  const classes = useStyles({ colors });

  const ref = useRef(null);
  const labelRef = useRef(null);

  const [width, setWidth] = useState(504);

  // Update the width of the graph on change
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    observer.observe(ref.current);
    return () => ref.current && observer.unobserve(ref.current);
  }, []);

  const bucketAmount = 5;

  const curveData = getCurveData(
    comparisonData?.dataset,
    lowCutoffPercentage,
    100 - highCutoffPercentage,
    bucketAmount,
  );

  const chartTop = curveData.highCutoff;
  const chartBottom = curveData.lowCutoff;

  const getPositionOffset = (percentileValue = 0, rectangleWidth = 504) => {
    const padding = 6;
    const labelWidth = 104;
    const positionOffset = labelWidth / 2;

    return ((rectangleWidth - 2 * padding) / 100) * percentileValue - positionOffset + padding;
  };

  const [animationWait, setAnimationWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setAnimationWait(false);
    }, 100);
  }, []);

  // The bar goes from 5% to 95% so it's not just a linear 0-100
  const getBarFillPercent = () => {
    // Bar has to scale to 90% of its actual size since it starts at 5% and ends at 95%
    // Any number below 5 or above 95 will hit the edge of the bar
    const scale = 100 - lowCutoffPercentage - highCutoffPercentage; // 90
    const scaledPercentage = calculatePercentage(percentile - lowCutoffPercentage, scale);
    return Math.max(0, Math.min(100, scaledPercentage));
  };
  const barFillPercent = getBarFillPercent();

  return (
    <Box className={classes.comparisonBarContainer}>
      <Box
        className={classes.valueContainer}
        style={{
          left: getPositionOffset(animationWait ? 0 : barFillPercent ?? 0, width),
        }}
      >
        <Box className={classes.value} ref={labelRef}>
          {format(roundToNearest(value))}
        </Box>
        <Box className={classes.dashedLine} />
      </Box>
      <Box className={classes.bar}>
        <Box className={classes.barBackground} ref={ref}>
          <Box className={classes.barBackgroundBorder}>
            <Box
              className={classes.barBackgroundInner}
              style={{
                width: `${animationWait ? 0 : barFillPercent}%`,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.chartLines}>
          <Box className={classes.horizontalAxis} />
          <Box
            className={classes.edgeTick}
            style={{
              left: 0,
            }}
          />
          <Box
            style={{
              position: 'absolute',
              height: '50%',
              width: '1px',
              top: '50%',
              left: '50%',
              backgroundColor: 'black',
            }}
          />
          <Box
            className={classes.edgeTick}
            style={{
              right: 0,
            }}
          />
        </Box>
      </Box>
      <Spacer className={classes.xValues}>
        <Spacer direction='vertical' className={`${classes.xValue} ${classes.bottomValue}`}>
          <Box className={classes.xValueLabel}>Bottom {lowCutoffPercentage}%</Box>
          <Box>{format(roundToNearest(chartBottom))}</Box>
        </Spacer>
        <Spacer direction='vertical' className={classes.xValue}>
          <Box className={classes.xValueLabel}>Avg</Box>
          <Box>{format(roundToNearest(comparisonData.median))}</Box>
        </Spacer>
        <Spacer direction='vertical' className={`${classes.xValue} ${classes.topValue}`}>
          <Box className={classes.xValueLabel}>Top {highCutoffPercentage}%</Box>
          <Box>{format(roundToNearest(chartTop))}</Box>
        </Spacer>
      </Spacer>
    </Box>
  );
};

ComparisonBar.propTypes = {
  value: PropTypes.number.isRequired,
  comparisonData: PropTypes.shape({
    low: PropTypes.number,
    mean: PropTypes.number,
    high: PropTypes.number,
  }).isRequired,
  colors: PropTypes.shape({
    light: PropTypes.string,
    dark: PropTypes.string,
  }).isRequired,
  format: PropTypes.func,
  percentile: PropTypes.number,
  lowCutoffPercentage: PropTypes.number,
  highCutoffPercentage: PropTypes.number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonBar);
