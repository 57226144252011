import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './HeadingStyles';

/**
 * A Heading component
 * @example <Heading>Hello!</Heading>
 */
export const Heading = ({ children = null, className = '', style = {}, size = '1' }) => {
  const sizeMapping = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  };

  const variant = sizeMapping[size] ?? 'h1';

  const Component = variant;

  const classes = useStyles({ variant });

  return (
    <Component className={`${classes.container} ${className}`} style={style}>
      {children}
    </Component>
  );
};

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
};
