import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceDot,
  Line,
  LineChart,
} from 'recharts';
import { HiddenContent, Dropdown } from '@eatclub-apps/ec-component-library';
import { goalPropTypes } from '../../../data/models/Goal';
import { SPACING, FONT_WEIGHTS, COLORS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import {
  formatCurrency,
  capitaliseFirstLetter,
  getDayFromInt,
  safeDivision,
  formatDate,
} from '../../../utils/helpers';
import GraphDot from './GraphDot';
import AlertIcon from '../../../assets/alert_icon.svg';
import GraphPlaceholder from '../../../assets/graph_placeholder.svg';
import useStyles from './DailyPerformanceGraphStyles';

const DailyPerformanceGraph = ({ goal }) => {
  const classes = useStyles();
  const [showDayData, setShowDayData] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [range, setRange] = useState('total');
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setShowDayData(false);
    setSelectedDay(null);

    const today = dayjs().startOf('day'); // TODO get in restaurant's timezone

    const goalStartDate = dayjs(goal?.startDate);
    const goalEndDate = dayjs(goal?.endDate);

    const dataStartDate = goalStartDate;
    const dataEndDate = dayjs.min([goalEndDate, today]);

    let dataByDate = [];

    for (let date = dataStartDate; date < dataEndDate; date = date.add(1, 'day')) {
      let dataForDate = goal?.dailyHistory?.find((dayData) => {
        return dayData?.date === formatDate(date, 'YYYY-MM-DD');
      });

      if (!dataForDate) {
        dataForDate = {
          aov: 0,
          bookings: 0,
          customers: 0,
          customersExpectedLower: 0,
          customersExpectedUpper: 0,
          date: formatDate(date, 'YYYY-MM-DD'),
          dow: date.day(),
          goalId: goal?.objectId,
          orders: 0,
          redemptions: 0,
          revenue: 0,
          revenueExpected: 0,
          revenueExpectedLower: 0,
          revenueExpectedUpper: 0,
          closedMessage: 'No data available for this date',
        };
      }

      dataByDate = [
        ...dataByDate,
        {
          ...dataForDate,
          day: getDayFromInt(dataForDate?.dow, true),
          labelledDate: dayjs(dataForDate?.date).format('ddd Do MMM'),
          completed: dayjs(dataForDate?.date) < today,
        },
      ];
    }

    setSortedData(dataByDate.slice(range === 'weekly' ? -7 : 0));
  }, [goal?.dailyHistory, range]);

  // TODO loading state

  const selectedDayReadable = selectedDay ? dayjs(selectedDay.date).format('dddd') : '';

  const revenue = sortedData.reduce((acc, day) => acc + day?.revenue, 0);
  const expectedRevenue = sortedData.reduce((acc, day) => acc + day?.revenueExpected, 0);

  const getAmountRelativeToGoal = (amount, goalAmount) => {
    // Avoid division by zero
    if (goalAmount === 0) {
      return '';
    }

    if (Math.round(amount) === Math.round(goalAmount)) {
      return <Box style={{ color: COLORS.DARKER_SUNNY_GREEN }}>Perfectly on target</Box>;
    }

    const percentage = Math.round((amount / goalAmount) * 100);

    if (amount < goalAmount) {
      return (
        <Box style={{ color: COLORS.PRIMARY_RED_MATTE }}>
          {100 - percentage}% below {formatCurrency(goalAmount, false)}
        </Box>
      );
    }

    return (
      <Box style={{ color: COLORS.DARKER_SUNNY_GREEN }}>
        {percentage - 100}% above {formatCurrency(goalAmount, false)}
      </Box>
    );
  };

  const selectDay = (day) => {
    if (day?.date === selectedDay?.date && showDayData) {
      setShowDayData(false);
    } else {
      setShowDayData(true);
      setSelectedDay(day);
    }
  };

  const calculateQuota = (target, actual) => {
    // How much of this goal was reached.
    // e.g. 50% if half, 200% if double
    return Math.round(safeDivision((actual * 100) / target));
  };

  return (
    <Card>
      <CardContent>
        <Box className={classes.graphHeader}>
          <Dropdown
            style={{ margin: '10px', width: '230px', fontSize: '16px' }}
            items={[
              { label: 'Revenue: Goal to date', value: 'total' },
              { label: 'Revenue: Last 7 days', value: 'weekly' },
            ]}
            value={range}
            onSelect={setRange}
          />
          {sortedData.length > 0 && (
            <Box className={classes.legendContainer}>
              {/* Legend */}
              <Box className={classes.legend}>
                <Box className={classes.legendItem}>
                  <Box className={classes.legendBarContainer}>
                    <Box className={classes.legendBar} style={{ backgroundColor: COLORS.OLIVE }} />
                  </Box>
                  <Box>Revenue</Box>
                </Box>
                <Box className={classes.legendItem}>
                  <Box className={classes.legendBarContainer}>
                    <Box className={classes.predictedLegendBar} />
                    <Box className={classes.predictedLegendBar} />
                  </Box>
                  <Box>Predicted range</Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {sortedData.length > 0 ? (
          <>
            {range === 'weekly' && (
              <Box className={classes.performanceDayContainer}>
                {sortedData.map((day) => (
                  <Box
                    key={day?.date}
                    flex={1}
                    className={classes.performanceDay}
                    style={{
                      backgroundColor:
                        selectedDay?.date === day?.date && showDayData ? '#F5F5F5' : 'transparent',
                    }}
                    onClick={() => {
                      trackEvent('button_click: performance_graph_set_day');
                      selectDay(day);
                    }}
                  >
                    <Box className={classes.dayName}>{day.day}</Box>
                    {day?.completed ? (
                      <Box>
                        <Box className={classes.dayRevenue}>
                          {formatCurrency(day?.revenue, false)}
                        </Box>
                        <Box>{getAmountRelativeToGoal(day?.revenue, day?.revenueExpected)}</Box>
                      </Box>
                    ) : (
                      <Box>{dayjs(day?.date).format('Do MMM')}</Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}

            {/* Graph here */}
            <Box className={classes.graphContainer}>
              <ResponsiveContainer width='100%'>
                <LineChart
                  animationDuration={100}
                  width={500}
                  height={400}
                  data={sortedData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id='colorAmount' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='20%' stopColor='#425C58' stopOpacity={0.2} />
                      <stop offset='100%' stopColor='#425C58' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke='#F3F3F3' />
                  <XAxis dataKey='labelledDate' hide />
                  <YAxis
                    interval='preserveStartEnd'
                    allowDecimals={false}
                    tickLine={false}
                    tick={{
                      fontSize: '7px',
                      color: COLORS.SMOKE,
                      fontWeight: FONT_WEIGHTS.MEDIUM,
                    }}
                    axisLine={false}
                    tickFormatter={(value) => formatCurrency(value, false)}
                  />
                  <Tooltip
                    formatter={(value, name) => [
                      `$${value}`,
                      capitaliseFirstLetter(name)
                        // Insert a space before each capital letter
                        .replace(/([A-Z])/g, ' $1')
                        .trim(),
                    ]}
                  />
                  <ReferenceDot cx={0} cy={0} r={20} fill='red' stroke='1' />

                  <Line
                    type='monotone'
                    dataKey='revenueExpectedUpper'
                    stroke='#AEAEAE'
                    strokeDasharray='4 4'
                    dot={false}
                  />
                  <Line
                    type='monotone'
                    dataKey='revenueExpectedLower'
                    stroke='#AEAEAE'
                    strokeDasharray='4 4'
                    dot={false}
                  />
                  <Line
                    dot={false}
                    type='monotone'
                    dataKey='revenue'
                    stroke='#425C58'
                    activeDot={
                      <GraphDot
                        clickHandler={(e) => {
                          selectDay(e?._targetInst?.return?.memoizedProps?.payload); // This gets the day :)
                        }}
                      />
                    }
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>

            {showDayData === false ? (
              <Box className={classes.goalDataContainer}>
                {/* Goal to date details */}
                <Box className={classes.goalData}>
                  <Box
                    className={classes.detailItem}
                    style={{ marginRight: 'auto', minWidth: '100px' }}
                  >
                    <Box className={classes.detailHeading}>
                      {range === 'total' ? 'Goal to date' : 'Last 7 days'}
                    </Box>
                    <Box className={classes.detailDate}>
                      {`${dayjs(sortedData[0]?.date).format('Do MMM')} - ${dayjs(
                        sortedData[sortedData.length - 1]?.date,
                      ).format('Do MMM')}`}
                    </Box>
                  </Box>
                  {range === 'weekly' && (
                    <>
                      <Box className={classes.detailItem}>
                        <Box className={classes.detailSubheading}>Revenue</Box>
                        <Box className={classes.detailValue}>{formatCurrency(revenue, false)}</Box>
                      </Box>
                      <Box className={classes.detailItem}>
                        <Box className={classes.detailSubheading}>Target</Box>
                        <Box className={classes.detailValue}>
                          {formatCurrency(expectedRevenue, false)}
                        </Box>
                      </Box>
                    </>
                  )}
                  <Box className={classes.detailItem}>
                    <Box className={classes.detailSubheading}>Quota</Box>
                    <Box className={classes.detailValue}>
                      {calculateQuota(expectedRevenue, revenue)}%
                    </Box>
                  </Box>
                  <Box className={classes.detailItem}>
                    <Box className={classes.detailSubheading}>Avg order spend</Box>
                    <Box className={classes.detailValue}>
                      {formatCurrency(
                        sortedData.reduce((acc, day) => acc + day?.aov, 0) / sortedData.length,
                        false,
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.detailItem}>
                    <Box className={classes.detailSubheading}>Takeaway Orders</Box>
                    <Box className={classes.detailValue}>
                      {sortedData.reduce((acc, day) => acc + day?.orders, 0)}
                    </Box>
                  </Box>
                  <Box className={classes.detailItem}>
                    <Box className={classes.detailSubheading}>Walk-ins</Box>
                    <Box className={classes.detailValue}>
                      {sortedData.reduce((acc, day) => acc + day?.bookings, 0)}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <HiddenContent showContent={showDayData} contentHeight='200px'>
                {/* Day details */}
                <Box className={classes.goalDataContainer}>
                  <Box className={classes.goalData}>
                    <Box mr='auto' className={classes.detailItem} style={{ minWidth: '100px' }}>
                      <Box className={classes.detailHeading}>{selectedDayReadable}</Box>
                      <Box className={classes.detailDate}>
                        {dayjs(selectedDay?.date).format('Do of MMMM')}
                      </Box>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Box className={classes.detailSubheading}>Revenue</Box>
                      <Box className={classes.detailValue}>
                        {formatCurrency(selectedDay?.revenue, false)}
                      </Box>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Box className={classes.detailSubheading}>Target</Box>
                      <Box className={classes.detailValue}>
                        {formatCurrency(selectedDay?.revenueExpected, false)}
                      </Box>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Box className={classes.detailSubheading}>Quota</Box>
                      <Box className={classes.detailValue}>
                        {calculateQuota(selectedDay?.revenueExpected, selectedDay?.revenue)}%
                      </Box>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Box className={classes.detailSubheading}>Avg order spend</Box>
                      <Box className={classes.detailValue}>
                        {formatCurrency(selectedDay?.aov, false)}
                      </Box>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Box className={classes.detailSubheading}>Takeaway Orders</Box>
                      <Box className={classes.detailValue}>{selectedDay?.orders}</Box>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Box className={classes.detailSubheading}>Walk-ins</Box>
                      <Box className={classes.detailValue}>{selectedDay?.bookings}</Box>
                    </Box>
                  </Box>
                  {selectedDay?.closedMessage && (
                    <Box className={classes.closedMessageContainer}>
                      <AlertIcon style={{ marginTop: '5px' }} />
                      <Box>
                        <Box className={classes.closedMessageHeader}>
                          This day may be missing data.
                        </Box>
                        <Box className={classes.closedMessageContent}>
                          {selectedDay?.closedMessage}
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {selectedDay?.closedEarly && (
                    <Box display='flex' mt='20px' gap={SPACING.S}>
                      <AlertIcon style={{ marginTop: '5px' }} />
                      <Box>
                        <Box className={classes.closedMessageHeader}>
                          Your venue closed early for the day.
                        </Box>
                        <Box className={classes.closedMessageContent}>
                          {selectedDay?.closedMessage}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </HiddenContent>
            )}
          </>
        ) : (
          <Box className={classes.emptyStateContainer}>
            <GraphPlaceholder />
            <Box className={classes.emptyStateHeading}>Your goal has just started</Box>
            <Box>Check back soon to track your performance.</Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

DailyPerformanceGraph.propTypes = {
  goal: goalPropTypes.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(DailyPerformanceGraph);
