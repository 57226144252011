import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAllOffersAction } from '../../../actions/offersForGoalAction';
import { trackEvent } from '../../../utils/analytics';
import { Modal } from '../../Modal';

const DeleteAllOffersModal = ({ isOpen, onClose, onSave = () => {} }) => {
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);

  const save = () => {
    trackEvent('button_click: confirm_delete_all_offers');
    setDeleting(true);
    dispatch(deleteAllOffersAction())
      .then(onSave)
      .finally(() => setDeleting(false));
  };

  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: delete_all_offers');
    }
  }, [isOpen]);

  return (
    <Modal
      confirmLabel='Delete all offers'
      heading='Delete all offers'
      isLoading={deleting}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={save}
    >
      <Box>Are you sure you want to delete all offers?</Box>
    </Modal>
  );
};

DeleteAllOffersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default DeleteAllOffersModal;
