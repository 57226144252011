/* eslint-disable */
import { useTheme } from '@mui/material/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { Box, Divider } from '@eatclub-apps/ec-component-library';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ArrowUp from '../../../assets/icons/arrow_up.svg';
import { pluralise } from '../../../utils/helpers';

const ComparePercent = ({ change, percent, dateRange, dateRangeUnit }) => {
  const theme = useTheme();

  const formatPercent = (num) => {
    if (typeof num !== 'number' || num === 0 || Number.isNaN(num)) {
      return '-';
    }
    return `${num.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%`;
  };

  const number = formatPercent(percent);

  const getDeltaColor = (change) => {
    if (number === '-') {
      return 'inherit';
    }

    switch (change) {
      case 'gain':
        return theme.colors.positiveGreen;
      case 'loss':
        return theme.colors.negativeRed;
      default:
        return 'inherit';
    }
  };

  const getDeltaArrow = (change) => {
    if (number === '-') {
      return '';
    }

    switch (change) {
      case 'gain':
        return <ArrowUp color='inherit' />;
      case 'loss':
        return <ArrowUp style={{ transform: 'rotate(180deg)' }} color='inherit' />;
      default:
        return '';
    }
  };

  return (
    <Tooltip
      title={`Compared to the previous ${dateRange} ${pluralise(dateRangeUnit ?? 'day', dateRange)}`}
      placement='bottom-start'
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          color: getDeltaColor(change),
          fontSize: 'inherit',
          gap: '1px',
        }}
      >
        {getDeltaArrow(change)}
        <Divider direction={'vertical'} />
        <span style={{ fontSize: 'inherit' }}>{number}</span>
      </Box>
    </Tooltip>
  );
};

ComparePercent.propTypes = {
  dateRange: PropTypes.number.isRequired,
  dateRangeUnit: PropTypes.string.isRequired,
  change: PropTypes.string.isRequired,
  percent: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.dates.dateRange,
  dateRangeUnit: state.dates.dateUnit,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComparePercent);
