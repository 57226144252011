/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TableOrder from './TableOrder';
import { clearOrderAction } from '../../actions/ordersAction';

const TableOrdersDialog = ({ clearOrderAction, onClose, openDialog, activeBooking }) => {
  const tableOrders = JSON.parse(activeBooking.tableOrders);

  const [activeOrderId, setActiveOrderId] = useState(false);

  const handleToggleOrder = (orderId) => {
    if (activeOrderId === orderId) {
      setActiveOrderId(false);
      return;
    }
    setActiveOrderId(orderId);
  };
  const handleCloseOrder = () => {
    setActiveOrderId(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      open={openDialog}
      TransitionProps={{
        onEnter: () => {
          setActiveOrderId(false);

          // Open first order if it's the only one
          if (tableOrders.length === 1) {
            handleToggleOrder(tableOrders[0].objectId);
          }
        },
        onExit: () => {
          clearOrderAction();
        },
      }}
    >
      <DialogTitle>
        <Typography variant='h6' component='span' display='inline' style={{ marginRight: '.5rem' }}>
          Table Orders
        </Typography>
        <Typography variant='h6' display='inline' style={{ fontWeight: '400' }}>
          {activeBooking.reservationName}
        </Typography>
      </DialogTitle>

      <DialogContent dividers style={{ padding: 0 }}>
        {tableOrders.length > 0 ? (
          <List disablePadding>
            {tableOrders.map((order, index) => (
              <TableOrder
                activeOrderId={activeOrderId}
                handleToggleOrder={handleToggleOrder}
                handleCloseOrder={handleCloseOrder}
                key={order.objectId}
                order={order}
                isLast={tableOrders.length - 1 === index}
              />
            ))}
          </List>
        ) : (
          <Typography paragraph>There were no Table Orders found.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({ clearOrderAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TableOrdersDialog);
