import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { dealsByIds } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchDealAction = (dealId, region, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'DEAL',
  });
  dispatch({
    type: type.FETCH_DEAL_PENDING,
  });

  const getData = async () => {
    try {
      const response = await API.graphql(
        {
          query: dealsByIds,
          variables: { dealIds: dealId, region },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      const {
        data: { dealsByIds: deal },
      } = response;

      devLog('success', 'deal', deal[0]);

      dispatch({
        type: type.FETCH_DEAL_SUCCESS,
        payload: deal[0],
      });
    } catch (error) {
      devLog('error', 'deal', error);

      dispatch({
        type: type.FETCH_DEAL_FAILURE,
        payload: `Unable to retrieve deal: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'DEAL',
      });
    }
  };
  getData();
};

export const clearDealAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_DEAL,
  });
};
