import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    marketComparisonSection: {
      // marginTop: '24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      color: theme.colors.charcoal,
    },
    sectionHeading: {
      fontSize: '22px',
      fontWeight: theme.fontWeights.medium,
    },
    spinnerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '280px',

      color: theme.colors.nimbus,
    },
  }),
  { name: 'MarketComparison' },
);

export default useStyles;
