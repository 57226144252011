import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    barBackground: {
      backgroundColor: (props) => props?.colors?.light,
      borderRadius: '16px',
      border: '1px solid black',
      // padding: '5px',
      width: '100%',
      height: '52px',
      margin: '16px 0',
    },
    barBackgroundBorder: {
      width: '100%',
      height: '100%',
      borderRadius: '16px',
      // border: '5px solid blue',
      overflow: 'hidden',
      // padding: '5px',
      border: (props) => `5px solid ${props?.colors?.light}`,
    },
    barBackgroundInner: {
      backgroundColor: (props) => props?.colors?.dark,
      borderRadius: '11px',
      width: '100%',
      height: '100%',
      transition: 'width 0.5s',
      boxSizing: 'border-box',
      border: (props) => `2px solid ${props.colors.dark}`,

      background: (props) =>
        `repeating-linear-gradient(120deg, ${props?.colors?.extraDark} 1px,${props?.colors?.dark} 2px, ${props?.colors?.dark} 22px)`,
    },
    comparisonBarContainer: {
      position: 'relative',
      maxWidth: '504px',
    },
    bar: {
      marginTop: '92px',
      color: (props) => props?.colors?.light,
      position: 'relative',
    },
    xValues: {
      justifyContent: 'space-between',
      textAlign: 'center',
      fontSize: '12px',
      paddingTop: '4px',
    },
    xValue: {
      minWidth: '50px',
      flex: 1,
      gap: '4px',
    },
    bottomValue: {
      textAlign: 'left',
      marginRight: 'auto',
    },
    topValue: {
      textAlign: 'right',
      marginLeft: 'auto',
    },
    xValueLabel: {
      color: theme.colors.smoke,
    },
    valueContainer: {
      position: 'absolute',
      height: '82px',
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      gap: '10px',
      transition: 'left 0.5s',
    },
    value: {
      height: '56px',
      width: '104px',
      backgroundColor: (props) => props?.colors?.dark,
      border: '1px solid #6A6A6A',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: theme.fontWeights.medium,
      fontSize: '18px',
      flexShrink: 0,
    },
    dashedLine: {
      height: '15px',
      width: '1px',
      flexGrow: '1',
      border: 'none',
      borderRight: '1px dashed #CACACA',
    },
    chartLines: {
      height: '28px',
      width: '100%',
      // marginTop: '-34px',
      position: 'relative',
    },
    horizontalAxis: {
      position: 'absolute',
      height: '2px',
      width: '100%',
      top: '50%',
      backgroundColor: '#313131',
    },
    edgeTick: {
      position: 'absolute',
      height: '100%',
      width: '1px',
      // left: 0,
      backgroundColor: '#313131',
    },
  }),
  { name: 'ComparisonBar' },
);

export default useStyles;
