/* eslint-disable import/prefer-default-export */
import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { monthlyOrderSummaries as monthlyOrderSummaryQuery } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchMonthlyOrderSummariesAction =
  (restId, userId, userType, platform) => (dispatch) => {
    dispatch({
      type: type.FETCH_MONTHLY_ORDER_SUMMARIES_PENDING,
    });
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'ORDER_SUMMARIES',
    });

    const paginatedData = [];
    const fetchLoop = async (pageSize, offset) => {
      try {
        const response = await API.graphql(
          {
            query: monthlyOrderSummaryQuery,
            variables: {
              restId,
              pageSize,
              offset,
              platform,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { monthlyOrderSummaries },
        } = response;

        // congregate data
        if (monthlyOrderSummaries.length > 0) {
          paginatedData.push(...monthlyOrderSummaries);
        }

        if (monthlyOrderSummaries.length >= pageSize) {
          fetchLoop(pageSize, offset + pageSize);
          return;
        }

        devLog('success', 'monthlyOrderSummaries', paginatedData);

        // Last call
        dispatch({
          type: type.FETCH_MONTHLY_ORDER_SUMMARIES_SUCCESS,
          payload: { monthlyOrderSummaries: paginatedData },
        });
      } catch (error) {
        devLog('error', 'monthlyOrderSummaries', error);

        dispatch({
          type: type.FETCH_MONTHLY_ORDER_SUMMARIES_FAILURE,
          payload: `Unable to retrieve monthlyOrderSummaries: ${error}`,
        });
      }

      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ORDER_SUMMARIES',
      });
    };
    fetchLoop(500, 0);
  };
