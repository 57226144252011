import PropTypes from 'prop-types';
import { formatCurrency } from '../../utils/helpers';
import { dealPropTypes } from './Deal';

export const offerPropTypes = PropTypes.shape({
  goalOfferId: PropTypes.string,
  restId: PropTypes.string,
  enabled: PropTypes.bool,
  discount: PropTypes.string,
  lightning: PropTypes.bool,
  recurring: PropTypes.bool,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dayOfWeek: PropTypes.number,
  goalId: PropTypes.string,
  partySizeLower: PropTypes.number,
  partySizeUpper: PropTypes.number,
  partySizeMonthlyLower: PropTypes.number,
  partySizeMonthlyUpper: PropTypes.number,
  revenueExpected: PropTypes.number,
  revenueLower: PropTypes.number,
  revenueUpper: PropTypes.number,
  revenueMonthlyExpected: PropTypes.number,
  revenueMonthlyLower: PropTypes.number,
  revenueMonthlyUpper: PropTypes.number,
  partySizeActual: PropTypes.number,
  revenueActual: PropTypes.number,
  onPeak: PropTypes.bool,
  deals: PropTypes.arrayOf(dealPropTypes),
});

export const offersPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(offerPropTypes),
  error: PropTypes.string,
  fetching: PropTypes.bool,
  success: PropTypes.bool,
});

/**
 *
 * @param {*} offer
 * @returns
 */
export const getReadableCustomers = (offer) => {
  if (offer.partySizeLower === offer.partySizeUpper) {
    return Math.max(1, offer.partySizeLower);
  }
  return `${Math.max(1, offer.partySizeLower)} - ${Math.max(2, offer.partySizeUpper)}`;
};

/**
 *
 * @param {*} actualRevenue
 * @param {*} expectedRevenue
 * @returns
 */
export const getReadableRevenueRelativeToGoal = (actualRevenue, expectedRevenue) => {
  // Avoid division by zero
  if (expectedRevenue <= 0) {
    return '';
  }

  if (Math.round(actualRevenue) === Math.round(expectedRevenue)) {
    return 'Perfectly on target';
  }

  const percentage = Math.round((actualRevenue / expectedRevenue) * 100);

  if (actualRevenue < expectedRevenue) {
    return `${100 - percentage}% below ${formatCurrency(expectedRevenue, false)}`;
  }

  return `${percentage - 100}% above ${formatCurrency(expectedRevenue, false)}`;
};

/**
 *
 * @param {*} day
 * @param {*} daysInAdvance
 * @returns
 */
export const getDayRelativeToToday = (day, daysInAdvance) => {
  if (daysInAdvance === 0) {
    return `${day}: Today`;
  }

  if (daysInAdvance === 1) {
    return `${day}: Tomorrow`;
  }

  return day;
};

/**
 * returns true if the offer has takeaway deals
 */
export const hasTakeawayDeals = (offer) => offer?.deals.some((deal) => !deal.takeawayOnly);

/**
 * returns true if the offer has dine-in deals
 */
export const hasDineInDeals = (offer) => offer?.deals.some((deal) => !deal.dineInOnly);

/**
 * returns the initial type dropdown value for the disable and delete offer modals
 * @param {boolean} hasTakeaway
 * @param {boolean} hasDineIn
 * @returns
 */
export const getInitialType = (hasTakeaway, hasDineIn) =>
  hasTakeaway && hasDineIn
    ? 'dineInAndTakeaway'
    : (hasTakeaway && 'takeaway') || (hasDineIn && 'dineIn') || '';

export const newOfferProps = PropTypes.shape({
  service: PropTypes.shape({
    index: PropTypes.number,
    name: PropTypes.string,
    tagline: PropTypes.string,
    component: PropTypes.element,
    enabled: PropTypes.boolean,
    value: PropTypes.shape({
      dineIn: PropTypes.boolean,
      takeaway: PropTypes.boolean,
      drinksOnly: PropTypes.boolean,
    }),
  }),
  date: PropTypes.shape({
    index: PropTypes.number,
    name: PropTypes.string,
    tagline: PropTypes.string,
    component: PropTypes.element,
    enabled: PropTypes.boolean,
    value: PropTypes.shape({
      recurringDays: PropTypes.shape({
        mon: PropTypes.bool,
        tue: PropTypes.bool,
        wed: PropTypes.bool,
        thu: PropTypes.bool,
        fri: PropTypes.bool,
        sat: PropTypes.bool,
        sun: PropTypes.bool,
      }),
      recurring: PropTypes.boolean,
      startDate: PropTypes.boolean, // moment proptype
    }),
  }),
  whatTimes: PropTypes.shape({
    index: PropTypes.number,
    name: PropTypes.string,
    tagline: PropTypes.string,
    component: PropTypes.element,
    enabled: PropTypes.boolean,
    value: PropTypes.shape({
      lightning: PropTypes.boolean,
      startTime: PropTypes.boolean, // moment proptype
      endTime: PropTypes.boolean, // moment proptype
    }),
  }),
  offerAmount: PropTypes.shape({
    index: PropTypes.number,
    name: PropTypes.string,
    tagline: PropTypes.string,
    description: PropTypes.element,
    component: PropTypes.element,
    enabled: PropTypes.boolean,
    value: PropTypes.boolean, // prediction proptype
  }),
});
