import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Box, SegmentedControl, Spacer, TimePicker } from '@eatclub-apps/ec-component-library';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';
import { formatTimeFromInt } from '../../../utils/helpers';
import { newOfferProps } from '../../../data/models/Offer';

const OfferTime = ({ newOffer, setNewOffer, drinksOnly, hoursParsed }) => {
  const handleChange = (newValues) => {
    const value = { ...newOffer.whatTimes.value, ...newValues };

    const valid = !value.lightning || (value.lightning && value.startTime < value.endTime);

    setNewOffer({
      ...newOffer,
      whatTimes: {
        ...newOffer.whatTimes,
        value,
        error: valid ? '' : 'Please select a valid start and end time to continue.',
      },
      offerAmount: { ...newOffer.offerAmount, enabled: valid },
      offerLimit: { ...newOffer.offerLimit, enabled: valid },
    });
  };

  const days = Object.keys(newOffer.date.value.recurringDays).filter(
    (day) => newOffer.date.value.recurringDays[day],
  );

  const firstAvailableTime = Math.max(...days.map((day) => hoursParsed[`${day}Open`]));
  const lastAvailableTime = Math.min(...days.map((day) => hoursParsed[`${day}Close`]));

  const availableTimes = [firstAvailableTime, lastAvailableTime];

  const styles = {
    label: {
      color: COLORS.CHARCOAL,
      fontSize: '16px',
      fontWeight: FONT_WEIGHTS.BOLD,
      marginBottom: '4px',
      textAlign: 'left',
    },
    padding: '0',
    width: '100%',
    container: { height: 'unset' },
    dropdownArrow: {
      alignItems: 'center',
      bottom: '0',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      paddingRight: 0,
      position: 'absolute',
      right: '0px',
      top: '0px',
      width: '40px',
    },
  };

  return (
    <Spacer direction='vertical' gap='m'>
      <SegmentedControl
        value={newOffer.whatTimes.value.lightning}
        items={[
          { label: 'All day', value: false },
          { label: 'Time limited', value: true },
        ]}
        onSelect={(value) => handleChange({ lightning: value })}
        style={{ fontSize: '16px' }}
      />

      {newOffer.whatTimes.value.lightning && (
        <Spacer gap={20}>
          <TimePicker
            label='Start'
            value={newOffer.whatTimes.value.startTime}
            onSelect={(value) => handleChange({ startTime: value })}
            startTime={availableTimes[0]}
            endTime={availableTimes[1]}
            increment={15}
            style={styles}
          />

          <TimePicker
            label='End'
            value={newOffer.whatTimes.value.endTime}
            onSelect={(value) => handleChange({ endTime: value })}
            startTime={availableTimes[0]}
            endTime={availableTimes[1]}
            increment={15}
            style={styles}
          />
        </Spacer>
      )}

      {newOffer.whatTimes.value.lightning && (
        <Box
          style={{
            backgroundColor: '#F1F5FA',
            borderRadius: '8px',
            padding: '20px',
            maxWidth: '612px',
          }}
        >
          <Box style={{ fontSize: '14px', color: '#6E6E6D', lineHeight: '24px' }}>
            {drinksOnly
              ? `Customers can tap their EatClub card to pay multiple times from ${formatTimeFromInt(
                  newOffer.whatTimes.value.startTime,
                )} - ${formatTimeFromInt(newOffer.whatTimes.value.endTime)}. All
          payments must be made by ${formatTimeFromInt(
            newOffer.whatTimes.value.endTime,
          )} for their offer to be deducted from their bill.`
              : `Select the time which you would like customers to arrive by.`}
          </Box>
        </Box>
      )}
    </Spacer>
  );
};

OfferTime.propTypes = {
  newOffer: newOfferProps.isRequired,
  setNewOffer: PropTypes.func.isRequired,
  drinksOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hoursParsed: state.restaurantActive.restaurant.hoursParsed,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfferTime);
