import MaterialTable from '@material-table/core';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import { bindActionCreators } from 'redux';
import { clearDealsAction, fetchDealsByIdsAction } from '../../../actions/dealsByIdsAction';
import OutlinedTabBar from '../../../components/TabBar/OutlinedTabBar/OutlinedTabBar';
import { devLog, formatNumber } from '../../../utils';
import { calculateRevenue } from '../../../utils/calculateRevenue';
import {
  formatCurrency,
  groupByProperty,
  groupObjects,
  isEmpty,
  roundToDecimal,
} from '../../../utils/helpers';
import { sortListNaturalByProperty } from '../../../utils/listHelpers';
import { calculatePercentage } from '../../../utils/math';
import useStyles from './RedemptionsTableStyles';

const RedemptionsTable = ({ dates, analytics, redemptions, activeRestaurant }) => {
  const classes = useStyles();

  const [tab, setTab] = useState('dineIn');

  /**
   *  Count each deal's occurrence value.
   *  Basically unlimited recurring deals need to be capped
   *
   * @param deal
   * @returns {*|number}
   */
  const countDealOccurrences = (deal) => {
    if (isEmpty(deal)) {
      return 0;
    }

    const { qtyLeft, recurring, recurringQty } = deal;

    if (recurring) {
      // Limit the recurring qty since unlimited deals go all the way to 999
      const getLimitedQuantity = () => {
        if (recurringQty >= 99) {
          return Math.min(5, recurringQty);
        }

        return recurringQty;
      };

      const tablesPosted = getLimitedQuantity();
      return tablesPosted;
    }

    devLog('debug', 'non-recurring deal: ', deal);

    const tablesPosted = qtyLeft;
    return tablesPosted;
  };
  //
  // const hasDineInOffers = analytics.historicalOffers.some((offer) => !offer?.takeawayOnly);
  // const hasTakeawayOffers = analytics.historicalOffers.some((offer) => !offer?.dineInOnly);
  // const hasAnyOffers = analytics.historicalOffers.length > 0;
  //
  // const tabs = [
  //   hasDineInOffers && { label: 'Dine-in', value: 'dine-in' },
  //   hasTakeawayOffers && { label: 'Takeaway', value: 'takeaway' },
  //   { label: 'All', value: 'all' },
  // ].filter((tab) => !!tab);
  // // if (hasDineInOffers) {
  // //   tabs.push({ label: 'Dine-in', value: 'dine-in' });
  // // }

  /**
   * Form the data to show in the table
   */
  const revenueData = useMemo(() => {
    const offersForFilter = (filter = null) => {
      const filteredOffers = analytics.historicalOffers.filter(
        (offer) =>
          filter === 'all' ||
          (filter === 'dineIn' && !offer?.takeawayOnly) ||
          (filter === 'takeaway' && !offer?.dineInOnly),
      );

      const offersByPercentage = groupByProperty(
        sortListNaturalByProperty(filteredOffers, 'discount'),
        'discount',
      );

      const filteredRedemptions = redemptions.filter(
        (redemption) =>
          filter === 'all' ||
          (filter === 'takeaway' && redemption.takeaway) ||
          (filter === 'dineIn' && !redemption.takeaway),
      );

      const redemptionsByPercentage = groupObjects(filteredRedemptions, 'discount');

      // If there are redemptions but no historical offers for them, create a virtual historical offer
      let isMissingOffers = false;
      Object.keys(redemptionsByPercentage).forEach((percentage) => {
        if (!offersByPercentage.some((offer) => offer?.name === percentage)) {
          offersByPercentage.push({ name: percentage, items: [] });
          isMissingOffers = true;
        }
      });

      // Count the amount of occurrences of each deal
      const dataForDiscount = offersByPercentage.map((offer) => {
        // Count the amount of available deals (e.g. count all 20% deals for each day)
        const dealOccurrences = offer.items
          // .filter((item) => item.enabled)
          .reduce((accum, weekdayOffer) => {
            const occurrences = countDealOccurrences(weekdayOffer);
            return accum + occurrences;
          }, 0);

        const redemptionsForDiscount = redemptionsByPercentage?.[offer.name] ?? [];
        const revenueForGroup = calculateRevenue(
          redemptionsForDiscount,
          [],
          activeRestaurant.averageOrderValue,
          activeRestaurant.averageBookingValue,
        );

        // Conversions can't be calculated if historical offers are missing
        const conversionPercentage =
          dealOccurrences > 0
            ? roundToDecimal(
                calculatePercentage(redemptionsForDiscount?.length, dealOccurrences),
                1,
              )
            : '-';

        return {
          discount: offer.name,
          revenue: revenueForGroup,
          available: dealOccurrences > 0 ? dealOccurrences : '-',
          redeemed: redemptionsForDiscount?.length,
          conversion: dealOccurrences > 0 ? `${conversionPercentage}%` : '-',
          customers: revenueForGroup.totalGuests,
          netRevenue: revenueForGroup.netRevenue,
          aov: revenueForGroup.totalAOV,
          isMissingOffers,
        };
      });

      return dataForDiscount;
    };

    return {
      all: offersForFilter('all'),
      dineIn: offersForFilter('dineIn'),
      takeaway: offersForFilter('takeaway'),
    };
  }, [
    analytics.historicalOffers,
    redemptions,
    activeRestaurant.averageOrderValue,
    activeRestaurant.averageBookingValue,
  ]);

  // Automatically switch to whichever tab has data when data is loaded.
  // Prevents showing empty dine-in state if there are takeaway deals
  useEffect(() => {
    if (isEmpty(revenueData[tab])) {
      const tabsWithData = ['dineIn', 'takeaway', 'all'].filter(
        (filter) => !isEmpty(revenueData[filter]),
      );
      setTab(tabsWithData?.[0] ?? 'dineIn');
    }
  }, [revenueData]);

  const columns = [
    {
      title: 'Offer',
      field: 'discount',
      render: (rowData) => (
        <Chip
          label={rowData.discount}
          variant='outlined'
          style={{ border: '1px solid black', align: 'left', textAlign: 'left !important' }}
        />
      ),
    },
    {
      field: 'available',
      title: 'Available',
      rowStyle: { textAlign: 'right' },
      render: (rowData) => <>{formatNumber(rowData.available, 0)}</>,
    },
    {
      field: 'redeemed',
      title: 'Redeemed',
      rowStyle: { textAlign: 'right' },
      render: (rowData) => <>{formatNumber(rowData.redeemed, 0)}</>,
    },
    {
      field: 'conversion',
      title: 'Conversion',
      rowStyle: { textAlign: 'right' },
    },
    {
      field: 'customers',
      title: 'Customers',
      rowStyle: { textAlign: 'right' },
    },
    {
      field: 'netRevenue',
      title: 'Net Revenue',
      rowStyle: { textAlign: 'right' },
      render: (rowData) => <>{formatCurrency(rowData.netRevenue)} </>,
    },
    {
      field: 'aov',
      title: 'AOV',
      rowStyle: { textAlign: 'right' },
      render: (rowData) => (
        <span style={{ justifyContent: 'end' }}>{formatCurrency(rowData.aov)} </span>
      ),
    },
  ];

  return (
    <Box className={classes.tableStyles}>
      <Spacer direction='vertical' gap={16} className={classes.header}>
        <Box>Redemption</Box>
        <OutlinedTabBar
          tabs={[
            { label: 'Dine-in', value: 'dineIn' },
            { label: 'Takeaway', value: 'takeaway' },
            { label: 'All', value: 'all' },
          ]}
          onSelect={setTab}
          value={tab}
          disableScroll
        />
        {revenueData[tab].some((offer) => offer?.isMissingOffers) && (
          <Box style={{ fontSize: '14px', fontWeight: '400', maxWidth: '600px' }}>
            We’re unable to show some offer availability and conversion data as some historical
            offer data is unavailable for the selected date range.
          </Box>
        )}
      </Spacer>
      <MaterialTable
        isLoading={false}
        columns={columns}
        data={revenueData[tab]}
        options={{
          emptyRowsWhenPaging: false,
          padding: 'dense',
          search: false,
          draggable: false,
          toolbar: true,
          paging: false,
          showTitle: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No redemptions to display',
          },
        }}
      />
    </Box>
  );
};

RedemptionsTable.propTypes = {
  analytics: PropTypes.shape({
    success: PropTypes.bool,
  }).isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
  redemptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeRestaurant: PropTypes.shape({
    averageOrderValue: PropTypes.number,
    averageBookingValue: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  dates: state.dates,
  activeRestaurant: state.restaurantActive,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearDeals: clearDealsAction,
      fetchDealsByIds: fetchDealsByIdsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionsTable);
