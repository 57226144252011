/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import dayjs from 'dayjs';

const generateTimeIntervals = (startTime, endTime) => {
  let mutableStartTime = dayjs(startTime);
  const timeStops = [];

  // if store opens at 9:01 start bookings at 9:15
  const minutesFromInterval = mutableStartTime.minute() % 15;
  if (minutesFromInterval > 0) {
    mutableStartTime = mutableStartTime.add(15 - minutesFromInterval, 'minutes');
  }

  while (mutableStartTime <= endTime) {
    timeStops.push(mutableStartTime.format('hh:mm a'));
    mutableStartTime = mutableStartTime.add(15, 'minutes');
  }
  return timeStops;
};

const TextFieldTimePicker = ({
  className,
  label,
  value,
  onChange,
  error,
  helperText,
  earliest,
  latest,
}) => {
  const timeIntervals = useMemo(() => generateTimeIntervals(earliest, latest), []);

  return (
    <Autocomplete
      options={timeIntervals}
      getOptionLabel={(option) => option}
      style={{ width: 300 }}
      value={value}
      onChange={(event, newInputValue) => {
        const selectedTime = newInputValue ? dayjs(newInputValue.toLowerCase(), 'hh:mm a') : null;
        onChange(selectedTime);
      }}
      onBlur={(event) => {
        const { value: timeValue } = event.target;
        const selectedTime = timeValue ? dayjs(timeValue.toLowerCase(), 'hh:mm a') : null;
        onChange(selectedTime);
      }}
      handleHomeEndKeys={false}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          className={className}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      // disableClearable={true}
    />
  );
};

export default TextFieldTimePicker;
