/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  Card,
  CardContent,
  Typography,
  Box,
  Skeleton,
  Grid,
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import { clearDealsAction, fetchDealsByIdsAction } from '../../../actions/dealsByIdsAction';
import useStyles from './RedemptionTableStyles';

const RedemptionTable = ({
  analytics,
  clearDeals,
  dates,
  deals,
  fetchDealsByIds,
  region,
  restId,
  userId,
  userType,
}) => {
  const classes = useStyles();

  const [bookingRows, setBookingRows] = useState([]);
  const [reducedBookingRows, setReducedBookingRows] = useState([]);

  /*
   * Functions
   */

  // Get a single deal's tables posted
  const getTablesPosted = useCallback(
    (discountCount, currentDeal) => {
      if (!currentDeal) {
        return;
      }

      const {
        enabled,
        endDate,
        endTime,
        lastUpdated,
        qtyLeft,
        recurring,
        recurringQty,
        startDate,
        startTime,
      } = currentDeal;

      if (recurring) {
        // StartTime is either the Deal's or the Date Picker's. Whichever comes first.

        let dayjsStartDate = dayjs(startDate, 'YYYY-MM-DDZ')
          .startOf('day')
          .add(startTime, 'minutes');
        if (dayjsStartDate.isBefore(dates.startDate, 'day')) {
          dayjsStartDate = dayjs(dates.startDate);
        }

        // EndTime is either the Deal's or the Date Picker's. Whichever comes last.
        let dayjsEndDate = endDate
          ? dayjs(endDate, 'YYYY-MM-DDZ').startOf('day').add(endTime, 'minutes')
          : dayjs(dates.endDate);

        if (dayjsEndDate.isAfter(dates.endDate, 'day')) {
          dayjsEndDate = dayjs(dates.endDate);
        }

        // Unless the Endtime is disabled
        // End time is either Last Updated or Date Picker's. Whichever comes last.
        if (!enabled) {
          const dayjsLastUpdated = dayjs(lastUpdated, 'YYYY-MM-DDTHH:mm:ssZ');
          if (dayjsLastUpdated.isBetween(dayjsStartDate, dayjsEndDate, 'day', '[]')) {
            dayjsEndDate = dayjsLastUpdated;
          }
        }

        const occurences =
          Math.floor(dayjs.duration(dayjsEndDate.diff(dayjsStartDate)).asWeeks()) + 1;

        const tablesPosted = occurences * recurringQty;
        return tablesPosted;
      }
      const tablesPosted = qtyLeft + discountCount;
      return tablesPosted;
    },
    [dates.endDate, dates.startDate],
  );

  // Get single deal's take rate
  const getTakeRate = (tablesPosted, discountCount) => {
    const takeRate = discountCount / tablesPosted;
    let takeRateFormat = (takeRate * 100).toFixed(0);

    if (Number.isNaN(takeRateFormat)) {
      return '-';
    }

    if (takeRateFormat < 1) {
      takeRateFormat = '<1';
    } else if (takeRateFormat > 100) {
      takeRateFormat = 100;
    }
    return takeRateFormat;
  };

  /*
   * UseEffects
   */

  //
  useEffect(() => {
    if (!analytics.success) {
      return;
    }

    const bookingsByDealId = analytics.bookingsData.reduce((accumByDealId, booking) => {
      // check if booking exists in reducer
      const dealIndex = accumByDealId.findIndex((deal) => deal.dealId === booking.dealId);

      // store initial booking
      if (dealIndex === -1) {
        accumByDealId.push({
          discountCount: 1,
          discount: booking.discount,
          dealId: booking.dealId,
        });
      } else {
        // accumulate counts
        accumByDealId[dealIndex].discountCount += 1;
      }

      // return reducer array
      return accumByDealId;
    }, []);

    const bookingsDealIds = bookingsByDealId.map((booking) => booking.dealId);
    setBookingRows(bookingsByDealId);

    // only fetch deals if they have not/need fetching
    if (deals.dealsSuccess) {
      return;
    }

    if (bookingsDealIds.length === 0) {
      clearDeals();
      return;
    }

    fetchDealsByIds(bookingsDealIds, region, restId, userId, userType);
  }, [
    analytics.bookingsData,
    analytics.success,
    clearDeals,
    deals.dealsSuccess,
    fetchDealsByIds,
    region,
  ]);

  // Reduce bookings by discount type, and add the tables posted for each deal
  useEffect(() => {
    if (deals.dealsFetching) {
      return;
    }

    const bookingRowsReducer = bookingRows.reduce((accumByDealType, booking) => {
      // Get current deal to caculate tables posted
      const currentDeal = deals?.deals?.find((deal) => deal.objectId === booking.dealId);

      if (!currentDeal) {
        return accumByDealType;
      }

      const tablesPosted = getTablesPosted(booking.discountCount, currentDeal);

      // check if booking exists in reducer
      const dealIndex = accumByDealType.findIndex((deal) => deal.discount === booking.discount);

      // store initial booking
      if (dealIndex === -1) {
        accumByDealType.push({
          discountCount: booking.discountCount,
          discount: booking.discount,
          tablesPosted,
        });
      } else {
        // accumulate counts
        accumByDealType[dealIndex].discountCount += booking.discountCount;
        accumByDealType[dealIndex].tablesPosted += tablesPosted;
      }

      // return reducer array
      return accumByDealType;
    }, []);
    setReducedBookingRows(bookingRowsReducer);
  }, [bookingRows, deals.deals, deals.dealsFetching, getTablesPosted]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography variant='subtitle1' className={classes.cardTitle}>
              Redemption Per %
            </Typography>
          </Grid>

          <Grid item lg={12} sm={12} xs={12}>
            <div className={classes.redemptionTableContainer}>
              {!analytics.success || deals.dealsFetching || !deals.dealsSuccess ? (
                <Box pt={0.5}>
                  <Skeleton
                    variant='rectangular'
                    height={200}
                    style={{ marginBottom: 6, borderRadius: '32px' }}
                  />
                  <Skeleton />
                  <Skeleton width='50%' />
                </Box>
              ) : (
                <Table aria-label='Redemption Table'>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align='right'>Redeemed</TableCell>
                      <TableCell align='right'>Take Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reducedBookingRows.length > 0 ? (
                      <>
                        {reducedBookingRows.map((row) => (
                          <TableRow key={row.discount}>
                            <TableCell component='th' scope='row'>
                              <Chip color='secondary' variant='outlined' label={row.discount} />
                            </TableCell>
                            <TableCell align='right'>{row.discountCount}</TableCell>
                            <TableCell align='right'>
                              {getTakeRate(row.tablesPosted, row.discountCount)}%
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell component='td' scope='row'>
                          No Redemptions
                        </TableCell>
                        <TableCell component='td' align='right' scope='row'>
                          0
                        </TableCell>
                        <TableCell component='td' align='right' scope='row'>
                          -
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

RedemptionTable.propTypes = {
  analytics: PropTypes.shape({
    bookingsData: PropTypes.arrayOf(PropTypes.shape({})),
    success: PropTypes.bool,
  }).isRequired,
  clearDeals: PropTypes.func.isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
  deals: PropTypes.shape({
    deals: PropTypes.arrayOf(PropTypes.shape({})),
    dealsSuccess: PropTypes.bool,
    dealsFetching: PropTypes.bool,
  }).isRequired,
  fetchDealsByIds: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  dates: state.dates,
  deals: state.dealsByIds,
  region: state.restaurantActive.restaurant.region,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearDeals: clearDealsAction,
      fetchDealsByIds: fetchDealsByIdsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionTable);
