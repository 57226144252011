import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatCurrency } from '../../utils/helpers';

const CustomLabel = ({ x = 0, y = 0, value = 0 }) => {
  const theme = useTheme();

  if (value <= 0) {
    return <></>;
  }

  return (
    <text
      x={x}
      y={y}
      dy={-12}
      fill={theme.colors.charcoal}
      textAnchor='middle'
      fontSize={12}
      enableBackground='true'
      style={{ color: theme.colors.charcoal }}
    >
      {formatCurrency(value, false, false, false, true)}
    </text>
  );
};

CustomLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomLabel);
