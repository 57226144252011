import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SmallCheck from '../../../assets/small_check.svg';
import { opportunityPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { minutesToTime } from '../../../utils';
import { dealType } from '../../../utils/helpers';
import useStyles from './OpportunityCardStyles';

export const OpportunityDetails = ({ opportunity, number = null }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box>
        <Box className={classes.opportunityName}>
          {opportunity?.name || `Opportunity ${number}`}
        </Box>
        {opportunity?.description && (
          <Box className={classes.opportunityDescription}>{opportunity?.description}</Box>
        )}
      </Box>
      <Box className={classes.opportunityDetails}>
        <Box className={classes.opportunityDetailColumnLeft}>
          <SmallCheck height={12} width={16} />
          <Box className={classes.opportunityDetailValue}>{opportunity?.discount} off</Box>
        </Box>
        <Box className={classes.opportunityDetailColumnRight}>
          <SmallCheck height={12} width={16} />
          <Box className={classes.opportunityDetailValue}>
            {`From ${minutesToTime(opportunity?.startTime, 'h:mma')} to ${minutesToTime(
              opportunity?.endTime,
              'h:mma',
            )}`}
          </Box>
        </Box>
        <Box className={classes.opportunityDetailColumnLeft}>
          <SmallCheck height={12} width={16} />
          <Box className={classes.opportunityDetailValue}>{opportunity?.qty} available</Box>
        </Box>
        <Box className={classes.opportunityDetailColumnRight}>
          <SmallCheck height={12} width={16} />
          <Box className={classes.opportunityDetailValue}>{dealType(opportunity)}</Box>
        </Box>
      </Box>
    </Box>
  );
};

OpportunityDetails.propTypes = {
  opportunity: opportunityPropTypes.isRequired,
  number: PropTypes.number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetails);
