import dayjs from 'dayjs';

/**
 * Sort by Date
 *
 * @param date1 first date
 * @param date2 next date
 * @param format format of dates
 */
export const sortByDate = (date1, date2, format = import.meta.env.VITE_DATE_FORMAT) => {
  const dayjsDate1 = dayjs(date1, format);
  const dayjsDate2 = dayjs(date2, format);

  if (dayjsDate1.isBetween(dayjsDate2)) {
    return -1;
  }
  if (dayjsDate1.isAfter(dayjsDate2)) {
    return 1;
  }
  return 0;
};
