/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import theme from '../../../../AppTheme';

const OfferDataLabel = ({ label, value, style = {}, feature = false, bgColor = '#F29160B3' }) => {
  // TODO I haven't yet got style overrides working with classNames so keeping this for now
  const styles = {
    container: {
      ...style,
      display: 'flex',
      alignItems: 'center',
      ...style?.container,
    },
    label: {
      color: '#313131',
      letterSpacing: '1.2px',
      minWidth: '140px',
      ...style?.label,
    },
    value: {
      ...style?.value,
    },
    featureValue: {
      padding: '8px 12px',
      borderRadius: '12px',
      backgroundColor: bgColor,
      color: '#313131',
      whiteSpace: 'nowrap',
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        whiteSpace: 'auto',
      },
      ...style?.featureValue,
    },
  };

  return (
    <Box style={styles?.container}>
      {label && <Box style={styles.label}>{label}</Box>}
      <Box style={{ ...styles?.value, ...(feature && styles.featureValue) }}>{value}</Box>
    </Box>
  );
};

OfferDataLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  feature: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfferDataLabel);
