import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import AppError from '../../errorApp/ErrorApp';
import LogMessages from '../../logMessages/LogMessages';

const useStyles = makeStyles(
  {
    contentInner: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },

    overrides: {
      listItemText: {
        fontSize: '2px',
      },
      MuiTypography: {
        body1: {
          color: 'orange',
          fontSize: '2px',
        },
      },
    },
  },
  { name: 'Body' },
);

const Body = ({ children, style = {} }) => {
  const classes = useStyles();

  return (
    <main className={classes.content} style={style}>
      <div className={classes.contentInner}>{children}</div>
      <AppError />
      <LogMessages />
    </main>
  );
};

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  style: PropTypes.shape({}),
};

export default Body;
