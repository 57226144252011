import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@mui/material/styles';
import { formatCompactNumber, formatCurrency } from '../../utils/helpers';
import InsightItem from './InsightItem';
import useStyles from './InsightSummaryStyles';
import CustomersIcon from '../../assets/icons/customers.svg';
import RevenueIcon from '../../assets/icons/revenue.svg';
import ImpressionsIcon from '../../assets/icons/impressions.svg';
import AOVIcon from '../../assets/icons/aov.svg';
import PillarModal from './PillarModal/PillarModal';
import { INSIGHT_TAB } from './PillarModal/PillarModalStyles';

const InsightSummary = ({ data, loading, impressions }) => {
  const isLoading = loading?.loadingIds.length > 0;
  const classes = useStyles();
  const theme = useTheme();

  const [showModal, setShowModal] = useState(false);
  const [modalTab, setModalTab] = useState(null);

  const getNetRevenue = (dataset) => {
    return dataset.netRevenue;
  };

  const getNetRevenueCompare = (dataset) => {
    return dataset.netRevenueCompare;
  };

  const asCurrency = (value) => {
    return formatCurrency(value, false, '$0', false, true);
  };

  return (
    <Box className={classes.root}>
      <Grid container wrap='wrap' spacing='1px' className={classes.grid}>
        <Grid item className={classes.gridBlock}>
          <InsightItem
            title='Impressions'
            Icon={ImpressionsIcon}
            iconColor={theme.colors.impressions}
            data={impressions?.impressions?.value} // TODO
            previous={impressions?.compareImpressions?.value}
            dataFormatted={formatCompactNumber(impressions?.impressions?.value)}
            previousFormatted={formatCompactNumber(impressions?.compareImpressions?.value)}
            pastYear={formatCompactNumber(impressions?.pastYearImpressions?.value)}
            loading={isLoading}
            onClick={() => {
              setShowModal(true);
              setModalTab(INSIGHT_TAB.IMPRESSIONS);
            }}
          />
        </Grid>
        <Grid item className={classes.gridBlock}>
          <InsightItem
            title='Customers'
            Icon={CustomersIcon}
            iconColor={theme.colors.customers}
            data={data.current.totalGuests} // Factors in party size so is typically larger than deals redeemed.
            previous={data.current.totalGuestsCompare}
            dataFormatted={formatCompactNumber(data.current.totalGuests)}
            previousFormatted={formatCompactNumber(data.current.totalGuestsCompare)}
            pastYear={formatCompactNumber(data.pastYear.totalGuests)}
            loading={isLoading}
            onClick={() => {
              setShowModal(true);
              setModalTab(INSIGHT_TAB.CUSTOMERS);
            }}
          />
        </Grid>
        <Grid item className={classes.gridBlock}>
          <InsightItem
            title='Avg order value'
            Icon={AOVIcon}
            iconColor={theme.colors.aov}
            data={data.current.totalAOV}
            previous={data.current.totalAOVCompare}
            dataFormatted={asCurrency(data.current.totalAOV)}
            previousFormatted={asCurrency(data.current.totalAOVCompare)}
            pastYear={asCurrency(data.pastYear.totalAOV)}
            loading={isLoading}
            onClick={() => {
              setShowModal(true);
              setModalTab(INSIGHT_TAB.AOV);
            }}
          />
        </Grid>
        <Grid item className={classes.gridBlock}>
          <InsightItem
            title='Net revenue'
            Icon={RevenueIcon}
            iconColor={theme.colors.revenue}
            data={getNetRevenue(data.current)}
            previous={getNetRevenueCompare(data.current)}
            dataFormatted={asCurrency(getNetRevenue(data.current))}
            previousFormatted={asCurrency(getNetRevenueCompare(data.current))}
            pastYear={asCurrency(getNetRevenue(data.pastYear))}
            loading={isLoading}
            onClick={() => {
              setShowModal(true);
              setModalTab(INSIGHT_TAB.NET_REVENUE);
            }}
          />
        </Grid>
      </Grid>

      <PillarModal
        isOpen={showModal}
        initialTab={modalTab}
        onClose={() => setShowModal(false)}
        data={data}
      />
    </Box>
  );
};

InsightSummary.propTypes = {
  data: PropTypes.shape({
    current: PropTypes.shape({
      totalAOV: PropTypes.number,
      totalAOVCompare: PropTypes.number,
      totalGuests: PropTypes.number,
      totalGuestsCompare: PropTypes.number,
    }),
    pastYear: PropTypes.shape({
      totalAOV: PropTypes.number,
      totalAOVCompare: PropTypes.number,
      totalGuests: PropTypes.number,
      totalGuestsCompare: PropTypes.number,
    }),
  }).isRequired,
  impressions: PropTypes.shape({}).isRequired,
  loading: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InsightSummary);
