import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { Spacer } from '@eatclub-apps/ec-component-library';
import { Heading } from '../../../components/Heading';
import useStyles from './PillarModalStyles';
import LeftArrowSVG from '../../../assets/icons/chevron_left_big.svg';
import RightArrowSVG from '../../../assets/icons/chevron_right_big.svg';

export const PillarModalHeader = ({ icon, heading, onNext, onPrev, dateRange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerContainer}>
      <Box className={classes.headerTitleContainer}>
        <Spacer direction='vertical'>
          <Spacer>
            {icon}
            <Heading size='2'>{heading}</Heading>
          </Spacer>
          <Box style={{ fontWeight: '500' }}>{dateRange}</Box>
        </Spacer>
      </Box>
      <Box className={classes.headerButtonsContainer}>
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          onClick={onPrev}
          style={{ minWidth: 'unset', height: '50px', width: '42px' }}
        >
          <LeftArrowSVG />
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          onClick={onNext}
          style={{ minWidth: 'unset', height: '50px', width: '42px' }}
        >
          <RightArrowSVG />
        </Button>
      </Box>
    </Box>
  );
};

PillarModalHeader.propTypes = {
  icon: PropTypes.element.isRequired,
  heading: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};
