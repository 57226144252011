/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Grid, CardContent, Card, Typography, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePageViews } from '../../utils/analytics';
import useStyles from './AnalyticsStyles';
import { fetchAnalyticBookingsAction } from '../../actions/analyticsAction';
import DealsRedeemed from './dealsRedeemed/DealsRedeemed';
import GuestsSeated from './guestsSeated/GuestsSeated';
import RedemptionTrendsChart from './redemptionTrendsChart/RedemptionTrendsChart';
import RedemptionTimesChart from './redemptionTimesChart/RedemptionTimesChart';
import RedemptionPerPercentTable from './redemptionPerPercentTable/RedemptionPerPercentTable';
import DineInRevenue from './dineInRevenue/DineInRevenue';
import Error from '../error/Error';
import TakeawayRevenue from './takeawayRevenue/TakeawayRevenue';
import AverageOrderValue from './averageOrderValue/AverageOrderValue';
import TotalRevenue from './totalRevenue/TotalRevenue';
import { platformMap } from '../../constants';
import { safeDivision, uniqueObjectsByProperty } from '../../utils/helpers';
import {
  getDineInBookings,
  getOfflineBookings,
  getOnlineBookings,
  getOnlineRevenueAfterOffer,
  getOnlineRevenueBeforeOffer,
  getTakeawayBookings,
  getTotalGuests,
  getOfflineRevenueAfterOffer,
} from '../../data/models/Analytics';

const Analytics = ({
  activeRestaurant,
  fetchAnalyticBookings,
  dates,
  analytics,
  platform,
  userId,
  userType,
}) => {
  const classes = useStyles();
  const { startDate, endDate } = dates;

  const { bookingsData, compareBookingsData } = analytics;

  const [data, setData] = useState({});
  usePageViews();

  useEffect(() => {
    if (!analytics.shouldFetch) {
      return;
    }

    const formatStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formatEndDate = dayjs(endDate).format('YYYY-MM-DD');

    const rangeOfDays = Math.floor(dayjs(endDate).diff(startDate, 'days', true));

    const formatCompareStartDate = dayjs(startDate)
      .subtract(rangeOfDays + 1, 'days')
      .format('YYYY-MM-DD');
    const formatCompareEndDate = dayjs(endDate)
      .subtract(rangeOfDays + 1, 'days')
      .format('YYYY-MM-DD');

    fetchAnalyticBookings(
      activeRestaurant.objectId,
      activeRestaurant.region,
      formatStartDate,
      formatEndDate,
      formatCompareStartDate,
      formatCompareEndDate,
      platform,
      userId,
      userType,
    );
  }, [
    activeRestaurant.objectId,
    activeRestaurant.region,
    analytics.shouldFetch,
    endDate,
    fetchAnalyticBookings,
    platform,
    startDate,
    userId,
    userType,
  ]);

  // Quick maths
  useEffect(() => {
    if (platform === 'whitelabel') {
      return;
    }

    if (analytics.fetching || analytics.shouldFetch) {
      return;
    }

    // Filter out multi-tap for the same deal
    const uniqueBookingsData = uniqueObjectsByProperty(bookingsData, 'dealId');
    const uniqueCompareBookingsData = uniqueObjectsByProperty(compareBookingsData, 'dealId');

    const uniqueDineInBookings = getDineInBookings(uniqueBookingsData);
    const uniqueCompareDineInBookings = getDineInBookings(uniqueCompareBookingsData);

    const uniqueOnlineDineInBookings = getOnlineBookings(uniqueDineInBookings);
    const uniqueCompareOnlineDineInBookings = getOnlineBookings(uniqueCompareDineInBookings);

    const uniqueOfflineDineInBookings = getOfflineBookings(uniqueDineInBookings);
    const uniqueCompareOfflineDineInBookings = getOfflineBookings(uniqueCompareDineInBookings);

    // Total guests
    const totalGuests = getTotalGuests(uniqueBookingsData);
    const totalGuestsCompare = getTotalGuests(uniqueCompareBookingsData);

    // Deals redeemed
    const dealsRedeemed = uniqueBookingsData.length;
    const dealsRedeemedCompare = uniqueCompareBookingsData.length;

    // Dine-in
    const dineInBookings = getDineInBookings(bookingsData);
    const onlineDineInBookings = getOnlineBookings(dineInBookings);
    const offlineDineInBookings = getOfflineBookings(dineInBookings);

    const dineInBookingsCompare = getDineInBookings(compareBookingsData);
    const onlineDineInBookingsCompare = getOnlineBookings(dineInBookingsCompare);
    const offlineDineInBookingsCompare = getOfflineBookings(dineInBookingsCompare);

    const dineInOnlineRevenueBeforeOffer = getOnlineRevenueBeforeOffer(onlineDineInBookings);
    const dineInOnlineRevenueBeforeOfferCompare = getOnlineRevenueBeforeOffer(
      onlineDineInBookingsCompare,
    );
    const dineInOnlineRevenueAfterOffer = getOnlineRevenueAfterOffer(onlineDineInBookings);
    const dineInOnlineRevenueAfterOfferCompare = getOnlineRevenueAfterOffer(
      onlineDineInBookingsCompare,
    );

    // dine-in online aov
    const dineInAOV = safeDivision(
      dineInOnlineRevenueBeforeOffer,
      uniqueOnlineDineInBookings.length,
    );
    const dineInAOVCompare = safeDivision(
      dineInOnlineRevenueBeforeOfferCompare,
      uniqueCompareOnlineDineInBookings.length,
    );

    // The AOVs to use for a mix of online and offline bookings
    const dineInOnlineAOVForOffline = activeRestaurant.averageBookingValue || dineInAOV;
    const dineInOnlineAOVForOfflineCompare =
      activeRestaurant.averageBookingValue || dineInAOVCompare;

    const dineInRevenueBeforeOffer =
      dineInOnlineRevenueBeforeOffer +
      uniqueOfflineDineInBookings.length * dineInOnlineAOVForOffline;
    const dineInRevenueBeforeOfferCompare =
      dineInOnlineRevenueBeforeOfferCompare +
      uniqueCompareOfflineDineInBookings.length * dineInOnlineAOVForOfflineCompare;

    const dineInRevenueAfterOffer =
      dineInOnlineRevenueAfterOffer +
      getOfflineRevenueAfterOffer(uniqueOfflineDineInBookings, dineInOnlineAOVForOffline);
    const dineInRevenueAfterOfferCompare =
      dineInOnlineRevenueAfterOfferCompare +
      getOfflineRevenueAfterOffer(
        uniqueCompareOfflineDineInBookings,
        dineInOnlineAOVForOfflineCompare,
      );

    // Offline only stats
    // Note: Revenue is calculated across all bookings, including multi tap, but the AOV is applied to unique bookings
    const dineInOfflineAOV = activeRestaurant.averageBookingValue;
    const dineInOfflineRevenueBeforeOffer = dineInOfflineAOV * uniqueOnlineDineInBookings.length;
    const dineInOfflineRevenueBeforeOfferCompare =
      dineInOfflineAOV * uniqueCompareOnlineDineInBookings.length;
    const dineInOfflineRevenueAfterOffer = getOfflineRevenueAfterOffer(
      uniqueDineInBookings,
      dineInOfflineAOV,
    );
    const dineInOfflineRevenueAfterOfferCompare = getOfflineRevenueAfterOffer(
      uniqueCompareDineInBookings,
      dineInOfflineAOV,
    );

    // Filter out multi-tap for the same deal
    const uniqueTakeawayBookings = getTakeawayBookings(uniqueBookingsData);
    const uniqueOnlineTakeawayBookings = getOnlineBookings(uniqueTakeawayBookings);
    const uniqueOfflineTakeawayBookings = getOfflineBookings(uniqueTakeawayBookings);

    const uniqueCompareTakeawayBookings = getTakeawayBookings(uniqueCompareBookingsData);
    const uniqueCompareOnlineTakeawayBookings = getOnlineBookings(uniqueCompareTakeawayBookings);
    const uniqueCompareOfflineTakeawayBookings = getOfflineBookings(uniqueCompareTakeawayBookings);

    // Takeaway
    // TODO filter unique
    const takeawayBookings = getTakeawayBookings(bookingsData);
    const onlineTakeawayBookings = getOnlineBookings(takeawayBookings);
    const offlineTakeawayBookings = getOfflineBookings(takeawayBookings);

    const takeawayBookingsCompare = getTakeawayBookings(compareBookingsData);
    const onlineTakeawayBookingsCompare = getOnlineBookings(takeawayBookingsCompare);
    const offlineTakeawayBookingsCompare = getOfflineBookings(takeawayBookingsCompare);

    const takeawayOnlineRevenueBeforeOffer = getOnlineRevenueBeforeOffer(onlineTakeawayBookings);
    const takeawayOnlineRevenueBeforeOfferCompare = getOnlineRevenueBeforeOffer(
      onlineTakeawayBookingsCompare,
    );
    const takeawayOnlineRevenueAfterOffer = getOnlineRevenueAfterOffer(onlineTakeawayBookings);
    const takeawayOnlineRevenueAfterOfferCompare = getOnlineRevenueAfterOffer(
      onlineTakeawayBookingsCompare,
    );

    const takeawayAOV = safeDivision(
      takeawayOnlineRevenueBeforeOffer,
      uniqueOnlineTakeawayBookings.length,
    );
    const takeawayAOVCompare = safeDivision(
      takeawayOnlineRevenueBeforeOfferCompare,
      uniqueCompareOnlineTakeawayBookings.length,
    );

    // The AOVs to use for a mix of online and offline bookings
    const takeawayOnlineAOVForOffline = activeRestaurant.averageOrderValue || takeawayAOV;
    const takeawayOnlineAOVForOfflineCompare =
      activeRestaurant.averageOrderValue || takeawayAOVCompare;

    const takeawayRevenueBeforeOffer =
      takeawayOnlineRevenueBeforeOffer +
      uniqueOfflineTakeawayBookings.length * takeawayOnlineAOVForOffline;
    const takeawayRevenueBeforeOfferCompare =
      takeawayOnlineRevenueBeforeOfferCompare +
      uniqueCompareOfflineTakeawayBookings.length * takeawayOnlineAOVForOfflineCompare;

    const takeawayRevenueAfterOffer =
      takeawayOnlineRevenueAfterOffer +
      getOfflineRevenueAfterOffer(uniqueOfflineTakeawayBookings, takeawayOnlineAOVForOffline);
    const takeawayRevenueAfterOfferCompare =
      takeawayOnlineRevenueAfterOfferCompare +
      getOfflineRevenueAfterOffer(
        uniqueCompareOfflineTakeawayBookings,
        takeawayOnlineAOVForOfflineCompare,
      );

    // Offline only stats
    const takeawayOfflineAOV = activeRestaurant.averageOrderValue;
    const takeawayOfflineRevenueBeforeOffer = takeawayOfflineAOV * uniqueTakeawayBookings.length;
    const takeawayOfflineRevenueBeforeOfferCompare =
      takeawayOfflineAOV * uniqueCompareTakeawayBookings.length;

    const takeawayOfflineRevenueAfterOffer = getOfflineRevenueAfterOffer(
      uniqueTakeawayBookings,
      takeawayOfflineAOV,
    );
    const takeawayOfflineRevenueAfterOfferCompare = getOfflineRevenueAfterOffer(
      uniqueCompareTakeawayBookings,
      takeawayOfflineAOV,
    );

    setData({
      // All
      totalGuests,
      totalGuestsCompare,
      dealsRedeemed,
      dealsRedeemedCompare,

      // Dine-in
      isDineInOffline: !activeRestaurant.ecPayEnabled,
      // Dine-in offline stats
      dineInOfflineAOV,
      dineInOfflineRevenueBeforeOffer,
      dineInOfflineRevenueBeforeOfferCompare,
      dineInOfflineRevenueAfterOffer,
      dineInOfflineRevenueAfterOfferCompare,
      // Dine-in online stats (ECPAY)
      dineInAOV,
      dineInAOVCompare,
      dineInRevenueAfterOffer,
      dineInRevenueAfterOfferCompare,
      dineInRevenueBeforeOffer,
      dineInRevenueBeforeOfferCompare,

      // Takeaway
      isTakeawayOffline: !activeRestaurant.inAppOrdering,
      // Takeaway offline stats
      takeawayOfflineAOV,
      takeawayOfflineRevenueBeforeOffer,
      takeawayOfflineRevenueBeforeOfferCompare,
      takeawayOfflineRevenueAfterOffer,
      takeawayOfflineRevenueAfterOfferCompare,
      // Takeaway online stats (IAO)
      takeawayAOV,
      takeawayAOVCompare,
      takeawayRevenueAfterOffer,
      takeawayRevenueAfterOfferCompare,
      takeawayRevenueBeforeOffer,
      takeawayRevenueBeforeOfferCompare,
    });
  }, [
    activeRestaurant.ecPayEnabled,
    activeRestaurant.inAppOrdering,
    activeRestaurant.averageBookingValue,
    activeRestaurant.averageOrderValue,
    analytics.fetching,
    analytics.shouldFetch,
    bookingsData,
    compareBookingsData,
    platform,
  ]);
  return (
    <>
      {analytics.error && <Error error={analytics.error} message={analytics.errorMessage} />}

      {platform === platformMap.eatclub.value && (
        <Grid container spacing={2} className={classes.mb2}>
          <Grid item lg={4} sm={12} xs={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item lg={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: 500 }}>Customers</Typography>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <DealsRedeemed data={data} />
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
                    <Divider />
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12}>
                    <GuestsSeated data={data} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <DineInRevenue data={data} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <TakeawayRevenue data={data} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {platform === platformMap.whitelabel.value && (
        <Grid container spacing={2} className={classes.mb2}>
          <Grid item lg={6} sm={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <AverageOrderValue />
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <TotalRevenue />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} className={classes.mb2}>
        <Grid item md={platform === 'eatclub' ? 6 : 12} xs={12}>
          <RedemptionTrendsChart
            platform={platform}
            redemptions={bookingsData}
            fetchingOrShouldFetch={analytics.fetching || analytics.shouldFetch}
          />
        </Grid>

        {platform === 'eatclub' && (
          <Grid item md={6} xs={12}>
            <RedemptionPerPercentTable />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} className={classes.mb2}>
        <Grid item xs={12}>
          <RedemptionTimesChart redemptions={bookingsData} platform={platform} />
        </Grid>
      </Grid>
    </>
  );
};

Analytics.propTypes = {
  activeRestaurant: PropTypes.shape({
    objectId: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  analytics: PropTypes.shape({
    bookingsData: PropTypes.arrayOf(PropTypes.shape({})),
    compareBookingsData: PropTypes.arrayOf(PropTypes.shape({})),
    fetching: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    success: PropTypes.bool,
    shouldFetch: PropTypes.bool,
  }).isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
  fetchAnalyticBookings: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  dates: state.dates,
  platform: state.platform.platform,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  activeRestaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAnalyticBookings: fetchAnalyticBookingsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
