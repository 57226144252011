/* eslint-disable import/prefer-default-export */
import { paginatedRequest } from './actions';
import * as type from './types';
import { whiteLabelOrderForRestaurant } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchWhiteLabelOrdersAction = (region, startDate, endDate) => async (dispatch) => {
  dispatch({
    type: type.FETCH_WHITE_LABEL_TRANSACTIONS_PENDING,
  });

  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'WHITE_LABEL_TRANSACTIONS',
  });

  try {
    const response = await paginatedRequest(
      region,
      whiteLabelOrderForRestaurant,
      {
        region,
        startDate,
        endDate,
      },
      'whiteLabelOrderForRestaurant',
    );

    devLog('success', 'white label orders', response);

    dispatch({
      type: type.FETCH_WHITE_LABEL_TRANSACTIONS_SUCCESS,
      payload: {
        bookings: response,
      },
    });
  } catch (error) {
    devLog('error', 'white label orders', error);
    dispatch({
      type: type.FETCH_WHITE_LABEL_TRANSACTIONS_FAILURE,
      payload: `Unable to retrieve transactions: ${error.message}`,
    });
  } finally {
    dispatch({
      type: type.REMOVE_ID_APP_LOADING,
      payload: 'WHITE_LABEL_TRANSACTIONS',
    });
  }
};
