import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles(() => ({
  container: (props) => ({
    width: '400px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    height: '40px',
    fontSize: '18px',
    ...props?.style?.container,
  }),
  label: (props) => ({
    width: '140px',
    flexShrink: 0,
    color: COLORS.SMOKE,
    ...props?.style?.label,
  }),
  activeValue: (props) => ({
    background: COLORS.DATE_SELECT_BACKGROUND,
    ...props?.style?.activeValue,
  }),
  value: (props) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    height: '40px',
    // padding: '10px 14px',
    width: '100%',
    borderRadius: '6px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    userSelect: 'none',
    justifyContent: 'space-between',
    ...props?.style?.value,
    '&:hover': {
      background: COLORS.DATE_SELECT_BACKGROUND,
      cursor: 'pointer',
      ...props?.style?.value?.['&:hover'],
    },
  }),
  valueOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  valuePlaceholder: (props) => ({
    color: COLORS.NIMBUS,
    ...props?.style?.valuePlaceholder,
  }),
  errorText: (props) => ({
    color: COLORS.ERROR_RED,
    fontSize: '10px',
    ...props?.style?.errorText,
  }),
}));

export default useStyles;
