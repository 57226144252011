import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles(() => ({
  button: {
    alignItems: 'center',
    background: COLORS.WHITE,
    border: '1px solid #AEAEAE',
    borderRadius: '6px',
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    top: 0,
    width: '30px',
    zIndex: 2,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#313131',
    },
  },
  items: {
    display: 'flex',
    gap: '8px',
    position: 'absolute',
  },
}));

export default useStyles;
