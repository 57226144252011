import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiCall } from '../../../actions/actions';
import { restaurantsExposureDatasetStats } from '../../../graphql/queries';
import { formatCompactNumber, roundToNearest, safeDivision } from '../../../utils/helpers';
import { getComparisonMessage } from '../../../utils/insights/insightHelpers';
import { sortAlphaNumeric } from '../../../utils/listHelpers';
import MarketComparisons from './MarketComparisons/MarketComparisons';
import useStyles, { getInsightTabColors, INSIGHT_TAB } from './PillarModalStyles';
import { PillarModalRowItem } from './PillarModalRowItem';
import { PillarModalSummary } from './PillarModalSummary';
import { formatNumber } from '../../../utils';

const ImpressionsPillarModalContent = ({ data, dates, setFilter, filter }) => {
  const tab = INSIGHT_TAB.IMPRESSIONS;
  const classes = useStyles({ tab });
  const theme = useTheme();

  const [loadingData, setLoadingData] = useState(true);
  const [comparisonData, setComparisonData] = useState({});

  // const dateRangeOptions = [
  //   { label: 'Since all time', value: 'allTime' },
  //   { label: getTimePeriodMessage(dates.startDate, dates.endDate), value: 'dateRange' },
  // ];

  // Fetch comparison data
  useEffect(() => {
    const fetchComparisonData = async () => {
      const { startDate, endDate } = dates;
      const formatStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formatEndDate = dayjs(endDate).format('YYYY-MM-DD');

      const variables = {
        startDate: formatStartDate,
        endDate: formatEndDate,
        filters: filter !== 'all' ? [filter] : [],
      };
      const response = await apiCall(restaurantsExposureDatasetStats, variables, true);
      const responseData = response.response.data.restaurantsExposureDatasetStats;

      setLoadingData(false);
      setComparisonData({
        low: roundToNearest(responseData?.low ?? 0),
        mean: roundToNearest(responseData?.mean ?? 0),
        high: roundToNearest(responseData?.high ?? 0),
        dataset: [...(responseData?.dataset ?? [])].sort(sortAlphaNumeric),
      });
    };

    try {
      setLoadingData(true);
      fetchComparisonData();
    } catch (e) {
      setLoadingData(false);
    }
  }, [dates, filter]);

  const redemptions = data?.current?.totalOrders ?? 0;
  const total = data?.impressions?.impressions?.value;

  // Interesting insight - conversions are approx 10% for each step
  console.log(
    'funnel conversions:',
    `views: ${roundToNearest(
      safeDivision(
        data?.impressions?.impressions?.profileViews * 100,
        data?.impressions?.impressions?.value,
      ),
    )}%`,
    `redemptions: ${roundToNearest(safeDivision(redemptions * 100, data?.impressions?.impressions?.profileViews))}%`,
  );

  return (
    <>
      <Box className={classes.sectionContainer}>
        <PillarModalSummary
          heading='Brand exposure that converts real customers'
          summary='Reach customers at scale! Impressions help you understand how much exposure your brand is getting on the EatClub app. Getting your brand in front of customers, when they’re making dining decisions is essential to converting these customers into paying diners.

Brand exposure should be fundamental driver of your digital marketing strategy. EatClub offers a key advantage in comparison to other forms of digital advertising as you can see how many convert into transacting customers.

Further to this, you’re able to use this data to optimise your profile on the EatClub app. Try improving offers, optimise photos and descriptions to see if you can improve your conversion rates and attract more customers when you want them.'
          maxCharacters={172}
        >
          <Box className={classes.sectionBreakdownContainer}>
            {/* <TextDropdown items={dateRangeOptions} value={dateRange} onSelect={setDateRange} /> */}

            <Box className={classes.sectionBreakdownRowItemsContainer}>
              <PillarModalRowItem
                label='Total impressions'
                value={formatNumber(data?.impressions?.impressions?.value, 0)}
                color={tab}
                variant='filled'
              />
              <PillarModalRowItem
                label='Total profile views'
                value={formatNumber(data?.impressions?.impressions?.profileViews, 0)}
                variant='outlined'
              />
              <PillarModalRowItem
                label='Total redemptions'
                value={formatNumber(redemptions, 0)}
                variant='outlined'
              />
              {/* <PillarModalRowItem */}
              {/*   label='Total Exposure' */}
              {/*   value={formatNumber(total, 0)} */}
              {/*   variant='filled' */}
              {/*   color={tab} */}
              {/* /> */}
            </Box>
          </Box>
        </PillarModalSummary>
      </Box>

      <Divider className={classes.divider} />

      <MarketComparisons
        description={getComparisonMessage(total, comparisonData)}
        comparisonData={comparisonData}
        total={data?.impressions?.impressions?.value}
        colors={getInsightTabColors(INSIGHT_TAB.IMPRESSIONS, theme)}
        format={(value) => formatCompactNumber(value)}
        loading={loadingData}
        setFilter={setFilter}
        filter={filter}
      />
    </>
  );
};

ImpressionsPillarModalContent.propTypes = {
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImpressionsPillarModalContent);
