import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './SupportStyles';
import { contactBillingAction } from '../../actions/contactBillingAction';
import { trackEvent } from '../../utils/analytics';

const SupportFormBilling = ({ billing, contactBilling, restId, userId, userType }) => {
  const classes = useStyles();
  const [reason, setReason] = useState(10);
  const [message, setMessage] = useState('');

  const reasonOptions = [
    { name: 'Managing your account', value: 10 },
    { name: 'Payment options', value: 20 },
    { name: '(Other)', value: 30 },
  ];

  const handleBillingSubmit = (event) => {
    event.preventDefault();
    trackEvent('button_click: contact_support_billing');

    if (!event.target.checkValidity()) {
      return;
    }

    const reasonName = reasonOptions.find((option) => option.value === reason).name;

    contactBilling(message, reasonName, restId, userId, userType);
    setReason(10);
    setMessage('');
  };

  return (
    <form noValidate autoComplete='off' onSubmit={handleBillingSubmit}>
      <FormGroup>
        <FormControl className={`${classes.formControl} ${classes.fixOutline}`} variant='outlined'>
          <InputLabel id='support-select-label'>Select Subject</InputLabel>
          <Select
            labelId='support-select-label'
            id='support-select'
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            required
          >
            {reasonOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
      <FormGroup className={classes.mb2}>
        <TextField
          label=''
          multiline
          minRows={5}
          maxRows={20}
          margin='normal'
          required
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          placeholder='Your Message'
        />
      </FormGroup>

      <Button type='submit' disabled={billing.pending} size='large'>
        Send
        {billing.pending && (
          <CircularProgress
            color='inherit'
            thickness={5}
            size={18}
            style={{ marginLeft: '10px' }}
          />
        )}
      </Button>
    </form>
  );
};

SupportFormBilling.propTypes = {
  billing: PropTypes.shape({
    pending: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  contactBilling: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  billing: state.contactBilling,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      contactBilling: contactBillingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SupportFormBilling);
