import dayjs from 'dayjs';
import * as type from '../actions/types';

const initialState = {
  data: [],
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_GOALS_PENDING:
      return {
        ...state,
        pending: true,
        error: '',
      };
    case type.FETCH_GOALS_SUCCESS:
      const today = dayjs().startOf('day'); // TODO get in restaurant's timezone

      const getGoalStatus = (goal) => {
        if (dayjs(goal.endDate) >= today) {
          if (goal.origEndDate == null) {
            return 'active';
          }
        }

        return 'complete';
      };

      const updatedGoals = action.payload.map((goal) => ({
        ...goal,
        status: getGoalStatus(goal),
      }));

      return {
        ...state,
        data: updatedGoals,
        pending: false,
        success: true,
      };
    case type.FETCH_GOALS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    case type.CLEAR_GOALS:
      return {
        ...state,
        data: [],
        pending: false,
        error: '',
        success: false,
      };

    default:
      return state;
  }
};
