import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Box } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency } from '../../../utils/helpers';
import useStyles from './ModalStyles';
import { Modal } from '../../Modal';
import { Text } from '../../Text';

const RevenueGoalModal = ({ isOpen, onClose, onSave = () => {}, targetRevenue }) => {
  const classes = useStyles();

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: revenue_goal');
    }
  }, [isOpen]);

  return (
    <Modal
      confirmLabel='Continue to create new goal'
      heading='Update your revenue goal amount'
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onSave}
    >
      <Box>
        <Box className={classes.impactRow}>
          <Box>Current revenue goal</Box>
          <Box className={classes.impactCurrency}>{formatCurrency(targetRevenue, false)}</Box>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Text>
            As this will impact your underlying offers, you will need to create a new revenue goal.
          </Text>
          <Text style={{ marginTop: '8px' }}>
            This process only takes a few minutes to complete. Once you have finished creating a new
            revenue goal, your current one will be removed.
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};

RevenueGoalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  targetRevenue: PropTypes.number.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RevenueGoalModal);
