import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  navigationLinks: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '240px',
    overflowY: 'auto',
  },
  navigationLink: {
    padding: '6px 12px',
    borderRadius: '12px',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    transition: 'all 0.1s',
    lineHeight: '30px',

    '&:hover': {
      backgroundColor: '#EEEAE3',
    },
  },
}));

export default useStyles;
