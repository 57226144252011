import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important",
      lineHeight: 'normal',
      letterSpacing: 'normal',
      [theme.breakpoints.up('md')]: {
        // display: 'flex',
        fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important",
      },
    },
    main: {
      paddingLeft: '35px',
      paddingRight: '35px',
    },
  }),
  { name: 'App' },
);

export default useStyles;
