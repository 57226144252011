import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip, TextField } from '@eatclub-apps/ec-component-library';
import { FONT_WEIGHTS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, roundToNearest, safeDivision } from '../../../utils/helpers';
import Bag from '../../../assets/bag.svg';
import Chair from '../../../assets/chair.svg';
import Pencil from '../../../assets/pencil.svg';
import useStyles from './CreateGoalStyles';
import { Heading } from '../../Heading';
import { Slider } from '../../Slider';
import { Text } from '../../Text';

const RevenueTarget = ({
  onNext,
  maxRevenue,
  maxRevenueLower,
  maxRevenueUpper,
  setRevenueTarget,
  revenueTarget,
  setDeliveryType,
  deliveryType,
  onBack,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [showRevenueInput, setShowRevenueInput] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (revenueTarget > maxRevenue) {
      setRevenueTarget(maxRevenue);
    }
  }, [maxRevenue]);

  const setType = (value) => {
    switch (value) {
      case 'dineIn':
        setDeliveryType(['dineIn']);
        return;
      case 'takeaway':
        setDeliveryType(['takeaway']);
        return;
      case 'dineInAndTakeaway':
      default:
        setDeliveryType(['dineIn', 'takeaway']);
    }
  };

  const getValueFromType = () => {
    // If one delivery type chosen, use it
    if (deliveryType.length === 1) {
      if (deliveryType.includes('dineIn')) {
        return 'dineIn';
      }

      if (deliveryType.includes('takeaway')) {
        return 'takeaway';
      }
    }

    // default to both
    return 'dineInAndTakeaway';
  };

  const handleChange = (event, newValue) => {
    setRevenueTarget(newValue);
  };

  const chairInCircle = () => (
    <Box
      style={{
        position: 'relative',
        borderRadius: '50px',
        backgroundColor: '#EFE8DE',
        width: '21px',
        height: '21px',
        alignItems: 'center',
      }}
    >
      <Chair
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '21px',
          // height: '21px',
          // position: 'absolute',
        }}
      />
    </Box>
  );

  const bagInCircle = () => (
    <Box
      style={{
        position: 'relative',
        borderRadius: '50px',
        backgroundColor: '#EFE8DE',
        width: '21px',
        height: '21px',
        alignItems: 'center',
      }}
    >
      <Bag
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '21px',
          // height: '21px',
          // position: 'absolute',
        }}
      />
    </Box>
  );

  const getTypeLabel = (type) => {
    switch (type) {
      case 'dineIn':
        return (
          <Box
            style={{
              display: 'flex',
              gap: '14px',
            }}
          >
            <Box>Dine-in only</Box>
            <Box style={{ display: 'flex', gap: '6px', paddingTop: '2px' }}>{chairInCircle()}</Box>
          </Box>
        );
      case 'takeaway':
        return (
          <Box
            style={{
              display: 'flex',
              gap: '14px',
            }}
          >
            <Box>Takeaway only</Box>
            <Box style={{ display: 'flex', gap: '6px', paddingTop: '2px' }}>{bagInCircle()}</Box>
          </Box>
        );
      case 'dineInAndTakeaway':
      default:
        return (
          <Box
            style={{
              display: 'flex',
              gap: '14px',
            }}
          >
            <Box>Takeaway and dine-in</Box>
            <Box style={{ display: 'flex', gap: '6px', paddingTop: '2px' }}>
              {chairInCircle()}
              {bagInCircle()}
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box className={classes.revenueTargetContainer}>
      <Box>
        <Heading>Set your monthly revenue goal</Heading>
        <Text size='lg' className={classes.revenueTargetDescription}>
          Revenue in your pocket after the offer amount given to the customer
        </Text>
        <Box mt='32px'>
          <Button onClick={handleOpenDropdown} color='secondary' variant='outlined' size='large'>
            {getTypeLabel(getValueFromType())}
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseDropdown}
          >
            {[
              {
                label: getTypeLabel('dineInAndTakeaway'),
                value: 'dineInAndTakeaway',
              },
              { label: getTypeLabel('takeaway'), value: 'takeaway' },
              { label: getTypeLabel('dineIn'), value: 'dineIn' },
            ].map((option) => (
              <MenuItem
                key={option}
                onClick={() => setType(option.value)}
                selected={option.value === option.type}
                value={option.type}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box style={{ marginTop: '40px' }}>
          <Box className={classes.sliderTitleContainer}>
            <Box className={classes.sliderTitle}>
              <span style={{ fontWeight: '500' }}>{formatCurrency(revenueTarget, false)}</span> per
              month
            </Box>
            <Pencil
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                trackEvent('button_click: toggle_revenue_input');
                setShowRevenueInput(!showRevenueInput);
              }}
            />
          </Box>
          {showRevenueInput && (
            <Box mb='20px' maxWidth='300px'>
              <TextField
                label='Revenue target'
                value={revenueTarget}
                onChange={setRevenueTarget}
                // type='number'
                maxNumber={maxRevenue}
                inputPrefix='$'
              />
            </Box>
          )}
          <Box style={{ marginTop: '40px' }}>
            <Slider max={maxRevenue} onChange={handleChange} value={revenueTarget} />
          </Box>
        </Box>
        <Box className={classes.potentialRevenueContainer}>
          <Box className={classes.potentialRevenueRow}>
            <Box>
              {breakpointSmDown ? 'Max potential revenue' : 'Maximum potential extra revenue'}
            </Box>
            <Box className={classes.tooltipContainer}>
              <Tooltip
                maxWidth='400px'
                message='Our AI has taken your historical data and compared it to the market to estimate the highest amount you could be earning from the EatClub Marketplace.'
              />
            </Box>
          </Box>
          <Box className={classes.potentialRevenueRow}>
            <Box style={{ fontWeight: '500' }}>{formatCurrency(maxRevenue, false)}</Box>

            <Button
              color='secondary'
              variant='outlined'
              onClick={() => {
                trackEvent('button_click: set_max_revenue');
                setRevenueTarget(maxRevenue);
              }}
            >
              SELECT
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className={classes.goalsFooter} flexDirection='column'>
        <Box className={classes.footerEstimateContainer}>
          <Box style={{ width: '100%' }}>
            <Box className={classes.footerEstimate}>
              <Box className={classes.goalsFooterProjection}>
                <Box display='flex' style={{ gap: '10px' }}>
                  <Box>Expected monthly range: </Box>
                  <Box style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}>
                    {formatCurrency(
                      roundToNearest(safeDivision(revenueTarget, maxRevenue) * maxRevenueLower, 10),
                      false,
                    )}
                    -
                    {formatCurrency(
                      roundToNearest(safeDivision(revenueTarget, maxRevenue) * maxRevenueUpper, 10),
                      false,
                    )}
                  </Box>
                </Box>

                <Box display='flex' style={{ gap: '10px' }}>
                  <Box>Expected yearly range: </Box>
                  <Box style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}>
                    {formatCurrency(
                      roundToNearest(
                        safeDivision(revenueTarget, maxRevenue) * maxRevenueLower * 12,
                        10,
                      ),
                      false,
                    )}
                    -
                    {formatCurrency(
                      roundToNearest(
                        safeDivision(revenueTarget, maxRevenue) * maxRevenueUpper * 12,
                        10,
                      ),
                      false,
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '100%' }}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={classes.goalsFooterPadding}>
              <Button onClick={onBack} variant='outlined' color='secondary' size='large'>
                Back
              </Button>

              <Button
                disabled={revenueTarget <= 0}
                style={{ marginLeft: 'auto' }}
                onClick={onNext}
                variant='contained'
                size='large'
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

RevenueTarget.propTypes = {
  onNext: PropTypes.func.isRequired,
  maxRevenue: PropTypes.number.isRequired,
  maxRevenueLower: PropTypes.number.isRequired,
  maxRevenueUpper: PropTypes.number.isRequired,
  setRevenueTarget: PropTypes.func.isRequired,
  revenueTarget: PropTypes.number.isRequired,
  deliveryType: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDeliveryType: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RevenueTarget);
