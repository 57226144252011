import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { clearPostDailyOppoAction } from '../../../actions/dealDailyOpposPostAction';
import { opportunitiesPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { trackEvent } from '../../../utils/analytics';
import useStyles from './ModalStyles';
import PostedOpportunity from '../Opportunity/PostedOpportunity';
import { Modal } from '../../Modal';
import { isEmpty } from '../../../utils/helpers';

const PostOppoModal = ({ dealDailyOppoPost, clearPostDailyOppo, dailyOppos }) => {
  const classes = useStyles();
  const history = useHistory();

  const oppo = dailyOppos?.data?.find((oppo) => oppo?.objectId === dealDailyOppoPost?.data?.oppoId);

  if (isEmpty(oppo)) {
    return <></>;
  }

  const confirm = () => {
    clearPostDailyOppo();
    trackEvent(`button_click: oppo_success_view_active_offers`);
    history.push('/offers/active');
  };

  return (
    <Modal
      cancelLabel='Return to offers'
      confirmLabel='View all active offers'
      heading='Success, more customers on the way'
      isOpen={dealDailyOppoPost?.success && oppo}
      onCancel={() => history.push('/offers')}
      onClose={clearPostDailyOppo}
      onConfirm={confirm}
      style={{
        heading: {
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '400px',
          textAlign: 'center',
        },
      }}
    >
      <Box className={classes.oppoModal}>
        <Box style={{ maxWidth: '400px' }}>
          The opportunity has been successfully launched and can be located in your active offers.
        </Box>
        <Box style={{ width: '100%' }}>
          <PostedOpportunity opportunity={oppo} />
        </Box>
      </Box>
    </Modal>
  );
};

PostOppoModal.propTypes = {
  clearPostDailyOppo: PropTypes.func.isRequired,
  dealDailyOppoPost: opportunitiesPropTypes.isRequired,
  dailyOppos: opportunitiesPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  dealDailyOppoPost: state.dealDailyOppoPost,
  dailyOppos: state.dealDailyOppos,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearPostDailyOppo: clearPostDailyOppoAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostOppoModal);
