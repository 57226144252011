import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    '@global': {
      '.MuiPaper-root': {
        styleOverrides: { root: { backgroundImage: 'unset' } },
      },
      '.MuiButton-root': {
        borderRadius: '20px',
      },
    },
  }),
  { name: 'AppContainer' },
);

export default useStyles;
