/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from 'react';
import { Button, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RemoveShoppingCart, ShoppingCart } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { toggleChoiceStockAction } from '../../../actions/choiceStockAction';
import useStyles from './MenusStyles';

const MenuChoiceStock = ({ toggleChoiceStock, choice, optionId, restId, userId, userType }) => {
  const classes = useStyles();

  const theme = useTheme();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('sm'));

  const isDisabledToday = (disabledDate) =>
    dayjs(disabledDate, 'YYYY-MM-DD').isSame(dayjs(), 'day');

  const isOutOfStock = isDisabledToday(choice.disableDate);

  const toggleIsDisabled = () => {
    if (isOutOfStock) {
      toggleChoiceStock(restId, choice.objectId, true, userId, userType);
      return;
    }

    toggleChoiceStock(restId, choice.objectId, false, userId, userType);
  };

  // MOBILE BUTTON
  if (breakpointMdDown) {
    return (
      <IconButton
        size='small'
        color={isOutOfStock ? 'secondary' : 'default'}
        className={isOutOfStock ? 'listButtonDisabled' : ''}
        onClick={toggleIsDisabled}
        title='Toggle stock level'
      >
        {isOutOfStock ? <RemoveShoppingCart fontSize='small' /> : <ShoppingCart fontSize='small' />}
      </IconButton>
    );
  }

  // DESKTOP BUTTON
  return (
    <Button
      variant='text'
      color='secondary'
      size='small'
      startIcon={isOutOfStock ? <RemoveShoppingCart /> : <ShoppingCart />}
      className={`${classes.listButton} ${isOutOfStock ? 'listButtonDisabled' : ''}`}
      onClick={toggleIsDisabled}
    >
      {isOutOfStock ? 'Out of Stock' : 'In Stock'}
    </Button>
  );
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleChoiceStock: toggleChoiceStockAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuChoiceStock);
