import React from 'react';
import { Slider as MuiSlider, SliderThumb } from '@mui/material';
import useStyles from './SliderStyles';

const CustomThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <div style={{ display: 'flex', gap: '16px' }}>
        <svg
          width='11'
          height='20'
          viewBox='0 0 11 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M10 19L1 10L10 1' stroke='#313131' />
        </svg>
        <svg
          width='11'
          height='20'
          viewBox='0 0 11 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M1 1L10 10L1 19' stroke='#313131' />
        </svg>
      </div>
    </SliderThumb>
  );
};

export const Slider = (props) => {
  const classes = useStyles();

  return (
    <MuiSlider
      className={classes.slider}
      style={{
        alignItems: 'center',
        color: '#313131',
        display: 'flex',
        marginTop: '40px',
      }}
      slots={{ thumb: CustomThumbComponent }}
      {...props}
    />
  );
};
