import PropTypes from 'prop-types';

export const restaurantPropTypes = PropTypes.shape({
  objectId: PropTypes.string,
  enabled: PropTypes.bool,
  name: PropTypes.string,
  region: PropTypes.string,
  menuVersion: PropTypes.number,
  bdmEmail: PropTypes.string,
  bdmName: PropTypes.string,
  bdmPhone: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  revPerDiner: PropTypes.number,
  revPerDinerTakeaway: PropTypes.number,
  hours: PropTypes.string,
  hoursParsed: PropTypes.shape({}),
  platform: PropTypes.string,
  showOppos: PropTypes.bool,
  showPredictions: PropTypes.bool,
  offersEnabled: PropTypes.bool,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  abn: PropTypes.string,
  tradingName: PropTypes.string,
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
  obeeEnabled: PropTypes.bool,
  premium: PropTypes.bool,
  ecPayEnabled: PropTypes.bool,
  obeeGoLiveDate: PropTypes.string,
  obeeIsLive: PropTypes.bool,
  closures: PropTypes.arrayOf(
    PropTypes.shape({
      restId: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      startTime: PropTypes.number,
      endTime: PropTypes.number,
    }),
  ),
  inAppOrdering: PropTypes.bool,
  averageOrderValue: PropTypes.number,
  averageBookingValue: PropTypes.number,
  foodCostPercent: PropTypes.number,
  closeDate: PropTypes.string,
});

export const restaurantActivePropTypes = PropTypes.shape({
  restaurant: restaurantPropTypes,
  id: PropTypes.string,
  restaurantFetching: PropTypes.bool,
  fetching: PropTypes.bool,
});

export const affiliatedRestaurantsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(restaurantPropTypes),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const RestaurantInputKeys = () => [
  'name',
  'address1',
  'address2',
  'city',
  'state',
  'postcode',
  'phone',
  'website',
  'abn',
  'tradingName',
  'instagramLink',
  'facebookLink',
  'twitterLink',
];
