/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RemoveShoppingCart, ShoppingCart } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { toggleProductStockAction } from '../../../actions/productStockAction';
import useStyles from './MenusStyles';

// TODO use toggleProductStockAction
const MenuItemStock = ({ item, toggleProductStockAction, restId, userId, userType }) => {
  const classes = useStyles();

  const theme = useTheme();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('sm'));

  const isDisabledToday = () =>
    dayjs(item?.disableDate, 'yyyy-MM-DD').isSame(dayjs(), 'day') ||
    dayjs(item?.disableUntilDate, 'yyyy-MM-DD').isAfter(dayjs(), 'day');

  const inStock = !isDisabledToday();

  const toggleInStock = () => {
    if (inStock) {
      toggleProductStockAction(restId, item.objectId, false, userId, userType);
      return;
    }

    toggleProductStockAction(restId, item.objectId, true, userId, userType);
  };

  // MOBILE BUTTON
  if (breakpointMdDown) {
    return (
      <IconButton
        size='small'
        color={inStock ? 'default' : 'secondary'}
        className={inStock ? '' : 'listButtonDisabled'}
        onClick={toggleInStock}
        title='Toggle stock level'
      >
        {inStock ? <ShoppingCart fontSize='small' /> : <RemoveShoppingCart fontSize='small' />}
      </IconButton>
    );
  }

  // DESKTOP BUTTON
  return (
    <Button
      variant='text'
      color='secondary'
      size='small'
      startIcon={inStock ? <ShoppingCart /> : <RemoveShoppingCart />}
      className={`${classes.listButton} ${inStock ? '' : 'listButtonDisabled'}`}
      onClick={toggleInStock}
      title='Toggle stock level'
    >
      {inStock ? 'In Stock' : 'Out of Stock'}
    </Button>
  );
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleProductStockAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemStock);
