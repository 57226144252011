import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { postAllOppos } from '../graphql/mutations';
import { devLog } from '../utils';

export const postAllOpposAction = (restId, date, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'POST_ALL_DAILY_OPPOS',
  });
  dispatch({
    type: type.POST_ALL_DAILY_OPPOS_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: postAllOppos,
          variables: { restId, date },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'rest-id': restId,
          'user-type': userType,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'post all daily oppos', response.data.postAllOppos);

      dispatch({
        type: type.POST_ALL_DAILY_OPPOS_SUCCESS,
        payload: { restId },
      });
    } catch (error) {
      devLog('error', 'post all daily oppos', error);

      dispatch({
        type: type.POST_ALL_DAILY_OPPOS_FAILURE,
        payload: `${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'POST_ALL_DAILY_OPPOS',
      });
    }
  })();
};

export const clearPostAllDailyOpposAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_POST_ALL_DAILY_OPPOS,
  });
};
