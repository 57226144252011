import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Popover } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomersOutlinedIcon from '../../../assets/icons/customers_outlined.svg';
import RevenueOutlinedIcon from '../../../assets/icons/revenue_outlined.svg';
import ImpressionsOutlinedIcon from '../../../assets/icons/impressions_outlined.svg';
import AOVOutlinedIcon from '../../../assets/icons/aov_outlined.svg';
import { getTimePeriodMessage } from '../../../utils/insights/insightHelpers';
import { PillarModalFooter } from './PillarModalFooter';
import useStyles, { INSIGHT_TAB } from './PillarModalStyles';
import AovPillarModalContent from './AovPillarModalContent';
import CustomersPillarModalContent from './CustomersPillarModalContent';
import ImpressionsPillarModalContent from './ImpressionsPillarModalContent';
import NetRevenuePillarModalContent from './NetRevenuePillarModalContent';
import { PillarModalHeader } from './PillarModalHeader';

const getTitle = (tab) => {
  switch (tab) {
    case INSIGHT_TAB.CUSTOMERS: {
      return 'Customers';
    }
    case INSIGHT_TAB.AOV: {
      return 'Avg order value';
    }
    case INSIGHT_TAB.NET_REVENUE: {
      return 'Net revenue';
    }
    case INSIGHT_TAB.IMPRESSIONS:
    default: {
      return 'Impressions';
    }
  }
};

const getIcon = (tab) => {
  switch (tab) {
    case INSIGHT_TAB.CUSTOMERS: {
      return <CustomersOutlinedIcon />;
    }
    case INSIGHT_TAB.AOV: {
      return <AOVOutlinedIcon />;
    }
    case INSIGHT_TAB.NET_REVENUE: {
      return <RevenueOutlinedIcon />;
    }
    case INSIGHT_TAB.IMPRESSIONS:
    default: {
      return <ImpressionsOutlinedIcon />;
    }
  }
};

const getPrevTab = (tab) => {
  switch (tab) {
    case INSIGHT_TAB.CUSTOMERS: {
      return 'impressions';
    }
    case INSIGHT_TAB.AOV: {
      return 'customers';
    }
    case INSIGHT_TAB.NET_REVENUE: {
      return 'aov';
    }
    case INSIGHT_TAB.IMPRESSIONS:
    default: {
      return 'netRevenue';
    }
  }
};

const getNextTab = (tab) => {
  switch (tab) {
    case INSIGHT_TAB.CUSTOMERS: {
      return 'aov';
    }
    case INSIGHT_TAB.AOV: {
      return 'netRevenue';
    }
    case INSIGHT_TAB.NET_REVENUE: {
      return 'impressions';
    }
    case INSIGHT_TAB.IMPRESSIONS:
    default: {
      return 'customers';
    }
  }
};

const PillarModal = ({
  initialTab = INSIGHT_TAB.IMPRESSIONS,
  isOpen = false,
  onClose = () => {},
  data = { low: 0, mean: 0, high: 0 },
  dates,
}) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const classes = useStyles({ tab: activeTab });

  // Market comparisons
  const [filter, setFilter] = useState('all');
  const [dateRange, setDateRange] = useState('dateRange');

  useEffect(() => {
    setActiveTab(initialTab);
    setFilter('all');
    // setDateRange('dateRange');
  }, [initialTab, isOpen]);

  if (!isOpen) {
    return null;
  }

  const getContent = (tab) => {
    switch (tab) {
      case INSIGHT_TAB.CUSTOMERS: {
        return (
          <CustomersPillarModalContent
            data={data}
            setFilter={setFilter}
            filter={filter}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        );
      }
      case INSIGHT_TAB.AOV: {
        return (
          <AovPillarModalContent
            data={data}
            setFilter={setFilter}
            filter={filter}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        );
      }
      case INSIGHT_TAB.NET_REVENUE: {
        return (
          <NetRevenuePillarModalContent
            data={data}
            setFilter={setFilter}
            filter={filter}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        );
      }
      case INSIGHT_TAB.IMPRESSIONS:
      default: {
        return (
          <ImpressionsPillarModalContent
            data={data}
            setFilter={setFilter}
            filter={filter}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        );
      }
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      style={{
        backgroundColor: '#00000070',
      }}
      innerStyle={{
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 18.9px 0px #03030340',
        marginTop: '0',
        marginRight: '0',
        maxWidth: '580px',
        borderRadius: '32px',
        width: '100%',
        height: '100%',
        padding: 0,
        overflowY: 'visible',
        left: '16px',

        '@media (maxWidth:599.95px)': {
          maxWidth: '100%',
          right: 0,
          marginTop: '0',
          marginRight: '0',
          marginLeft: '0',
          marginBottom: '0',
        },
      }}
    >
      <Box className={classes.container}>
        <Box>
          <PillarModalHeader
            dateRange={getTimePeriodMessage(dates.startDate, dates.endDate)}
            icon={getIcon(activeTab)}
            heading={getTitle(activeTab)}
            onPrev={() => {
              const prevTab = getPrevTab(activeTab);
              setActiveTab(prevTab);
            }}
            onNext={() => {
              const nextTab = getNextTab(activeTab);
              setActiveTab(nextTab);
            }}
            onClose={onClose}
          />
          {getContent(activeTab)}
        </Box>
        <PillarModalFooter tab={activeTab} />
      </Box>
    </Popover>
  );
};

PillarModal.propTypes = {
  initialTab: PropTypes.oneOf([
    INSIGHT_TAB.NET_REVENUE,
    INSIGHT_TAB.AOV,
    INSIGHT_TAB.CUSTOMERS,
    INSIGHT_TAB.IMPRESSIONS,
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({ low: PropTypes.number, mean: PropTypes.number, high: PropTypes.number }),
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PillarModal);
