import { makeStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: ({ variant }) => ({
    ...theme.eatclub.typography.body[variant],
  }),
}));

/**
 * A Text component
 * @example <Text>Hello!</Text>
 */
export const Text = ({ children = null, className = '', style = {}, size = 'base' }) => {
  const sizeMapping = {
    xl: 'xl',
    lg: 'lg',
    base: 'base',
    sm: 'sm',
    xs: 'xs',
  };

  const variant = sizeMapping[size] ?? 'base';

  const classes = useStyles({ variant });

  return (
    <span className={`${classes.container} ${className}`} style={style}>
      {children}
    </span>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', 'xl']),
};
