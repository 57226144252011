import { Divider, Skeleton } from '@mui/material';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Spacer, Popover } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { setLogoutAction } from '../../../../actions/loginAction';
import { COLORS } from '../../../../EatClubTheme';
import { restaurantNameById } from '../../../../graphql/queries';
import { devLog } from '../../../../utils';
import { saveToSessionStorage } from '../../../../utils/apiHelpers';
import useStyles from './RestaurantSwitcherStyles';
import ECPlateSVG from '../../../../assets/eatclub_plate.svg';
import DownArrowSVG from '../../../../assets/down_caret.svg';
import RestaurantFinder from './RestaurantFinder/RestaurantFinder';
import { fetchAffiliatedRestaurantsAction } from '../../../../actions/restaurantAction';
import { getAppVersion, isEmpty } from '../../../../utils/helpers';
import {
  affiliatedRestaurantsPropTypes,
  restaurantActivePropTypes,
} from '../../../../data/models/Restaurant';
import { userPropTypes } from '../../../../data/models/User';
import { Heading } from '../../../Heading';
import { Text } from '../../../Text';

const RestaurantSwitcher = ({
  restaurantActive,
  setLogout,
  user,
  fetchAffiliatedRestaurants,
  affiliatedRestaurants,
  restaurantFetching,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const restaurant = restaurantActive?.restaurant;
  const isStaff = user.userInfo?.userType === 'staff';

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const [finderAnchorEl, setFinderAnchorEl] = useState(null);
  const openFinder = Boolean(finderAnchorEl);

  const [venuesAnchorEl, setVenuesAnchorEl] = useState(null);
  const openVenues = Boolean(venuesAnchorEl);
  const [mainRestaurant, setMainRestaurant] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (user?.userInfo?.restaurants?.[0]?.objectId) {
      fetchAffiliatedRestaurants(user.userInfo.restaurants[0].objectId);
    }
  }, [fetchAffiliatedRestaurants, user.userInfo.restaurants?.[0]?.objectId]);

  // Fetch the "userById" venue since it doesn't come back in affiliated venues
  useEffect(() => {
    (async () => {
      if (user?.userInfo?.restaurants?.[0]?.objectId) {
        try {
          const response = await API.graphql(
            {
              query: restaurantNameById,
              variables: {
                restId: user?.userInfo?.restaurants?.[0]?.objectId,
              },
              authMode: 'AMAZON_COGNITO_USER_POOLS',
            },
            {
              // 'user-id': userId,
              // 'user-type': userType,
              // 'rest-id': restId,
              'app-version': getAppVersion(),
            },
          ).catch((error) => {
            throw new Error(error);
          });

          devLog('success', 'mainRestaurant', response.data.restaurantById);

          setMainRestaurant(response.data.restaurantById);
        } catch (error) {
          devLog('error', 'mainRestaurant', error);
        }
      }
    })();
  }, [user?.userInfo?.restaurants?.[0]?.objectId]);

  const linkedRestaurants = [mainRestaurant, ...affiliatedRestaurants.data].filter(
    (affiliatedRestaurant) =>
      affiliatedRestaurant?.objectId && affiliatedRestaurant?.objectId !== restaurant?.objectId,
  );

  const changeVenue = (venue) => {
    handleCloseMenu();
    saveToSessionStorage(`selectedRestaurant:${user?.userInfo?.objectId}`, venue.objectId);
    localStorage.setItem(`lastUsedRestaurant:${user?.userInfo?.objectId}`, venue.objectId);

    // Reload so all data gets fetched again
    window.location.reload();
  };

  // TODO fix a bug where staff without restaurant selected stays with restaurantFetching: true
  const isLoading = restaurantActive?.fetching || (restaurantFetching && !isStaff);

  return (
    <Box style={{ position: 'relative' }}>
      <Box className={classes.restaurantSwitcherContainer} onClick={(e) => handleOpenMenu(e)}>
        <Box className={classes.logoContainer}>
          <ECPlateSVG height={34} width={34} />
        </Box>

        <Box style={{ overflow: 'hidden' }}>
          <Heading size='5' style={{ marginBottom: '2px' }}>
            EatClub portal
          </Heading>
          <Box>
            <Text size='sm' className={`${classes.textOverflow}`}>
              {restaurant.name ||
                (isLoading && <Skeleton width={100} />) ||
                (isStaff && <Box style={{ color: COLORS.ERROR_RED }}>No restaurant</Box>)}
            </Text>
          </Box>
        </Box>

        <DownArrowSVG
          className={classes.restaurantDropdownArrow}
          style={{ transform: openMenu ? 'rotate(180deg)' : '' }}
        />
      </Box>

      <Popover
        isOpen={openMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        style={{
          backgroundColor: '#00000070',
        }}
        innerStyle={{
          padding: 0,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          pointerEvents: 'none',
          marginTop: '12px',
          borderRadius: 'none',
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <>
          <Box className={classes.accountMenuContent}>
            {!isEmpty(restaurant) && (
              <>
                <Spacer gap='m'>
                  <Box className={classes.logoContainer}>
                    <ECPlateSVG height={34} width={34} />
                  </Box>

                  <Spacer direction='vertical' style={{ gap: '4px' }}>
                    <>
                      <Heading size='5'>{restaurant.name}</Heading>
                      <Text size='sm'>{restaurant.address1}</Text>
                    </>
                  </Spacer>
                </Spacer>

                <Divider className={classes.divider} style={{ marginTop: '12px' }} />
              </>
            )}

            <Box className={classes.navigationLinks} style={{ marginTop: '8px' }}>
              {/* Is a partner and has multiple affiliated venues */}
              {!isStaff && linkedRestaurants.length > 0 && (
                <Box
                  onClick={(e) => setVenuesAnchorEl(e.currentTarget || e.target)}
                  className={openVenues ? classes.activeNavigationLink : classes.navigationLink}
                >
                  Change venue
                </Box>
              )}

              {/* Is staff */}
              {isStaff && (
                <>
                  <Box
                    onClick={(e) => setFinderAnchorEl(e.currentTarget)}
                    className={classes.navigationLink}
                    style={{ backgroundColor: openFinder && '#EEEAE3' }}
                  >
                    Change venue
                  </Box>

                  <Box
                    onClick={() => {
                      window.open(
                        `${import.meta.env.VITE_HUB_RESTAURANT_URL}/${
                          restaurantActive.restaurant.objectId
                        }`,
                        '_blank',
                      );
                    }}
                    className={classes.navigationLink}
                  >
                    Open in Hub
                  </Box>
                </>
              )}

              <Box
                className={classes.navigationLink}
                onClick={() => {
                  history.push('/profile');
                  handleCloseMenu();
                }}
              >
                Profile
              </Box>

              <Box className={classes.navigationLink} onClick={setLogout}>
                Log out
              </Box>
            </Box>
          </Box>

          {!isStaff && (
            <Popover
              isOpen={!!openVenues}
              onClose={() => setVenuesAnchorEl(null)}
              anchorEl={venuesAnchorEl}
              innerProps={{
                disableAutoFocus: true,
                disableEnforceFocus: true,
                disableRestoreFocus: true,
                keepMounted: true,
              }}
              innerStyle={{
                borderRadius: '10px',
                fontSize: '18px',
                marginTop: '10px',
                padding: '8px',
                width: '380px',
              }}
            >
              <Box className={classes.navigationLinks}>
                <>
                  {affiliatedRestaurants.success &&
                    linkedRestaurants.map((affiliatedRestaurant) => (
                      <Box
                        key={affiliatedRestaurant.objectId}
                        className={classes.navigationLink}
                        onClick={() => {
                          changeVenue(affiliatedRestaurant);
                          setVenuesAnchorEl(null);
                        }}
                      >
                        {affiliatedRestaurant.name}
                      </Box>
                    ))}
                </>
              </Box>
            </Popover>
          )}

          {isStaff && (
            <RestaurantFinder
              isOpen={openFinder}
              onClose={() => setFinderAnchorEl(null)}
              onSelect={(venue) => {
                changeVenue(venue);
                setFinderAnchorEl(null);
              }}
              anchorEl={finderAnchorEl}
            />
          )}
        </>
      </Popover>
    </Box>
  );
};

RestaurantSwitcher.propTypes = {
  restaurantActive: restaurantActivePropTypes.isRequired,
  user: userPropTypes.isRequired,
  setLogout: PropTypes.func.isRequired,
  fetchAffiliatedRestaurants: PropTypes.func.isRequired,
  affiliatedRestaurants: affiliatedRestaurantsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurantActive: state.restaurantActive,
  user: state.user,
  affiliatedRestaurants: state.affiliatedRestaurants,
  restaurantFetching: state.restaurants.fetching,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLogout: setLogoutAction,
      fetchAffiliatedRestaurants: fetchAffiliatedRestaurantsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantSwitcher);
