import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, ListPopover, Button, Spacer } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@mui/material/styles';
import { getLabelForValue } from '../../../../utils/helpers';
import useStyles from './TextDropdownStyles';
import DownCaretSVG from '../../../../assets/down_caret.svg';

/**
 * A dropdown that looks like text with a little arrow next to it
 *
 * TODO move to component library
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TextDropdown = ({ value = null, items = [], onSelect = (e) => e }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [anchor, setAnchor] = useState(null);

  const label = getLabelForValue(value, items);

  return (
    <Box>
      <Button
        title={label}
        type='custom'
        className={classes.textDropdownContainer}
        onClick={(e) => setAnchor(e.currentTarget)}
        forceHoverState={!!anchor}
        style={{
          button: {
            color: theme.colors.charcoal,
            fontWeight: theme.fontWeights.medium,
            alignItems: 'center',
            padding: '4px 8px',
            borderRadius: '6px',
            width: 'fit-content',
            transition: 'background 0.05s',
            userSelect: 'none',
          },
        }}
      >
        <Spacer gap={8}>
          <Box style={{ maxWidth: '220px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {label}
          </Box>
          <DownCaretSVG
            style={{
              width: '10px',
              height: '10px',
              marginTop: '2px',
              transform: anchor && 'rotate(180deg)',
              transition: 'transform 0.1s',
            }}
          />
        </Spacer>
      </Button>

      <ListPopover
        isOpen={!!anchor}
        onClose={() => setAnchor(null)}
        anchor={anchor}
        items={items}
        onSelect={onSelect}
        style={{ item: { fontSize: '16px' } }}
        value={value}
      />
    </Box>
  );
};

TextDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
};

export default TextDropdown;
