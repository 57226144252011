import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card } from '@eatclub-apps/ec-component-library';
import { fetchGeneratedOffersAction } from '../../../../actions/generatedOffersAction';
import { offerPropTypes } from '../../../../reducers/generatedOffersReducer';
import { potentialRevenuesPropTypes } from '../../../../reducers/potentialRevenuesReducer';
import { trackEvent } from '../../../../utils/analytics';
import {
  getDateRange,
  getDayOccurrences,
  isEmpty,
  minutesToTimeString,
  pluralise,
} from '../../../../utils/helpers';
import EditParametersModal from './EditParametersModal';
import LaunchGoalBox from './LaunchGoalBox';
import RemoveOfferModal from '../../Modals/RemoveOfferModal';
import PredictedOffersByDay from '../../OffersByDay/PredictedOffersByDay';
import StartingOffersFooter from './StartingOffersFooter';
import EditStartingOfferModal from '../../Modals/EditStartingOfferModal';
import useStyles from './StartingOfferStyles';
import { Heading } from '../../../Heading';
import { Text } from '../../../Text';

const StartingOffers = ({
  onNext,
  startingOffers = [],
  setStartingOffers,
  fetchGeneratedOffers,
  offerStartDay,
  setOfferStartDay,
  offerTimePeriod,
  setOfferTimePeriod,
  updateParameters,
  revenueTarget, // TODO these goal-specific properties should be in redux
  strategy,
  types,
  potentialRevenues,
  restId,
  loading,
  submitting,
  dineInMax,
  setStrategy,
}) => {
  const classes = useStyles();

  const [totals, setTotals] = useState({
    customersMonthlyLower: 0,
    customersMonthlyUpper: 0,
    revenue: 0,
    revenueMonthlyLower: 0,
    revenueMonthlyUpper: 0,
  });
  const [showEditParametersModal, setShowEditParametersModal] = useState(false);
  const [offerToRemove, setOfferToRemove] = useState(null);
  const [offerToEdit, setOfferToEdit] = useState(null);
  const [repeatDays, setRepeatDays] = useState({}); // How many times each day repeats

  // Fetch deals from the AI
  useEffect(() => {
    // Get target in weekly, since that's what the API uses
    const weeklyRevenueTarget = Math.round(revenueTarget);

    if (types.length === 1) {
      if (types.includes('takeaway')) {
        fetchGeneratedOffers(restId, strategy, weeklyRevenueTarget, 'takeawayOnly');
      }

      if (types.includes('dineIn')) {
        fetchGeneratedOffers(restId, strategy, weeklyRevenueTarget, 'dineInOnly');
      }
    } else {
      fetchGeneratedOffers(restId, strategy, weeklyRevenueTarget, 'all');
    }
  }, [revenueTarget, strategy, types, dineInMax]);

  // Calculate expected customers and revenue from offers
  useEffect(() => {
    setTotals({
      customersMonthlyLower: startingOffers.reduce(
        (total, offer) => total + parseInt(offer?.partySizeMonthlyLower, 10),
        0,
      ),
      customersMonthlyUpper: startingOffers.reduce(
        (total, offer) => total + parseInt(offer?.partySizeMonthlyUpper, 10),
        0,
      ),
      revenueMonthly: startingOffers.reduce(
        (total, offer) => total + parseFloat(offer?.targetValMonthly, 10),
        0,
      ),
      revenueMonthlyLower: startingOffers.reduce(
        (total, offer) => total + parseFloat(offer?.targetValMonthlyLower, 10),
        0,
      ),
      revenueMonthlyUpper: startingOffers.reduce(
        (total, offer) => total + parseFloat(offer?.targetValMonthlyUpper, 10),
        0,
      ),
    });
  }, [startingOffers]);

  // Recalculate the amount of times a deal appears
  useEffect(() => {
    const endDate = dayjs(offerStartDay).add(1, 'month');
    const repeatDays = getDayOccurrences(offerStartDay, endDate);
    setRepeatDays(repeatDays);
  }, [offerStartDay]);

  const removeOffer = (offer) => {
    setStartingOffers(
      startingOffers.filter((offerToCheck) => offerToCheck?.tempId !== offer?.tempId),
    );

    setOfferToRemove(null);
  };

  const editOffer = (
    offerTempId,
    prediction,
    updatedPeriod,
    updatedStartTime,
    updatedEndTime,
    updatedDiscount,
    updatedQuantity,
    updatedType,
  ) => {
    setStartingOffers(
      startingOffers.map((offerToCheck) => {
        if (offerToCheck?.tempId === offerTempId) {
          return {
            ...offerToCheck,
            bookingsExpected: prediction.bookingsExpected,
            bookingsLower: prediction.bookingsLower,
            bookingsPrediction: prediction.bookingsPrediction,
            bookingsUpper: prediction.bookingsUpper,
            commissionExpected: prediction.commissionExpected,
            commissionLower: prediction.commissionLower,
            commissionUpper: prediction.commissionUpper,
            contributionADExpected: prediction.contributionADExpected,
            contributionADLower: prediction.contributionADLower,
            contributionADUpper: prediction.contributionADUpper,
            contributionExpected: prediction.contributionExpected,
            contributionLower: prediction.contributionLower,
            contributionUpper: prediction.contributionUpper,
            dineInOnly: updatedType.length === 1 && updatedType.includes('dineIn'),
            discount: updatedDiscount,
            discountExpected: prediction.discountExpected,
            discountLower: prediction.discountLower,
            discountUpper: prediction.discountUpper,
            endTime: updatedEndTime,
            endTimeReadable: minutesToTimeString(updatedEndTime),
            foodCostExpected: prediction.foodCostExpected,
            foodCostLower: prediction.foodCostLower,
            foodCostUpper: prediction.foodCostUpper,
            lightning: updatedPeriod === 'timeLimited',
            partySizeExpected: prediction.partySizeExpected,
            partySizeLower: prediction.partySizeLower,
            partySizeMonthlyExpected: prediction.partySizeMonthlyExpected,
            partySizeMonthlyLower: prediction.partySizeMonthlyLower,
            partySizeMonthlyUpper: prediction.partySizeMonthlyUpper,
            partySizePrediction: prediction.partySizePrediction,
            partySizeUpper: prediction.partySizeUpper,
            qty: updatedQuantity,
            revenueExpected: prediction.revenueExpected,
            revenueLower: prediction.revenueLower,
            revenueUpper: prediction.revenueUpper,
            startTime: updatedStartTime,
            startTimeReadable: minutesToTimeString(updatedStartTime),
            takeawayOnly: updatedType.length === 1 && updatedType.includes('takeaway'),
            targetVal: prediction.targetVal,
            targetValLower: prediction.targetValLower,
            targetValMonthly: prediction.targetValMonthly,
            targetValMonthlyLower: prediction.targetValMonthlyLower,
            targetValMonthlyUpper: prediction.targetValMonthlyUpper,
            targetValUpper: prediction.targetValUpper,
          };
        }

        return offerToCheck;
      }),
    );

    setOfferToEdit(null);
  };

  return (
    <Box className={classes.container}>
      <Heading>Review and launch your goal</Heading>
      <Text size='lg' className={classes.description}>
        Our AI has generated the best schedule of offers to help you reach your goal.
      </Text>
      <Box mt='42px'>
        <Box className={classes.smallHeading}>Select your strategy</Box>
        <Box className={classes.strategyContainer}>
          <Card
            selectable
            onSelect={() => setStrategy('offPeak')}
            selected={strategy === 'offPeak'}
            style={{ padding: '10px 18px', width: '380px' }}
            color='#425C58'
          >
            <Heading size='4' className={classes.strategyTitle}>
              Optimise for off-peak
            </Heading>
            <Text className={classes.strategyDescription}>
              Higher offers on fewer days and times.
            </Text>
          </Card>

          <Card
            selectable
            onSelect={() => setStrategy('lowerOffers')}
            selected={strategy === 'lowerOffers'}
            style={{ marginRight: 'auto', padding: '10px 18px', width: '380px' }}
            color='#425C58'
          >
            <Heading size='4' className={classes.strategyTitle}>
              Optimise for lower offers
            </Heading>
            <Text className={classes.strategyDescription}>
              Lower offers on more days and times.
            </Text>
          </Card>

          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              trackEvent('button_click: toggle_show_parameters_modal');
              setShowEditParametersModal(!showEditParametersModal);
            }}
            size='large'
          >
            More parameters
          </Button>
        </Box>
      </Box>

      <Divider style={{ marginTop: '20px' }} />

      {loading ? (
        <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '60px' }} />
      ) : (
        <Box>
          <Box style={{ paddingTop: '60px' }}>
            <Box className={classes.startingOffersContainer}>
              <Heading size='2'>
                {startingOffers.length} starting {pluralise('offer', startingOffers.length)}
              </Heading>
              <Heading size='l'>
                {getDateRange(dayjs(offerStartDay), dayjs(offerStartDay).add(1, 'month'))}
              </Heading>
            </Box>
            <Box mb='75px'>
              <PredictedOffersByDay
                bottomBuffer={false}
                offers={startingOffers}
                deleteOffer={setOfferToRemove}
                editOffer={setOfferToEdit}
                repeatDays={repeatDays}
                notificationMessage='Customer and revenue predictions are for the total number of times an offer repeats within the goal period.'
              />
            </Box>
          </Box>

          <LaunchGoalBox
            offers={startingOffers}
            onNext={onNext}
            customersMonthlyLower={totals.customersMonthlyLower}
            customersMonthlyUpper={totals.customersMonthlyUpper}
            revenueMonthlyLower={totals.revenueMonthlyLower}
            revenueMonthlyUpper={totals.revenueMonthlyUpper}
            offerStartDay={offerStartDay}
            setOfferStartDay={setOfferStartDay}
            setOfferTimePeriod={setOfferTimePeriod}
            offerTimePeriod={offerTimePeriod}
            postingOffers={submitting}
          />

          <StartingOffersFooter
            offers={startingOffers}
            onNext={onNext}
            revenueMonthlyLower={totals.revenueMonthlyLower}
            revenueMonthlyUpper={totals.revenueMonthlyUpper}
            offerStartDay={offerStartDay}
            setOfferStartDay={setOfferStartDay}
            setOfferTimePeriod={setOfferTimePeriod}
            offerTimePeriod={offerTimePeriod}
            postingOffers={submitting}
          />

          <EditParametersModal
            isOpen={showEditParametersModal}
            onClose={() => setShowEditParametersModal(false)}
            onSave={updateParameters}
            revenueTarget={revenueTarget}
            strategy={strategy}
            types={types}
            potentialRevenues={potentialRevenues}
            dineInMax={dineInMax}
          />

          <RemoveOfferModal
            isOpen={offerToRemove !== null}
            onClose={() => setOfferToRemove(null)}
            offer={offerToRemove}
            revenueMonthly={totals.revenueMonthly}
            onSave={() => {
              trackEvent('button_click: confirm_remove_offer');
              removeOffer(offerToRemove);
            }}
            occurrences={repeatDays?.[offerToRemove?.dayOfWeek]}
          />

          {!isEmpty(offerToEdit) && (
            <EditStartingOfferModal
              isOpen
              onClose={() => setOfferToEdit(null)}
              offer={offerToEdit}
              revenueMonthly={totals.revenueMonthly}
              onSave={editOffer}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

StartingOffers.propTypes = {
  onNext: PropTypes.func.isRequired,
  startingOffers: PropTypes.arrayOf(offerPropTypes),
  setStartingOffers: PropTypes.func.isRequired,
  fetchGeneratedOffers: PropTypes.func.isRequired,
  offerStartDay: PropTypes.string.isRequired,
  setOfferStartDay: PropTypes.func.isRequired,
  offerTimePeriod: PropTypes.string.isRequired, // TODO this might not be used?
  setOfferTimePeriod: PropTypes.func.isRequired,
  updateParameters: PropTypes.func.isRequired,
  revenueTarget: PropTypes.number.isRequired,
  strategy: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
  restId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dineInMax: PropTypes.number.isRequired,
  setStrategy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchGeneratedOffers: fetchGeneratedOffersAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StartingOffers);
