import React from 'react';
import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import { Box, Modal as ECModal } from '@eatclub-apps/ec-component-library';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { modalInputStyle, modalStyle } from './ModalStyles';
import { Heading } from '../Heading';

/**
 * Wrapper for ec modal to make sure all modals use the same styling in PP
 */
export const Modal = ({
  cancelLabel = 'Cancel',
  children,
  confirmLabel = 'Confirm',
  disabled = false,
  heading,
  headingStartIcon = null,
  isLoading = false,
  isOpen,
  onCancel = () => {},
  onClose,
  onConfirm = () => {},
  style = {},
  inputStyle = {},
}) => {
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  return (
    <ECModal
      fullWidth={false}
      inputStyle={{ ...modalInputStyle, ...inputStyle }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      style={modalStyle(breakpointSmDown, style)}
      footer={
        <>
          <Button
            onClick={() => {
              onCancel();
              onClose();
            }}
            fullWidth={breakpointSmDown}
            color='secondary'
            variant='outlined'
            size='large'
            disabled={isLoading}
          >
            {cancelLabel}
          </Button>
          <Button
            onClick={onConfirm}
            fullWidth={breakpointSmDown}
            color='secondary'
            variant='contained'
            size='large'
            disabled={isLoading || disabled}
          >
            {isLoading ? <CircularProgress color='inherit' size={24} /> : confirmLabel}
          </Button>
        </>
      }
    >
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          style={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
            marginBottom: '20px',
            ...style?.heading,
          }}
        >
          {headingStartIcon}

          <Heading size='3' style={{ fontSize: '28px' }}>
            {heading}
          </Heading>
        </Box>

        <Box>{children}</Box>
      </Box>
    </ECModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  headingStartIcon: PropTypes.node,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  style: PropTypes.shape({}),
  inputStyle: PropTypes.shape({}),
};
