import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
    },
    cardTitle: {
      // color: theme.palette.text.secondary,
      marginBottom: theme.spacing(1),
    },
    hr: {
      marginBottom: theme.spacing(2),
      // borderColor: grey[100],
      opacity: 0.3,
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mobilePaddingBreak: {
      [theme.breakpoints.down('sm')]: {
        margin: '0 -20px',
      },
    },
    mobilePadding: {
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      },
    },
    cardContainer: {
      [theme.breakpoints.down('sm')]: {
        margin: '0 -20px',
        padding: '0 20px 10px 20px',
        flexDirection: 'column',
      },
    },
  }),
  { name: 'Insight' },
);

export default useStyles;
