/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { Box, Button, Divider, Popover, Spacer } from '@eatclub-apps/ec-component-library';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { usePageViews } from '../../../utils/analytics';
import useStyles from './CreateOfferStyles';
import OfferTime from './OfferTime';
import OfferAmount from './OfferAmount';
import OfferService from './OfferService';
import OfferDate from './OfferDate';
import { dayjsAsMinutes } from '../../../utils';
import CustomersSmall from '../../../assets/icon_customers_small.svg';
import RevenueSmall from '../../../assets/icon_revenue_small.svg';
import { formatCurrency } from '../../../utils/helpers';
import { restaurantPropTypes } from '../../../data/models/Restaurant';
import Error from '../../error/Error';
import OfferLimit from './OfferLimit';
import { createOfferAction } from '../../../actions/offersForGoalAction';

const CreateOffer = ({ createOffer, offerCreate, restaurant }) => {
  const classes = useStyles();
  const history = useHistory();
  usePageViews();

  const theme = useTheme();
  const breakpointXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [creating, setCreating] = useState(false);
  const [learnMoreAnchor, setLearnMoreAnchor] = useState(null);

  const drinksOnly = false; // todo: get this from api

  const closed = (day) =>
    Object.entries(restaurant.hoursParsed).some(
      ([label, value]) => label.substring(0, 3) === day && value === -1,
    );

  /**
   * get the next open day relative to today
   */
  const startDate = [...new Array(7)]
    .map((_, i) => dayjs().add(i, 'days'))
    .find((day) => {
      if (!closed(day.format('ddd').toLocaleLowerCase())) {
        return true;
      }

      return false;
    });

  const startDateFormatted = startDate?.format('ddd').toLocaleLowerCase();

  const [newOffer, setNewOffer] = useState({
    service: {
      index: 1,
      name: 'service',
      tagline: 'What would you like to achieve?',
      component: OfferService,
      enabled: true,
      value: { dineIn: false, takeaway: false, drinksOnly: false },
      error: '',
    },
    date: {
      index: 2,
      name: 'date',
      tagline: 'When do you want more customers?',
      component: OfferDate,
      enabled: false,
      value: {
        recurringDays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].reduce((acc, day) => {
          return { ...acc, [day]: startDateFormatted === day };
        }, {}),
        recurring: true,
        startDate,
      },
      error: !startDateFormatted ? 'You have no opening times' : '',
    },
    whatTimes: {
      index: 3,
      name: 'whatTimes',
      tagline: 'What times?',
      component: OfferTime,
      enabled: false,
      value: {
        lightning: false,
        startTime: restaurant.hoursParsed[`${startDateFormatted}Open`],
        endTime: restaurant.hoursParsed[`${startDateFormatted}Close`],
      },
      error: '',
    },
    offerLimit: {
      index: 4,
      name: 'offerLimit',
      tagline: 'Select the offer you want to limit',
      component: OfferLimit,
      enabled: false,
      value: {
        limit: ['dineIn', 'takeaway'],
        dineInQuantity: 20,
        takeawayQuantity: 20,
      },
    },
    offerAmount: {
      index: 5,
      name: 'offerAmount',
      tagline: 'Offer amount',
      description: (
        <Box style={{ display: 'inline-block' }}>
          <CustomersSmall style={{ verticalAlign: 'middle' }} />
          <span style={{ verticalAlign: 'middle' }}> customers and </span>
          <RevenueSmall style={{ verticalAlign: 'middle' }} />
          <span style={{ verticalAlign: 'middle' }}>
            {' '}
            revenue are predictions based on AI and machine learning.{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={(e) => setLearnMoreAnchor(e.currentTarget)}
            >
              Learn more
            </span>
          </span>
        </Box>
      ),
      component: OfferAmount,
      enabled: false,
      value: undefined,
    },
  });

  const create = () => {
    setCreating(true);

    createOffer(
      `${newOffer.offerAmount.value.discount}% Off`,
      newOffer.whatTimes.value.lightning,
      newOffer.service.value.dineIn,
      newOffer.service.value.takeaway,
      newOffer.service.value.drinksOnly,
      newOffer.whatTimes.value.lightning ? dayjsAsMinutes(newOffer.whatTimes.value.startTime) : 0,
      newOffer.whatTimes.value.lightning ? dayjsAsMinutes(newOffer.whatTimes.value.endTime) : 1439,
      newOffer.date.value.startDate.format('YYYY-MM-DD'),
      newOffer.date.value.recurring,
      newOffer.date.value.recurring
        ? JSON.stringify(
            Object.entries(newOffer.date.value.recurringDays)
              .map(([label, value]) => value && label)
              .filter((v) => v),
          )
        : null,

      newOffer.offerLimit.value.dineInQuantity,
      newOffer.offerLimit.value.takeawayQuantity,
    );
  };

  // Redirect back to deals page upon action success
  useEffect(() => {
    if (creating && offerCreate?.success) {
      setCreating(false);

      history.push('/offers');
    }
  }, [creating, offerCreate?.success, history]);

  useEffect(() => {
    if (creating && offerCreate?.error) {
      setCreating(false);
    }
  }, [creating, offerCreate?.error]);

  return (
    <>
      <Spacer direction='vertical' gap={24} style={{ padding: '0 0 158px 0' }}>
        <Box style={{ color: '#6E6E6D', fontSize: '18px', maxWidth: '470px' }}>
          Publish exclusive offers to the EatClub Marketplace and connect with a world of new
          customers.
        </Box>
        {/**  overflow: 'visible' fixes 'What times?' step dropdown cutoff */}
        <Card style={{ overflow: 'visible' }}>
          <CardContent>
            {Object.values(newOffer)
              .sort((a, b) => a.index - b.index)
              .map((step) => (
                <Collapse key={step.name} in={Boolean(step.enabled)} mountOnEnter unmountOnExit>
                  {step.index !== 1 && <Divider style={{ margin: '30px 0' }} />}

                  <Spacer direction='vertical' gap='m'>
                    <Spacer gap='m'>
                      <Box className={classes.indexLabel}>{step.index}</Box>
                      <Box className={classes.stepLabel}>{step.tagline}</Box>
                    </Spacer>

                    <Spacer direction='vertical' gap='m' className={classes.stepContent}>
                      {step.description && (
                        <Box className={classes.stepDescription}>{step.description}</Box>
                      )}

                      <step.component
                        newOffer={newOffer}
                        setNewOffer={setNewOffer}
                        drinksOnly={drinksOnly}
                      />

                      <Error error={step.error} message={step.error} />
                    </Spacer>
                  </Spacer>
                </Collapse>
              ))}
          </CardContent>
        </Card>
      </Spacer>

      {/* TODO RESTAURANT showPredicitions api value???? */}
      <Box
        style={{
          alignSelf: 'flex-end',
          display: 'flex',
          flexGrow: '1',
          position: 'fixed',
          zIndex: 99,
        }}
      >
        <Box className={classes.predictionBar}>
          <Box className={classes.offerChangePrediction}>
            <Box className={classes.predictedResultsText}>PREDICTION</Box>
            {newOffer.offerAmount.value ? (
              <Box className={classes.predictedResultsContainer}>
                <Box className={classes.predictedResult}>
                  <Box className={classes.predictedResultLabel}>Customers</Box>
                  <Box
                    className={classes.predictedResultValue}
                  >{`${newOffer.offerAmount.value.partySizeLower}-${newOffer.offerAmount.value.partySizeUpper}`}</Box>
                </Box>
                <Box className={classes.predictedResult}>
                  <Box className={classes.predictedResultLabel}>Revenue</Box>
                  <Box className={classes.predictedResultValue}>
                    {formatCurrency(newOffer.offerAmount.value.revenueExpected, false)}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box style={{ color: '#6E6E6D' }}>
                Finish setting up your offer to view customer and revenue predictions
              </Box>
            )}
          </Box>

          <Button
            disabled={!newOffer.offerAmount.value}
            onClick={create}
            type='secondary'
            style={{ width: breakpointXsDown && '100%', button: { background: '#FBF6E4' } }}
            fullWidth
            loading={creating}
          >
            Publish offer
          </Button>
        </Box>
      </Box>

      <Popover
        isOpen={!!learnMoreAnchor}
        onClose={() => setLearnMoreAnchor(null)}
        anchorEl={learnMoreAnchor}
        innerStyle={{
          marginTop: '-10px',
          maxWidth: '400px',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'middle',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'middle',
        }}
      >
        <Box>
          By analysing millions of rows of data collected from thousands of venues, EatClub’s
          machine learning algorithm predicts the number of expected customers and revenue a
          particular offer will generate for your business with an accuracy rate of 93%!
          <br />
          <br />
          This will help you make better informed decisions making it easier for you to fill more
          tables, more often and raise your bottom line.
        </Box>
      </Popover>
    </>
  );
};

CreateOffer.propTypes = {
  createOffer: PropTypes.func.isRequired,
  offerCreate: PropTypes.shape({
    saving: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  offerCreate: state.offerCreate,
  restaurant: state.restaurantActive.restaurant,
  selectedDay: state.dealsByDay.selectedDay,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOffer: createOfferAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateOffer);
