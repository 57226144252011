import { makeStyles } from '@mui/styles';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '32px',
    color: '#313131',
    padding: '30px',

    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 20px 20px',
    },
  },
  bannerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '20px',
  },
  analyticsTotals: {
    gap: '22px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  redText: {
    color: COLORS.PRIMARY_RED_MATTE,
    fontSize: '14px',
  },
  greenText: {
    color: COLORS.DARKER_SUNNY_GREEN,
    fontSize: '14px',
  },
  heading: {
    fontWeight: 500,
  },
  target: {
    color: COLORS.SMOKE,
  },
  totalImage: {
    minWidth: '60px',
    minHeight: '60px',
    width: '60px',
    height: '60px',
  },
  label: {
    color: COLORS.SMOKE,
    letterSpacing: '1.2px',
  },
  totalValue: {
    fontSize: '22px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  card: {
    borderRadius: '8px',
    width: '100%',
    fontSize: '18px',
    color: '#313131',
    // minHeight: open ? '150px' : '75px',
    // transition: 'height 0.2s',
    cursor: 'pointer',
    userSelect: 'none',
  },
  cardInner: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cardTitle: {
    alignItems: 'center',
    fontWeight: '500',
  },
  cardDate: {
    alignItems: 'center',
    color: COLORS.SMOKE,
  },
  arrowContainer: {
    display: 'flex',
    gap: '85px',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
