import { Box, Button, Card, CardContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postDailyOppoAction } from '../../../actions/dealDailyOpposPostAction';
import {
  opportunitiesPropTypes,
  opportunityPropTypes,
} from '../../../reducers/dealDailyOpposReducer';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency } from '../../../utils/helpers';
import { OpportunityDetails } from './OpportunityDetails';
import useStyles from './OpportunityCardStyles';
import { Text } from '../../Text';

const TopOpportunity = ({
  opportunity,
  number = 1,
  dealDailyOppoPost,
  restId,
  userId,
  userType,
  postDailyOppo,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  // const { track } = useAnalytics();

  const postOppo = () => {
    trackEvent(`button_click: post_opportunity`);
    postDailyOppo(
      opportunity?.objectId,
      opportunity?.discount,
      'partnerPortal',
      restId,
      userId,
      userType,
    );
  };

  const styles = {
    buttonContainer: {
      alignItems: 'flex-end',
      height: '100%',
      paddingTop: '10px',
    },
    centeredContent: {
      height: '100%',
    },
  };

  return (
    <Card>
      <CardContent>
        <Box className={classes.container}>
          <Box className={classes.opportunityDetailsContainer}>
            <Box mr='auto'>
              <OpportunityDetails opportunity={opportunity} number={number} />
            </Box>

            <Box className={classes.estimatesContainer}>
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Text>Customers</Text>

                <Box>
                  <Text
                    size='xl'
                    style={{
                      backgroundColor: 'rgb(251, 244, 189)',
                      borderRadius: '100px',
                      padding: '3px 9px',
                    }}
                  >
                    {opportunity?.prediction}
                  </Text>
                </Box>

                <Text size='xs' style={{ color: '#7C7C7C' }}>
                  Predictions only
                </Text>
              </Box>

              <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Text>Revenue</Text>

                <Box>
                  <Text
                    size='xl'
                    style={{
                      backgroundColor: 'rgb(251, 244, 189)',
                      borderRadius: '100px',
                      padding: '3px 9px',
                    }}
                  >
                    {formatCurrency(opportunity?.revenueExpected, false)}
                  </Text>
                </Box>

                <Text size='xs' style={{ color: '#7C7C7C' }}>
                  Predictions only
                </Text>
              </Box>
            </Box>
          </Box>

          {/* Post now button */}
          <Box className={classes.postButtonContainer}>
            <Button
              disabled={
                dealDailyOppoPost.pending ||
                dealDailyOppoPost.pendingOppos.includes(opportunity?.objectId)
              }
              onClick={postOppo}
              color='secondary'
              variant='outlined'
              size='large'
              fullWidth
            >
              Post
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

TopOpportunity.propTypes = {
  postDailyOppo: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  dealDailyOppoPost: opportunitiesPropTypes.isRequired,
  opportunity: opportunityPropTypes.isRequired,
  number: PropTypes.number, // The number we show next to the row
};

const mapStateToProps = (state) => ({
  dealDailyOppoPost: state.dealDailyOppoPost,
  oppoPost: state.dealDailyOppoPost,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ postDailyOppo: postDailyOppoAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopOpportunity);
