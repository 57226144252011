import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {},
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  heading: {
    fontWeight: 500,
  },
  date: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  headingContainer: {
    display: 'flex',
    gap: '20px',
    alignItems: 'baseline',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    rowGap: 0,
  },
  iconLegend: {
    display: 'flex',
    gap: '20px',
    fontSize: FONT_SIZES.M,
    color: COLORS.SMOKE,
    height: '100%',
  },
  icon: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  iconBackground: {
    height: '28px',
    width: '28px',
    background: '#EFE8DE',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
