import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { useTheme } from '@mui/material/styles';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Checkbox, Divider, InlineTextArea } from '@eatclub-apps/ec-component-library';
import React, { useEffect, useState } from 'react';
import { CleanTextField } from '@eatclub-apps/ec-bookings-library';
import useStyles from './BookingsLandingStyles';
import { clearObeeRequestAction } from '../../../actions/obeeSignupAction';
import { isEmpty } from '../../../utils/helpers';
import { COLORS } from '../../../EatClubTheme';
import { Modal } from '../../Modal';

const RequestAccessModal = ({
  isOpen,
  onClose,
  plan = 'lite',
  onConfirm,
  loading,
  clearObeeRequest,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [comments, setComments] = useState(
    `I’m interested in getting access to the EatClub ${plan} Bookings tool.`,
  );

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const requiredErrorMsg = 'This field is required.';
  const validPhoneErrorMsg = 'A valid phone number is required.';
  const validEmailErrorMsg = 'A valid email is required.';

  useEffect(() => {
    if (isOpen) {
      clearObeeRequest(); // clear reducer state
      unstable_batchedUpdates(() => {
        setName(null);
        setNameError(null);
        setPhone(null);
        setEmail(null);
        setComments(`I’m interested in getting access to the EatClub ${plan} Bookings tool.`);
        setAgreedToTerms(false);
      });
    }
  }, [isOpen]);

  const textFieldStyles = {
    label: {
      fontSize: '15px',
      width: '150px',
    },
    errorText: {
      marginLeft: breakpointXsDown ? 0 : '160px',
    },
    input: { fontSize: '15px', padding: '8px 12px' },
  };

  const confirm = () => {
    let isValid = true;

    if (isEmpty(name)) {
      setNameError(requiredErrorMsg);
      isValid = false;
    }

    if (!isEmpty(phone) && !validator.isMobilePhone(phone)) {
      setPhoneError(validPhoneErrorMsg);
      isValid = false;
    }

    if (!isEmpty(email) && !validator.isEmail(email)) {
      setEmailError(validEmailErrorMsg);
      isValid = false;
    }

    if (isValid) {
      onConfirm(plan === 'premium', name, phone, email, comments);
    }
  };

  return (
    <Modal
      confirmLabel='Send request'
      heading='Your contact details'
      disabled={!agreedToTerms}
      isLoading={loading}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirm}
    >
      <Box className={classes.requestAccessFields}>
        <CleanTextField
          required
          value={name}
          onChange={setName}
          placeholder={breakpointXsDown ? 'Name' : 'Empty'}
          label={!breakpointXsDown && 'Name'}
          style={textFieldStyles}
          error={nameError}
          clearError={() => setNameError(null)}
        />
        <CleanTextField
          value={phone}
          onChange={setPhone}
          placeholder={breakpointXsDown ? 'Phone' : 'Empty'}
          label={!breakpointXsDown && 'Phone'}
          style={textFieldStyles}
          error={phoneError}
          clearError={() => setPhoneError(null)}
        />
        <CleanTextField
          value={email}
          onChange={setEmail}
          placeholder={breakpointXsDown ? 'Email' : 'Empty'}
          label={!breakpointXsDown && 'Email'}
          style={textFieldStyles}
          error={emailError}
          clearError={() => setEmailError(null)}
        />
        <Box>
          <Box className={classes.modalFieldLabel}>Comment</Box>
          <InlineTextArea
            value={comments}
            onChange={setComments}
            placeholder='Empty'
            style={{
              textFieldInner: {
                padding: '8px 12px',
                margin: '0 -12px',
              },
              input: {
                fontSize: '15px',
                width: '100%',
                resize: 'none',
              },
            }}
          />
        </Box>
        <Divider />
        <Box>
          Click send request below and an account specialist will reach out to discuss the product.
        </Box>
        <Box className={classes.agreeToTermsContainer}>
          <Checkbox
            selected={agreedToTerms}
            onChange={setAgreedToTerms}
            style={{ checkboxBox: { height: '24px', width: '24px' } }}
            color={COLORS.CHARCOAL}
          />
          <Box>
            I have reviewed and agreed to the EatClub bookings{' '}
            <a
              href='https://faq.eatclub.com.au/bookings-terms'
              target='_blank'
              rel='noopener noreferrer'
              className={classes.link}
            >
              terms and conditions. - View here
            </a>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

RequestAccessModal.propTypes = {
  plan: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  clearObeeRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  requestBookings: state.requestBookings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearObeeRequest: clearObeeRequestAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RequestAccessModal);
