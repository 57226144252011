import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, DonutChart } from '@eatclub-apps/ec-component-library';
import { groupObjects, isEmpty } from '../../../utils/helpers';
import {
  getRevenue,
  getTimeslotForBooking,
  getTimeslots,
} from '../../../utils/insights/insightHelpers';
import { calculatePercentage } from '../../../utils/math';
import useStyles from './RevenueDistributionStyles';

const RevenueDistribution = ({ transactionDetails, activeRestaurant, restaurantPeriods }) => {
  const classes = useStyles();

  // Get items for the view (days, weeks, etc.)
  const revenueByPeakDay = useMemo(() => {
    if (isEmpty(transactionDetails.transactionDetails)) {
      return [];
    }

    // Group the data by the time period (day, week, etc.)
    const peakDays = [5, 6]; // Friday, Saturday
    const bookingsByGroup = groupObjects(
      transactionDetails.transactionDetails.map((booking) => ({
        ...booking,
        peakDay: peakDays.includes(dayjs(booking?.created).day()),
      })),
      'peakDay',
    );

    const offPeakRevenue = getRevenue(
      bookingsByGroup?.false,
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
    );
    const onPeakRevenue = getRevenue(
      bookingsByGroup?.true,
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
    );

    return [
      {
        percentage: calculatePercentage(offPeakRevenue, offPeakRevenue + onPeakRevenue),
        label: 'Sun-Thu',
        color: '#FBFCCF',
      },
      {
        percentage: calculatePercentage(onPeakRevenue, offPeakRevenue + onPeakRevenue),
        label: 'Fri-Sat',
        color: '#FAD14A',
      },
    ];
  }, [transactionDetails.transactionDetails]);

  // Get items for the view (days, weeks, etc.)
  const revenueByTimeslot = useMemo(() => {
    if (isEmpty(transactionDetails.transactionDetails)) {
      return [];
    }

    const timeslots = getTimeslots(restaurantPeriods.restaurantTimePeriods);

    const bookingsWithTimeslot = transactionDetails.transactionDetails.map((booking) => ({
      ...booking,
      timeslot: getTimeslotForBooking(booking, timeslots),
    }));

    // Group the data by the timeslot
    const bookingsByGroup = groupObjects(bookingsWithTimeslot, 'timeslot');

    const earlyRevenue = getRevenue(
      bookingsByGroup?.earlyBird ?? [],
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
    );
    const peakRevenue = getRevenue(
      bookingsByGroup?.peak ?? [],
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
    );
    const lateRevenue = getRevenue(
      bookingsByGroup?.lateSession ?? [],
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
    );
    const totalRevenue = earlyRevenue + peakRevenue + lateRevenue;

    return [
      {
        label: 'Early bird',
        percentage: calculatePercentage(earlyRevenue, totalRevenue),
        color: '#706C4C',
      },
      {
        label: 'Peak',
        percentage: calculatePercentage(peakRevenue, totalRevenue),
        color: '#B7ADF3',
      },
      {
        label: 'Late session',
        percentage: calculatePercentage(lateRevenue, totalRevenue),
        color: '#FBFCCF',
      },
    ];
  }, [
    activeRestaurant.averageBookingValue,
    activeRestaurant.averageOrderValue,
    restaurantPeriods.restaurantTimePeriods,
    transactionDetails.transactionDetails,
  ]);

  return (
    <Box className={classes.root}>
      <Box className={classes.graphHeader}>
        <Box className={classes.title}>Revenue by day</Box>
        <DonutChart data={revenueByPeakDay} animationSeconds={0.4} />
      </Box>
      <Box className={classes.graphHeader}>
        <Box className={classes.title}>Revenue by time</Box>
        <DonutChart data={revenueByTimeslot} animationSeconds={0.4} />
      </Box>
    </Box>
  );
};

RevenueDistribution.propTypes = {
  activeRestaurant: PropTypes.shape({
    objectId: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  transactionDetails: PropTypes.shape({
    transactionDetails: PropTypes.arrayOf(PropTypes.shape({})),
    compareTransactionDetails: PropTypes.arrayOf(PropTypes.shape({})),
    fetching: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    success: PropTypes.bool,
    shouldFetch: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  transactionDetails: state.transactionDetails,
  restaurantPeriods: state.restaurantPeriods,
  activeRestaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RevenueDistribution);
