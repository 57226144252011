import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import MaterialTable from '@material-table/core';
import { useDispatch, useSelector } from 'react-redux';
import SettlementsBanner from '../SettlementsBanner/SettlementsBanner';
import useStyles from './SettlementStyles';
import { fetchSettlementsByRestIdAction } from '../../actions/settlementsAction';
import ExposureSVG from '../../assets/exposure_icon.svg';
import NetRevenueSVG from '../../assets/net_revenue_icon.svg';
import CustomerSVG from '../../assets/customer_icon.svg';
import AovSVG from '../../assets/aov_icon.svg';
import Label from '../label/Label';
import { formatCurrency, formatDate } from '../../utils/helpers';
import { sortByDate } from '../../utils';
import { Text } from '../Text';

const Settlements = () => {
  const classes = useStyles();
  const restaurantActive = useSelector((state) => state.restaurantActive);
  const settlements = useSelector((state) => state.settlements);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettlementsByRestIdAction(200, 0));
  }, [dispatch, restaurantActive.restaurant.objectId]);

  const columns = [
    {
      field: 'startDate',
      title: 'Period',
      render: (rowData) => (
        <div>
          <div style={{ whiteSpace: 'nowrap' }}>
            {formatDate(rowData.startDate, 'ddd Do MMM')} -{' '}
            {formatDate(rowData.endDate, 'ddd Do MMM')}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {rowData.platform !== 'EatClub' && (
              <>
                <Label
                  label={rowData.platform}
                  rounded
                  style={{
                    backgroundColor: '#F5F2ED',
                    color: '#696969',
                    fontWeight: 500,
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }}
                />
                <div
                  style={{
                    borderRadius: '100%',
                    height: '7px',
                    width: '7px',
                    background: '#E9E3D8',
                  }}
                />
              </>
            )}

            <Text size='xs' style={{ color: '#696969' }}>
              #{rowData.settlementId}
            </Text>
          </div>
        </div>
      ),
      customSort: (a, b) => sortByDate(a.startDate, b.startDate, 'YYYY-MM-DD'),
      width: '100%',
    },
    {
      field: 'exposure',
      title: (
        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <ExposureSVG style={{ flexShrink: 0 }} />
          <Box>Exposure</Box>
        </Box>
      ),
      render: (rowData) => (rowData.exposure === 0 ? '-' : rowData.exposure),
    },

    {
      field: 'totalCustomers',
      title: (
        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <CustomerSVG style={{ flexShrink: 0 }} />
          <Box>Customers</Box>
        </Box>
      ),
    },
    {
      field: 'averageOrderValue',
      title: (
        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <AovSVG style={{ flexShrink: 0 }} />
          <Box>Avg order value</Box>
        </Box>
      ),
      render: (rowData) => formatCurrency(rowData.averageOrderValue, true, undefined, true),
    },
    {
      field: 'netRevenue',
      title: (
        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <NetRevenueSVG style={{ flexShrink: 0 }} />
          <Box>Net Revenue</Box>
        </Box>
      ),
      render: (rowData) => formatCurrency(rowData.netRevenue, true, undefined, true),
    },
    {
      field: 'view',
      render: (rowData) => (
        <Button
          style={{
            paddingLeft: '7px',
            paddingRight: '7px',
            minWidth: 'unset',
            // display: 'flex',
            // marginLeft: 'auto',
          }}
          variant='outlined'
          color='secondary'
          onClick={() => window.open(rowData.pdfLink, '_blank')}
        >
          <svg
            width='22'
            height='21'
            viewBox='0 0 22 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M11.5 1L21 10.5M21 10.5L11.5 20M21 10.5H0' stroke='black' />
          </svg>
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <SettlementsBanner />

      <div className={classes.tableStyles}>
        <MaterialTable
          isLoading={settlements.pending}
          columns={columns}
          data={settlements.data}
          options={{
            emptyRowsWhenPaging: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            padding: 'dense',
            search: false,
            draggable: false,
            toolbar: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No settlements to display',
            },
          }}
        />
      </div>
    </Box>
  );
};

export default Settlements;
