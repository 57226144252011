import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Button, CircularProgress, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextDropdown, DatePicker, CenteredContent } from '@eatclub-apps/ec-component-library';
import { trackEvent } from '../../../../utils/analytics';
import { getLabelForValue, formatCurrency, roundToNearest } from '../../../../utils/helpers';
import OfferDataLabel from './OfferDataLabel';
import useStyles from './StartingOfferStyles';

const StartingOffersFooter = ({
  offers,
  onNext,
  offerTimePeriod,
  setOfferTimePeriod,
  offerStartDay,
  setOfferStartDay,
  revenueMonthlyLower,
  revenueMonthlyUpper,
  postingOffers,
}) => {
  const theme = useTheme();
  const breakpointXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [offerPeriods] = useState([
    { label: '1 Month', value: 'monthly' },
    { label: 'Ongoing', value: 'ongoing' },
  ]);

  const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

  return (
    <Box>
      <Box className={classes.goalsFooter}>
        <Box className={classes.goalsFooterPadding}>
          <CenteredContent style={{ marginRight: 'auto' }}>
            <Box className={classes.footerOptions}>
              <Box className={classes.footerOption}>
                <Box>Post for</Box>
                <TextDropdown
                  items={offerPeriods}
                  value={offerTimePeriod}
                  shownValue={getLabelForValue(offerTimePeriod, offerPeriods).toLowerCase()}
                  onSelect={(value) => {
                    setOfferTimePeriod(value);
                  }}
                  direction='inverse'
                  style={{
                    fontWeight: '500',
                    container: { height: 'unset' },
                    dropdown: {
                      backgroundColor: 'transparent',
                    },
                  }}
                />
              </Box>
              <Box className={classes.footerOption}>
                <Box>starting</Box>
                {showDatePicker ? (
                  <DatePicker
                    style={{ maxWidth: '100px' }}
                    value={offerStartDay}
                    onSelect={(value) => setOfferStartDay(value)}
                    disablePast
                    closeOnScroll
                  />
                ) : (
                  <Box
                    onClick={() => {
                      trackEvent('button_click: create_goal_footer_set_date');
                      setShowDatePicker(true);
                    }}
                  >
                    <TextDropdown
                      items={[{ label: 'Tomorrow', value: tomorrow }]}
                      value={tomorrow}
                      onSelect={(value) => {
                        setOfferStartDay(value);
                      }}
                      type='inverse'
                      style={{
                        fontWeight: '500',
                        container: { height: 'unset' },
                        dropdown: {
                          backgroundColor: 'transparent',
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </CenteredContent>
          <Box className={classes.buttonAndLabel}>
            <OfferDataLabel
              value={`${formatCurrency(
                roundToNearest(revenueMonthlyLower, 10),
                false,
              )}-${formatCurrency(roundToNearest(revenueMonthlyUpper, 10), false)} monthly`}
              feature
              bgColor='#fbf4bd'
            />
            <Box className={classes.buttonContainer}>
              <Button
                disabled={offers?.length === 0 || postingOffers}
                onClick={onNext}
                size='large'
              >
                <Box className={classes.launchGoal}>
                  {postingOffers && <CircularProgress size={16} color='inherit' />}Launch goal
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

StartingOffersFooter.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onNext: PropTypes.func.isRequired,
  offerTimePeriod: PropTypes.string.isRequired,
  setOfferTimePeriod: PropTypes.func.isRequired,
  offerStartDay: PropTypes.string.isRequired,
  setOfferStartDay: PropTypes.func.isRequired,
  revenueMonthlyLower: PropTypes.number.isRequired,
  revenueMonthlyUpper: PropTypes.number.isRequired,
  postingOffers: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StartingOffersFooter);
