import { makeStyles } from '@mui/styles';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';

const getBackgroundColor = (status) => {
  if (status === 'cancelled') {
    return COLORS.DISABLED_GOAL_CARD;
  }
  if (status === 'disabled') {
    return COLORS.DISABLED_GOAL_CARD;
  }

  if (status === 'complete') {
    return COLORS.OFFER_CARD_COMPLETED_BACKGROUND;
  }

  return COLORS.WHITE;
};

const getFontColor = (status) => {
  if (status === 'cancelled') {
    return COLORS.DISABLED_TEXT;
  }

  if (status === 'disabled') {
    return COLORS.DISABLED_TEXT;
  }

  return COLORS.BODY_TEXT;
};

const getBarColor = (status) => {
  if (status === 'complete') {
    return COLORS.OFFER_CARD_STATUS_BAR_COMPLETED;
  }

  if (status === 'active') {
    return COLORS.OFFER_CARD_STATUS_BAR_ACTIVE;
  }

  if (status === 'disabled') {
    return COLORS.NIMBUS;
  }

  return COLORS.CLOUD;
};

const labelTypes = {
  GREEN: 'green',
  RED: 'red',
  GRAY: 'gray',
  WHITE: 'white',
  YELLOW: 'yellow',
  BLUE: 'blue',
};

const typeMap = {
  active: labelTypes.RED,
  complete: labelTypes.GREEN,
  live_later: labelTypes.GRAY,
  disabled: labelTypes.GRAY,
  cancelled: labelTypes.WHITE,
  upcoming: labelTypes.GRAY,
};

const getLabelType = (status) => {
  if (Object.keys(typeMap).includes(status)) {
    return typeMap[status];
  }

  return labelTypes.WHITE;
};

const getThemeForType = (status) => {
  const type = getLabelType(status);
  const themes = {
    green: {
      color: COLORS.WHITE,
      backgroundColor: COLORS.GREEN_LABEL,
    },
    red: {
      color: COLORS.DISABLED,
      backgroundColor: COLORS.GRAPEFRUIT,
    },
    gray: {
      color: COLORS.BODY_TEXT,
      backgroundColor: COLORS.CLOUD,
    },
    white: {
      color: '#313131',
    },
    yellow: {
      color: COLORS.CHARCOAL,
    },
    blue: {
      color: COLORS.CHARCOAL,
    },
  };

  if (!Object.keys(themes).includes(type)) {
    return themes.gray;
  }

  return themes[type];
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px 24px',
    width: '100%',
    position: 'relative',
    display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr min-content',
    gridTemplateColumns: '1fr min-content',
  },
  predictionLabel: {
    fontSize: '14px',
    color: '#313131',
    minWidth: '140px',
    marginBottom: '4px',
  },
  headingRow: {},
  statusAndPeakContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  peak: {
    color: COLORS.BODY_TEXT,
    backgroundColor: COLORS.CLOUD,
    padding: '6px 7px',
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
  },
  detailsContainer: {
    display: 'grid',
    gap: '12px',
    gridTemplateColumns: '1fr 1fr',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    rowGap: '20px',
  },
  details: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
    fontSize: '14px',
    width: 'fit-content',
  },
  detail: {
    display: 'inline-flex',
    gap: '4px',
    alignItems: 'center',
    flexShrink: 0,
  },
  iconBackground: {
    height: '23px',
    width: '23px',
    backgroundColor: '#EFE8DE',
    borderRadius: '14px',
    position: 'relative',
  },
  goalTarget: {
    ...theme.small,
    color: COLORS.SMOKE,
    fontSize: '12px',
    lineHeight: '15px',
    marginTop: '12px',
  },
  actionMenu: {
    position: 'absolute',
    top: '24px',
    right: '32px',
  },
  predictionsContainer: {},
  statusLabel: (props) => ({
    ...getThemeForType(props?.status),
    padding: '6px 7px',
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
  }),
  subHeading: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  gridContainer: {
    display: 'grid',
    gap: '12px',
    gridTemplateColumns: '1fr 2fr',
    rowGap: '20px',

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

export default useStyles;
