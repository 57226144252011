import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Divider, Spacer } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@mui/material/styles';
import ComparePercent from '../../components/analytics/comparePercent/ComparePercent';
import { roundToDecimal } from '../../utils/helpers';
import useStyles from './InsightSummaryStyles';

const InsightPill = ({
  label = null,
  increase = '0%',
  change = 'gain',
  value = 0,
  loading,
  variant = 'filled',
  showChange = true,
}) => {
  const classes = useStyles({ loading, variant });
  const theme = useTheme();

  return (
    <Spacer className={classes.pill}>
      {label && <Box className={classes.pillLabel}>{label}</Box>}
      {showChange && (
        <Box className={classes.pillContent}>
          <ComparePercent percent={roundToDecimal(increase)} change={change} />
        </Box>
      )}
      <Divider direction='vertical' color={theme.colors.dividerLine} />
      <Box className={classes.pillContent}>{value}</Box>
    </Spacer>
  );
};

InsightPill.propTypes = {
  label: PropTypes.string,
  increase: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  showChange: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.loading?.loadingIds.length > 0,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InsightPill);
