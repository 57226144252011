import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@eatclub-apps/ec-component-library';
import { COLORS } from '../../../EatClubTheme';
import useStyles from './CleanDatePopoverStyles';
import DatePopover from './DatePopover';
import { formatDate, isEmpty } from '../../../utils/helpers';

const CleanDatePopover = ({
  label,
  value,
  onSelect,
  placeholder,
  format,
  datePickerProps,
  disabled,
  error,
  clearError,
  style,
}) => {
  const classes = useStyles({ style, disabled });
  const [anchor, setAnchor] = useState(null);
  return (
    <Box>
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Box className={classes.label}>{label}</Box>
          <Box
            className={`${classes.value} ${!!anchor && classes.activeValue} ${
              isEmpty(value) && classes.valuePlaceholder
            }`}
            style={{
              ...(disabled
                ? {
                    cursor: 'default',
                    backgroundColor: COLORS.DATE_SELECT_BACKGROUND,
                    color: COLORS.NIMBUS,
                  }
                : {}),
              ...(error ? { border: `1px solid ${COLORS.RED}` } : {}),
            }}
            onClick={(e) => {
              if (!disabled) {
                setAnchor(e.currentTarget);
              }
            }}
          >
            <Box className={classes.valueOverflow}>
              {!isEmpty(value) ? formatDate(value, format) : placeholder}
            </Box>
          </Box>
        </Box>
        {!isEmpty(error) && <Box className={classes.errorText}>{error}</Box>}
      </Box>
      {!disabled && (
        <DatePopover
          value={value}
          anchor={anchor}
          isOpen={!!anchor}
          onClose={() => setAnchor(null)}
          onSetDate={(newValue) => {
            onSelect(newValue);
            clearError();
          }}
          datePickerProps={datePickerProps}
        />
      )}
    </Box>
  );
};

CleanDatePopover.defaultProps = {
  placeholder: '',
  value: null,
  format: 'ddd. Do. MMM. YYYY',
  datePickerProps: {},
  disabled: false,
  onSelect: () => {},
  clearError: () => {},
  error: null,
  style: {},
};

CleanDatePopover.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  datePickerProps: PropTypes.shape({}),
  disabled: PropTypes.bool,
  clearError: PropTypes.func,
  error: PropTypes.string,
  style: PropTypes.shape({}),
};

export default CleanDatePopover;
