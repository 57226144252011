import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { CenteredContent } from '@eatclub-apps/ec-component-library';
import { dealPropTypes } from '../../../data/models/Deal';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, formatCurrency } from '../../../utils/helpers';
import useStyles from './ModalStyles';
import ModalSectionHeader from './ModalSectionHeader';
import { Modal } from '../../Modal';

const RemoveOfferModal = ({ isOpen, offer = null, onClose, onSave = () => {}, revenueMonthly }) => {
  const classes = useStyles();

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: remove_offer');
    }
  }, [isOpen]);

  return (
    <Modal
      confirmLabel='Save changes'
      heading='Remove offer'
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onSave}
    >
      <Box className={classes.modalContent}>
        <Box>
          <Box>You&apos;re about to remove the following:</Box>
          <Box mt='10px' className={classes.inlineText}>
            <Box className={classes.discountLabel}>{offer?.discount}% off</Box>
            <CenteredContent>
              on {getDayFromInt(offer?.dayOfWeek)} from{' '}
              {`${offer?.startTimeReadable} - ${offer?.endTimeReadable}`}
            </CenteredContent>
          </Box>
        </Box>
        <Box>
          <ModalSectionHeader header='Impact' />
          <Box className={classes.impactRow}>
            <Box>Change in revenue goal</Box>
            <Box className={classes.redLabel}>
              -{formatCurrency(offer?.targetValMonthly, false)}
            </Box>
          </Box>
          <Box className={classes.impactRow}>
            <Box>Adjusted revenue goal</Box>
            <Box className={classes.impactCurrency}>
              {formatCurrency(revenueMonthly - offer?.targetValMonthly, false)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

RemoveOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  revenueMonthly: PropTypes.number.isRequired,
};

export default RemoveOfferModal;
