import React from 'react';
import PropTypes from 'prop-types';
import { Spacer, Box } from '@eatclub-apps/ec-component-library';
import { formatCurrency } from '../../../utils/helpers';
import useStyles from './RevenueByTimeStyles';

const RevenueByTimeSubRow = ({ rowData, label, subLabel }) => {
  const classes = useStyles();

  const getRowValue = (data) => {
    return (
      <Box className={classes.rowValueContainer}>
        {data > 0 ? (
          formatCurrency(data, false)
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={classes.emptyCell} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <tr className={`${classes.subTableRow}`}>
      <td className={`${classes.subTableItem} ${classes.subTableItemLabel}`}>
        <Spacer direction='vertical' gap={2}>
          {label} <Box className={classes.subTableSubLabel}>{subLabel}</Box>
        </Spacer>
      </td>
      <td className={classes.subTableItem}>{getRowValue(rowData.mon)}</td>
      <td className={classes.subTableItem}>{getRowValue(rowData.tue)}</td>
      <td className={classes.subTableItem}>{getRowValue(rowData.wed)}</td>
      <td className={classes.subTableItem}>{getRowValue(rowData.thu)}</td>
      <td className={classes.subTableItem}>{getRowValue(rowData.fri)}</td>
      <td className={classes.subTableItem}>{getRowValue(rowData.sat)}</td>
      <td className={classes.subTableItem}>{getRowValue(rowData.sun)}</td>
      <td className={`${classes.subTableItem} ${classes.subTableItemLast}`} />
    </tr>
  );
};

RevenueByTimeSubRow.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  rowData: PropTypes.shape({
    mon: PropTypes.number,
    tue: PropTypes.number,
    wed: PropTypes.number,
    thu: PropTypes.number,
    fri: PropTypes.number,
    sat: PropTypes.number,
    sun: PropTypes.number,
  }).isRequired,
};

export default RevenueByTimeSubRow;
