import dayjs from 'dayjs';
import * as type from '../actions/types';

const initialState = {
  restaurant: {},
  id: '',
  restaurantFetching: true, // TODO what makes this different to fetching?
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_ACTIVE_RESTAURANT_ID:
      return {
        ...state,
        id: action.payload,
        fetching: true,
      };

    case type.SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload,
        restaurantFetching: false,
        fetching: false,
      };

    case type.FETCH_RESTAURANT_SUCCESS:
      return {
        ...state,
        restaurantFetching: true,
        fetching: true,
      };

    case type.FETCH_RESTAURANT_FAILURE:
      return {
        ...state,
        restaurantFetching: false,
        fetching: false,
      };

    case type.UPDATE_AVERAGE_ORDER_VALUE_SUCCESS:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          averageOrderValue: action.payload.data.averageOrderValue,
        },
      };

    case type.UPDATE_AVERAGE_BOOKING_VALUE_SUCCESS:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          averageBookingValue: action.payload.data.averageBookingValue,
        },
      };

    case type.OBEE_ONBOARD_SUCCESS: {
      const { obeeEnabled, obeeGoLiveDate, premium } = action.payload;
      return {
        ...state,
        restaurant: { ...state.restaurant, obeeEnabled, obeeGoLiveDate, premium },
      };
    }

    case type.CLOSE_RESTAURANT_SUCCESS:
    case type.REOPEN_RESTAURANT_SUCCESS: {
      const today = dayjs().startOf('day');
      const closedEarlyToday =
        (action.payload.data?.closures || []).filter((closure) =>
          today.isBetween(dayjs(closure.startDate), dayjs(closure.endDate), 'day', '[]'),
        )?.length > 0;

      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          closures: action.payload.data?.closures,
          closedEarlyToday,
        },
      };
    }

    case type.UPDATE_FOOD_COST_PERCENT_PENDING: {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          foodCostPercent: action.payload.data?.foodCostPercent ?? 30,
        },
      };
    }

    case type.UPDATE_FOOD_COST_PERCENT_SUCCESS: {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          foodCostPercent: action.payload.data?.foodCostPercent ?? 30,
        },
      };
    }

    default:
      return state;
  }
};
