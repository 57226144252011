import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@eatclub-apps/ec-component-library';
import useStyles from './HorizontalScrollStyles';

const HorizontalScroll = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

HorizontalScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HorizontalScroll;
