import React, { useRef, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { RadioButtonGroup } from '@eatclub-apps/ec-component-library';
import {
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  Skeleton,
} from '@mui/material';
import { Heading } from '../Heading';
import { formatCurrency } from '../../utils/helpers';
import {
  fetchNetBalanceByRestIdAction,
  fetchPaymentMethodsAction,
  payBalanceAction,
} from '../../actions/settlementsAction';
import { AddNewCardModal } from './AddNewCardModal';
import { COLORS } from '../../EatClubTheme';
import { Text } from '../Text';

const validateAmount = (input, useDecimals = false) => {
  if (useDecimals) {
    return /^[0-9\b]+\.?[0-9]?[0-9]?$/.test(input);
  }

  return /^[0-9\b]+$/.test(input);
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY);

export const PayBalance = () => {
  const [openAddNewCardModal, setOpenAddNewCardModal] = useState(false);
  const inputRef = useRef();
  const history = useHistory();

  const [editableBalance, setEditableBalance] = useState(0);
  const [payableBalance, setPayableBalance] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const dispatch = useDispatch();
  const restaurantActive = useSelector((state) => state.restaurantActive);
  const netBalance = useSelector((state) => state.netBalance);
  const paymentMethods = useSelector((state) => state.paymentMethods);
  const payBalance = useSelector((state) => state.payBalance);
  const savePaymentDetails = useSelector((state) => state.savePaymentDetails);

  let balance = Number.isNaN(netBalance.data) ? 0 : netBalance.data;
  // Whether they pay us or are due for a refund or something
  const owesEatClub = netBalance.data <= 0;

  // If negative, the restaurant owes EatClub money
  if (owesEatClub) {
    balance *= -1;
  }

  useEffect(() => {
    dispatch(fetchNetBalanceByRestIdAction());
    dispatch(fetchPaymentMethodsAction());
  }, [dispatch, restaurantActive.restaurant.objectId]);

  useEffect(() => {
    setEditableBalance(balance / 100);
    setPayableBalance(balance);
  }, [balance]);

  useEffect(() => {
    if (savePaymentDetails.success) {
      dispatch(fetchPaymentMethodsAction());
    }
  }, [savePaymentDetails.success, dispatch, restaurantActive.restaurant.objectId]);

  useEffect(() => {
    const defaultPaymentMethod = paymentMethods.data.find((item) => item.defaultPaymentMethod);

    setPaymentMethod(defaultPaymentMethod);
  }, [paymentMethods.data]);

  const formatPaymentMethod = (item) => {
    let prefix = '';

    if (item.paymentMethodType === 'card') {
      prefix = 'Credit card';
    }

    if (item.paymentMethodType === 'au_becs_debit') {
      prefix = 'Direct debit acc ending';
    }

    return `${prefix} ${item.paymentMethodName}`;
  };

  useEffect(() => {
    if (payBalance.success) {
      history.push('/settlements/balance-history');
    }
  }, [payBalance.success, history]);

  if (netBalance.pending) {
    return <CircularProgress />;
  }

  return (
    <Elements stripe={stripePromise}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '32px 140px' }}>
        <div style={{ maxWidth: '360px', width: '100%' }}>
          <Heading>
            {owesEatClub ? 'Please pay the balance of ' : 'Please pay the amount owed '}
            <Input
              inputRef={inputRef}
              style={{ fontSize: 'inherit', fontWeight: 500 }}
              variant='standard'
              startAdornment={
                <InputAdornment position='start'>
                  <span style={{ fontSize: 'inherit', fontWeight: 500, color: '#000000DE' }}>
                    $
                  </span>
                </InputAdornment>
              }
              value={editableBalance}
              onChange={(e) => {
                if (e.target.value === '' || validateAmount(e.target.value, true)) {
                  const num = Math.round(parseFloat(e.target.value) * 100);
                  if (num > balance) {
                    setEditableBalance(balance / 100);
                  } else {
                    setEditableBalance(e.target.value);
                  }
                }
              }}
              onBlur={() => {
                const num = Math.round(parseFloat(editableBalance) * 100);
                setPayableBalance(Math.min(Number.isNaN(num) ? 0 : num, balance));
              }}
              placeholder={balance / 100}
              endAdornment={
                <IconButton onClick={() => inputRef.current?.focus()}>
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 22 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.0811 8.16536L7.25126 19.0441C7.00259 19.2939 6.69232 19.4736 6.35188 19.5649L1.50114 20.8656C1.27825 20.9254 1.07415 20.7216 1.13355 20.4986L2.46187 15.5119C2.55321 15.169 2.73415 14.8565 2.98611 14.6066L13.7623 3.91895M18.0811 8.16536L13.7623 3.91895M18.0811 8.16536L19.9112 6.3841C20.5102 5.80102 20.517 4.84073 19.9263 4.24922L17.7419 2.06202C17.1562 1.47559 16.206 1.47529 15.6199 2.06134L13.7623 3.91895'
                      stroke='#313131'
                      strokeWidth='2'
                    />
                  </svg>
                </IconButton>
              }
            />
          </Heading>
          <div style={{ marginTop: '40px' }}>
            <Text size='lg'>Fill out the payment details to finalise your payment.</Text>
          </div>
          <Divider style={{ margin: '32px 0', borderColor: '#ECDECB' }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Heading size='5'>Balance remaining</Heading>
            <Text>{formatCurrency(balance - payableBalance, true, undefined, true)}</Text>
          </div>
        </div>

        <Card
          style={{
            background: '#fff',
            borderRadius: '24px',
            maxWidth: '485px',
            padding: '20px 0',
            width: '100%',
          }}
        >
          <div style={{ padding: '20px 24px' }}>
            <Heading size='4'>Payment details</Heading>
          </div>

          <Divider />

          <div style={{ padding: '20px 24px' }}>
            {paymentMethods.data.length === 0 && paymentMethods.pending && (
              <Skeleton style={{ maxWidth: '160px' }} />
            )}

            <RadioButtonGroup
              items={paymentMethods.data.map((item) => ({
                label: formatPaymentMethod(item),
                value: item,
              }))}
              onSelect={setPaymentMethod}
              value={paymentMethod}
              style={{
                buttonCircleInnerSelected: {
                  backgroundColor: COLORS.BLACK,
                },
              }}
            />
            <div style={{ marginTop: '20px' }}>
              <Button
                color='secondary'
                onClick={() => setOpenAddNewCardModal(true)}
                size='small'
                variant='outlined'
              >
                Add new
              </Button>
            </div>
          </div>

          <div style={{ padding: '0 24px' }}>
            <div style={{ padding: '20px 0' }}>{/*  */}</div>

            <Divider style={{ borderColor: '#313131' }} />

            <div style={{ padding: '20px 0' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Heading size='4'>Total payment</Heading>
                <Text style={{ fontSize: '18px' }}>
                  {formatCurrency(payableBalance, true, undefined, true)}
                </Text>
              </div>
            </div>

            <Button
              disabled={payBalance.pending || !paymentMethod || payableBalance <= 0}
              fullWidth
              variant='contained'
              size='large'
              style={{ height: '54px', outline: 'none', borderRadius: '100px' }}
              onClick={() => {
                dispatch(
                  payBalanceAction(
                    payableBalance,
                    paymentMethod.paymentMethodId,
                    paymentMethod.paymentMethodName,
                    paymentMethod.paymentMethodType,
                  ),
                );
              }}
            >
              {payBalance.pending ? <CircularProgress color='inherit' size={16} /> : 'Pay now'}
            </Button>
          </div>
        </Card>
      </div>

      <AddNewCardModal isOpen={openAddNewCardModal} onClose={() => setOpenAddNewCardModal(false)} />
    </Elements>
  );
};
