import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    paper: {
      maxWidth: '500px',
      margin: '80px auto 0',
      padding: '40px',
    },
    form: {
      margin: '0 auto',
    },
    textfield: {
      marginBottom: '24px',
    },
    logo: {
      width: '200px',
      display: 'block',
      margin: '0 auto',
      marginBottom: '24px',
    },
  }),
  { name: 'CustomSignIn' },
);

export default useStyles;
