import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    pill: {
      backgroundColor: (props) =>
        props.variant !== 'filled'
          ? 'transparent'
          : props.change === 'gain'
            ? theme.colors.backgroundGreen
            : props.change === 'loss'
              ? theme.colors.backgroundRed
              : theme.colors.dropdownGray,
      fontSize: '12px',
      padding: '4px 0',
      width: '60px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: theme.fontWeights.medium,
      cursor: 'default',

      // Fade in effects are nicer than the data just popping in
      opacity: (props) => (props.loading ? 0 : 1),
      transition: (props) => (props.loading ? 'none' : 'opacity 0.5s'),

      '& > *': {
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
    pillContent: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'ChangePill' },
);

export default useStyles;
