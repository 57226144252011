import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { startGoalAction } from '../../../actions/goalsAction';
import {
  clearPotentialRevenuesAction,
  fetchPotentialRevenuesAction,
} from '../../../actions/potentialRevenuesAction';
import { goalsPropTypes } from '../../../data/models/Goal';
import { offersPropTypes } from '../../../reducers/generatedOffersReducer';
import { potentialRevenuesPropTypes } from '../../../reducers/potentialRevenuesReducer';
import { trackEvent, usePageViews } from '../../../utils/analytics';
import StartingOffers from './StartingOffers/StartingOffers';
import Success from './Success';
import { setBreadcrumbsAction, clearBreadcrumbsAction } from '../../../actions/breadcrumbsAction';
import RevenueTarget from './RevenueTarget';
import GoalsOverview from './GoalsOverview';
import {
  clearPotentialTakeawayRevenuesAction,
  fetchPotentialTakeawayRevenuesAction,
} from '../../../actions/potentialTakeawayRevenuesAction';
import {
  clearPotentialDineInRevenuesAction,
  fetchPotentialDineInRevenuesAction,
} from '../../../actions/potentialDineInRevenuesAction';
import { potentialTakeawayRevenuesPropTypes } from '../../../reducers/potentialTakeawayRevenuesReducer';
import { potentialDineInRevenuesPropTypes } from '../../../reducers/potentialDineInRevenuesReducer';

const CreateGoal = ({
  setBreadcrumbs,
  clearBreadcrumbs,
  restId,
  potentialRevenues,
  fetchPotentialRevenues,
  potentialTakeawayRevenues,
  fetchPotentialTakeawayRevenues,
  potentialDineInRevenues,
  fetchPotentialDineInRevenues,
  generatedOffers,
  startGoal,
  userId,
  userType,
  goalStart,
  clearPotentialRevenues,
  clearPotentialTakeawayRevenues,
  clearPotentialDineInRevenues,
}) => {
  const history = useHistory();
  usePageViews();
  const [step, setStep] = useState(0);
  const [revenueTarget, setRevenueTarget] = useState(0);
  const [targetSegments, setTargetSegments] = useState([]);
  const [startingOffers, setStartingOffers] = useState([]);
  const [offerTimePeriod, setOfferTimePeriod] = useState('ongoing');
  const [offerStartDay, setOfferStartDay] = useState(dayjs().add(1, 'day').format('YYYY-MM-DD'));
  const [strategy, setStrategy] = useState(null);
  const [autoAdjustments, setAutoAdjustments] = useState(true);
  const [types, setTypes] = useState(['dineIn', 'takeaway']);
  const [submitting, setSubmitting] = useState(false);
  const [dineInMax, setDineInMax] = useState(0);

  // setStep but with tracking
  const changeStep = (newStep) => {
    trackEvent(`set_goal_step: ${newStep}`);
    setStep(newStep);
  };

  // Clear old data if restaurant was changed
  useEffect(() => {
    clearPotentialRevenues();
    clearPotentialTakeawayRevenues();
    clearPotentialDineInRevenues();
  }, [restId]);

  // Fetch potential revenue
  useEffect(() => {
    if (!potentialRevenues?.success) {
      fetchPotentialRevenues(restId, userId, userType);
    }
  }, [potentialRevenues?.success]);

  // Fetch potential takeaway revenue if haven't yet
  useEffect(() => {
    if (types.length === 1 && types.includes('takeaway')) {
      if (!potentialTakeawayRevenues?.success) {
        fetchPotentialTakeawayRevenues(restId, userId, userType);
      }
    }
  }, [potentialTakeawayRevenues?.success, types]);

  // Fetch potential dinein revenue if haven't yet
  useEffect(() => {
    if (types.length === 1 && types.includes('dineIn')) {
      if (!potentialDineInRevenues?.success) {
        fetchPotentialDineInRevenues(restId, userId, userType);
      }
    }
  }, [potentialDineInRevenues?.success, types]);

  // Set starting offers from what the AI generated
  useEffect(() => {
    if (generatedOffers.success) {
      setStartingOffers(generatedOffers.data);
    }
  }, [setStartingOffers, generatedOffers]);

  // Set defaults based on potential revenue
  useEffect(() => {
    // Only update if it hasn't already been set
    if (potentialRevenues?.success && strategy === null) {
      setStrategy(potentialRevenues.data?.maxRevenue?.type);
    }
  }, [setStrategy, potentialRevenues.success]);

  // Update breadcrumbs
  useEffect(() => {
    switch (step) {
      case 0:
        setBreadcrumbs([
          {
            label: 'Offers',
            onClick: () => {
              trackEvent('button_click: offers_home');
              history.push('/offers');
            },
          },
          { label: 'Overview', onClick: () => changeStep(0) },
        ]);
        break;
      case 1:
        setBreadcrumbs([
          {
            label: 'Offers',
            onClick: () => {
              trackEvent('button_click: offers_home');
              history.push('/offers');
            },
          },
          { label: 'Overview', onClick: () => changeStep(0) },
          { label: 'Goals', onClick: () => changeStep(1) },
        ]);
        break;
      case 2:
        setBreadcrumbs([
          {
            label: 'Offers',
            onClick: () => {
              trackEvent('button_click: offers_home');
              history.push('/offers');
            },
          },
          { label: 'Overview', onClick: () => changeStep(0) },
          { label: 'Goals', onClick: () => changeStep(1) },
          // { label: 'Strategy', onClick: () => changeStep(2) },
        ]);
        break;
      case 3:
        setBreadcrumbs([
          {
            label: 'Offers',
            onClick: () => {
              trackEvent('button_click: offers_home');
              history.push('/offers');
            },
          },
          { label: 'Overview', onClick: () => changeStep(0) },
          { label: 'Goals', onClick: () => changeStep(1) },
          // { label: 'Strategy', onClick: () => changeStep(2) },
          // { label: 'Customers', onClick: () => changeStep(3) },
        ]);
        break;
      case 4:
        setBreadcrumbs([
          {
            label: 'Offers',
            onClick: () => {
              trackEvent('button_click: offers_home');
              history.push('/offers');
            },
          },
          { label: 'Overview', onClick: () => changeStep(0) },
          { label: 'Goals', onClick: () => changeStep(1) },
          // { label: 'Strategy', onClick: () => changeStep(2) },
          // { label: 'Customers', onClick: () => changeStep(3) }, // Disabled screen for now
          { label: 'Review', onClick: () => changeStep(4) },
        ]);
        break;
      case 5:
        clearBreadcrumbs();
        break;
      default:
        setBreadcrumbs([
          {
            label: 'Offers',
            onClick: () => {
              trackEvent('button_click: offers_home');
              history.push('/offers');
            },
          },
          {
            label: 'Overview',
            onClick: () => {
              trackEvent('button_click: offers_home');
              history.push('/goals/create');
            },
          },
        ]);
        break;
    }

    return () => {
      clearBreadcrumbs();
    };
  }, [setBreadcrumbs, step]);

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const getStrategyName = (strategy) =>
    strategy === 'lowerOffers' ? 'lowerOffersOptimized' : 'offPeakOptimized';

  const getStrategyNameLower = (strategy) =>
    strategy === 'lowerOffers' ? 'lowerOffersRevenueLower' : 'offPeakRevenueLower';

  const getStrategyNameUpper = (strategy) =>
    strategy === 'lowerOffers' ? 'lowerOffersRevenueUpper' : 'offPeakRevenueUpper';

  const updateParameters = (updatedRevenue, updatedStrategy, updatedTypes, updatedDineInMax) => {
    // TODO it's probably not great to update them all individually like this
    const strategyName = getStrategyName(updatedStrategy);
    let updatedRevenueTarget = Math.min(updatedRevenue, potentialRevenues.data[strategyName]); // capped at strategy's max

    if (types.length === 1) {
      updatedRevenueTarget = types.includes('takeaway')
        ? Math.min(updatedRevenue, potentialTakeawayRevenues.data[strategyName])
        : Math.min(updatedRevenue, potentialDineInRevenues.data[strategyName]);
    }

    setStartingOffers(generatedOffers?.data);
    setRevenueTarget(updatedRevenueTarget);
    setStrategy(updatedStrategy);
    setTypes(updatedTypes);
    setDineInMax(updatedDineInMax);
  };

  const getPotentialMaxRevenue = (bound = '') => {
    let strategyName = '';

    if (bound === 'lower') {
      strategyName = getStrategyNameLower(strategy);
    } else if (bound === 'upper') {
      strategyName = getStrategyNameUpper(strategy);
    } else {
      strategyName = getStrategyName(strategy);
    }

    if (types.length === 1) {
      return types.includes('takeaway')
        ? potentialTakeawayRevenues.data[strategyName]
        : potentialDineInRevenues.data[strategyName];
    }

    return potentialRevenues.data[strategyName];
  };

  // Post the new goal / deals
  const postGoal = () => {
    setSubmitting(true);
    trackEvent(`button_click: launch_goal`);

    const offerTotals = startingOffers.reduce(
      (total, startingOffer) => ({
        ...total,
        targetVal: (total?.targetVal || 0) + startingOffer?.targetVal,
        targetValLower: (total?.targetValLower || 0) + startingOffer?.targetValLower,
        targetValUpper: (total?.targetValUpper || 0) + startingOffer?.targetValUpper,
        targetValMonthly: (total?.targetValMonthly || 0) + startingOffer?.targetValMonthly,
        targetValMonthlyLower:
          (total?.targetValMonthlyLower || 0) + startingOffer?.targetValMonthlyLower,
        targetValMonthlyUpper:
          (total?.targetValMonthlyUpper || 0) + startingOffer?.targetValMonthlyUpper,
        bookingsLower: (total?.bookingsLower || 0) + startingOffer?.bookingsLower,
        bookingsExpected: (total?.bookingsExpected || 0) + startingOffer?.bookingsExpected,
        bookingsUpper: (total?.bookingsUpper || 0) + startingOffer?.bookingsUpper,
        partySizeExpected: (total?.partySizeExpected || 0) + startingOffer?.partySizeExpected,
        partySizeLower: (total?.partySizeLower || 0) + startingOffer?.partySizeLower,
        partySizeUpper: (total?.partySizeUpper || 0) + startingOffer?.partySizeUpper,
        partySizeMonthlyExpected:
          (total?.partySizeMonthlyExpected || 0) + startingOffer?.partySizeMonthlyExpected,
        partySizeMonthlyLower:
          (total?.partySizeMonthlyLower || 0) + startingOffer?.partySizeMonthlyLower,
        partySizeMonthlyUpper:
          (total?.partySizeMonthlyUpper || 0) + startingOffer?.partySizeMonthlyUpper,

        revenueExpected: (total?.revenueExpected || 0) + startingOffer?.revenueExpected,
        contributionExpected:
          (total?.contributionExpected || 0) + startingOffer?.contributionExpected,
        contributionADExpected:
          (total?.contributionADExpected || 0) + startingOffer?.contributionADExpected,
        discountExpected: (total?.discountExpected || 0) + startingOffer?.discountExpected,
        foodCostExpected: (total?.foodCostExpected || 0) + startingOffer?.foodCostExpected,
        commissionExpected: (total?.commissionExpected || 0) + startingOffer?.commissionExpected,
      }),
      {},
    );

    const goal = {
      restId,
      strategy,
      autoAdjust: autoAdjustments,
      newCustBoost: targetSegments.includes('new'),
      churnedCustBoost: targetSegments.includes('churned'),
      // targetVal: Math.round(offerTotals.targetVal),
      // targetValLower: Math.round(offerTotals.targetValLower),
      // targetValUpper: Math.round(offerTotals.targetValUpper),
      targetVal: Math.round(offerTotals.targetValMonthly),
      targetValLower: Math.round(offerTotals.targetValMonthlyLower),
      targetValUpper: Math.round(offerTotals.targetValMonthlyUpper),
      // targetValMonthly: Math.round(offerTotals.targetValMonthly),
      // targetValMonthlyLower: Math.round(offerTotals.targetValMonthlyLower),
      // targetValMonthlyUpper: Math.round(offerTotals.targetValMonthlyUpper),
      weekly: offerTotals.targetVal,
      monthly: offerTotals.targetValMonthly,
      numDeals: startingOffers.length,
      startDate: offerStartDay,
      duration: offerTimePeriod,
      maxWeekly: (getPotentialMaxRevenue() * 12 * 7) / 365,
      maxMonthly: getPotentialMaxRevenue(),
      bookingsExpected: offerTotals.bookingsExpected,
      bookingsLower: offerTotals.bookingsLower,
      bookingsUpper: offerTotals.bookingsUpper,
      partySizeExpected: offerTotals.partySizeExpected,
      partySizeLower: offerTotals.partySizeLower,
      partySizeUpper: offerTotals.partySizeUpper,
      partySizeMonthlyExpected: offerTotals.partySizeMonthlyExpected,
      partySizeMonthlyLower: offerTotals.partySizeMonthlyLower,
      partySizeMonthlyUpper: offerTotals.partySizeMonthlyUpper,
      revenueExpected: Math.round(offerTotals.revenueExpected),
      contributionExpected: Math.round(offerTotals.contributionExpected),
      contributionADExpected: Math.round(offerTotals.contributionADExpected),
      discountExpected: Math.round(offerTotals.discountExpected),
      foodCostExpected: Math.round(offerTotals.foodCostExpected),
      commissionExpected: Math.round(offerTotals.commissionExpected),
    };
    startGoal(restId, userId, userType, goal, startingOffers);
  };

  // Handle a successful goal post
  useEffect(() => {
    if (submitting && !goalStart.pending && goalStart.success) {
      setSubmitting(false);
      changeStep(5); // Move to success screen
    }

    // TODO handle error
    if (submitting && !goalStart.pending && goalStart.error) {
      setSubmitting(false);
      // TODO show a notification
    }
  }, [goalStart]);

  if (
    potentialRevenues.fetching ||
    (types.length === 1 && types.includes('takeaway') && potentialTakeawayRevenues.fetching) ||
    (types.length === 1 && types.includes('dineIn') && potentialDineInRevenues.fetching)
  ) {
    return <CircularProgress />;
  }

  // TODO this should be an error popup like when other API calls fail
  if (
    !potentialRevenues.success ||
    (types.length === 1 && types.includes('takeaway') && !potentialTakeawayRevenues.success) ||
    (types.length === 1 && types.includes('dineIn') && !potentialDineInRevenues.success)
  ) {
    return <Box>An error occurred while fetching potential revenues</Box>;
  }

  return (
    <Box>
      {step === 0 && (
        <GoalsOverview
          customersLower={potentialRevenues.data?.maxRevenue?.customersLower}
          customersUpper={potentialRevenues.data?.maxRevenue?.customersUpper}
          maxRevenue={potentialRevenues.data?.maxRevenue?.amount}
          onNext={() => changeStep(1)}
        />
      )}
      {step === 1 && (
        <RevenueTarget
          setRevenueTarget={setRevenueTarget}
          revenueTarget={revenueTarget}
          maxRevenue={getPotentialMaxRevenue()}
          maxRevenueLower={getPotentialMaxRevenue('lower')}
          maxRevenueUpper={getPotentialMaxRevenue('upper')}
          onNext={() => changeStep(4)}
          setDeliveryType={setTypes}
          deliveryType={types}
          onBack={() => changeStep(0)}
        />
      )}
      {step === 4 && (
        <StartingOffers
          startingOffers={startingOffers}
          setStartingOffers={setStartingOffers}
          onNext={postGoal}
          offerTimePeriod={offerTimePeriod}
          setOfferTimePeriod={setOfferTimePeriod}
          offerStartDay={offerStartDay}
          setOfferStartDay={setOfferStartDay}
          updateParameters={updateParameters}
          revenueTarget={revenueTarget}
          strategy={strategy}
          types={types}
          loading={generatedOffers.fetching}
          submitting={submitting}
          dineInMax={dineInMax}
          setStrategy={setStrategy}
        />
      )}
      {step === 5 && <Success startingOffers={startingOffers} />}
    </Box>
  );
};

CreateGoal.propTypes = {
  setBreadcrumbs: PropTypes.func.isRequired,
  clearBreadcrumbs: PropTypes.func.isRequired,
  fetchPotentialRevenues: PropTypes.func.isRequired,
  fetchPotentialTakeawayRevenues: PropTypes.func.isRequired,
  fetchPotentialDineInRevenues: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
  potentialTakeawayRevenues: potentialTakeawayRevenuesPropTypes.isRequired,
  potentialDineInRevenues: potentialDineInRevenuesPropTypes.isRequired,
  generatedOffers: offersPropTypes.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  startGoal: PropTypes.func.isRequired,
  goalStart: goalsPropTypes.isRequired,
  clearPotentialRevenues: PropTypes.func.isRequired,
  clearPotentialTakeawayRevenues: PropTypes.func.isRequired,
  clearPotentialDineInRevenues: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  potentialRevenues: state.potentialRevenues,
  potentialTakeawayRevenues: state.potentialTakeawayRevenues,
  potentialDineInRevenues: state.potentialDineInRevenues,
  generatedOffers: state.generatedOffers,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  goalStart: state.goalStart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBreadcrumbs: setBreadcrumbsAction,
      clearBreadcrumbs: clearBreadcrumbsAction,
      fetchPotentialRevenues: fetchPotentialRevenuesAction,
      fetchPotentialTakeawayRevenues: fetchPotentialTakeawayRevenuesAction,
      fetchPotentialDineInRevenues: fetchPotentialDineInRevenuesAction,
      clearPotentialRevenues: clearPotentialRevenuesAction,
      clearPotentialTakeawayRevenues: clearPotentialTakeawayRevenuesAction,
      clearPotentialDineInRevenues: clearPotentialDineInRevenuesAction,
      startGoal: startGoalAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateGoal);
