import dayjs from 'dayjs';
import { getDayOfWeekWithMondayFirst } from './helpers';

/**
 * Get the start and end dates from a range of months.
 * Start = beginning of start month
 * End = end of end month
 */
export const getMonthRange = (monthRange, endDate = null, startToEnd = false) => {
  const end = endDate ?? dayjs();

  const startDay = end.subtract(monthRange, 'months');
  const endDay = end;

  if (startToEnd) {
    return {
      startDate: startDay.startOf('month'),
      endDate: endDay.endOf('month'),
      range: endDay.endOf('month').diff(startDay.startOf('month'), 'month'),
    };
  }

  return {
    startDate: startDay.startOf('day'),
    endDate: endDay.startOf('day'),
    range: endDay.startOf('day').diff(startDay.startOf('day'), 'month'),
  };
};

/**
 * Get the date x months ago
 *
 * TODO should this be inclusive of today?
 */
export const getRange = (monthRange) => {
  const startDay = dayjs().subtract(monthRange, 'months').startOf('day');
  const endDay = dayjs().startOf('day');

  return {
    startDate: startDay,
    endDate: endDay,
  };
};

/**
 * For a given weekday, calculate how many times it appears within the dates, inclusively
 * This was a pain to make but I'm positive it works
 *
 * @param startDate
 * @param endDate
 * @param weekday
 */
export const getWeekdayOccurrencesInRange = (startDate, endDate, weekday) => {
  const daysBetween = dayjs(endDate).diff(dayjs(startDate), 'days') + 1; // plus one because it's inclusive of the end date

  // 2024-01-01 -> 2024-01-08 should be 2 for Monday (0), 1 for all other days

  const startDayOfWeek = getDayOfWeekWithMondayFirst(startDate);
  const endDayOfWeek = getDayOfWeekWithMondayFirst(endDate);
  const weeks = Math.floor(daysBetween / 7);
  const extraDays = daysBetween % 7; // How many days in the partial week

  // Add an extra day if this fell in the partial week
  let weekdayIsInExtraDays = weekday >= startDayOfWeek && weekday <= endDayOfWeek;

  if (startDayOfWeek + extraDays >= 7) {
    weekdayIsInExtraDays = weekday >= startDayOfWeek || weekday <= endDayOfWeek;
  }

  return weeks + (weekdayIsInExtraDays ? 1 : 0);
};
