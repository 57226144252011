import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderRefundConfirmDialog from './OrderRefundConfirmDialog';
import { refundAmountAction, clearRefundAction } from '../../actions/refundAction';
import { formatNumber } from '../../utils';

const OrderRefundAmountDialog = ({
  clearRefund,
  handleCloseDialog,
  openDialog,
  order,
  refund,
  refundAmount,
  restId,
  userId,
  userType,
}) => {
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState(<></>);

  const handleClickOpenDialogConfirm = (message) => {
    setOpenDialogConfirm(true);
    setConfirmMessage(message);
  };
  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const validateField = (field, error, setError, value) => {
    let errorMessage = '';

    if (field === 'amount') {
      if (parseFloat(value) <= 0 || value === '') {
        errorMessage = 'Please enter an amount';
      } else if (parseFloat(value) > order.total) {
        errorMessage = `Please enter an amount below the order total $${order.total}`;
      }
    }

    if (errorMessage) {
      setError(errorMessage);
    }

    if (!errorMessage && error) {
      setError('');
    }
  };

  const validateForm = () => {
    let formErrors = false;

    if (Number.isNaN(parseFloat(amount)) || amount <= 0 || amount === '' || amount > order.total) {
      if (Number.isNaN(parseFloat(amount)) || amount <= 0 || amount === '') {
        setAmountError('Please enter an amount');
      }

      if (amount > order.total) {
        setAmountError(`Please enter an amount below the order total $${order.total}`);
      }

      formErrors = true;
    } else if (amountError) {
      setAmountError('');
    }

    if (amount > order.total) {
      formErrors = true;
    }

    return formErrors;
  };

  const submit = (event) => {
    event.preventDefault();

    const hasErrors = validateForm();

    if (hasErrors) {
      return;
    }

    const message = (
      <>
        Are you sure you want to refund <strong>${formatNumber(amount)}</strong>?
      </>
    );

    handleClickOpenDialogConfirm(message);
  };

  const confirm = () => {
    const amountInCents = parseFloat(amount) * 100;
    refundAmount(order.objectId, amountInCents, restId, userId, userType);
  };

  // NOTE: Close dialog when state returns successful
  useEffect(() => {
    if (!refund.success || !openDialog) {
      return;
    }
    handleCloseDialog();
  }, [handleCloseDialog, openDialog, refund.success]);

  // NOTE: Disable close when loading
  const handleOnClose = () => {
    if (refund.loading) {
      return;
    }
    handleCloseDialog();
  };

  const handleBlurAmount = (e) => {
    let number = parseFloat(e.target.value);

    if (Number.isNaN(number)) {
      number = '';
    } else {
      number = number.toFixed(2);
    }

    setAmount(number);
    validateField('amount', amountError, setAmountError, number);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleOnClose}
        keepMounted={false}
        TransitionProps={{
          onEnter: () => {
            setAmount('');
            setAmountError('');
            setConfirmMessage(<></>);
          },
          onExit: clearRefund,
        }}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>Refund Amount</DialogTitle>
        <DialogContent dividers>
          <form id='order-refund-amount' noValidate autoComplete='off' onSubmit={submit}>
            <TextField
              fullWidth
              label='Amount'
              type='number'
              value={amount}
              margin='normal'
              onChange={(e) => {
                // const number = parseFloat(e.target.value).toFixed(2);
                setAmount(e.target.value);
              }}
              onBlur={handleBlurAmount}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                min: 0,
                step: 1,
              }}
              error={Boolean(amountError)}
              helperText={amountError}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} variant='outlined' color='secondary'>
            Cancel
          </Button>
          <Button onClick={submit} type='submit' form='order-refund-amount' autoFocus>
            {refund.loading ? <CircularProgress color='primary' size={22} /> : 'Refund'}
          </Button>
        </DialogActions>
      </Dialog>
      <OrderRefundConfirmDialog
        title='Refund Amount?'
        description={confirmMessage}
        handleCloseDialog={handleCloseDialogConfirm}
        openDialog={openDialogConfirm}
        confirmAction={confirm}
      />
    </>
  );
};

OrderRefundAmountDialog.propTypes = {
  clearRefund: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    objectId: PropTypes.string,
    reservationName: PropTypes.string,
    total: PropTypes.number,
  }).isRequired,
  refund: PropTypes.shape({
    data: PropTypes.shape({}),
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
  refundAmount: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  refund: state.refund,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearRefund: clearRefundAction, refundAmount: refundAmountAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderRefundAmountDialog);
