import dayjs from 'dayjs';
import packageInfo from '../../package.json';
import { devLog } from './devLog';

export const isSet = (value) => value !== null && value !== undefined;

/*
 * Useful for checking whether a string has any value or is empty
 * Now works with arrays too.
 * Avoids accidentally checking falsy values like 0
 */
export const isEmpty = (item) => {
  if (item === null || item === undefined) {
    return true;
  }

  // empty object
  if (Object.getPrototypeOf(item) === Object.prototype) {
    return item && Object.keys(item).length === 0;
  }

  // empty array
  if (item.constructor === Array) {
    return item?.length === 0;
  }

  // empty string
  return item === '';
};

// Attempted workaround for my IDE not importing the isEmpty function
export const empty = (item) => isEmpty(item);

// Timestring is how many minutes after 12am
export const formatTimeFromInt = (time, overrideFormat = null, suffix = 'a') => {
  // If on the hour, don't show minutes
  const format = overrideFormat || (time % 60 === 0 ? `h${suffix}` : `h:mm${suffix}`);
  return dayjs().startOf('day').add(time, 'm').format(format);
};

// Convert a dayjs object into the time as minutes past midnight
export const formatIntFromTime = (time) => {
  if (isEmpty(time)) {
    return null;
  }

  const hours = parseInt(time.format('H'), 10);
  const minutes = parseInt(time.format('m'), 10);

  return hours * 60 + minutes;
};

export const pluralise = (string, number, pluralString) => {
  if (number === 1) {
    return string;
  }

  if (pluralString) {
    return pluralString;
  }

  return `${string}s`;
};

export const getLabelForValue = (value, items) =>
  items?.find((item) => item?.value === value)?.label;

export const dealType = (deal) => {
  if (deal?.takeawayOnly) {
    return 'Takeaway';
  }

  if (deal?.dineInOnly) {
    return 'Dine-in';
  }

  return 'Dine-in & Takeaway';
};

export const capitaliseFirstLetter = (string) => {
  if (typeof string !== 'string') {
    return string;
  }

  if (isEmpty(string)) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Round to the nearest increment of a number provided.
 * e.g. Round a time to the nearest 15 minutes
 *
 * @param value
 * @param roundTo
 * @param type
 * @returns {number}
 */
export const roundToNearest = (value, roundTo = 0, type = 'round') => {
  if (roundTo === 0) {
    switch (type) {
      case 'floor':
        return Math.floor(value);
      case 'ceil':
        return Math.ceil(value);
      case 'round':
      default:
        return Math.round(value);
    }
  }

  switch (type) {
    case 'floor':
      return Math.floor(value / roundTo) * roundTo;
    case 'ceil':
      return Math.ceil(value / roundTo) * roundTo;
    case 'round':
    default:
      return Math.round(value / roundTo) * roundTo;
  }
};

/**
 * Round a number to the nearest x decimal places
 * @param value
 * @param roundTo
 * @param type
 * @returns {number}
 */
export const roundToDecimal = (value, roundTo = 0, type = 'round') => {
  if (typeof value !== 'number') {
    return 0;
  }

  if (roundTo === 0) {
    switch (type) {
      case 'floor':
        return Math.floor(value);
      case 'ceil':
        return Math.ceil(value);
      case 'round':
      default:
        return Math.round(value);
    }
  }

  switch (type) {
    case 'floor':
      return Math.floor(value * 10 ** roundTo) / 10 ** roundTo;
    case 'ceil':
      return Math.ceil(value * 10 ** roundTo) / 10 ** roundTo;
    case 'round':
    default:
      return Math.round(value * 10 ** roundTo) / 10 ** roundTo;
  }
};

/**
 * Format a number so that it becomes [x]k if over 1000 and [x]m if over 1 million
 * @param value
 * @param decimals
 * @param roundType
 * @param invalidValue
 * @returns {string}
 */
export const formatCompactNumber = (
  value,
  decimals = 1,
  roundType = 'floor',
  invalidValue = '',
) => {
  const parsedValue = parseFloat(value, 10);
  if (Number.isNaN(parsedValue)) {
    return invalidValue;
  }

  const thousandsValue = roundToDecimal(parsedValue / 1000, decimals, roundType);

  // Also don't collapse until it's over 2 of the thing. e.g. 1.5k
  if (thousandsValue >= 1) {
    // Only go up to millions
    const millionsValue = roundToDecimal(thousandsValue / 1000, decimals, roundType);

    if (millionsValue >= 1) {
      return `${millionsValue}m`;
    }

    return `${thousandsValue}k`;
  }

  return roundToDecimal(parsedValue, decimals, roundType);
};

/**
 * Formats a string to a currency
 */
export const formatCurrency = (
  value,
  useDecimals = true,
  invalidValue = '$0',
  isInCents = false,
  useLetters = false, // e.g. k for thousand, m for million
) => {
  if (value === null || Number.isNaN(value)) {
    return invalidValue;
  }

  let newValue = `${isInCents ? value / 100 : value}`;
  if (newValue.includes('$')) {
    newValue = newValue.replace('$', '');
  }

  const parsedValue = parseFloat(newValue, 10);
  if (Number.isNaN(parsedValue)) {
    return invalidValue;
  }

  // Bypass currency formatter if we're showing letters, since it's now a custom format
  // Note: I also used floor rather than round since we don't want to say people hit 1k when they didn't
  if (useLetters && parsedValue >= 1000) {
    return `$${formatCompactNumber(value)}`;
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: useDecimals ? 2 : 0,
    maximumFractionDigits: useDecimals ? 2 : 0,
  });

  return formatter.format(parsedValue).toLocaleString();
};

export const getType = (offer) => {
  if (offer?.takeawayOnly) {
    return 'Takeaway';
  }

  if (offer?.dineInOnly) {
    return 'Dine In';
  }

  return 'Dine-in & takeaway';
};

export const getDayFromInt = (dayInt, short = false) => {
  if (!isSet(dayInt)) {
    return '';
  }

  const day = dayjs().isoWeekday(dayInt);

  if (short) {
    return day.format('ddd');
  }

  return day.format('dddd');
};

/**
 * Return the day of the week as integer
 * Note: SUNDAY = 0
 *
 * @param time
 * @returns {number}
 */
export const getDayOfWeek = (time) => parseInt(time.format('d'), 10);

/**
 * Return the day of the week as integer, but with Monday = 0 and Sunday = 6
 *
 * @param time
 * @returns {number}
 */
export const getDayOfWeekWithMondayFirst = (time) => (parseInt(time.format('d'), 10) + 6) % 7;

export const generateUUID = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );

/**
 * Finds the first object in the list where the given property matches the value
 * @param objects
 * @param id
 * @param property
 * @param fallbackObject
 * @returns {null|*}
 */
export const findObjectByProperty = (objects, id, property = 'objectId', fallbackObject = null) => {
  if (isEmpty(id) || !Array.isArray(objects)) {
    return fallbackObject;
  }

  return objects?.find((obj) => obj?.[property] === id) || fallbackObject;
};

export const objectKeyExists = (objects, id, property = 'objectId') =>
  objects?.includes((obj) => obj?.[property] === id);

export const emptyObject = (value) =>
  value && Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype;

export const safeDivision = (numerator, denominator) => {
  if (!numerator) {
    return 0;
  }

  if (!denominator) {
    return numerator;
  }

  return numerator / denominator;
};

export const getOfferTime = (offer) =>
  !offer?.lightning ? 'all day' : `from ${offer?.startTimeReadable} - ${offer?.endTimeReadable}`;

/** Check for both null and undefined * */
export const isVoid = (item) => item === null || item === undefined;

/**
 * Updates all objects that match the given property.
 * Returns the updated array with those items replaced.
 *
 * NOTE: Does not try to update anything with a null value for the property
 * to prevent accidentally wiping multiple records
 */
export const updateObjectByProperty = (objects, updatedObject, property = 'objectId') =>
  objects?.map((obj) => {
    if (!isVoid(updatedObject?.[property]) && obj?.[property] === updatedObject?.[property]) {
      return updatedObject;
    }

    return obj;
  });

/**
 * Replace the first object found where the id value matches the value of the property
 * Useful for updating a single resource's data
 *
 * Return the list of objects with the updated item
 */
export const replaceObject = (
  objects,
  newObject,
  id,
  property = 'objectId',
  createIfNotFound = false,
) => {
  if (!Array.isArray(objects)) {
    return [];
  }

  let found = false;
  const updatedObjects = objects?.map((obj) => {
    if (!found && !isVoid(obj?.[property]) && id === obj?.[property]) {
      found = true;
      return newObject;
    }

    return obj;
  });

  if (createIfNotFound && !found) {
    return [...updatedObjects, newObject];
  }
  return updatedObjects;
};

/** Kind of annoying, but Sunday is 7 in our system and 0 in dayjs.
 * Without converting the day, offers for Sunday may not show.
 */
export const eatClubDayOfWeekToDayjs = (dayOfWeek) => dayOfWeek % 7;

/**
 * Tries to give a smart date range that omits the month if both
 * dates are in the same month
 */
export const getDateRange = (startDate, endDate) => {
  if (startDate.isSame(endDate, 'month')) {
    return `${startDate.format('Do')} - ${endDate.format('Do MMM')}`;
  }
  return `${startDate.format('Do MMM')} - ${endDate.format('Do MMM')}`;
};

/**
 * Turn a string into a dayjs object, and format it to the desired string
 *
 * @param date
 * @param format
 * @param invalidValue
 * @returns {string}
 */
export const formatDate = (date, format = 'Do', invalidValue = 'Invalid date') => {
  const formattedDate = `${dayjs(date).format(format)}`;

  if (formattedDate === 'Invalid date') {
    return invalidValue;
  }
  return formattedDate;
};

export const minutesToTimeString = (minutes, format = 'h:mm a') =>
  dayjs().startOf('day').add(minutes, 'minutes').format(format);

/**
 * Gets the amount of times each appears between two dayjs dates
 */
export const getDayOccurrences = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const daysBetween = end.diff(start, 'days');
  const weeksBetween = end.diff(start, 'weeks');

  // Start with how many weeks there are in the period.
  // For every week, each day must appear once
  const occurrencesPerDay = {
    1: weeksBetween, // Monday
    2: weeksBetween,
    3: weeksBetween,
    4: weeksBetween,
    5: weeksBetween,
    6: weeksBetween,
    7: weeksBetween, // Sunday
  };

  // If this fit cleanly into the period, we're done
  if (daysBetween % 7 === 0) {
    return occurrencesPerDay;
  }

  // Get the amount of additional days.
  // e.g. if the range started on a tuesday and ended on a thursday, the additional days
  // are tuesday, wednesday, and thursday, because that's where the overlap is
  let currentDay = start.day();
  for (let i = 0; i <= daysBetween % 7; i += 1) {
    // Increment that day
    occurrencesPerDay[currentDay] += 1;

    // Go to the next day, looping the week
    currentDay = (currentDay % 7) + 1;
  }

  return occurrencesPerDay;
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

/**
 * Return only the objects in the array that match the value (e.g. given ID)
 * Can also pass an array as the value and it'll filter to
 * values which are present in the array
 *
 * Can pass through an invert flag so it returns values that don't match instead
 */
export const filterByProperty = (array, value, property = 'objectId', invert = false) => {
  if (isEmpty(array)) {
    return [];
  }

  if (Array.isArray(value)) {
    return array?.filter((item) =>
      !invert ? value.includes(item?.[property]) : !value.includes(item?.[property]),
    );
  }

  return array?.filter((item) =>
    !invert ? item?.[property] === value : item?.[property] !== value,
  );
};

/**
 * Return only the values in the array that match the value
 * Can also pass an array as the value and it'll filter to
 * values which are present in the array
 *
 * Can pass through an invert flag so it returns values that don't match instead
 */
export const filter = (array, value, invert = false) => {
  if (isEmpty(array)) {
    return [];
  }

  if (Array.isArray(value)) {
    return array?.filter((item) => (!invert ? value.includes(item) : !value.includes(item)));
  }

  return array?.filter((item) => (!invert ? item === value : item !== value));
};

/**
 * Generate a random string of characters of length characters long
 * "borrowed" from https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 */
export const generateRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/** Pulls out specific keys from an object and returns only them in a new object
 * If a list is provided, goes recursive on every object in list
 */
export const only = (object, keys) => {
  if (Array.isArray(object)) {
    return object.map((item) => only(item, keys));
  }

  const newObject = {};

  if (isEmpty(keys)) {
    return {};
  }

  keys?.forEach((key) => {
    newObject[key] = object?.[key];
  });

  return newObject;
};

/**
 * Flatten an array of objects into just the values of a specific key
 */
export const flatten = (arr, key = 'id') => {
  if (!arr?.length) {
    return [];
  }

  return arr?.map((item) => item?.[key]);
};

export const revertObscureString = (string) => {
  if (!string) {
    return '';
  }

  let s = atob(string);
  s = atob(s);
  s = s.slice(-3, s.length) + s.slice(0, -3);
  s = atob(s);
  s = s.slice(2, -6);
  s = atob(s);
  return s;
};

/**
 * Returns null if the value is negative, otherwise returns the value
 * The backend tends to use -1 for null integers. This converts those to null
 */
export const nullIfNegative = (value) => (value < 0 ? null : value);

/**
 * Returns the full app version (major.minor.patch)
 */
export const getVersion = () => {
  return packageInfo.version;
};

/**
 * Returns the app version for use in API calls
 */
export const getAppVersion = () => {
  return `web_${packageInfo.version.substring(0, packageInfo.version.lastIndexOf('.'))}`;
};

export const isObject = (obj) => {
  return typeof obj === 'object' && obj !== null;
};
/**
 * https://dmitripavlutin.com/how-to-compare-objects-in-javascript/#4-deep-equality
 * @param {*} object1
 * @param {*} object2
 * @returns
 */
export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  return keys1.every((key) => {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
    return true;
  });
};

/**
 * Remove all null fields from the object
 * Works recursively? Yeah why not
 */
export const removeNullFields = (object) => {
  // If it's an array, recurse through all its items
  if (Array.isArray(object)) {
    return object.map((item) => removeNullFields(item));
  }

  const newObject = {};

  Object.keys(object).forEach((key) => {
    if (typeof object[key] !== 'string' && isObject(object[key])) {
      newObject[key] = removeNullFields(object[key]);
    } else if (!isVoid(object[key])) {
      newObject[key] = object[key];
    }
  });

  return newObject;
};

export const removeDuplicates = (array) => {
  return [...new Set(array)];
};

/**
 * Returns the item wrapped in an array
 * If the item is already an array, it just returns the item
 * @param item
 * @returns {*[]|*}
 */
export const getAsArray = (item) => {
  if (!Array.isArray(item)) {
    return [item];
  }

  return item;
};

/**
 * Remove an item, or list of items, from the array
 * @param arr
 * @param item
 * @returns {*[]}
 */
export const removeFromArray = (arr, item) => {
  const itemList = getAsArray(item);
  const newItems = [...arr];

  itemList.forEach((itemToCheck) => {
    const index = newItems.indexOf(itemToCheck);
    newItems.splice(index, 1);
  });

  return newItems;
};

/*
 * Safely parses a json string
 * @param {string} json
 * @returns {{}|undefined}
 */
export const safeParse = (json) => {
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch {
    devLog('warning', 'safeParse', `unable to parse ${json}`);
  }
  return parsed; // Could be undefined
};

export const filterUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const unique = (arr) => {
  return arr.filter(filterUnique);
};

export const getVersionText = () => {
  switch (import.meta.env.VITE_MODE) {
    case 'development':
      return `development v${getVersion()}`;
    case 'demo':
      return `demo v${getVersion()}`;
    default:
      return `v${getVersion()}`;
  }
};

/**
 * Immutable deep merge.
 * Adapted from https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 */
export const mergeDeep = (target, source, depth = 0, maxDepth = 20) => {
  const output = { ...target };
  if (depth < maxDepth && isObject(target) && isObject(source)) {
    // To prevent infinite recursion
    const newDepth = depth + 1;
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        // If we have a matching key, merge them
        if (!(key in target)) {
          // Key does not exist in target, so add it as new
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key], newDepth, maxDepth);
        }
      } else {
        // Not an object, so replace
        // TODO merging two lists should try to add list2 to list1 instead of replacing
        Object.assign(output, { [key]: source[key] });
      }
    });
  }

  return output;
};

/**
 * Modulo, because javascript's built in one sucks at
 * negative numbers
 * https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
 */
export const mod = (n, m) => {
  return ((n % m) + m) % m;
};

export const isStaffUser = (user) => {
  return user === 'staff' || user?.userInfo?.userType === 'staff';
};

/**
 * Whether this user can edit everything, e.g. delete sessions
 * We currently restrict restaurants from doing this, but account managers can,
 * and it should be allowed in the demo env
 */
export const canEditAll = (user) => {
  return isStaffUser(user) || import.meta.env.VITE_MODE === 'demo';
};

/**
 * Generates a URL friendly slug from a string
 * @param {string} string
 * @returns
 */
export const generateSlug = (string) => {
  if (typeof string !== 'string') {
    return '';
  }

  return string
    ?.trim() // remove whitespace at the start or end
    .toLowerCase() // make all characters lowercase
    .replace(/ +/g, '-') // replace whitespace with `-`
    .replace(/(-)\1+/g, '$1') // remove adjacent `-` characters
    .replace(/[^a-zA-Z0-9-_]/g, '') // remove unsafe url characters
    .slice(0, 30); // slugs must be no greater than 30 characters
};

export const findObjectIndexByProperty = (objects, id, property = 'objectId') =>
  objects?.findIndex((obj) => obj?.[property] === id);

/**
 * Filter an array of objects to only one of each object that matches the property.
 * e.g. if two objects have the same id, only the first will be kept
 */
export const uniqueObjectsByProperty = (arr, key = 'id') => {
  // const ids = flatten(arr, key);
  return arr.reduce((accum, item) => {
    const itemIsInArray = findObjectIndexByProperty(accum, item?.[key], key) > -1;

    // Don't add
    if (itemIsInArray) {
      return accum;
    }

    return [...accum, item];
  }, []);
};

/**
 * Group a list of objects by the property of that object.
 * e.g. group by an id
 *
 * TODO move from bookings library to component library and use that
 *
 * @param objects
 * @param groupBy
 * @param allowMultiple
 * @returns {*}
 */
export const groupObjects = (objects, groupBy, allowMultiple = true) =>
  objects?.reduce((accum, object) => {
    const updated = { ...accum };
    if (allowMultiple) {
      if (Object.hasOwnProperty.call(updated, object[groupBy])) {
        updated[object[groupBy]].push(object);
      } else {
        updated[object[groupBy]] = [object];
      }
    } else {
      updated[object[groupBy]] = object;
    }

    return updated;
  }, {});

/**
 * Group a list of objects by that property
 * TODO this name is not an accurate description of what it does. You may want groupObjects instead
 */
export const groupByProperty = (arr, property, propertyName = 'name') => {
  let groupedObjects = []; // array rather than object, to preserve order

  arr?.forEach((item) => {
    const index = findObjectIndexByProperty(groupedObjects, item?.[property], propertyName);

    // Add new if doesn't exist
    if (index < 0) {
      const newItem = { [propertyName]: item?.[property], items: [item] };
      groupedObjects = [...groupedObjects, newItem];
    } else {
      const newItems = [...groupedObjects?.[index]?.items, item];
      groupedObjects[index] = { ...groupedObjects?.[index], items: newItems };
    }
  });

  return groupedObjects;
};

/**
 * Get the restaurant's opening hours... by day
 * @param dayName
 * @param restaurantHours
 * @param capToMidnight
 * @returns {unknown[]}
 */
export const getOpenHoursByDay = (dayName, restaurantHours, capToMidnight = true) => {
  const hoursByDay = JSON.parse(restaurantHours);

  const hours = Object.keys(hoursByDay)
    .map((day, index) => (day.substr(0, 3) === dayName ? index : null))
    .filter((e) => e !== null)
    .map((index) => Object.values(hoursByDay)[index]);

  if (capToMidnight && hours[0] > hours[1]) {
    hours[1] = 1439;
  }

  return hours;
};

export const restaurantIsOpenForDay = (day, openHoursByDay) => {
  const currentDay = day.format('ddd').toLowerCase();
  const todayHours = openHoursByDay(currentDay, false);

  return !todayHours.some((el) => el === -1);
};

/**
 * Generates an array of 0...n
 * @param length
 * @returns {number[]}
 */
export const numberArray = (length) => {
  return [...Array(length).keys()];
};

/**
 * Generate a range of numbers between two values
 * @param start
 * @param end
 * @param interval how much between each number
 * @param inclusive Whether the end number should be included
 * @returns {[]}
 */
export const numberRange = (start, end, interval = 1, inclusive = false) => {
  if (isEmpty(start) || isEmpty(end)) {
    return [];
  }

  if (start === end) {
    return [start];
  }

  const amountInRange = Math.ceil(safeDivision(end - start, interval)) + (inclusive ? 1 : 0);

  if (amountInRange <= 0) {
    return [];
  }

  return Array.from(Array(amountInRange).keys()).map((num) => num * interval + start);
};

/**
 * Calculate the nearest unit that should be used for rounding purposes. 10's, 100's 1000's, etc.
 *
 * @param value
 */
export const calculateNearestUnit = (value) => {
  if (value > 2000) {
    return 1000;
  }

  if (value > 200) {
    return 100;
  }

  if (value > 20) {
    return 10;
  }

  return 1;
};

/**
 * Safely convert a string to lowercase
 * Returns the original value if not a string
 * @param string
 * @returns {*}
 */
export const lowerCase = (string) => {
  if (typeof string !== 'string') {
    return string;
  }

  return string.toLowerCase();
};
