// TODO experimenting with having models to represent the different objects.
// This will hopefully allow re-usability of data (such as proptypes) and logic (such as data mapping)

import dayjs from 'dayjs';
// Export prop types for easy import in components that use this data
import PropTypes from 'prop-types';
import { getDayOfWeek, formatIntFromTime, eatClubDayOfWeekToDayjs } from '../../utils/helpers';

// Export prop types for easy import in components that use this data
export const dealPropTypes = PropTypes.shape({
  objectId: PropTypes.string,
  oppoId: PropTypes.string,
});

export const dealsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(dealPropTypes),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // The reducers are inconsistent
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  fetching: PropTypes.bool,
  shouldFetch: PropTypes.bool,
});

// TODO maybe value lists should be hydrated in the reducer?
export const statusValues = [
  { label: 'Live Now', value: 'active' },
  { label: 'Complete', value: 'complete' },
  { label: 'Live Later', value: 'live_later' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Upcoming', value: 'upcoming' },
];

export const statuses = () => statusValues.map((statusValue) => statusValue?.value);

export const getStatus = (a) => statuses[a?.status] || null;

export const calculateStatusForDeal = (deal, offerDate) => {
  const nowAsDayjs = dayjs();

  const currentTimeAsInt = formatIntFromTime(nowAsDayjs);
  const currentDayOfWeek = getDayOfWeek(nowAsDayjs.startOf('day'));

  // Disabled status shows above all else
  // TODO handle disabledUntilDate
  // Note: Disabled uses the date on the offer, so we can show if an offer later in the week is already disabled
  if (deal?.disabledForDate === dayjs(offerDate).format('YYYY-MM-DD')) {
    return 'disabled';
  }

  const dealForToday = currentDayOfWeek === eatClubDayOfWeekToDayjs(deal?.dayOfWeek);

  if (
    (deal?.endDate && dayjs(deal.endDate) < nowAsDayjs.startOf('day')) ||
    (dealForToday && deal?.endTime <= currentTimeAsInt)
  ) {
    // TODO check with allen if end date is inclusive
    return 'complete';
  }

  // Get active
  if (dealForToday && deal.startTime <= currentTimeAsInt) {
    return 'active';
  }

  // TODO get cancelled

  // Otherwise it must be active?
  return 'live_later';
};

export const calculateStatusForOffer = (offer) => {
  // If all deals are disabled for this offer, mark the offer as disabled
  const dealStatuses = offer?.deals?.map((deal) => calculateStatusForDeal(deal));
  if (
    dealStatuses?.filter((dealStatus) => dealStatus === 'disabled').length === dealStatuses?.length
  ) {
    return 'disabled';
  }

  const nowAsDayjs = dayjs();

  const currentTimeAsInt = formatIntFromTime(nowAsDayjs);
  const currentDayOfWeek = getDayOfWeek(nowAsDayjs.startOf('day'));

  const offerForToday = currentDayOfWeek === eatClubDayOfWeekToDayjs(offer?.dayOfWeek);

  if (
    (offer?.endDate && dayjs(offer.endDate) < nowAsDayjs.startOf('day')) ||
    (offerForToday && offer?.endTime <= currentTimeAsInt)
  ) {
    // TODO check with allen if end date is inclusive
    return 'complete';
  }

  // Get active
  if (offerForToday && offer.startTime <= currentTimeAsInt) {
    return 'active';
  }

  // TODO get cancelled

  // Otherwise it must be active?
  return 'live_later';
};
