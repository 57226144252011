import { Box } from '@eatclub-apps/ec-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './BannerStyles';

export const Banner = ({ children, action }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box className={classes.iconContainer}>
          <svg
            width='4'
            height='24'
            viewBox='0 0 4 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.958 4.266V0.292998H3.336V4.266L3.191 16.417H1.074L0.958 4.266ZM0.436 21.55C0.436 20.622 1.19 19.868 2.147 19.868C3.075 19.868 3.858 20.622 3.858 21.55C3.858 22.507 3.075 23.261 2.147 23.261C1.19 23.261 0.436 22.507 0.436 21.55Z'
              fill='#313131'
            />
          </svg>
        </Box>
        <Box>{children}</Box>
      </Box>

      {action}
    </Box>
  );
};

Banner.propTypes = {
  children: PropTypes.element.isRequired,
  action: PropTypes.element.isRequired,
};
