import { devLog } from './devLog';

/**
 * Same as Promise.all(items.map(item => task(item))), but it waits for
 * the first {batchSize} promises to finish before starting the next batch.
 * Taken from https://stackoverflow.com/questions/37213316/execute-batch-of-promises-in-series-once-promise-all-is-done-go-to-the-next-bat
 *
 * @template A
 * @template B
 * @param promises
 * @param {int} batchSize
 * @returns {Promise<B[]>}
 */
export const promiseAllInBatches = async (promises, batchSize = 5) => {
  let position = 0;
  let results = [];
  while (position < promises.length) {
    const itemsForBatch = promises.slice(position, position + batchSize);
    // eslint-disable-next-line no-await-in-loop
    results = [...results, ...(await Promise.allSettled(itemsForBatch.map((item) => item())))];
    position += batchSize;
  }
  return results;
};

/**
 * Creates and runs promises in a batch
 * Takes a list of "actions", an object with two properties, request and handleResponse.
 * This means to use it you just need to pass in two functions and not
 * worry about promises, resolvers, etc.
 * @param actions
 * @param batchSize
 * @returns {Promise<*[]>}
 */
export const createBatchedPromises = async (actions, batchSize = 5) => {
  const promises = actions.map((action) => {
    return () =>
      new Promise((resolve, reject) => {
        action.request().then((response) => {
          resolve(action.handleResponse(response));
        });
      });
  });

  const results = await promiseAllInBatches(promises, batchSize);

  return results.map((result) => result?.value);
};

/**
 * Save an item to session storage
 * @param {string} name
 * @param {*} state
 * @returns
 */
export const saveToSessionStorage = (name, state) => {
  try {
    const serialState = JSON.stringify(state);
    sessionStorage.setItem(name, serialState);
  } catch (err) {
    devLog('error', 'saveState', err);
  }
};

/**
 * Load an item in session storage
 * @param {string} name
 * @returns
 */
export const loadFromSessionStorage = (name) => {
  try {
    const serialState = sessionStorage.getItem(name);
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    devLog('error', 'loadState', err);
    return undefined;
  }
};
