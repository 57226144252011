import * as type from '../actions/types';
import { updateItemInArray } from './reducerHelper';
import { RegexDiscountType } from '../constants';

const initialState = {
  data: [],
  fetching: true,
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
  filter: 'all', // Used to filter table
};

const removeStunts = (data) => {
  const withoutStunts = data.filter((reso) => {
    // NOTE: include whitelabel orders (they don't have discounts)
    if (reso.discount === null) {
      return true;
    }

    const hasStunt = RegexDiscountType.test(reso.discount);
    return hasStunt;
  });
  return withoutStunts;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_TRANSACTION_BOOKINGS_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_TRANSACTION_BOOKINGS_SUCCESS: {
      const { bookings } = action.payload;

      const updatedBookings = removeStunts(bookings);

      return {
        ...state,
        data: updatedBookings,
        fetching: false,
        success: true,
        shouldFetch: false,
      };
    }
    case type.FETCH_TRANSACTION_BOOKINGS_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };

    // case type.SET_PLATFORM:
    //   return {
    //     ...state,
    //     data: [],
    //     fetching: false,
    //     error: false,
    //     errorMessage: '',
    //     success: false,
    //     shouldFetch: true,
    //   };

    case type.SET_TRANSACTIONS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };

    case type.FLAG_ORDER_SUCCESS: {
      const { updatedOrder } = action.payload;

      const orders = updateItemInArray(state.data, 'objectId', updatedOrder.objectId, (item) => ({
        ...item,
        issueStatus: updatedOrder.issueStatus,
        partySize: updatedOrder.partySize,
      }));

      return {
        ...state,
        data: orders,
      };
    }

    case type.SUB_NEW_BOOKING_SUCCESS: {
      const { newBooking } = action.payload;

      if (!newBooking.platform) {
        return state;
      }

      const newBookingIndex = state.data.findIndex(
        (booking) => booking.objectId === newBooking.objectId,
      );

      // NOTE: skip if booking already exists
      if (newBookingIndex > -1) {
        return state;
      }

      const updatedBookings = [{ ...newBooking, _new: true }, ...state.data];

      return {
        ...state,
        data: updatedBookings,
      };
    }

    case type.REFUND_SUCCESS: {
      const { objectId, refundAmount, refundReason, refundType } = action.payload;

      const updatedTransactions = updateItemInArray(state.data, 'orderId', objectId, (item) => ({
        ...item,
        refundAmount,
        refundReason,
        refundType,
      }));

      return {
        ...state,
        data: updatedTransactions,
      };
    }

    // NOTE: update bookings if restaurant or date changes
    case type.SET_ACTIVE_RESTAURANT:
    case type.SET_SEARCH_DATES:
    case type.SET_SEARCH_DATES_DAYJS:
    case type.SET_DATE_RANGE:
      return {
        ...state,
        shouldFetch: true,
        fetching: false,
        success: false,
      };

    default:
      return state;
  }
};
