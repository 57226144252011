import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import ComparePercent from '../../../components/analytics/comparePercent/ComparePercent';
import { roundToDecimal } from '../../../utils/helpers';
import useStyles from './ChangePillStyles';

const ChangePill = ({
  label = null,
  increase = '0%',
  change = 'gain',
  loading,
  variant = 'filled',
  showChange = true,
}) => {
  const classes = useStyles({ loading, variant, change });

  return (
    <Spacer className={classes.pill}>
      {label && <Box className={classes.pillLabel}>{label}</Box>}
      {showChange && (
        <Box className={classes.pillContent}>
          <ComparePercent percent={roundToDecimal(increase)} change={change} />
        </Box>
      )}
    </Spacer>
  );
};

ChangePill.propTypes = {
  label: PropTypes.string,
  increase: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  showChange: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.loading?.loadingIds.length > 0,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePill);
