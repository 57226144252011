import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  CenteredContent,
  InlineTextField,
  TextArea,
  Dropdown,
} from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import { dealPropTypes } from '../../../data/models/Deal';
import { goalsPropTypes } from '../../../data/models/Goal';
import { FONT_WEIGHTS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, formatCurrency, getOfferTime } from '../../../utils/helpers';
import useStyles from './ModalStyles';
import ModalSectionHeader from './ModalSectionHeader';
import Bin from '../../../assets/bin.svg';
import { Modal } from '../../Modal';

const RequestDeleteOfferModal = ({ isOpen, offer, onClose, onSave, goals }) => {
  const classes = useStyles();

  const goalForOffer = goals?.data?.find((goal) => goal?.objectId === offer?.goalId);

  const [allOccurrences, setAllOccurrences] = useState(true);
  const [deleteOfferReason, setDeleteOfferReason] = useState(null);
  const [deleteOfferAdditionalReason, setDeleteOfferAdditionalReason] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [contactPhone, setContactPhone] = useState(null);

  const goalEndDate = dayjs(goalForOffer?.endDate, 'DD/MM/YYYY');
  const currentDate = dayjs().format('DD/MM/YYYY');
  const diffInWeeks = goalEndDate.diff(currentDate, 'weeks');

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: delete_offer_request');
    }
  }, [isOpen]);

  const confirm = () => {
    onSave(deleteOfferReason, deleteOfferAdditionalReason, contactName, contactPhone);
  };

  return (
    <Modal
      confirmLabel='Request a call'
      heading='Delete offer'
      headingStartIcon={<Bin height='24px' width='24px' />}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirm}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Box>
          <Box className={classes.inlineText}>
            <Box>
              <Box className={classes.discountLabel}>{offer?.discount}</Box>
            </Box>
            <CenteredContent>
              on {getDayFromInt(offer?.dayOfWeek)} {getOfferTime(offer)}
              {offer.recurring ? ', repeats weekly' : ''}
            </CenteredContent>
          </Box>
        </Box>
        <Box style={{ maxWidth: '580px' }}>
          This offer was set up by your account manager. Request a call and they’ll be in touch
          shortly to help you make any changes.
        </Box>
        {offer?.revenueExpected > 0 && (
          <Box>
            <ModalSectionHeader header='Impact' />

            <Box className={classes.impactRow}>
              <Box>Change in weekly revenue</Box>
              <Box className={classes.redLabel}>
                -{formatCurrency(offer?.revenueExpected, false)}
              </Box>
            </Box>
            {!goalForOffer && allOccurrences && (
              <Box className={classes.impactRow}>
                <Box>Change in 12 month revenue</Box>
                <Box className={classes.redLabel}>
                  -{formatCurrency(offer?.revenueMonthlyExpected * 12, false)}
                </Box>
              </Box>
            )}
            {goalForOffer && (
              <>
                <Box className={classes.impactRow}>
                  <Box>Goal period change</Box>
                  <Box className={classes.redLabel}>
                    -
                    {formatCurrency(
                      offer?.revenueExpected * (allOccurrences ? diffInWeeks : 1),
                      false,
                    )}
                  </Box>
                </Box>
                <Box className={classes.impactRow}>
                  <Box>Adjusted revenue goal</Box>
                  <Box style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}>
                    {formatCurrency(
                      goalForOffer?.targetRevenue -
                        offer?.revenueExpected * (allOccurrences ? diffInWeeks : 1),
                      false,
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}
        <Box>
          <ModalSectionHeader header='Let us know why' />

          <Box mt='20px' display='flex' flexDirection='column' style={{ gap: '15px' }}>
            <Dropdown
              allowDeselect
              items={[
                { label: 'Restaurant became busy', value: 'busy' },
                { label: 'Staffing issue', value: 'staffIssue' },
                { label: "Time isn't suitable", value: 'badTime' },
                { label: 'Offer percentage too high', value: 'offerTooHigh' },
                { label: 'Other', value: 'other' },
              ]}
              onSelect={setDeleteOfferReason}
              placeholder='Select a reason for deleting the offer'
              value={deleteOfferReason}
            />
            <TextArea
              placeholder='Additional Reason?'
              resizable={false}
              value={deleteOfferAdditionalReason}
              onChange={setDeleteOfferAdditionalReason}
            />
          </Box>
        </Box>
        <Box>
          <ModalSectionHeader header='Contact details' />

          <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
            <Box>Name</Box>
            <InlineTextField
              value={contactName}
              onChange={setContactName}
              style={{
                textFieldInner: { padding: '5px 10px' },
                input: { width: '100%' },
              }}
              placeholder='Empty (optional)'
              maxCharacters={24}
            />
          </Box>

          <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
            <Box>Phone</Box>
            <InlineTextField
              value={contactPhone}
              onChange={setContactPhone}
              style={{
                textFieldInner: { padding: '5px 10px' },
                input: { width: '100%' },
              }}
              placeholder='Empty (optional)'
              maxCharacters={24}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

RequestDeleteOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  goals: goalsPropTypes.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  goals: state.goals,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestDeleteOfferModal);
