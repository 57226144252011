import { makeStyles } from '@mui/styles';

// Note: A lot of the paddings are to precisely line up the graph with the table. Be careful changing them
const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      overflowX: 'hidden',
      zIndex: 10,
    },
    content: {
      overflowX: 'auto',
    },
  },
  { name: 'HorizontalScroll' },
);

export default useStyles;
