import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { Box, Button, Divider } from '@eatclub-apps/ec-component-library';
import IconCirclePlusSVG from '../../../assets/icon_circle_plus.svg';
import CheckSVG from '../../../assets/check.svg';
import useStyles from './BookingsLandingStyles';
import { COLORS } from '../../../EatClubTheme';

const PlanDetailsCard = ({
  plan,
  price,
  topItems,
  bottomItems,
  onRequest,
  highlightTop = false,
  ctaLabel,
  loading = false,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.planCardContainer}>
      <Box className={classes.planCardHeaderContainer}>
        <Box className={classes.planCardHeader}>{plan}</Box>
        <Box className={classes.planCardHeaderPrice}>{loading ? <Skeleton /> : price}</Box>
      </Box>
      <Box className={classes.planCardContentContainer}>
        <Box
          className={classes.planCardContentItemContainer}
          style={{ background: highlightTop && '#F0F4F3', borderRadius: '8px' }}
        >
          {topItems.map((item) => (
            <Box key={item} className={classes.planCardContentItem}>
              <CheckSVG style={{ flexShrink: 0 }} color={COLORS.OLIVE_2} />
              <Box className={classes.planCardContentItemText}>{item}</Box>
            </Box>
          ))}
        </Box>

        <Box style={{ position: 'relative', margin: '30px 0' }}>
          <Divider />
          <IconCirclePlusSVG className={classes.planCardPlusButton} />
        </Box>

        <Box className={classes.planCardContentItemContainer}>
          {bottomItems.map((item) => (
            <Box key={item} className={classes.planCardContentItem}>
              <CheckSVG style={{ flexShrink: 0 }} color={COLORS.OLIVE_2} />
              <Box className={classes.planCardContentItemText}>{item}</Box>
            </Box>
          ))}
        </Box>

        <Button onClick={onRequest} disabled={disabled} fullWidth style={{ marginTop: '32px' }}>
          {ctaLabel}
        </Button>
        <Box className={classes.smallText}>For memberships on $49 per month</Box>
      </Box>
    </Box>
  );
};

PlanDetailsCard.propTypes = {
  plan: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  topItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  bottomItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRequest: PropTypes.func.isRequired,
  highlightTop: PropTypes.bool,
  ctaLabel: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PlanDetailsCard;
