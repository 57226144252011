import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { refundEntireOrder, refundAmount, refundItems } from '../graphql/mutations';
import { devLog } from '../utils';

export const refundFullAction = (orderId, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.REFUND_PENDING,
  });

  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'REFUND',
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: refundEntireOrder,
          variables: {
            orderId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'refund full', response.data.refundEntireOrder);

      dispatch({
        type: type.REFUND_SUCCESS,
        payload: response.data.refundEntireOrder,
      });
    } catch (error) {
      devLog('error', 'refund full', error);

      dispatch({
        type: type.REFUND_FAILURE,
        payload: `Unable to refund full order: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'REFUND',
      });
    }
  })();
};

export const refundAmountAction = (orderId, amount, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.REFUND_PENDING,
  });

  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'REFUND',
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: refundAmount,
          variables: {
            orderId,
            amount,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'refund amount', response.data.refundAmount);

      dispatch({
        type: type.REFUND_SUCCESS,
        payload: response.data.refundAmount,
      });
    } catch (error) {
      devLog('error', 'refund amount', error);

      dispatch({
        type: type.REFUND_FAILURE,
        payload: `Unable to refund amount: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'REFUND',
      });
    }
  })();
};

export const refundItemsAction = (orderId, itemIds, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.REFUND_PENDING,
  });

  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'REFUND',
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: refundItems,
          variables: {
            orderId,
            itemIds,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'refund items', response.data.refundItems);

      dispatch({
        type: type.REFUND_SUCCESS,
        payload: response.data.refundItems,
      });
    } catch (error) {
      devLog('error', 'refund items', error);

      dispatch({
        type: type.REFUND_FAILURE,
        payload: `Unable to refund items: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'REFUND',
      });
    }
  })();
};

export const clearRefundAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_REFUND,
  });
};
