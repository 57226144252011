import { makeStyles } from '@mui/styles';

// Note: A lot of the paddings are to precisely line up the graph with the table. Be careful changing them
const useStyles = makeStyles(
  (theme) => ({
    // for mobile scrolling
    wrapper: {
      // width: '100%',
      // overflowX: 'hidden',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '32px',
      padding: '20px',
      gap: '16px',
      // overflowX: 'hidden',

      backgroundColor: theme.colors.revenueTimeChartBackground,
      border: `4px solid ${theme.colors.revenueTimeChartBackground}`,

      [theme.breakpoints.down('sm')]: {
        width: '650px',
        marginLeft: '20px',
        marginRight: '20px',
        // overflowX: 'auto',
      },
    },
    graphContainer: {
      // paddingTop: '16px',
      paddingRight: '10px',
      height: '204px',
      '& .recharts-surface': {
        overflow: 'visible',
      },
      // margin: '20px -10px',

      // [theme.breakpoints.down('sm')]: {
      //   width: '600px',
      //   paddingRight: '0',
      // },
    },
    graphHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '16px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    title: {
      fontSize: '22px',
      color: '#000000',
      opacity: 0.75, // Lets it blend in to the yellow and looks much nicer than just a grey
      fontWeight: theme.fontWeights.medium,
    },
    performanceDayContainer: {
      // display: 'flex',
      margin: '0 -24px', // To offset padding from the card
      paddingTop: '12px',
      // flexGrow: 0,
      // flexShrink: 1,
      borderTop: `1px solid ${theme.colors.brownChartDivider}`,
      overflowX: 'auto',

      // [theme.breakpoints.down('xs')]: {
      //   overflowX: 'auto',
      // },
    },
    performanceDayContainerInner: {
      display: 'flex',
      fontSize: '15px',

      // minWidth: '600px',
      overflowX: 'auto',
      // paddingLeft: `${leftColumnWidth}px`,
      // paddingRight: '6px',

      [theme.breakpoints.down('xs')]: {
        overflowX: 'auto',
      },
    },
    performanceDay: {
      width: '100%',
      // maxWidth: '60px',
      overflowX: 'hidden',
      flexGrow: 0,
      flexShrink: 1,
      alignSelf: 'start',
      textAlign: 'center',
      padding: '8px 0',
      // border: '1px solid transparent',
      borderRadius: '8px',

      // Interactivity
      // cursor: 'pointer',
      // userSelect: 'none',
      // '&:hover': {
      //   border: '1px solid black',
      //   backgroundColor: '#FFFFFF50 !important',
      // },
    },
    dayOfferInfo: {
      display: 'flex',
      borderTop: `1px solid ${theme.colors.brownChartDivider}`,
      paddingTop: '20px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dayName: {
      fontWeight: theme.fontWeights.medium,
      whiteSpace: 'nowrap',
    },

    // Below graph
    emptyStateContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '57px auto 100px auto',
      gap: '15px',
      fontSize: '16px',
      alignItems: 'center',
    },
    emptyStateHeading: {
      fontWeight: theme.fontWeights.medium,
    },

    daySummaryTable: {
      width: '100%',
      display: 'grid',
      // paddingRight: '6px',
      gridTemplateColumns: `128px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 40px`,
      textAlign: 'center',
      color: theme.colors.charcoal,
      // TODO left align left column and right align right column
    },
    tableItem: {
      // display: 'flex',
      height: '70px',
      minWidth: '70px',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: `1px solid ${theme.colors.brownChartDivider}`,
      padding: '0 8px 0 8px',
      zIndex: 1,
      // pointerEvents: 'none',
      textAlign: 'right',
    },
    tableItemLabel: {
      justifyContent: 'flex-start',
      padding: '0 0 0 16px',
      fontWeight: theme.fontWeights.medium,
      textAlign: 'left',
      width: '133px',
    },
    tableButton: {
      padding: '0 12px 0 6px',
      width: '40px',
      minWidth: '40px',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
    tableRow: {
      // position: 'absolute',
      height: '70px',
      // width: '100%',
      zIndex: 0,
      borderRadius: '10px',
      color: theme.colors.charcoal,
    },
    tableRowInteractive: {
      cursor: 'pointer',
      userSelect: 'none',
    },
    tableRowInteractiveHover: {
      '&:hover': {
        backgroundColor: '#F9F7EC',
      },
    },
    subTableContainer: {
      fontWeight: 500,
      padding: '10px',
      borderTop: `1px solid ${theme.colors.brownChartDivider}`,
    },
    subTable: {
      width: '100%',
      borderRadius: '30px',
      overflow: 'hidden',
      backgroundColor: '#F9F7EC',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      padding: '20px 0',
    },
    subTableRow: {
      zIndex: 0,
      color: theme.colors.charcoal,
      fontWeight: theme.fontWeights.regular,
      overflow: 'hidden',

      '&:nth-child(2) > $subTableItem': {
        borderTop: 'none',
        height: '60px',
        maxHeight: '60px',
      },

      // '&:nth-child(3) > $subTableItem': {
      //   borderTop: 'none',
      //   height: '60px',
      //   maxHeight: '60px',
      // },

      '&:nth-last-child(2) > $subTableItem': {
        height: '60px',
      },
    },
    subTableItem: {
      height: '70px',
      padding: '0 8px 0 8px',
      zIndex: 1,
      textAlign: 'right',
      borderLeft: `1px solid #B6B5AE44`, // TODO add to theme
      borderTop: `1px solid #B6B5AE44`,
      minWidth: 'calc(100% - 163px)', // minus 123px for first column and 40px for arrow column
      alignItems: 'center',
      justifyContent: 'center',

      '&:nth-child(2)': {
        borderLeft: 'none',
      },
    },
    subTableItemLabel: {
      padding: '0 0 0 12px',
      textAlign: 'left',
      width: '123px',
      border: `none`,
    },
    subTableItemLast: {
      padding: '0 10px 0 0',
      width: '30px',
      border: 'none',
    },
    subTableLabel: {
      fontSize: '14px',
    },
    subTableSubLabel: {
      fontSize: '12px',
      color: theme.colors.smoke,
    },
    emptyCell: {
      height: '4px',
      width: '18px',
      backgroundColor: theme.colors.revenueTimeBarBackground,
    },
    rowValueContainer: {
      width: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    sessionSubLabel: {
      fontSize: '12px',
      color: '#000000F0', // transparency approximating #313131
      overflowX: 'visible',
      whiteSpace: 'nowrap',
      fontWeight: theme.fontWeights.regular,
    },
  }),
  { name: 'RevenueByTime' },
);

export default useStyles;
