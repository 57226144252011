import { API } from 'aws-amplify';
import { confirmBooking } from '../graphql/mutations';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { devLog } from '../utils';

export const confirmBookingAction = (bookingId, restId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'CONFIRM_BOOKING',
  });
  dispatch({
    type: type.CONFIRM_BOOKING_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: confirmBooking,
          variables: { bookingId },
        },
        {
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error?.errors?.[0]?.message || error);
      });

      devLog('success', 'confirm booking', response.data);

      dispatch({
        type: type.CONFIRM_BOOKING_SUCCESS,
        payload: { restId, bookingId },
      });
    } catch (error) {
      devLog('error', 'confirm booking', error);

      dispatch({
        type: type.CONFIRM_BOOKING_FAILURE,
        payload: `${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'CONFIRM_BOOKING',
      });
    }
  })();
};

export const clearConfirmBookingAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_CONFIRM_BOOKING,
  });
};
