import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '24px',
      color: theme.colors.charcoalTransparent,
      fontSize: '16px',
      minWidth: '500px',
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        width: '550px',
        marginLeft: '20px',
        marginRight: '20px',
      },
    },
    header: {
      padding: '24px',
    },
    tableContainer: {
      padding: '8px 24px',
      overflowX: 'auto',
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',

      '& > thead': {
        textAlign: 'center',
        fontSize: '15px',

        '& > tr ': {
          height: '59px',

          '& th': {
            fontWeight: 500,
            padding: '8px 12px',
            whiteSpace: 'nowrap',
            textAlign: 'right',
          },

          '& th:first-child': {
            textAlign: 'left',
            paddingLeft: 0,
          },

          '& th:last-child': {
            paddingRight: '20px',
          },
        },
      },
      '& > tbody > tr': {
        borderTop: '1px solid #E9E9E9',
        height: '59px',

        '& td': {
          padding: '8px 12px',
        },

        '& td:first-child': {
          paddingLeft: 0,
        },

        '& td:last-child': {
          marginLeft: 'auto',
          paddingRight: '20px',
        },
      },
    },
    tableCell: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    revenue: {
      maxWidth: '90px',
    },
    pill: {},
  }),
  { name: 'MostPopularItemsTable' },
);

export default useStyles;
