/* eslint-disable */
import React, { useEffect, useState } from 'react';
// import PropTypes from "prop-types";
import { FormControlLabel, FormGroup, Checkbox, FormLabel, FormControl } from '@mui/material';
import Popover from '../../popover/Popover';

const DealValidFor = ({ newDeal, setNewDeal, mode }) => {
  const { validFor } = newDeal;

  useEffect(() => {
    // Enable the next Create Deal step

    if (
      !Object.values(newDeal.validFor.checks).some((el) => el !== false) ||
      newDeal.validFor.enabled === false ||
      newDeal.days.enabled === true ||
      newDeal.frequency.value !== 'recurring'
    ) {
      return;
    }

    setNewDeal({
      ...newDeal,
      days: { ...newDeal.days, enabled: true },
    });
  }, [newDeal, setNewDeal]);

  const handleChange = (event) => {
    setNewDeal({
      ...newDeal,
      validFor: {
        ...validFor,
        checks: {
          ...validFor.checks,
          [event.target.name]: event.target.checked,
        },
      },
      days: {
        ...newDeal.days,
        enabled: newDeal.frequency.value === 'recurring',
      },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  return (
    <>
      <FormControl
        variant='standard'
        disabled={mode === 'create'}
        onMouseEnter={mode === 'create' ? handlePopoverOpen : undefined}
        onMouseLeave={mode === 'create' ? handlePopoverClose : undefined}
      >
        <FormLabel component='legend'>Valid for:</FormLabel>
        <FormGroup row style={{ display: 'block' }}>
          {/* form group row bug, fix with display block */}
          <FormControlLabel
            labelPlacement='start'
            style={{ marginLeft: 0 }}
            control={
              <Checkbox checked={validFor.checks.dineIn} onChange={handleChange} name='dineIn' />
            }
            label='Dine In'
          />
          <FormControlLabel
            labelPlacement='start'
            control={
              <Checkbox
                checked={validFor.checks.takeAway}
                onChange={handleChange}
                name='takeAway'
              />
            }
            label='Takeaway'
          />
        </FormGroup>
      </FormControl>
      <Popover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        open={openPopover}
        text='Unable to edit in an existing deal'
        anchorOriginVertical='center'
        anchorOriginHorizontal='left'
        transformOriginHorizontal='left'
      />
    </>
  );
};

DealValidFor.propTypes = {};

export default DealValidFor;
