import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  slider: {
    '& .MuiSlider-rail': {
      opacity: '1',
      height: '30px',
      color: '#EEEAE3',
      borderRadius: '100px',
    },
    '& .MuiSlider-thumb': {
      borderRadius: '18px',
      boxShadow: '0px 4px 4px 0px #C7C7C740',
      color: '#ffffff',
      height: '48px',
      width: '60px',
    },
    '& .MuiSlider-track': {
      borderRadius: '100px',
      height: '30px',
    },
  },
}));

export default useStyles;
