import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#F5F2ED',
    height: '100%',
    margin: '20px',
  },
  sidebarNavigation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  list: {
    padding: 0,
    marginTop: '8px',
  },
  subItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  expandArrow: {
    color: theme.colors.BLACK,
    transitionDuration: '0.1s',
  },
  hideNavigationContainer: {
    padding: '12px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  divider: {
    marginTop: '8px',
    borderColor: '#E9E9E9',
  },
  versionNumber: {
    marginTop: '8px',
    // padding: '12px 20px',
    ...theme.eatclub.typography.body.sm,
    color: theme.colors.NIMBUS,
  },
  navigationLink: {
    padding: '6px 12px',
    height: '42px',
    borderRadius: '12px',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    transition: 'all 0.1s',
    lineHeight: '30px',
    marginTop: '4px',
    ...theme.eatclub.typography.body.lg,

    '&:hover': {
      backgroundColor: '#EEEAE3',
      // borderRadius: '12px',
      color: '#000000',
    },
  },
  staffAlert: {
    background: theme.colors.STAFF_BLUE,
    borderRadius: '8px',
    padding: '12px',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  flex: {
    display: 'inline-flex',
    // justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    gap: '12px',
  },
  newLabel: {
    color: theme.colors.black,
    textTransform: 'uppercase',
    backgroundColor: theme.colors.PROFIT_YELLOW_DARK,
    fontWeight: theme.fontWeights.medium,
    fontSize: '16px',
    padding: '0 8px',
    borderRadius: '4px',
    letterSpacing: 1.5,
  },
}));

export default useStyles;
