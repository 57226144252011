import React from 'react';
import PropTypes from 'prop-types';
import { getChange, getPercent } from '../../../data/models/Analytics';
import DataPoint from '../DataPoint/DataPoint';
import { formatNumber } from '../../../utils';

/**
 * @deprecated TODO remove
 */
const DealsRedeemed = ({ data }) => {
  const { dealsRedeemed, dealsRedeemedCompare } = data;

  const dealsRedeemedPercent = getPercent(dealsRedeemed, dealsRedeemedCompare);
  const dealsRedeemedChange = getChange(dealsRedeemed, dealsRedeemedCompare);

  const renderDealsRedeemed = (num) => {
    if (typeof num !== 'number') {
      return '-';
    }

    return formatNumber(num, 0);
  };

  return (
    <DataPoint
      change={dealsRedeemedChange}
      percent={dealsRedeemedPercent}
      title='Offers redeemed'
      value={renderDealsRedeemed(dealsRedeemed)}
    />
  );
};

DealsRedeemed.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default DealsRedeemed;
