import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTheme } from '@mui/material/styles';
import { apiCall } from '../../../actions/actions';
import { updateFoodCostPercentAction } from '../../../actions/restaurantAction';
import { restaurantsNetRevDatasetStats } from '../../../graphql/queries';
import { formatCurrency, roundToNearest, safeDivision } from '../../../utils/helpers';
import { getComparisonMessage, getTimePeriodMessage } from '../../../utils/insights/insightHelpers';
import { sortAlphaNumeric } from '../../../utils/listHelpers';
// import TextDropdown from './Dropdown/TextDropdown';
import MarketComparisons from './MarketComparisons/MarketComparisons';
import { PillarModalRowItemEditable } from './PillarModalRowItemEditable';
import useStyles, { getInsightTabColors, INSIGHT_TAB } from './PillarModalStyles';
import { PillarModalRowItem } from './PillarModalRowItem';
import { PillarModalSummary } from './PillarModalSummary';

const NetRevenuePillarModalContent = ({
  data,
  dates,
  setFilter,
  filter,
  activeRestaurant,
  dateRange,
  setDateRange,
  updateFoodCostPercent,
}) => {
  const tab = INSIGHT_TAB.NET_REVENUE;
  const classes = useStyles({ tab });
  const theme = useTheme();

  const [loadingData, setLoadingData] = useState(true);
  const [comparisonData, setComparisonData] = useState({});

  const [foodCost, setFoodCost] = useState(activeRestaurant.foodCostPercent ?? 30);

  const total = data.current.netRevenue;
  const foodCostAmount = (foodCost * data.current.totalGrossRevenue) / 100;
  const grossProfit = total - foodCostAmount;
  const grossProfitMargin = safeDivision(grossProfit * 100, data.current.totalGrossRevenue);

  const updateFoodCost = (newFoodCost) => {
    updateFoodCostPercent(newFoodCost);
    setFoodCost(newFoodCost);
  };

  const dateRangeOptions = [
    { label: 'Since all time', value: 'allTime' },
    { label: getTimePeriodMessage(dates.startDate, dates.endDate), value: 'dateRange' },
  ];

  // Fetch comparison data
  useEffect(() => {
    const fetchComparisonData = async () => {
      setLoadingData(true);

      const { startDate, endDate } = dates;
      const formatStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formatEndDate = dayjs(endDate).format('YYYY-MM-DD');

      const variables = {
        startDate: formatStartDate,
        endDate: formatEndDate,
        filters: filter !== 'all' ? [filter] : [],
      };
      const response = await apiCall(restaurantsNetRevDatasetStats, variables, true);
      const responseData = response.response.data.restaurantsNetRevDatasetStats;

      setLoadingData(false);
      setComparisonData({
        low: roundToNearest(responseData?.low ?? 0),
        mean: roundToNearest(responseData?.mean ?? 0),
        high: roundToNearest(responseData?.high ?? 0),
        dataset: [...(responseData?.dataset ?? [])].sort(sortAlphaNumeric),
      });
    };

    try {
      fetchComparisonData();
    } catch (e) {
      setLoadingData(false);
    }
  }, [dates, filter]);

  {
    /* This was the only way the offer total stayed accurate. Just deduces it from the other revenue and fees */
  }
  const offersGiven =
    data.current.totalGrossRevenue - data.current.netRevenue - data.current.fullFee;

  return (
    <>
      <Box className={classes.sectionContainer}>
        <PillarModalSummary
          summary='EatClub helps you turn quieter times into predictable revenue. We do this by bringing in those few extra takeaway orders or attracting more dine-in customers at those days and times you control. By leveraging the spare capacity in your kitchen and front of house staff, you maximise staff utilisation and therefore revenue and gross profits earned during those times. '
          maxCharacters={171}
        >
          <Box className={classes.sectionBreakdownContainer}>
            {/* <TextDropdown items={dateRangeOptions} value={dateRange} onSelect={setDateRange} /> */}

            <Box className={classes.sectionBreakdownRowItemsContainer}>
              <PillarModalRowItem
                label='Gross revenue (Before offer)'
                variant='outlined'
                value={formatCurrency(data.current.totalGrossRevenue, true, '$0')}
              />
              <PillarModalRowItem
                label='Offers given'
                variant='outlined'
                value={`(${formatCurrency(offersGiven, true, '$0')})`}
              />
              <PillarModalRowItem
                label={
                  <Box>
                    Less fees{' '}
                    <span style={{ fontSize: '10px', color: theme.colors.smoke }}>incl. GST</span>
                  </Box>
                }
                variant='outlined'
                value={`(${formatCurrency(data.current.fullFee, true, '$0')})`}
              />
              <PillarModalRowItem
                label='Net Revenue'
                value={formatCurrency(data.current.netRevenue, true, '$0')}
                variant='filled'
                color={tab}
              />
            </Box>
          </Box>
        </PillarModalSummary>

        <Divider className={classes.divider} />

        <PillarModalSummary
          title='Healthy margins that boost profitability'
          summary='The only other cost we consider to evaluate your gross profit margin is your food cost.

This is because EatClub offers are run at times when you’re looking to fill tables that might otherwise go empty, or optimise your kitchen staff to pump out a few extra orders.

All other costs, such as utilities and staffing, are already accounted for to keep your venue running even during your quieter times. Fixed costs of running a venue only highlight the importance of optimising any spare capacity and turning it into additional revenue.'
          maxCharacters={171}
          showDivider={false}
        >
          <Box className={classes.sectionBreakdownContainer}>
            {/* <TextDropdown items={dateRangeOptions} value={dateRange} onSelect={setDateRange} /> */}

            <Box className={classes.sectionBreakdownRowItemsContainer}>
              <PillarModalRowItem
                label='Net Revenue: EatClub'
                value={formatCurrency(data.current.netRevenue, true, '$0')}
                variant='outlined'
              />

              {/* <PillarModalRowItem */}
              {/*   label={`${foodCost}% food cost`} */}
              {/*   value={`(${formatCurrency((foodCost * data.current.netRevenue) / 100, true, '$0')})`} */}
              {/*   variant='outlined' */}
              {/* /> */}
              {/* TODO uncomment this when supported on backend */}
              <PillarModalRowItemEditable
                label=' food cost*'
                value={`(${formatCurrency(foodCostAmount, true, '$0')})`}
                variant='outlined'
                editable
                editableValue={foodCost}
                setEditableValue={updateFoodCost}
              />
              <PillarModalRowItem
                label='Gross profit'
                value={formatCurrency(grossProfit, true, '$0')}
                smallText={`${roundToNearest(grossProfitMargin)}% gross profit margin`}
                variant='filled'
                color={tab}
              />
            </Box>
          </Box>
        </PillarModalSummary>
      </Box>

      <Divider className={classes.divider} />

      <MarketComparisons
        description={getComparisonMessage(total, comparisonData)}
        comparisonData={comparisonData}
        total={total}
        colors={getInsightTabColors(INSIGHT_TAB.NET_REVENUE, theme)}
        loading={loadingData}
        format={(value) => formatCurrency(value, false, '', false, true)}
        setFilter={setFilter}
        filter={filter}
      />
    </>
  );
};

NetRevenuePillarModalContent.propTypes = {
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs),
    endDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
  activeRestaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFoodCostPercent: updateFoodCostPercentAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NetRevenuePillarModalContent);
