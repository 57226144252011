/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress,
  Collapse,
  Alert,
  AlertTitle,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { HelpOutline } from '@mui/icons-material';
import {
  clearFlagOrderAction,
  flagCustomerIssueAction,
  flagNoShowAction,
  flagPartySizeAction,
  flagIAOAction,
} from '../../actions/orderFlagAction';
import useStyles from './OrderFlagDialogStyles';
import Error from '../error/Error';
import { devLog } from '../../utils';

const OrderFlagDialog = ({
  booking,
  clearFlagOrder,
  flag,
  flagCustomerIssue,
  flagIAO,
  flagNoShow,
  flagPartySize,
  handleCloseDialog,
  openDialog,
  restId,
  userId,
  userType,
  ecPayEnabled,
}) => {
  const classes = useStyles();

  let options = [
    // in all ecpay regions, remove the Report No Show option from the drop down
    ...(ecPayEnabled ? [] : [{ name: 'Report No Show', value: 'noShow' }]),
    { name: 'Different Party Size', value: 'partySize' },
    { name: 'Report Customer Issue', value: 'customerIssue' },
  ];

  const tableOrderOptions = [
    { name: 'Different Party Size', value: 'partySize' },
    { name: 'Report Customer Issue', value: 'customerIssue' },
  ];
  const inAppOrderOptions = [{ name: 'Report Customer Issue', value: 'orderIssue' }];

  if (booking.type === 'tableOrder') {
    options = tableOrderOptions;
  } else if (booking.type === 'inAppOrder') {
    options = inAppOrderOptions;
  }

  const [issue, setIssue] = useState(options[0].value);
  const [issueError, setIssueError] = useState('');
  const [partySize, setPartySize] = useState(booking.partySize || '');
  const [partySizeError, setPartySizeError] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [issueDescriptionError, setIssueDescriptionError] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  const validateForm = () => {
    let formError = false;

    if (!issue) {
      setIssueError('Please select an option.');
      formError = true;
    } else if (issueError) {
      setIssueError('');
    }

    if (
      issue === 'partySize' &&
      (partySize === '' || Number(partySize) < 1 || Number(partySize) % 1 !== 0)
    ) {
      setPartySizeError('Please enter a number.');
      formError = true;
    } else if (partySizeError) {
      setPartySizeError('');
    }

    if ((issue === 'customerIssue' || issue === 'orderIsse') && issueDescription === '') {
      setIssueDescriptionError('Please enter a description.');
      formError = true;
    } else if (issueDescriptionError) {
      setIssueDescriptionError('');
    }

    return formError;
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    setShowConfirm(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    if (issue === 'noShow') {
      flagNoShow(booking.bookingId, restId, userId, userType);
    } else if (issue === 'partySize') {
      flagPartySize(booking.bookingId, Number(partySize), restId, userId, userType);
    } else if (issue === 'customerIssue') {
      flagCustomerIssue(booking.bookingId, issueDescription, restId, userId, userType);
    } else if (issue === 'orderIssue') {
      flagIAO(booking.bookingId, issueDescription, restId, userId, userType);
    } else {
      devLog('error', 'Unknown flag order issue submitted', issue);
    }
  };

  // Close dialog when flag returns successful
  useEffect(() => {
    if (!flag.success || !openDialog) {
      return;
    }
    handleCloseDialog();
  }, [flag.success, handleCloseDialog, openDialog]);

  if (booking.issueStatus) {
    return (
      <Dialog
        onClose={handleCloseDialog}
        open={openDialog}
        fullWidth
        maxWidth='xs'
        TransitionProps={{
          onExit: clearFlagOrder,
        }}
      >
        <DialogTitle>
          <Typography
            variant='h6'
            component='span'
            display='inline'
            style={{ marginRight: '.5rem' }}
          >
            Report Issue
          </Typography>
          <Typography variant='h6' display='inline' style={{ fontWeight: '400' }}>
            {booking.reservationName}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography paragraph>
            Your issue has been reported, an EatClub representative will reach out to you soon.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={handleCloseDialog} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      keepMounted={false}
      onClose={handleCloseDialog}
      open={openDialog}
      fullWidth
      maxWidth='sm'
      TransitionProps={{
        onEnter: () => {
          setIssue(options[0].value);
          setIssueError('');
          setPartySize(booking.partySize || '');
          setPartySizeError('');
          setIssueDescription('');
          setIssueDescriptionError('');
          setShowConfirm(false);
        },
        onExit: clearFlagOrder,
      }}
    >
      <DialogTitle>
        <Typography variant='h6' component='span' display='inline' style={{ marginRight: '.5rem' }}>
          Report Issue
        </Typography>
        <Typography variant='h6' display='inline' style={{ fontWeight: '400' }}>
          {booking.reservationName}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form id='issue-form'>
          <FormControl
            className={classes.formControl}
            fullWidth
            error={Boolean(issueError)}
            required
          >
            <InputLabel id='issue-select'>Select an issue</InputLabel>
            <Select
              labelId='issue-select'
              value={issue}
              onChange={(e) => {
                setShowConfirm(false);
                setIssue(e.target.value);
              }}
              onBlur={() => {
                validateForm();
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {Boolean(issueError) && <FormHelperText>{issueError}</FormHelperText>}
          </FormControl>

          {issue === 'partySize' && (
            <TextField
              label='Party Size'
              placeholder='1'
              fullWidth
              value={partySize}
              type='number'
              onChange={(e) => {
                setPartySize(e.target.value);
              }}
              onBlur={(e) => {
                setPartySize(e.target.value);
                validateForm();
              }}
              inputProps={{ min: 1, step: 1 }}
              margin='normal'
              required
              error={Boolean(partySizeError)}
              helperText={partySizeError}
            />
          )}

          {(issue === 'customerIssue' || issue === 'orderIssue') && (
            <TextField
              multiline
              minRows={4}
              label='Issue Description'
              placeholder='Describe the issue you were having.'
              fullWidth
              value={issueDescription}
              onChange={(e) => setIssueDescription(e.target.value)}
              onBlur={(e) => {
                setIssueDescription(e.target.value.trim());
                validateForm();
              }}
              margin='normal'
              required
              error={Boolean(issueDescriptionError)}
              helperText={issueDescriptionError}
            />
          )}
        </form>

        <Error error={flag.error} message={flag.errorMessage} style={{ marginTop: '1rem' }} />

        <Collapse in={showConfirm}>
          <Alert
            icon={<HelpOutline />}
            variant='filled'
            severity='error'
            style={{ marginTop: '1rem' }}
          >
            <AlertTitle>Are you sure this offer was a no-show?</AlertTitle>
            The customer will be notified of the report.
          </Alert>
        </Collapse>
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          onClick={issue === 'noShow' && !showConfirm ? handleConfirm : handleSubmit}
          type='submit'
          form='issue-form'
          autoFocus
        >
          {flag.saving ? (
            <CircularProgress color='primary' size={22} />
          ) : (
            <>{issue === 'noShow' && showConfirm ? 'Yes, Report Issue' : 'Report Issue'}</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  flag: state.orderFlag,
  region: state.restaurantActive.restaurant.region,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  ecPayEnabled: state.restaurantActive.restaurant.ecPayEnabled,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFlagOrder: clearFlagOrderAction,
      flagCustomerIssue: flagCustomerIssueAction,
      flagNoShow: flagNoShowAction,
      flagPartySize: flagPartySizeAction,
      flagIAO: flagIAOAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderFlagDialog);
