import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './ModalStyles';
import { Heading } from '../../Heading';

const ModalSectionHeader = ({ header }) => {
  const classes = useStyles();

  return (
    <Box className={classes.modalSectionHeaderContainer}>
      <Heading size='4'>{header}</Heading>
      <Box className={classes.modalSectionHeaderDivider} />
    </Box>
  );
};

ModalSectionHeader.propTypes = {
  header: PropTypes.string.isRequired,
};

export default ModalSectionHeader;
