import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { categoriesForMenu } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchCategoriesAction = (menuId, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'CATEGORIES',
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: categoriesForMenu,
          variables: { menuId },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'categories', response.data.categoriesForMenu);

      dispatch({
        type: type.FETCH_CATEGORIES_SUCCESS,
        payload: { categories: response.data.categoriesForMenu },
      });
    } catch (error) {
      devLog('success', 'categories', error);

      dispatch({
        type: type.FETCH_CATEGORIES_FAILURE,
        payload: `Unable to retrieve CATEGORIES: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'CATEGORIES',
      });
    }
  })();
};

export const clearCategoriesAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_CATEGORIES,
  });
};
