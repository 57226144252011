import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { bindActionCreators } from 'redux';
import { Box, Spacer, Button } from '@eatclub-apps/ec-component-library';
import { transactionDetailsPropTypes } from '../../../data/models/TransactionDetails';
import { devLog, getClosedDays } from '../../../utils';
import { calculateRevenueOnly } from '../../../utils/calculateRevenue';
import {
  capitaliseFirstLetter,
  formatCurrency,
  getLabelForValue,
  groupObjects,
  isEmpty,
  numberArray,
  roundToNearest,
} from '../../../utils/helpers';
import {
  calculateRevenueBySession,
  getDateRangeLabel,
  getTimePeriodLabel,
  getTimePeriodMessage,
  getTimeslots,
} from '../../../utils/insights/insightHelpers';
import { weekdays } from '../../../utils/valueLists';
import OpeningHoursEditModal from '../OpeningHoursEditModal/OpeningHoursEditModal';
import BarBackground from './BarBackground';
import RevenueByTimeSubRow from './RevenueByTimeSubRow';
import useStyles from './RevenueByTimeStyles';
import GraphPlaceholder from '../../../assets/graph_placeholder.svg';
import DownArrowSVG from '../../../assets/down_caret.svg';
import SunriseSVG from '../../../assets/icons/sunrise.svg';
import SunSVG from '../../../assets/icons/sun.svg';
import MoonSVG from '../../../assets/icons/moon.svg';

const FilledBar = ({ fill, x, y, width, height, closedForDay, weekday, revenue }) => {
  const theme = useTheme();

  let barHeight = height;
  let barY = y;

  let rad = Math.min(10, barHeight);

  // Adjustments for low values
  if (revenue < 10) {
    barHeight = 5;
    barY = 195;
    rad = 10;
  }

  const chartHeight = 200;

  // This was difficult to make. Has custom masking so small values are flat on top
  return (
    <svg
      id={weekday}
      width={width}
      height={chartHeight}
      x={x}
      opacity={closedForDay ? 0.5 : 1}
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='barmask' height={chartHeight}>
        <rect width='100%' height='100%' y={0} fill='white' rx={rad} ry={rad} />
      </mask>
      <pattern
        id='diagonalHatch'
        patternUnits='userSpaceOnUse'
        patternTransform='rotate(75 0 0)'
        width='5'
        height='5'
      >
        <rect width={5} height={5} fill={fill} />
        <line
          x1='0'
          y1='0'
          x2='0'
          y2='10'
          style={{ stroke: theme.colors.revenueTimeBarFillStripe, strokeWidth: 2 }}
        />
      </pattern>
      <rect
        width={width}
        height={barHeight}
        fill='url(#diagonalHatch)'
        mask='url(#barmask)'
        y={barY}
        rx={rad}
        ry={rad}
      />
    </svg>
  );
};

const RevenueByTime = ({ dates, transactionDetails, activeRestaurant, restaurantPeriods }) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('md'));

  const timeslotsForRestaurant = getTimeslots(restaurantPeriods.restaurantTimePeriods);

  // The breakfast/lunch/dinner rows that are open
  const [expandedSessions, setExpandedSessions] = useState([]);
  const [showEditOpeningHours, setShowEditOpeningHours] = useState(false);

  // Get items for the view (days, weeks, etc.)
  const revenueByDay = useMemo(() => {
    // mon, tue, etc.
    const daysArray = weekdays();

    // Group the data by the time period (day, week, etc.)
    const bookingsByGroup = groupObjects(
      transactionDetails.transactionDetails.map((booking) => ({
        ...booking,
        weekday: dayjs(booking?.created).day(),
        tooltipLabel: 'revenue',
      })),
      'weekday',
    );

    const compareBookingsByDay = groupObjects(
      transactionDetails.compareTransactionDetails.map((booking) => ({
        ...booking,
        weekday: dayjs(booking?.created).day(),
        tooltipLabel: 'revenue',
      })),
      'weekday',
    );

    // Make sure we include every day NOTE: Actually by group, not day
    const closedDays = getClosedDays(activeRestaurant.hoursParsed);
    const totalsByDay = daysArray.map((day, index) => {
      const dayIndex = (index + 1) % 7; // Dayjs starts from sunday but we start from monday

      const bookingsForGroup = bookingsByGroup?.[dayIndex] ?? [];
      const compareBookingsForGroup = compareBookingsByDay?.[dayIndex] ?? [];

      const calculatedRevenue = calculateRevenueOnly(
        bookingsForGroup,
        compareBookingsForGroup,
        activeRestaurant.averageOrderValue,
        activeRestaurant.averageBookingValue,
      );

      const revenueForDay = roundToNearest(calculatedRevenue);

      const weekday = capitaliseFirstLetter(daysArray?.[index]?.value);
      const closedForDay = closedDays.includes(weekday);

      return {
        weekday,
        weekdayLong: getLabelForValue(day?.value, weekdays()),
        revenue: revenueForDay,
        revenueForChart: Math.max(10, revenueForDay),
        revenueBySession: calculateRevenueBySession(
          bookingsForGroup,
          activeRestaurant.averageOrderValue,
          activeRestaurant.averageBookingValue,
          timeslotsForRestaurant,
        ),
        closedForDay,
      };
    });

    return totalsByDay;
  }, [
    transactionDetails.transactionDetails,
    transactionDetails.compareTransactionDetails,
    dates,
    timeslotsForRestaurant,
    activeRestaurant,
  ]);

  // TODO find a way to make this take up less lines of code lol
  const revenueBySession = [
    {
      id: 'total',
      Icon: null,
      name: 'Total',
      timeslots: null,
      mon: revenueByDay?.[0]?.revenue,
      tue: revenueByDay?.[1]?.revenue,
      wed: revenueByDay?.[2]?.revenue,
      thu: revenueByDay?.[3]?.revenue,
      fri: revenueByDay?.[4]?.revenue,
      sat: revenueByDay?.[5]?.revenue,
      sun: revenueByDay?.[6]?.revenue,
      total: numberArray(7).reduce((accum, index) => accum + revenueByDay?.[index]?.revenue, 0),
      expandable: false,
    },
    {
      id: 'breakfast',
      Icon: <SunriseSVG style={{ color: theme.colors.sunriseRed }} />,
      name: 'Breakfast',
      timeslots: timeslotsForRestaurant.breakfast,
      mon: revenueByDay?.[0]?.revenueBySession?.breakfast?.total,
      tue: revenueByDay?.[1]?.revenueBySession?.breakfast?.total,
      wed: revenueByDay?.[2]?.revenueBySession?.breakfast?.total,
      thu: revenueByDay?.[3]?.revenueBySession?.breakfast?.total,
      fri: revenueByDay?.[4]?.revenueBySession?.breakfast?.total,
      sat: revenueByDay?.[5]?.revenueBySession?.breakfast?.total,
      sun: revenueByDay?.[6]?.revenueBySession?.breakfast?.total,
      total: numberArray(7).reduce(
        (accum, index) => accum + revenueByDay?.[index]?.revenueBySession?.breakfast?.total,
        0,
      ),
      subSessions: [
        {
          mon: revenueByDay?.[0]?.revenueBySession?.breakfast?.earlyBird,
          tue: revenueByDay?.[1]?.revenueBySession?.breakfast?.earlyBird,
          wed: revenueByDay?.[2]?.revenueBySession?.breakfast?.earlyBird,
          thu: revenueByDay?.[3]?.revenueBySession?.breakfast?.earlyBird,
          fri: revenueByDay?.[4]?.revenueBySession?.breakfast?.earlyBird,
          sat: revenueByDay?.[5]?.revenueBySession?.breakfast?.earlyBird,
          sun: revenueByDay?.[6]?.revenueBySession?.breakfast?.earlyBird,
        },
        {
          mon: revenueByDay?.[0]?.revenueBySession?.breakfast?.peak,
          tue: revenueByDay?.[1]?.revenueBySession?.breakfast?.peak,
          wed: revenueByDay?.[2]?.revenueBySession?.breakfast?.peak,
          thu: revenueByDay?.[3]?.revenueBySession?.breakfast?.peak,
          fri: revenueByDay?.[4]?.revenueBySession?.breakfast?.peak,
          sat: revenueByDay?.[5]?.revenueBySession?.breakfast?.peak,
          sun: revenueByDay?.[6]?.revenueBySession?.breakfast?.peak,
        },
        {
          mon: revenueByDay?.[0]?.revenueBySession?.breakfast?.lateSession,
          tue: revenueByDay?.[1]?.revenueBySession?.breakfast?.lateSession,
          wed: revenueByDay?.[2]?.revenueBySession?.breakfast?.lateSession,
          thu: revenueByDay?.[3]?.revenueBySession?.breakfast?.lateSession,
          fri: revenueByDay?.[4]?.revenueBySession?.breakfast?.lateSession,
          sat: revenueByDay?.[5]?.revenueBySession?.breakfast?.lateSession,
          sun: revenueByDay?.[6]?.revenueBySession?.breakfast?.lateSession,
        },
      ],
      expandable: true,
    },
    {
      id: 'lunch',
      Icon: <SunSVG style={{ color: theme.colors.sunOrange }} />,
      name: 'Lunch',
      timeslots: timeslotsForRestaurant.lunch,
      mon: revenueByDay?.[0]?.revenueBySession?.lunch?.total,
      tue: revenueByDay?.[1]?.revenueBySession?.lunch?.total,
      wed: revenueByDay?.[2]?.revenueBySession?.lunch?.total,
      thu: revenueByDay?.[3]?.revenueBySession?.lunch?.total,
      fri: revenueByDay?.[4]?.revenueBySession?.lunch?.total,
      sat: revenueByDay?.[5]?.revenueBySession?.lunch?.total,
      sun: revenueByDay?.[6]?.revenueBySession?.lunch?.total,
      total: numberArray(7).reduce(
        (accum, index) => accum + revenueByDay?.[index]?.revenueBySession?.lunch?.total,
        0,
      ),
      subSessions: [
        {
          mon: revenueByDay?.[0]?.revenueBySession?.lunch?.earlyBird,
          tue: revenueByDay?.[1]?.revenueBySession?.lunch?.earlyBird,
          wed: revenueByDay?.[2]?.revenueBySession?.lunch?.earlyBird,
          thu: revenueByDay?.[3]?.revenueBySession?.lunch?.earlyBird,
          fri: revenueByDay?.[4]?.revenueBySession?.lunch?.earlyBird,
          sat: revenueByDay?.[5]?.revenueBySession?.lunch?.earlyBird,
          sun: revenueByDay?.[6]?.revenueBySession?.lunch?.earlyBird,
        },
        {
          mon: revenueByDay?.[0]?.revenueBySession?.lunch?.peak,
          tue: revenueByDay?.[1]?.revenueBySession?.lunch?.peak,
          wed: revenueByDay?.[2]?.revenueBySession?.lunch?.peak,
          thu: revenueByDay?.[3]?.revenueBySession?.lunch?.peak,
          fri: revenueByDay?.[4]?.revenueBySession?.lunch?.peak,
          sat: revenueByDay?.[5]?.revenueBySession?.lunch?.peak,
          sun: revenueByDay?.[6]?.revenueBySession?.lunch?.peak,
        },
        {
          mon: revenueByDay?.[0]?.revenueBySession?.lunch?.lateSession,
          tue: revenueByDay?.[1]?.revenueBySession?.lunch?.lateSession,
          wed: revenueByDay?.[2]?.revenueBySession?.lunch?.lateSession,
          thu: revenueByDay?.[3]?.revenueBySession?.lunch?.lateSession,
          fri: revenueByDay?.[4]?.revenueBySession?.lunch?.lateSession,
          sat: revenueByDay?.[5]?.revenueBySession?.lunch?.lateSession,
          sun: revenueByDay?.[6]?.revenueBySession?.lunch?.lateSession,
        },
      ],
      expandable: true,
    },
    {
      id: 'dinner',
      Icon: <MoonSVG style={{ color: theme.colors.moonPurple }} />,
      name: 'Dinner',
      timeslots: timeslotsForRestaurant.dinner,
      mon: revenueByDay?.[0]?.revenueBySession?.dinner?.total,
      tue: revenueByDay?.[1]?.revenueBySession?.dinner?.total,
      wed: revenueByDay?.[2]?.revenueBySession?.dinner?.total,
      thu: revenueByDay?.[3]?.revenueBySession?.dinner?.total,
      fri: revenueByDay?.[4]?.revenueBySession?.dinner?.total,
      sat: revenueByDay?.[5]?.revenueBySession?.dinner?.total,
      sun: revenueByDay?.[6]?.revenueBySession?.dinner?.total,
      total: numberArray(7).reduce(
        (accum, index) => accum + revenueByDay?.[index]?.revenueBySession?.dinner?.total,
        0,
      ),
      subSessions: [
        {
          mon: revenueByDay?.[0]?.revenueBySession?.dinner?.earlyBird,
          tue: revenueByDay?.[1]?.revenueBySession?.dinner?.earlyBird,
          wed: revenueByDay?.[2]?.revenueBySession?.dinner?.earlyBird,
          thu: revenueByDay?.[3]?.revenueBySession?.dinner?.earlyBird,
          fri: revenueByDay?.[4]?.revenueBySession?.dinner?.earlyBird,
          sat: revenueByDay?.[5]?.revenueBySession?.dinner?.earlyBird,
          sun: revenueByDay?.[6]?.revenueBySession?.dinner?.earlyBird,
        },
        {
          mon: revenueByDay?.[0]?.revenueBySession?.dinner?.peak,
          tue: revenueByDay?.[1]?.revenueBySession?.dinner?.peak,
          wed: revenueByDay?.[2]?.revenueBySession?.dinner?.peak,
          thu: revenueByDay?.[3]?.revenueBySession?.dinner?.peak,
          fri: revenueByDay?.[4]?.revenueBySession?.dinner?.peak,
          sat: revenueByDay?.[5]?.revenueBySession?.dinner?.peak,
          sun: revenueByDay?.[6]?.revenueBySession?.dinner?.peak,
        },
        {
          mon: revenueByDay?.[0]?.revenueBySession?.dinner?.lateSession,
          tue: revenueByDay?.[1]?.revenueBySession?.dinner?.lateSession,
          wed: revenueByDay?.[2]?.revenueBySession?.dinner?.lateSession,
          thu: revenueByDay?.[3]?.revenueBySession?.dinner?.lateSession,
          fri: revenueByDay?.[4]?.revenueBySession?.dinner?.lateSession,
          sat: revenueByDay?.[5]?.revenueBySession?.dinner?.lateSession,
          sun: revenueByDay?.[6]?.revenueBySession?.dinner?.lateSession,
        },
      ],
      expandable: true,
    },
    {
      id: 'other',
      name: 'No session',
      timeslots: timeslotsForRestaurant.other,
      mon: revenueByDay?.[0]?.revenueBySession?.other?.total,
      tue: revenueByDay?.[1]?.revenueBySession?.other?.total,
      wed: revenueByDay?.[2]?.revenueBySession?.other?.total,
      thu: revenueByDay?.[3]?.revenueBySession?.other?.total,
      fri: revenueByDay?.[4]?.revenueBySession?.other?.total,
      sat: revenueByDay?.[5]?.revenueBySession?.other?.total,
      sun: revenueByDay?.[6]?.revenueBySession?.other?.total,
      total: numberArray(7).reduce(
        (accum, index) => accum + revenueByDay?.[index]?.revenueBySession?.other?.total,
        0,
      ),
      expandable: false,
    },
  ];

  if (!isEmpty(timeslotsForRestaurant.other)) {
    devLog('debug', 'no session timeslots', timeslotsForRestaurant.other);
  }

  const toggleExpandedSession = (sessionIndex) => {
    if (expandedSessions.includes(sessionIndex)) {
      setExpandedSessions(expandedSessions.filter((item) => item !== sessionIndex));
    } else {
      setExpandedSessions([...expandedSessions, sessionIndex]);
    }
  };

  const getRowValue = (data) => {
    return (
      <Box className={classes.rowValueContainer}>
        {data > 0 ? (
          formatCurrency(data, false)
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={classes.emptyCell} />
          </Box>
        )}
      </Box>
    );
  };

  // // Set the top of the chart to the next $500 above the current max
  // const highestRevenue = numberArray(7).reduce(
  //   (best, day) => (revenueByDay?.[day]?.revenue > best ? revenueByDay?.[day]?.revenue : best),
  //   0,
  // );
  //
  // // If restaurant has low revenue, round to nearest $500, otherwise nearest thousand
  // const roundUpTo = highestRevenue > 2000 ? 1000 : 500;
  // const upperLimit = roundToNearest(highestRevenue, roundUpTo, 'ceil');

  return (
    <Box className={classes.root}>
      <Box className={classes.graphHeader}>
        {revenueByDay.length > 0 && (
          <Spacer style={{ justifyContent: 'space-between', width: '100%' }}>
            <Box className={classes.title}>Total Net Revenue by day</Box>
            <Box style={{ fontSize: '14px' }}>{getDateRangeLabel(dates)}</Box>
          </Spacer>
        )}
      </Box>
      {revenueByDay.length > 0 ? (
        <>
          {/* Graph here */}
          <Box className={classes.graphContainer}>
            <ResponsiveContainer width='100%'>
              <BarChart width={1400} height={188} data={revenueByDay}>
                <CartesianGrid vertical={false} stroke='#00000010' />
                <XAxis dataKey='weekday' hide tickLine={false} axisLine={false} />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  interval='preserveEnd'
                  allowDecimals={false}
                  width={104}
                  dx={-96}
                  tick={{
                    fontSize: '12px',
                    color: theme.colors.smoke,
                    fontWeight: theme.fontWeights.regular,
                    textAnchor: 'start',
                  }}
                  tickFormatter={(value) => formatCurrency(value, false)}
                  tickCount={5}
                  stroke='#00000099'
                  // domain={[0, upperLimit]}
                />
                <Bar
                  dataKey='revenueForChart'
                  background={<BarBackground />}
                  radius={[10, 10, 10, 10]}
                  barSize={28}
                  // activeBar={false}
                  shape={<FilledBar />}
                  isAnimationActive={false}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>

          <Box className={classes.performanceDayContainer}>
            <Box className={classes.performanceDayContainerInner}>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  tableLayout: 'fixed',
                }}
              >
                <thead>
                  <tr className={classes.tableRow} style={{ height: '40px' }}>
                    <td
                      className={`${classes.tableItem} ${classes.tableItemLabel}`}
                      style={{ height: '46px', padding: 0, borderTop: 'none' }}
                    />
                    {revenueByDay.map((day) => (
                      <td
                        key={day?.weekday}
                        className={classes.performanceDay}
                        style={{
                          height: '40px',
                          padding: 0,
                          paddingBottom: '8px',
                        }}
                      >
                        <Box className={classes.dayName}>
                          {breakpointSmDown ? day.weekday : day.weekdayLong}
                        </Box>
                      </td>
                    ))}
                    <td
                      className={`${classes.tableItem} ${classes.tableButton}`}
                      style={{ height: '40px', padding: 0, borderTop: 'none' }}
                    />
                  </tr>
                </thead>
                <tbody>
                  <>
                    {revenueBySession
                      .filter((revenueSession) => revenueSession?.total > 0)
                      .map((revenueSession, index) => {
                        if (expandedSessions?.includes(index)) {
                          return (
                            <tr
                              key={`${revenueSession?.id}-expanded`}
                              className={`${classes.tableRow} ${
                                revenueSession?.expandable ? `${classes.tableRowInteractive}` : ''
                              }`}
                              onClick={() => toggleExpandedSession(index)}
                            >
                              <td colSpan={9} className={classes.subTableContainer}>
                                <table className={classes.subTable}>
                                  <tbody>
                                    {/* This row is to create padding inside the table */}
                                    <tr>
                                      <td
                                        className={`${classes.subTableItem} ${classes.subTableItemLabel}`}
                                        style={{ height: '10px' }}
                                      />
                                      <td colSpan={7} />
                                      <td
                                        className={`${classes.subTableItem} ${classes.tableButton} ${classes.subTableItemLast}`}
                                        style={{ height: '10px', width: '30px' }}
                                      />
                                    </tr>
                                    <tr
                                      className={`${classes.subTableRow} ${classes.tableRowInteractive}`}
                                    >
                                      <td
                                        className={`${classes.subTableItem} ${classes.subTableItemLabel}`}
                                        style={{ fontWeight: 500 }}
                                      >
                                        <Spacer style={{ overflowX: 'visible' }}>
                                          <Box style={{ width: '37px' }}>{revenueSession.Icon}</Box>
                                          {revenueSession.name}
                                        </Spacer>
                                      </td>
                                      <td className={classes.subTableItem}>
                                        {getRowValue(revenueSession.mon)}
                                      </td>
                                      <td className={classes.subTableItem}>
                                        {getRowValue(revenueSession.tue)}
                                      </td>
                                      <td className={classes.subTableItem}>
                                        {getRowValue(revenueSession.wed)}
                                      </td>
                                      <td className={classes.subTableItem}>
                                        {getRowValue(revenueSession.thu)}
                                      </td>
                                      <td className={classes.subTableItem}>
                                        {getRowValue(revenueSession.fri)}
                                      </td>
                                      <td className={classes.subTableItem}>
                                        {getRowValue(revenueSession.sat)}
                                      </td>
                                      <td className={classes.subTableItem}>
                                        {getRowValue(revenueSession.sun)}
                                      </td>
                                      <td
                                        className={`${classes.subTableItem} ${classes.tableButton} ${classes.subTableItemLast}`}
                                      >
                                        <DownArrowSVG
                                          style={{
                                            width: '15px',
                                            height: '9px',
                                            transform: 'rotate(180deg)',
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <RevenueByTimeSubRow
                                      label={getTimePeriodLabel(revenueSession.timeslots.earlyBird)}
                                      subLabel='Early bird'
                                      rowData={revenueSession.subSessions?.[0]}
                                    />
                                    <RevenueByTimeSubRow
                                      label={getTimePeriodLabel(revenueSession.timeslots.peak)}
                                      subLabel='Peak'
                                      rowData={revenueSession.subSessions?.[1]}
                                    />
                                    <RevenueByTimeSubRow
                                      label={getTimePeriodLabel(
                                        revenueSession.timeslots.lateSession,
                                      )}
                                      subLabel='Late session'
                                      rowData={revenueSession.subSessions?.[2]}
                                    />
                                    {/* This row is to create padding inside the table */}
                                    <tr>
                                      <td
                                        className={`${classes.subTableItem} ${classes.subTableItemLabel}`}
                                        style={{ height: '10px' }}
                                      />
                                      <td colSpan={7} />
                                      <td
                                        className={`${classes.subTableItem} ${classes.tableButton} ${classes.subTableItemLast}`}
                                        style={{ height: '10px', width: '30px' }}
                                      />
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          );
                        }

                        return (
                          <tr
                            key={`${revenueSession?.id}-closed`}
                            className={`${classes.tableRow} ${
                              revenueSession?.expandable
                                ? `${classes.tableRowInteractive} ${classes.tableRowInteractiveHover}`
                                : ''
                            }`}
                            onClick={() => {
                              if (revenueSession?.expandable) {
                                toggleExpandedSession(index);
                              }
                            }}
                          >
                            <td
                              className={`${classes.tableItem} ${classes.tableItemLabel}`}
                              style={{ fontWeight: 500 }}
                            >
                              <Spacer style={{ overflowX: 'visible' }}>
                                {revenueSession?.Icon && (
                                  <Box style={{ width: '37px', flexShrink: 0 }}>
                                    {revenueSession.Icon}
                                  </Box>
                                )}
                                <Spacer direction='vertical' gap={6}>
                                  <Box>{revenueSession.name}</Box>
                                  {revenueSession.timeslots && (
                                    <Spacer className={classes.sessionSubLabel}>
                                      {getTimePeriodLabel({
                                        start: revenueSession?.timeslots?.earlyBird?.start,
                                        end: revenueSession?.timeslots?.lateSession?.end,
                                      })}

                                      <Button
                                        type='icon'
                                        onClick={() => setShowEditOpeningHours(true)}
                                      >
                                        Edit
                                      </Button>
                                    </Spacer>
                                  )}
                                </Spacer>
                              </Spacer>
                            </td>

                            <td className={classes.tableItem}>{getRowValue(revenueSession.mon)}</td>
                            <td className={classes.tableItem}>{getRowValue(revenueSession.tue)}</td>
                            <td className={classes.tableItem}>{getRowValue(revenueSession.wed)}</td>
                            <td className={classes.tableItem}>{getRowValue(revenueSession.thu)}</td>
                            <td className={classes.tableItem}>{getRowValue(revenueSession.fri)}</td>
                            <td className={classes.tableItem}>{getRowValue(revenueSession.sat)}</td>
                            <td className={classes.tableItem}>{getRowValue(revenueSession.sun)}</td>
                            <td className={`${classes.tableItem} ${classes.tableButton}`}>
                              {revenueSession?.expandable && (
                                <DownArrowSVG style={{ width: '15px', height: '9px' }} />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                </tbody>
              </table>
            </Box>
          </Box>
          <OpeningHoursEditModal
            onClose={() => {
              setShowEditOpeningHours(false);
            }}
            submitting={false}
            isOpen={showEditOpeningHours}
            onSave={() => {}}
          />
        </>
      ) : (
        <Box className={classes.emptyStateContainer}>
          <GraphPlaceholder />
          <Box>Check back soon to track your revenue, or try choosing a different date range.</Box>
        </Box>
      )}
    </Box>
  );
};

RevenueByTime.propTypes = {
  activeRestaurant: PropTypes.shape({
    objectId: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  dates: PropTypes.shape({}).isRequired,
  transactionDetails: transactionDetailsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
  transactionDetails: state.transactionDetails,
  restaurantPeriods: state.restaurantPeriods,
  activeRestaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RevenueByTime);
