import { makeStyles } from '@mui/styles';

export const INSIGHT_TAB = {
  CUSTOMERS: 'customers',
  AOV: 'aov',
  NET_REVENUE: 'netRevenue',
  IMPRESSIONS: 'impressions',
};

export const getInsightTabLightColor = (color, theme) => {
  switch (color) {
    case INSIGHT_TAB.CUSTOMERS: {
      return theme.colors.customersLight;
    }
    case INSIGHT_TAB.AOV: {
      return theme.colors.aovLight;
    }
    case INSIGHT_TAB.NET_REVENUE: {
      return theme.colors.revenueLight;
    }
    case INSIGHT_TAB.IMPRESSIONS:
    default: {
      return theme.colors.impressionsLight;
    }
  }
};

export const getInsightTabColors = (color, theme) => {
  switch (color) {
    case INSIGHT_TAB.CUSTOMERS: {
      return {
        light: theme.colors.customersLight,
        dark: theme.colors.customers,
        extraDark: theme.colors.customersDark,
      };
    }
    case INSIGHT_TAB.AOV: {
      return {
        light: theme.colors.aovLight,
        dark: theme.colors.aov,
        extraDark: theme.colors.aovDark,
      };
    }
    case INSIGHT_TAB.NET_REVENUE: {
      return {
        light: theme.colors.revenueLight,
        dark: theme.colors.revenue,
        extraDark: theme.colors.revenueDark,
      };
    }
    case INSIGHT_TAB.IMPRESSIONS:
    default: {
      return {
        light: theme.colors.impressionsLight,
        dark: theme.colors.impressions,
        extraDark: theme.colors.impressionsDark,
      };
    }
  }
};

const useStyles = makeStyles(
  (theme) => ({
    container: {
      borderRadius: '32px',

      height: '100%',
      maxWidth: '580px',
      padding: '32px 32px 30px 32px',
      zIndex: 1000,
      backgroundColor: '#ffffff',
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      gap: '0',
      flexShrink: 0,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      position: 'sticky',
      top: '-32px',
      backgroundColor: 'white',
      margin: '-32px -32px -4px -32px',
      padding: '32px 32px 4px 32px',
      zIndex: 100,
    },
    headerTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      marginRight: 'auto',
    },
    headerButtonsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    title: {
      fontSize: '18px',
      fontWeight: theme.fontWeights.medium,
      marginBottom: '16px',
    },
    subHeadingContainer: {
      marginTop: '12px',
      lineHeight: '28px',
    },
    highlightedText: {
      backgroundColor: (props) => getInsightTabLightColor(props.tab, theme),
      borderRadius: '100px',
      padding: '1px 3px',
    },
    divider: {
      margin: '24px 0',
    },
    sectionContainer: {
      marginTop: '24px',
    },
    sectionHeadingContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      justifyContent: 'space-between',
    },
    sectionSubHeadingContainer: {
      lineHeight: '28px',
    },
    sectionBreakdownContainer: {
      marginTop: '24px',
    },
    sectionBreakdownRowItemsContainer: {
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
      marginTop: '12px',
    },
    rowItemContainer: {
      border: '1px solid',
      borderColor: (props) =>
        props.variant === 'filled' ? getInsightTabLightColor(props.tab, theme) : '#DEDEDE',
      borderRadius: '16px',
      backgroundColor: (props) =>
        props.variant === 'filled' ? getInsightTabLightColor(props.tab, theme) : 'transparent',
      padding: '16px 20px',
      fontWeight: (props) => (props.variant === 'filled' ? 500 : 400),
    },
    rowItemInnerContainer: {
      display: 'flex',
      gap: '8px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rowItemInnerContainerLeft: {
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      justifyContent: 'space-between',
    },
    rowItemInnerContainerRight: {
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      justifyContent: 'space-between',
    },
    smallText: {
      fontSize: '12px',
      fontWeight: theme.fontWeights.regular,
      color: theme.colors.charcoal,
    },
    footerContainer: {
      borderTop: `1px solid ${theme.colors.cloud}`,
      margin: '30px -32px 0 -32px',
      padding: '30px 26px 0 26px',
      width: 'calc(100% + 64px)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    messageIcon: {
      color: 'blue',
      '& .light': {
        color: (props) => getInsightTabLightColor(props.tab, theme),
      },
      '& .dark': {
        color: (props) => getInsightTabColors(props.tab, theme).dark,
      },
    },
    footerText: {
      maxWidth: '272px',
    },
  }),
  { name: 'PillarModal' },
);

export default useStyles;
