import { Box } from '@eatclub-apps/ec-component-library';
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNetBalanceByRestIdAction } from '../../actions/settlementsAction';
import { Banner } from '../banner';
import { formatCurrency } from '../../utils/helpers';

const SettlementsBanner = () => {
  const history = useHistory();

  const restaurantActive = useSelector((state) => state.restaurantActive);
  const netBalance = useSelector((state) => state.netBalance);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNetBalanceByRestIdAction());
  }, [dispatch, restaurantActive.restaurant.objectId]);

  const outstandingBalance = netBalance.data * -1;

  return (
    <Box style={{ position: 'relative' }}>
      {netBalance.success && outstandingBalance > 0 && (
        <Banner
          action={
            <Button
              style={{ paddingLeft: '7px', paddingRight: '7px', minWidth: 'unset' }}
              variant='outlined'
              color='secondary'
              onClick={() => history.push('/settlements/balance-history')}
            >
              <svg
                width='22'
                height='21'
                viewBox='0 0 22 21'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M11.5 1L21 10.5M21 10.5L11.5 20M21 10.5H0' stroke='black' />
              </svg>
            </Button>
          }
        >
          You have an outstanding balance of{' '}
          <b>{formatCurrency(outstandingBalance, true, undefined, true)}</b>
        </Banner>
      )}
      {/* Just for fun, adds a random red pixel to the banner */}
      {/* <div */}
      {/*   style={{ */}
      {/*     position: 'absolute', */}
      {/*     width: '1px', */}
      {/*     height: '1px', */}
      {/*     left: `${Math.random() * 100}%`, */}
      {/*     top: `${Math.random() * 100}%`, */}
      {/*     backgroundColor: 'red', */}
      {/*     zIndex: 100, */}
      {/*   }} */}
      {/* /> */}
    </Box>
  );
};

export default SettlementsBanner;
