import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spacer } from '@eatclub-apps/ec-component-library';
import { Heading } from '../../../components/Heading';
import { formatNumber } from '../../../utils';
import { findObjectByProperty, formatCurrency, isEmpty } from '../../../utils/helpers';
import { sortByProperty } from '../../../utils/listHelpers';
import { calculatePercentage } from '../../../utils/math';
import ChangePill from '../ChangePill/ChangePill';
import useStyles from './MostPopularItemsTableStyles';

const MostPopularItemsTable = ({ analytics }) => {
  const classes = useStyles();

  const { popularMenuItems } = analytics;

  // For the comparison, we'll have to do it by name.
  // Find the item from the compare data with the same name and use it as the compare data
  const tableData = useMemo(() => {
    const maxItemsToShow = 10;
    return (
      popularMenuItems.menuItems
        .sort((a, b) => sortByProperty(a, b, 'netRevenue', true))
        .slice(0, maxItemsToShow)
        .map((menuItem) => {
          const compareMenuItem = findObjectByProperty(
            popularMenuItems.compareMenuItems,
            menuItem.name,
            'name',
            {},
          );
          const revenueDifference = menuItem.netRevenue - compareMenuItem.netRevenue;

          const comparePercentage = calculatePercentage(revenueDifference, menuItem.netRevenue);

          return {
            ...menuItem,
            compareNetRevenue: compareMenuItem?.netRevenue,
            compareOrderCount: compareMenuItem?.orderCount,
            comparePercentage,
            compareGainLoss:
              revenueDifference <= -1 ? 'loss' : revenueDifference >= 1 ? 'gain' : '',
          };
        }) ?? []
    );
  }, [popularMenuItems]);

  if (isEmpty(tableData)) {
    return <></>;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Heading size='3'>Most popular items - Takeaway menu</Heading>
      </Box>

      <Divider />

      <Box className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Item</th>
              <th>Sales</th>
              <th>Net Revenue</th>
            </tr>
          </thead>

          <tbody>
            {tableData.map((item) => (
              <tr key={item.name}>
                <td>{item.name}</td>
                <td>
                  <Box className={classes.tableCell}>{formatNumber(item.orderCount, 0)}</Box>
                </td>
                <td>
                  <Box className={classes.tableCell} style={{ paddingLeft: '20px' }}>
                    <Spacer style={{ justifyContent: 'flex-end' }} gap={12}>
                      <Box className={classes.revenue}>
                        {formatCurrency(item.netRevenue, false)}
                      </Box>
                      <Box className={classes.pill}>
                        <ChangePill
                          change={item.compareGainLoss}
                          increase={item.comparePercentage}
                        />
                      </Box>
                    </Spacer>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

MostPopularItemsTable.propTypes = {
  analytics: PropTypes.shape({
    popularMenuItems: PropTypes.shape({
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          netRevenue: PropTypes.number,
          orderCount: PropTypes.number,
        }),
      ),
      compareMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          netRevenue: PropTypes.number,
          orderCount: PropTypes.number,
        }),
      ),
    }),
    success: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MostPopularItemsTable);
