import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.backgroundBlack,
      borderRadius: '32px',
      padding: '24px',
      color: theme.colors.textWhite,
      width: '100%',
      fontSize: '18px',
    },
    title: {
      color: theme.colors.white,
      fontWeight: theme.fontWeights.medium,
      borderBottom: '1px solid #444038',
      paddingBottom: '24px',
    },
    row: {
      width: '100%',
      justifyContent: 'space-between',
      height: '60px',
      alignItems: 'center',
      '&:not(:last-child)': {
        borderBottom: '1px solid #444038',
      },
    },
    label: {},
    value: {},
  }),
  { name: 'InsightCard' },
);

export default useStyles;
