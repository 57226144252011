import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import TabBar from '../TabBar';
import { outlinedTabBarStyles } from './OutlinedTabBarStyles';

/**
 * A wrapper for the ec component library tab bar, with clean styles
 * @param tabs
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const OutlinedTabBar = ({ tabs = [], value = null, onSelect = (e) => e, ...props }) => {
  const theme = useTheme();
  return (
    <TabBar
      style={outlinedTabBarStyles(theme)}
      tabs={tabs}
      value={value}
      onSelect={onSelect}
      {...props}
    />
  );
};

OutlinedTabBar.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  ),
  value: PropTypes.string,
  onSelect: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OutlinedTabBar);
