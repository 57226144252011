/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { purple } from '@mui/material/colors';
import Chart from 'chart.js';
import dayjs from 'dayjs';
import { alpha } from '@mui/material/styles';
import { connect } from 'react-redux';
import { CardContent, Typography, Card, Grid } from '@mui/material';
import useStyles from './RedemptionTrendsChartStyles';

const RedemptionTrendsChart = (props) => {
  const classes = useStyles();
  const chartRef = useRef(null);
  const [chartObj, setChartObj] = useState(null);
  const [dateUnit, setDateUnit] = useState('day');

  // Update Date Unit when Reservations have finished fetching
  useEffect(() => {
    const rangeOfDays = dayjs(props.dates.endDate).diff(props.dates.startDate, 'days') + 1;

    if (rangeOfDays < 14) {
      setDateUnit('day');
    } else if (rangeOfDays >= 14 && rangeOfDays < 60) {
      setDateUnit('week');
    } else {
      setDateUnit('month');
    }
  }, [props.dates.endDate, props.dates.startDate]);

  const rangeFormat = {
    day: 'YYYY/MM/DD',
    week: 'YYYY/MM/DD',
    month: 'YYYY/MM',
  }[dateUnit];

  const getDatesArray = () => {
    const { startDate, endDate } = props.dates;
    let current = dayjs(startDate);
    const datesArray = [];

    while (current.startOf('day').isSameOrBefore(endDate, 'day')) {
      datesArray.push(current.format('YYYY/MM/DD'));
      current = current.add(1, 'days');
    }
    return datesArray;
  };

  const redemptionsWithNulls = getDatesArray().map((currentDate) => {
    const dateObject = dayjs(currentDate, 'YYYY/MM/DD hh:mm:ss');
    const formattedDate = dateObject.format(rangeFormat);

    const foundDates = props.redemptions.filter(
      (redemps) => dayjs(redemps.created).format(rangeFormat) === formattedDate,
    );

    if (foundDates.length > 0) {
      return {
        dateObject,
        dateFormat: formattedDate,
        count: foundDates.length,
      };
    }
    return { dateObject, dateFormat: formattedDate, count: 0 };
  });

  const redemptionsReduced = redemptionsWithNulls.reduce((allMonths, date) => {
    const key = date.dateFormat;

    if (!allMonths[key]) {
      allMonths[key] = { x: date.dateObject, y: date.count }; // The Chartjs label expects a date object, not string.
    }
    return allMonths;
  }, []);

  const redemptionsArray = Object.keys(redemptionsReduced).map((key) => redemptionsReduced[key]);

  useEffect(() => {
    const chart = new Chart(chartRef.current, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Trend',
            data: redemptionsArray,
            backgroundColor: alpha('#F29160', 0.5),
            pointBackgroundColor: '#F29160',
          },
        ],
      },
      options: {
        // Customize chart options
        tooltips: {
          callbacks: {
            title(tooltipItem, data) {
              return tooltipItem[0].xLabel.slice(0, -13);
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                offsetGridLines: true,
              },
              type: 'time',
              distribution: 'linear',
              time: {
                unit: dateUnit,
              },
            },
          ],
        },
      },
    });

    setChartObj(chart);

    return () => {
      chart.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update chart when data changes
  useEffect(() => {
    // Wait until reservations have fetched
    if (props.fetchingOrShouldFetch) {
      return;
    }

    if (!chartObj) {
      return;
    }

    if (JSON.stringify(redemptionsArray) !== JSON.stringify(chartObj.data.datasets[0].data)) {
      chartObj.data.datasets[0].data = redemptionsArray;
      chartObj.options.scales.xAxes[0].time.unit = dateUnit;
      chartObj.update();
    }
  }, [chartObj, redemptionsArray, dateUnit, props.fetchingOrShouldFetch]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography variant='subtitle1' className={classes.cardTitle}>
              {props.platform === 'eatclub' ? 'Redemption' : 'Order'} Trends
            </Typography>
          </Grid>

          <Grid item lg={12} sm={12} xs={12}>
            <div className={classes.chartHeight} style={{ position: 'relative' }}>
              <canvas
                id='myChart'
                ref={chartRef}
                className={`${classes.chart} ${classes.chartHeight}`}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

RedemptionTrendsChart.propTypes = {
  redemptions: PropTypes.array.isRequired,
  dates: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

export default connect(mapStateToProps, [])(RedemptionTrendsChart);
