import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goalPropTypes } from '../../../data/models/Goal';
import DesktopGoalCard from './DesktopGoalCard';
import MobileGoalCard from './MobileGoalCard';

const GoalCard = ({ goal, deleteGoal = () => {} }) => {
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));

  if (breakpointXsDown) {
    return <MobileGoalCard goal={goal} deleteGoal={deleteGoal} />;
  }

  return <DesktopGoalCard goal={goal} deleteGoal={deleteGoal} />;
};

GoalCard.propTypes = {
  goal: goalPropTypes.isRequired,
  deleteGoal: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GoalCard);
