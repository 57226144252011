export const getTodayHours = (restaurantHours, startDay) => {
  const hoursByDay = restaurantHours;

  const todayHours = Object.keys(hoursByDay)
    .map((day, index) => (day.substr(0, 3) === startDay ? index : null))
    .filter((e) => e !== null)
    .map((index) => Object.values(hoursByDay)[index]);

  return { start: todayHours[0], end: todayHours[1] };
};

export const getClosingTime = (restaurant, currentDay, endTime = 1439) => {
  try {
    const closingTime = restaurant?.hoursParsed?.[`${currentDay}Close`];

    if (closingTime) {
      return closingTime;
    }
  } catch (e) {
    return null;
  }

  return endTime; // close before midnight as default
};

export const getClosedDays = (restaurantHours) => {
  return [
    {
      label: 'Mon',
      isClosed: restaurantHours.monOpen === -1 && restaurantHours.monClose === -1,
    },
    {
      label: 'Tue',
      isClosed: restaurantHours.tueOpen === -1 && restaurantHours.tueClose === -1,
    },
    {
      label: 'Wed',
      isClosed: restaurantHours.wedOpen === -1 && restaurantHours.wedClose === -1,
    },
    {
      label: 'Thu',
      isClosed: restaurantHours.thuOpen === -1 && restaurantHours.thuClose === -1,
    },
    {
      label: 'Fri',
      isClosed: restaurantHours.friOpen === -1 && restaurantHours.friClose === -1,
    },
    {
      label: 'Sat',
      isClosed: restaurantHours.satOpen === -1 && restaurantHours.satClose === -1,
    },
    {
      label: 'Sun',
      isClosed: restaurantHours.sunOpen === -1 && restaurantHours.sunClose === -1,
    },
  ]
    .filter((day) => day.isClosed)
    .map((day) => day.label);
};
