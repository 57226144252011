import dayjs from 'dayjs';
import * as type from '../actions/types';
import { getMonthRange } from '../utils/dateHelpers';

const defaultDateUnit = 'month'; // So the date range can be something like "3 months"
const defaultMonthRange = getMonthRange(3); // 3 months

const initialState = {
  startDate: defaultMonthRange.startDate,
  endDate: defaultMonthRange.endDate,
  dateRange: defaultMonthRange.range,
  dateUnit: defaultDateUnit,
  value: '3_months', // string or number so the dropdown is accurate
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_SEARCH_DATES_DAYJS:
      const newState = { ...state, dateUnit: 'day', value: null };
      if (action.payload.startDate) {
        newState.startDate = action.payload.startDate;
      }

      if (action.payload.endDate) {
        newState.endDate = action.payload.endDate;
      }

      newState.dateRange = action.payload.dateRange;

      return newState;
    case type.SET_SEARCH_DATES:
      return {
        ...state,
        // startDate: dayjs(action.payload.startDate.format('YYYY-MM-DD')),
        // endDate: dayjs(action.payload.endDate.format('YYYY-MM-DD')),
        startDate: dayjs(action.payload.startDate, 'YYYY-MM-DD'),
        endDate: dayjs(action.payload.endDate, 'YYYY-MM-DD'),
        dateUnit: 'day',
        value: null,
      };
    case type.SET_DATE_RANGE:
      const newDateRange = action.payload;

      // Date range can be in months, not just days
      // TODO make this more scalable
      if (newDateRange === '1_month') {
        const monthRange = getMonthRange(1);
        return {
          ...state,
          dateRange: 1,
          startDate: monthRange.startDate,
          endDate: monthRange.endDate,
          dateUnit: 'month',
          value: newDateRange,
        };
      }

      if (newDateRange === '3_months') {
        const monthRange = getMonthRange(3);
        return {
          ...state,
          dateRange: 3,
          startDate: monthRange.startDate,
          endDate: monthRange.endDate,
          dateUnit: 'month',
          value: newDateRange,
        };
      }

      if (newDateRange === 'last_year') {
        return {
          ...state,
          dateRange: 1,
          startDate: dayjs().subtract(1, 'year').startOf('day'),
          endDate: dayjs().startOf('day'),
          dateUnit: 'year',
          value: newDateRange,
        };
      }

      if (newDateRange === 'all_time') {
        return {
          ...state,
          dateRange: 7,
          startDate: dayjs('2020-01-01'),
          endDate: dayjs().startOf('day'),
          dateUnit: 'year',
          value: newDateRange,
        };
      }

      return {
        ...state,
        dateRange: action.payload,

        startDate: dayjs()
          .subtract(newDateRange - 1, 'day')
          .startOf('day'),
        endDate: dayjs().startOf('day'),
        dateUnit: 'day',
        value: newDateRange,
      };
    default:
      return state;
  }
};
