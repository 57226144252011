import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '100px',
  },
  offersContainer: {
    paddingTop: '48px',
  },
  heading: {
    marginBottom: '30px',
    lineHeight: '24px',
  },
}));

export default useStyles;
