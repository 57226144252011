import React from 'react';
import PropTypes from 'prop-types';
import { Spacer, Box, Button } from '@eatclub-apps/ec-component-library';
import MessageIcon from '../../../assets/icons/message.svg';
import useStyles, { INSIGHT_TAB } from './PillarModalStyles';

export const PillarModalFooter = ({ tab = INSIGHT_TAB.NET_REVENUE }) => {
  const classes = useStyles({ tab });

  return (
    <Box className={classes.footerContainer}>
      <Spacer gap={12}>
        <MessageIcon className={classes.messageIcon} />
        <Box className={classes.footerText}>
          Speak to your account manager about our marketing support
        </Box>
      </Spacer>
      <Button type='secondary' href='/support'>
        Contact
      </Button>
    </Box>
  );
};

PillarModalFooter.propTypes = {
  tab: PropTypes.string,
};
