import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const BarBackground = ({ payload, x = 0, width = 0 }) => {
  const theme = useTheme();

  const closedForDay = payload?.closedForDay;

  return (
    <>
      <rect
        x={x}
        y={-5}
        width={width}
        height='100%'
        rx={10}
        ry={10}
        // fill={closedForDay ? '#afab8b' : theme.colors.revenueTimeBarBackground}
        fill={theme.colors.revenueTimeBarBackground}
        opacity={closedForDay ? 0.7 : 1}
      />
    </>
  );
};

BarBackground.propTypes = {
  x: PropTypes.number,
  width: PropTypes.number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BarBackground);
