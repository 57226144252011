import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles(
  (theme) => ({
    inlineText: {
      display: 'flex',
      gap: '10px',
      fontSize: '14px',
      color: theme.colors.smoke,
    },

    tableContainer: {
      overflowX: 'auto',
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      tableLayout: 'fixed',

      '& > thead': {
        textAlign: 'left',
        fontSize: '15px',

        '& > tr ': {
          height: '54px',

          '& th': {
            width: '200px',
            minWidth: '100px',
            maxWidth: '200px',
            fontWeight: 500,
            padding: '8px 12px',
            whiteSpace: 'nowrap',
          },

          '& th:first-child': {
            paddingLeft: 0,
          },

          '& th:last-child': {},
        },
      },
      '& > tbody > tr': {
        '& td': {
          width: '200px',
          minWidth: '100px',
          maxWidth: '200px',
          padding: '0 12px',
        },

        '& td:first-child': {
          paddingLeft: 0,
        },

        '& td:last-child': {
          marginLeft: 'auto',
          paddingRight: '20px',
        },
      },
    },
    tableCell: {
      textAlign: 'left',
      height: '64px',
      alignItems: 'center',
      display: 'flex',
    },

    tableRow: {
      borderTop: '1px solid #ECECEC',
      zIndex: 0,
      borderRadius: '10px',
      color: theme.colors.charcoal,
    },

    emptyCell: {
      height: '4px',
      width: '18px',
      backgroundColor: theme.colors.revenueTimeBarBackground,
    },
    rowValueContainer: {
      width: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    sessionSubLabel: {
      fontSize: '12px',
      color: '#000000F0', // transparency approximating #313131
      overflowX: 'visible',
      whiteSpace: 'nowrap',
      fontWeight: theme.fontWeights.regular,
    },

    tableItem: {
      // display: 'flex',
      // height: '70px',
      minWidth: '70px',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 8px 0 8px',
      zIndex: 1,
      textAlign: 'left',
    },
    tableItemLabel: {
      padding: '20px 0 0 16px',
      fontWeight: theme.fontWeights.medium,
      textAlign: 'left',
      height: '85px',
    },
    tableItemLabelInner: {
      overflowX: 'visible',
      paddingTop: '20px',
    },
    tableButton: {
      padding: '0 12px 0 6px',
      width: '40px',
      minWidth: '40px',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  }),
  { name: 'OpeningHoursEditModal' },
);

export default useStyles;

export const modalStyle = {
  container: {
    padding: '20px',
  },
  content: {
    padding: '32px 20px',
  },
  header: {
    background: COLORS.WHITE,
    padding: '12px 20px',
    position: 'sticky',
    top: 0,
  },
  footer: { padding: 0 },
  footerInner: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    gap: '8px',
    justifyContent: 'space-between',
    padding: '12px 20px',
  },
};

export const modalInputStyle = {
  borderRadius: '24px',
  maxWidth: '1000px',
  width: '100%',
};

export const dropdownStyles = {
  dropdown: { minWidth: '200px' },
};

export const dropdownErrorStyles = {
  dropdown: {
    border: '1px solid red',
    borderBottom: '1px solid red',
    color: 'red',
    lineHeight: '10px',
    minWidth: '200px',
    maxWidth: '200px',
    width: '200px',
  },
  dropdownInner: {
    border: '1px solid red',
    borderBottom: '1px solid red',
    color: 'red',
    lineHeight: '10px',
    maxWidth: '200px',
  },
};
