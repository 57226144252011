import * as type from '../actions/types';
import { findObjectByProperty, safeParse, isEmpty } from '../utils/helpers';

const initialState = {
  restaurantDayPeriods: [],
  restaurantTimePeriods: [],
  fetching: true,
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_RESTAURANT_DAY_PERIODS_SUCCESS: {
      return {
        ...state,
        restaurantDayPeriods: action.payload,
      };
    }

    case type.FETCH_RESTAURANT_TIME_PERIODS_PENDING: {
      return {
        ...state,
        shouldFetch: false,
        success: true,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_RESTAURANT_TIME_PERIODS_SUCCESS: {
      return {
        ...state,
        restaurantTimePeriods: action.payload,
        success: true,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_RESTAURANT_TIME_PERIODS_FAILURE: {
      return {
        ...state,
        success: false,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.UPDATE_RESTAURANT_TIME_PERIODS_PENDING: {
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }

    case type.UPDATE_RESTAURANT_TIME_PERIODS_SUCCESS: {
      // Merge updated items with pre-existing items
      const oldTimePeriods = safeParse(JSON.stringify(state.restaurantTimePeriods));

      const newTimePeriods = oldTimePeriods.map((timePeriod) => {
        const updatedTimePeriod = findObjectByProperty(
          action.payload,
          timePeriod.objectId,
          'objectId',
        );

        if (isEmpty(updatedTimePeriod)) {
          return timePeriod;
        }

        return updatedTimePeriod;
      });

      return {
        ...state,
        restaurantTimePeriods: newTimePeriods,
        success: true,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.UPDATE_RESTAURANT_TIME_PERIODS_FAILURE: {
      return {
        ...state,
        success: false,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    default:
      return state;
  }
};
