import { safeDivision } from './helpers';

/**
 * Returns the value as a percentage
 *
 * e.g. calculatePercentage(30, 90) = 33.33
 *
 * @param value
 * @param total
 */
export const calculatePercentage = (value, total) => {
  if (value === 0) {
    return 0;
  }

  if (total === 0) {
    return 0;
  }

  return safeDivision(value * 100, total);
};

/**
 * Returns the percentage of the number between the bottom value and the top value
 *
 * e.g. getNumberAsPercentage(8, 5, 11) = 50
 *
 * @param value
 * @param bottom
 * @param top
 */
export const getNumberAsPercentage = (value, bottom = 0, top = 100) => {
  const normalisedValue = value - bottom;
  const normalisedTop = top - bottom;

  return calculatePercentage(normalisedValue, normalisedTop);
};
