import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { bindActionCreators } from 'redux';
import { Box, DropdownTransparent } from '@eatclub-apps/ec-component-library';
import { transactionDetailsPropTypes } from '../../data/models/TransactionDetails';
import { FONT_WEIGHTS } from '../../EatClubTheme';
import { getClosedDays } from '../../utils';
import {
  calculateNearestUnit,
  capitaliseFirstLetter,
  findObjectIndexByProperty,
  formatCurrency,
  formatDate,
  groupObjects,
  roundToNearest,
  safeDivision,
  uniqueObjectsByProperty,
} from '../../utils/helpers';
import {
  formatDateForView,
  getDatesArray,
  getGroupEndDate,
  getSeasonalisedPredictionData,
  getSimpleDatesArray,
  getTotalsByGroup,
} from '../../utils/insights/insightHelpers';
import { sortListByProperty } from '../../utils/listHelpers';
import CustomLabel from './CustomLabel';
import CustomTooltip from './CustomTooltip';
import GraphDot from './GraphDot';
import useStyles from './RevenueGraphStyles';
import GraphPlaceholder from '../../assets/graph_placeholder.svg';

const RevenueGraph = ({ dates, transactionDetails, activeRestaurant }) => {
  const classes = useStyles();
  const theme = useTheme();

  const allowedViews = useMemo(() => {
    const { startDate, endDate } = dates;

    // Limit to about 15 items before it gets out of control
    const newAllowedViews = [];
    newAllowedViews.push({ label: 'Day', value: 'day' });

    if (!startDate.isSame(endDate, 'week')) {
      newAllowedViews.push({ label: 'Week', value: 'week' });
    }

    if (!startDate.isSame(endDate, 'month')) {
      newAllowedViews.push({ label: 'Month', value: 'month' });
    }

    if (!startDate.isSame(endDate, 'year')) {
      newAllowedViews.push({ label: 'Year', value: 'year' });
    }

    return newAllowedViews;
  }, [dates.dateRange, dates.startDate, dates.endDate]);

  const getDefaultView = () => {
    // All-time goes weird when it defaults to the years
    if (dates.value === 'all_time') {
      return 'month';
    }

    if (dates.dateUnit === 'year') {
      if (dates.dateRange > 2) {
        return 'year';
      }
      return 'month';
    }

    if (dates.dateUnit === 'month') {
      if (dates.dateRange > 1) {
        return 'month';
      }

      return 'week';
    }

    if (dates.dateRange < 21) {
      return 'day';
    }

    if (dates.dateRange < 90) {
      return 'week';
    }

    return 'month';
  };

  const [view, setView] = useState(getDefaultView());

  // Hide certain things from the chart if there is too much data. Otherwise it becomes impossible to read
  const maxDataPointsToShowDots = 90; // In the chart
  const maxDataPointsToShowLabelButtons = 40; // Under the chart

  const { compareTransactionDetails } = transactionDetails;

  // Go back one year. If data is over multiple years, go back to start of data
  const pastYearStart = dayjs().subtract(1, 'year');
  let bookingsData = transactionDetails.pastYearTransactionDetails; // So we can see data that sits outside of the range we are looking at
  if (pastYearStart.isAfter(dates.startDate)) {
    bookingsData = transactionDetails.transactionDetails;
  }

  // Get items for the view (days, weeks, etc.)
  // This is a big function as is calculates revenue for the period as well as daily revenue and predictions for the period
  const revenueByGroup = useMemo(() => {
    const closedDays = getClosedDays(activeRestaurant.hoursParsed);

    const seasonalisedData = getSeasonalisedPredictionData(bookingsData, dates, closedDays);

    const endDate = dates.endDate;
    let startDate = dates.startDate;
    if (dates.value === 'all_time' && bookingsData?.length > 0) {
      // If looking at all time data, start from where the first booking is.
      // Also go back an extra week/month so the graph starts at 0
      startDate = dayjs(sortListByProperty(bookingsData, 'bookingDate')?.[0]?.bookingDate).subtract(
        1,
        view,
      );
    }

    // Get the list of dates and group them by their values
    const datesArray = uniqueObjectsByProperty(
      getDatesArray(startDate, endDate, view),
      'groupedDate',
    );

    // Group the data by the time period (day, week, etc.)
    const bookingsByGroup = groupObjects(
      bookingsData.map((booking) => ({
        ...booking,
        groupedDate: formatDateForView(booking?.created, view),
        date: formatDate(booking?.created, 'YYYY-MM-DD', '-'),
      })),
      'groupedDate',
    );

    const compareBookingsByDay = groupObjects(
      compareTransactionDetails.map((booking) => ({
        ...booking,
        groupedDate: formatDateForView(
          dayjs(booking?.created).add(dates.dateRange, 'days').format('YYYY-MM-DD'),
          view,
        ),
        date: dayjs(booking?.created).add(dates.dateRange, 'days').format('YYYY-MM-DD'),
      })),
      'groupedDate',
    );

    // Now we have the bookings grouped by day/week/month/year
    // Calculate the revenue for each of those groups
    const totalsByGroup = getTotalsByGroup(
      datesArray,
      bookingsByGroup,
      closedDays,
      compareBookingsByDay,
      activeRestaurant.averageOrderValue,
      activeRestaurant.averageBookingValue,
    );

    // devLog('debug', 'prediction chart debug', totalsByGroup, seasonalisedData);

    // Generate a predicted revenue for today, so it doesn't plummet to 0 on the graph
    // Calculate the average value, since the current day is usually stuck on 0
    const today = dayjs().format('YYYY-MM-DD');
    if (startDate.startOf('day').isBefore(today) && endDate.endOf('day').isAfter(today)) {
      const todayIndex = findObjectIndexByProperty(
        totalsByGroup,
        formatDateForView(today, view),
        'groupedDate',
      );

      // Get the appropriate end date for the grouping. e.g. by month we want the 30th/31st
      const groupEndDates = totalsByGroup.map((group) => getGroupEndDate(group?.dayjsDate, view));

      // Add in the prediction for each group
      return totalsByGroup.map((total, index) => {
        // If a past day, no need to show prediction
        if (index < todayIndex - 2) {
          return total;
        }

        // Add up all of the daily predictions for the time period
        const rangeForPeriod = getSimpleDatesArray(total?.dayjsDate, groupEndDates?.[index]);
        const predictedRevenue = Math.max(
          0,
          rangeForPeriod.reduce((accum, day) => {
            const totalForDay = seasonalisedData.seasonalisedData.find(
              (dayWithTotals) => dayWithTotals.date === day.date,
            );

            // If we have revenue, use that instead of the prediction
            if (totalForDay?.revenue > 0) {
              return accum + totalForDay?.revenue;
            }

            // Get from seasonalisedData
            const dayOfWeekAsInt = day.dayOfWeek;
            const seasonalValueForDay = seasonalisedData.daySeasonals?.[dayOfWeekAsInt] ?? 0;
            const indexForDay = total.dayjsDate.diff(seasonalisedData.startDate, 'days');

            // y=mx+c * seasonal average for that day (e.g. fridays should spike up)
            const prediction =
              seasonalisedData.regression.slope * indexForDay +
              seasonalisedData.regression.intercept;
            const seasonalisedPrediction = seasonalValueForDay * prediction;

            // devLog('debug', 'day prediction', {
            //   day,
            //   prediction,
            //   seasonalisedPrediction,
            //   indexForDay,
            //   seasonalValueForDay,
            //   accum,
            //   rangeForPeriod,
            //   total,
            //   groupEndDates,
            // });

            return accum + seasonalisedPrediction;
          }, 0),
        );

        // Don't show prediction if already surpassed it
        // if (revenuePredictionForGroup <= total.revenue) {
        //   return total;
        // }

        // Start the dotted line from yesterday's revenue
        if (index < todayIndex) {
          return {
            ...total,
            initialPredictedRevenue: total?.revenue,
          };
        }

        // This is a fix to get the graph to smoothly curve to the current date
        if (index === todayIndex) {
          return {
            ...total,
            initialPredictedRevenue: predictedRevenue,
            predictedRevenue,
          };
        }

        // Prevent showing a solid line for future dates, but show the prediction
        if (index > todayIndex) {
          return {
            revenue: null,
            predictedRevenue,
          };
        }

        // Predict all current and future dates as the average
        return {
          ...total,
          predictedRevenue,
        };
      });
    }

    return totalsByGroup;
  }, [
    activeRestaurant.averageBookingValue,
    activeRestaurant.averageOrderValue,
    activeRestaurant.hoursParsed,
    bookingsData,
    transactionDetails,
    compareTransactionDetails,
    dates,
    view,
  ]);

  // Whether we can show the labels underneath or if they've gotten too squished
  const showLabelButtons =
    view === 'day'
      ? revenueByGroup.length < maxDataPointsToShowLabelButtons * 7
      : revenueByGroup.length < maxDataPointsToShowLabelButtons;

  // Automatically switch the view if the dates changed
  useEffect(() => {
    setView(getDefaultView());
  }, [allowedViews]);

  const chartHasTooMuchData = revenueByGroup?.length >= maxDataPointsToShowDots;

  /*
  We want to make some adjustments if the data doesn't change much
  If it starts from 0, but you're looking at monthly or yearly, the line sits near the top and doesn't change much
  So if it's not really using the bottom half, let's not start at 0 and instead start at something more useful

  NOTE: Rechart doesn't use the domain value specifically. It tries to find a clean value to set it to
   */
  const maxValue = sortListByProperty(revenueByGroup, 'revenue', true)?.[0]?.revenue ?? 100;
  const minValue = sortListByProperty(revenueByGroup, 'revenue')?.[0]?.revenue ?? 0;
  const bottomHalfStart = maxValue / 2;
  const usesBottomHalf = minValue < bottomHalfStart;

  // Also figure out whether to round to the nearest hundred, thousand, etc.
  const roundingValue = calculateNearestUnit(minValue);
  const domain = usesBottomHalf
    ? [0, 'auto']
    : [roundToNearest(bottomHalfStart, roundingValue), 'auto'];

  // Stops jankiness when finished loading
  const hasData = transactionDetails.pastYearTransactionDetails.length > 0;
  const hasAnyRevenue = revenueByGroup.some((group) => group.revenue > 0);
  const isLoading = transactionDetails.fetching || !hasData;

  const lineAnimationDuration = 500;

  // Track the position of the active dot so we can show the tooltip next to it
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const graphRef = useRef(null);

  return (
    <Box className={classes.root}>
      <Box className={classes.graphHeader}>
        {revenueByGroup?.length > 0 && <Box className={classes.title}>Net Revenue</Box>}
        <DropdownTransparent items={allowedViews} onSelect={setView} value={view} />
      </Box>
      {!isLoading && hasAnyRevenue ? (
        <>
          {/* Graph here */}
          <Box className={classes.graphContainer} ref={graphRef}>
            <ResponsiveContainer width='100%'>
              <AreaChart
                animationDuration={100}
                width={1400}
                height={200}
                data={revenueByGroup}
                connectNulls
              >
                <defs>
                  <linearGradient id='colorAmount' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='20%' stopColor='#FFFFFF' stopOpacity={0.5} />
                    <stop offset='100%' stopColor='#FFFFFF' stopOpacity={0} />
                  </linearGradient>

                  {/* For predictions */}
                  <linearGradient id='dashedLine' x1='0' y1='0' x2='1' y2='0'>
                    <stop offset='50%' stopColor='#313131' stopOpacity={0} />
                    <stop offset='50%' stopColor='#313131' stopOpacity={1} />
                  </linearGradient>

                  <linearGradient id='dashedLineComplete' x1='0' y1='0' x2='1' y2='0'>
                    <stop offset='50%' stopColor='#313131' stopOpacity={1} />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  stroke={view !== 'day' ? theme.colors.chartDivider : '#00000055'}
                  horizontal={view !== 'day'}
                  verticalCoordinatesGenerator={(props) => {
                    // If in day view, draw a dashed line over every monday
                    const leftOffset = 45; // Because of the y axis labels being approx 40px wide
                    const totalWidth = props.xAxis.width;
                    const widthPerItem = safeDivision(totalWidth, props.xAxis.domain.length - 1);

                    // This only works on day view.
                    if (view !== 'day' || props.xAxis.domain.length < 21) {
                      return [];
                    }

                    // Get the x position of each monday
                    const mondays = props.xAxis.domain
                      .map((date, index) => {
                        if (dayjs(date).day() === 1) {
                          return index * widthPerItem + leftOffset - widthPerItem / 2;
                        }
                        return [];
                      })
                      .filter((item) => item > 0);

                    return [...mondays];
                  }}
                />

                <XAxis dataKey='groupedDate' hide />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  interval='preserveStartEnd'
                  allowDecimals={false}
                  tick={{
                    fontSize: '12px',
                    fontWeight: FONT_WEIGHTS.REGULAR,
                  }}
                  tickFormatter={(value) => formatCurrency(value, false, '', false, true)}
                  tickCount={chartHasTooMuchData ? 5 : 3}
                  stroke='#00000070' // Transparent black looks better on a coloured background than charcoal
                  // stroke='#31313177'
                  width={40}
                  dx={-8}
                  domain={domain}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  position={tooltipPosition}
                  allowEscapeViewBox={{ x: true, y: true }}
                  formatter={(value, name) => [
                    `$${value}`,
                    capitaliseFirstLetter(name)
                      // Inserts a space before each capital letter
                      .replace(/([A-Z])/g, ' $1')
                      .trim(),
                  ]}
                />
                <ReferenceDot cx={0} cy={0} r={20} stroke='1' />

                {/* NOTE: the order of these matters for the tooltip. The revenue must be first, then the prediction */}

                {/* Show all revenue with closed days excluded (the line) */}
                <Area
                  dataKey='revenue'
                  type='monotone'
                  stroke='#000000'
                  fill='url(#colorAmount)'
                  connectNulls
                  animationDuration={lineAnimationDuration}
                  // onMouseMove={onMouseEnter}
                />

                <Area
                  dataKey='predictedRevenue'
                  type='monotone'
                  fill='none'
                  strokeDasharray='4 4'
                  activeDot={<></>}
                  stroke='url(#dashedLineComplete)'
                  animationDuration={lineAnimationDuration}
                />

                {/* Curve to join predicted revenue to main revenue line */}
                <Area
                  dataKey='initialPredictedRevenue'
                  type='monotone'
                  fill='none'
                  strokeDasharray='4 4'
                  activeDot={<></>}
                  stroke={
                    revenueByGroup?.length === 2 ? 'url(#dashedLineComplete)' : 'url(#dashedLine)'
                  }
                  animationDuration={lineAnimationDuration}
                />

                {/* Just the dots and tooltip (so the 0 days always show in the correct spots) */}
                <Area
                  dataKey='revenue'
                  type='monotone'
                  stroke='none'
                  fill='none'
                  dot={<GraphDot visible={!chartHasTooMuchData} />}
                  activeDot={
                    <GraphDot
                      active
                      visible={!chartHasTooMuchData}
                      setPosition={(x, y) => setTooltipPosition({ x, y })}
                      graphRef={graphRef}
                    />
                  }
                  label={!chartHasTooMuchData ? <CustomLabel /> : null}
                  animationDuration={lineAnimationDuration}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>

          {/* Only show if there's space to */}
          {showLabelButtons && (
            <Box
              className={classes.performanceDayContainer}
              style={{
                paddingLeft: chartHasTooMuchData ? '60px' : '40px',
                paddingRight: chartHasTooMuchData ? '12px' : 0,
                justifyContent: revenueByGroup?.length > 1 ? 'space-between' : 'center',
              }}
            >
              {revenueByGroup.map((day) => (
                <Fragment key={day?.groupedDate}>
                  {!chartHasTooMuchData ? (
                    <Box
                      flex={1}
                      className={classes.performanceDay}
                      style={{
                        flexBasis: `calc(100% / ${revenueByGroup.length})`,
                      }}
                    >
                      <Box className={classes.monthName}>{day.groupedDateLabel?.preLabel}</Box>
                      <Box className={classes.dayName}>{day.groupedDateLabel?.primary}</Box>
                      <Box className={classes.monthName}>{day.groupedDateLabel?.secondary}</Box>
                    </Box>
                  ) : (
                    // If the chart has too much data, only show the mondays, so long as they can fit
                    <>
                      {graphRef.current?.offsetWidth > 360 && day?.dayjsDate?.day() === 1 ? (
                        <Box
                          key={day?.groupedDate}
                          flex={1}
                          className={classes.performanceDay}
                          style={{
                            flexBasis: `calc(100% / ${revenueByGroup.length / 7})`,
                            overflowX: 'visible',
                          }}
                        >
                          <Box className={classes.monthName}>{day.groupedDateLabel?.preLabel}</Box>
                          <Box className={classes.dayName}>{day.groupedDateLabel?.primary}</Box>
                          <Box className={classes.monthName}>{day.groupedDateLabel?.secondary}</Box>
                        </Box>
                      ) : (
                        <Box
                          key={day?.groupedDate}
                          flex={1}
                          // className={classes.performanceDay}
                          style={{
                            flexBasis: `calc(100% / ${revenueByGroup.length})`,
                          }}
                        />
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </Box>
          )}

          {/* Offer information TODO how to get? */}
          {/* <Box className={classes.dayOfferInfo}>
            <Box>
              3 offers disabled with expected revenue of <span>$2,300</span>
            </Box>
            <RoundButton>View</RoundButton>
          </Box> */}
        </>
      ) : (
        <>
          {isLoading ? (
            <Box className={classes.emptyStateContainer}>
              <GraphPlaceholder />
              <Box>Loading</Box>
            </Box>
          ) : (
            <Box className={classes.emptyStateContainer}>
              <GraphPlaceholder />
              <Box>
                Check back soon to track your revenue, or try choosing a different date range.
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

RevenueGraph.propTypes = {
  activeRestaurant: PropTypes.shape({
    objectId: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  dates: PropTypes.shape({}).isRequired,
  transactionDetails: transactionDetailsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
  transactionDetails: state.transactionDetails,
  activeRestaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RevenueGraph);
