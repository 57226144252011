import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    gap: '20px',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    alignItems: 'center',
  },
  topOpportunityRow: {
    width: '100%',
    margin: '0',
  },
  topOpportunityCard: {
    border: '1px solid #DCDCDB',
    borderRadius: '6px',
    color: '#313131',
    maxWidth: '380px',
  },
  opportunityCardContent: {
    padding: '24px 20px',
  },
  topOpportunityCardPoints: {
    fontSize: '16px',
    marginTop: '28px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  cardBottom: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',
    padding: '16px',
    // height: '48px',
    cursor: 'pointer',
    borderTop: '1px solid #DCDCDB',
    '&:hover': {
      backgroundColor: '#DCDCDB',
    },
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  mobileOnly: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  opportunityName: {
    fontSize: '18px',
    fontWeight: 500,

    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '18px',
    // },
  },
  opportunityDescription: {
    paddingTop: '8px',
    fontSize: '14px',

    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '14px',
    // },
  },
  opportunityDetailsContainer: {
    display: 'grid',
    width: '100%',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr',

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
  },
  opportunityDetails: {
    paddingTop: '20px',
    // fontSize: '16px',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: '12px 20px',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  opportunityDetailRow: {
    display: 'flex',
    gap: '10px 20px',
    flexWrap: 'wrap',
  },
  opportunityDetailColumnLeft: {
    display: 'flex',
    gap: '5px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  opportunityDetailColumnRight: {
    display: 'flex',
    gap: '5px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  opportunityDetailValue: {
    fontSize: '14px',
  },
  postButtonContainer: {},
  estimatesContainer: {
    display: 'grid',
    columnGap: '48px',
    gridTemplateColumns: '1fr 1fr',
    width: 'fit-content',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      columnGap: '20px',
    },
  },
  labelsContainer: {},
  largeNumber: {
    color: 'transparent',
    WebkitTextStroke: '1px #6E6E6D',
    fontSize: '44px',
    fontWeight: '900',
    position: 'absolute',
    left: '15px',
    top: '0',
  },
  largeNumberBackground: {
    height: '55px',
    width: '55px',
    backgroundColor: '#FAF8F5',
    borderRadius: '100%',
    display: 'inline-block',
  },
  label: {
    fontSize: '16px',
  },
  labelValueContainer: (props) => ({
    display: 'inline-flex',
    padding: '4px 16px',
    borderRadius: '12px',
    marginTop: '8px',
    fontSize: props?.compact ? '16px' : '18px',
  }),
  redLabel: {
    color: '#E7554B',
    fontWeight: '500',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
