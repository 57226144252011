import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { menusForRestaurant } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchMenusAction = (restId, version, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'MENUS',
  });
  dispatch({
    type: type.FETCH_MENUS_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: menusForRestaurant,
          variables: {
            restId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'menus', response.data.menusForRestaurant);

      const menusForVersion = response.data.menusForRestaurant?.filter(
        (menu) => menu?.version === version,
      );

      dispatch({
        type: type.FETCH_MENUS_SUCCESS,
        payload: menusForVersion,
      });
    } catch (error) {
      devLog('error', 'menus', error);

      dispatch({
        type: type.FETCH_MENUS_FAILURE,
        payload: `Unable to retrieve MENUS: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'MENUS',
      });
    }
  })();
};

export const clearMenusAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_MENUS,
  });
};
