/* eslint-disable react/forbid-prop-types */
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ObeeDatePicker, Popover } from '@eatclub-apps/ec-component-library';

const DatePopover = ({
  value,
  onSetDate,
  isOpen,
  onClose,
  anchor,
  datePickerProps,
  anchorOrigin,
  transformOrigin,
}) => {
  const handleCloseMenu = () => {
    onClose(null);
  };

  const changeDay = (newDay) => {
    onSetDate(dayjs(newDay));
    handleCloseMenu();
  };

  return (
    <Box style={{ position: 'relative' }}>
      <Popover
        isOpen={isOpen}
        onClose={() => handleCloseMenu()}
        anchorEl={anchor}
        innerStyle={{ marginTop: '10px', padding: '12px', borderRadius: '6px' }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Box>
          <ObeeDatePicker
            inputProps={{ inline: true, ...datePickerProps }}
            onSelect={changeDay}
            value={value}
          />
        </Box>
      </Popover>
    </Box>
  );
};

DatePopover.defaultProps = {
  anchor: null,
  datePickerProps: {},
  value: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

DatePopover.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  onSetDate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.object,
  datePickerProps: PropTypes.shape({}),
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DatePopover);
