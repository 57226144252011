import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { Button, Typography, Box } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useStyles from './TableStyles';
import { fetchMonthlyOrderSummariesAction } from '../../actions/monthlyOrderSummariesAction';
import { formatNumber } from '../../utils';
import { platformMap } from '../../constants';

const MonthlySummaryTable = ({
  fetchMonthlyOrderSummaries,
  monthlyOrderSummaries,
  restId,
  userId,
  userType,
  platform,
}) => {
  const classes = useStyles();
  const appName = platformMap[platform].name;

  useEffect(() => {
    if (!monthlyOrderSummaries.shouldFetch || monthlyOrderSummaries.errorMessage) {
      return;
    }

    fetchMonthlyOrderSummaries(restId, userId, userType, platform);
  }, [
    fetchMonthlyOrderSummaries,
    monthlyOrderSummaries.error,
    monthlyOrderSummaries.shouldFetch,
    restId,
    userId,
    userType,
  ]);

  const renderLink = (rowData) => {
    if (!rowData.pdfLink) {
      return null;
    }

    return (
      <Button variant='text' size='small' color='secondary' target='_blank' href={rowData.pdfLink}>
        <Launch style={{ marginRight: 6 }} /> {rowData.summaryNumber || 'View PDF'}
      </Button>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        title: '',
        field: 'summaryNumber',
        render: (rowData) => renderLink(rowData),
      },
      {
        title: 'Sales Period',
        field: 'salesPeriod',
      },
      {
        title: 'Amount',
        field: 'amount',
        render: (rowData) => `$${formatNumber(rowData.amount / 100)}`,
      },
    ],
    [],
  );

  return (
    <Box className={classes.tableStyles} style={{ marginBottom: '2rem' }}>
      <MaterialTable
        isLoading={monthlyOrderSummaries.fetching}
        title={
          <>
            <Typography variant='h6' display='inline' style={{ marginRight: '.25rem' }}>
              Monthly summary for online orders
            </Typography>
            <Typography variant='h6' color='textSecondary' display='inline'>
              (payment by customer in {appName} app)
            </Typography>
          </>
        }
        columns={tableColumns}
        data={monthlyOrderSummaries.data}
        options={{
          emptyRowsWhenPaging: false,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          padding: 'dense',
          search: false,
          draggable: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No orders to display',
          },
        }}
        style={{ marginBottom: '0.5rem' }}
      />
      <Typography variant='caption'> </Typography>
    </Box>
  );
};

MonthlySummaryTable.propTypes = {
  platform: PropTypes.string.isRequired,
  fetchMonthlyOrderSummaries: PropTypes.func.isRequired,
  monthlyOrderSummaries: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    shouldFetch: PropTypes.bool.isRequired,
  }).isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  monthlyOrderSummaries: state.monthlyOrderSummaries,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  platform: state.platform.platform,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMonthlyOrderSummaries: fetchMonthlyOrderSummariesAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MonthlySummaryTable);
