import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { getAppVersion } from '../utils/helpers';
import { makeApiAction } from './actions';
import * as type from './types';
import {
  bookingsForAnalytics,
  historicalOffers,
  impressions,
  popularMenuItems,
  transactionDetails,
} from '../graphql/queries';
import { devLog } from '../utils';

const fetchBookings = (
  resolve,
  reject,
  restId,
  region,
  startDate,
  endDate,
  platform,
  bookingType,
  userId,
  userType,
) => {
  const paginatedBookings = [];

  const bookingsLoop = async (pageSize, offset) => {
    try {
      const response = await API.graphql(
        {
          query: bookingsForAnalytics,
          variables: { restId, region, pageSize, offset, startDate, endDate, platform },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const {
        data: { bookingsForAnalytics: bookings },
      } = response;

      if (!bookings) {
        throw new Error('analytics null');
      }

      // congregate data
      if (bookings.length > 0) {
        paginatedBookings.push(...bookings);
      }

      // But wait, there's more!
      if (bookings.length >= pageSize) {
        bookingsLoop(pageSize, offset + pageSize);
        return;
      }

      devLog('success', bookingType, paginatedBookings);

      // Success resolve
      resolve({ [bookingType]: paginatedBookings });
    } catch (error) {
      devLog('error', bookingType, error);

      reject(error);
    }
  };
  bookingsLoop(500, 0);
};

/**
 * Fetch the bookings analytics two times
 *
 * 1. For the current period (e.g. one week)
 * 2. For the previous period (e.g. the week before)
 *
 * @param restId
 * @param region
 * @param startDate
 * @param endDate
 * @param compareStartDate
 * @param compareEndDate
 * @param platform
 * @param userId
 * @param userType
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchAnalyticBookingsAction =
  (
    restId,
    region,
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    platform,
    userId,
    userType,
  ) =>
  async (dispatch) => {
    dispatch({
      type: type.FETCH_ANALYTIC_BOOKINGS_PENDING,
    });

    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'ANALYTIC_BOOKINGS',
    });

    const promiseBookings = new Promise((resolve, reject) => {
      fetchBookings(
        resolve,
        reject,
        restId,
        region,
        startDate,
        endDate,
        platform,
        'bookings',
        userId,
        userType,
      );
    });

    const promiseCompareBookings = new Promise((resolve, reject) => {
      fetchBookings(
        resolve,
        reject,
        restId,
        region,
        compareStartDate,
        compareEndDate,
        platform,
        'compareBookings',
        userId,
        userType,
      );
    });

    try {
      const response = await Promise.all([promiseBookings, promiseCompareBookings]);

      devLog('success', 'analytic bookings', response);

      dispatch({
        type: type.FETCH_ANALYTIC_BOOKINGS_SUCCESS,
        payload: {
          bookings: response[0].bookings,
          compareBookings: response[1].compareBookings,
        },
      });
    } catch (error) {
      devLog('error', 'analytic bookings', error);
      dispatch({
        type: type.FETCH_ANALYTIC_BOOKINGS_FAILURE,
        payload: `Unable to retrieve analytics: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ANALYTIC_BOOKINGS',
      });
    }
  };

export const fetchPastYearAnalyticsAction =
  (restId, region, platform, userId, userType) => async (dispatch) => {
    dispatch({
      type: type.FETCH_PAST_YEAR_ANALYTICS_PENDING,
    });

    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'FETCH_PAST_YEAR_ANALYTICS',
    });

    const today = dayjs().format('YYYY-MM-DD');
    const lastYearToday = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    const promisePastYearBookings = new Promise((resolve, reject) => {
      fetchBookings(
        resolve,
        reject,
        restId,
        region,
        lastYearToday,
        today,
        platform,
        'bookings',
        userId,
        userType,
      );
    });

    try {
      const response = await Promise.all([promisePastYearBookings]);

      devLog('success', 'past year bookings', response);

      dispatch({
        type: type.FETCH_PAST_YEAR_ANALYTICS_SUCCESS,
        payload: {
          pastYearBookings: response[0].bookings,
        },
      });
    } catch (error) {
      devLog('error', 'past year bookings', error);
      dispatch({
        type: type.FETCH_PAST_YEAR_ANALYTICS_FAILURE,
        payload: `Unable to retrieve past year bookings: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'FETCH_PAST_YEAR_ANALYTICS',
      });
    }
  };

const fetchImpressionsRequest = async (
  resolve,
  reject,
  restId,
  region,
  startDate,
  endDate,
  bookingType,
  userId,
  userType,
) => {
  try {
    const response = await API.graphql(
      {
        query: impressions,
        variables: { restId, startDate, endDate },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      },
      {
        'user-id': userId,
        'user-type': userType,
        'rest-id': restId,
        'app-version': getAppVersion(),
      },
    ).catch((error) => {
      throw new Error(error.errors[0].message);
    });

    // destructure
    const {
      data: { impressions: impressionsData },
    } = response;

    // if (!impressionsData) {
    //   throw new Error('impressions null');
    // }

    devLog('success', bookingType, impressionsData);

    // Success resolve
    resolve({ [bookingType]: impressionsData });
  } catch (error) {
    devLog('error', bookingType, error);

    reject(error);
  }
};

/**
 * Fetch the impressions two times
 *
 * 1. For the current period (e.g. one week)
 * 2. For the previous period (e.g. the week before)
 *
 * NOTE - does not take platform like analytics does
 *
 * @param restId
 * @param region
 * @param startDate
 * @param endDate
 * @param compareStartDate
 * @param compareEndDate
 * @param userId
 * @param userType
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchImpressionsAction =
  (restId, region, startDate, endDate, compareStartDate, compareEndDate, userId, userType) =>
  async (dispatch) => {
    dispatch({
      type: type.FETCH_ANALYTIC_IMPRESSIONS_PENDING,
    });

    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'ANALYTIC_IMPRESSIONS',
    });

    const today = dayjs().format('YYYY-MM-DD');
    const lastYearToday = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    const promisePastYearImpressions = new Promise((resolve, reject) => {
      fetchImpressionsRequest(
        resolve,
        reject,
        restId,
        region,
        lastYearToday,
        today,
        'pastYearImpressions',
        userId,
        userType,
      );
    });

    const promiseImpressions = new Promise((resolve, reject) => {
      fetchImpressionsRequest(
        resolve,
        reject,
        restId,
        region,
        startDate,
        endDate,
        'impressions',
        userId,
        userType,
      );
    });

    const promiseCompareImpressions = new Promise((resolve, reject) => {
      fetchImpressionsRequest(
        resolve,
        reject,
        restId,
        region,
        compareStartDate,
        compareEndDate,
        'compareImpressions',
        userId,
        userType,
      );
    });

    try {
      const response = await Promise.all([
        promiseImpressions,
        promiseCompareImpressions,
        promisePastYearImpressions,
      ]);

      devLog('success', 'analytic impressions', response);

      dispatch({
        type: type.FETCH_ANALYTIC_IMPRESSIONS_SUCCESS,
        payload: {
          impressions: response[0].impressions,
          compareImpressions: response[1].compareImpressions,
          pastYearImpressions: response[2].pastYearImpressions,
        },
      });
    } catch (error) {
      devLog('error', 'analytic impressions', error);
      dispatch({
        type: type.FETCH_ANALYTIC_IMPRESSIONS_FAILURE,
        payload: `Unable to retrieve impressions: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ANALYTIC_IMPRESSIONS',
      });
    }
  };

const fetchPopularItemsRequest = async (
  resolve,
  reject,
  restId,
  region,
  startDate,
  endDate,
  itemType,
  userId,
  userType,
) => {
  try {
    const response = await API.graphql(
      {
        query: popularMenuItems,
        variables: { restId, startDate, endDate },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      },
      {
        'user-id': userId,
        'user-type': userType,
        'rest-id': restId,
        'app-version': getAppVersion(),
      },
    ).catch((error) => {
      throw new Error(error.errors[0].message);
    });

    // destructure
    const {
      data: { popularMenuItems: popularMenuItemsData },
    } = response;

    devLog('success', 'PopularMenuItems sub request', popularMenuItemsData);

    // Success resolve
    resolve({ [itemType]: popularMenuItemsData });
  } catch (error) {
    devLog('error', type, error);

    reject(error);
  }
};

export const fetchPopularMenuItemsAction =
  (restId, region, startDate, endDate, compareStartDate, compareEndDate, userId, userType) =>
  async (dispatch) => {
    dispatch({
      type: type.FETCH_MENU_ITEM_ANALYTICS_PENDING,
    });

    const promiseItems = new Promise((resolve, reject) => {
      fetchPopularItemsRequest(
        resolve,
        reject,
        restId,
        region,
        startDate,
        endDate,
        'popularMenuItems',
        userId,
        userType,
      );
    });

    const promiseCompareItems = new Promise((resolve, reject) => {
      fetchPopularItemsRequest(
        resolve,
        reject,
        restId,
        region,
        compareStartDate,
        compareEndDate,
        'comparePopularMenuItems',
        userId,
        userType,
      );
    });

    try {
      const response = await Promise.all([promiseItems, promiseCompareItems]);

      devLog('success', 'PopularMenuItems', response);

      dispatch({
        type: type.FETCH_MENU_ITEM_ANALYTICS_SUCCESS,
        payload: {
          menuItems: response[0].popularMenuItems,
          compareMenuItems: response[1].comparePopularMenuItems,
        },
      });
    } catch (error) {
      devLog('error', 'PopularMenuItems', error);

      dispatch({
        type: type.FETCH_MENU_ITEM_ANALYTICS_FAILURE,
        payload: `Unable to retrieve popular menu items: ${error}`,
      });
    }
  };

const fetchTransactionDetails = (
  resolve,
  reject,
  restId,
  startDate,
  endDate,
  platform,
  bookingType,
  userId,
  userType,
) => {
  const paginatedTransactions = [];

  const transactionsLoop = async (pageSize, offset) => {
    try {
      // TODO refactor with new apiCall function
      const response = await API.graphql(
        {
          query: transactionDetails,
          variables: { restId, pageSize, offset, startDate, endDate },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const transactions = response.data.transactionDetails?.results;

      if (!transactions) {
        throw new Error('transactions null');
      }

      // congregate data
      if (transactions.length > 0) {
        paginatedTransactions.push(...transactions);
      }

      // But wait, there's more!
      if (transactions.length >= pageSize) {
        transactionsLoop(pageSize, offset + pageSize);
        return;
      }

      devLog('success', bookingType, paginatedTransactions);

      // Success resolve
      resolve({ [bookingType]: paginatedTransactions });
    } catch (error) {
      devLog('error', bookingType, error);

      reject(error);
    }
  };
  transactionsLoop(2000, 0);
};

/**
 * Fetch the bookings analytics two times
 *
 * 1. For the current period (e.g. one week)
 * 2. For the previous period (e.g. the week before)
 *
 * @param restId
 * @param startDate
 * @param endDate
 * @param compareStartDate
 * @param compareEndDate
 * @param platform
 * @param userId
 * @param userType
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchTransactionDetailsAction =
  (restId, startDate, endDate, compareStartDate, compareEndDate, platform, userId, userType) =>
  async (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'TRANSACTION_DETAILS',
    });

    const promiseTransactions = new Promise((resolve, reject) => {
      fetchTransactionDetails(
        resolve,
        reject,
        restId,
        startDate,
        endDate,
        platform,
        'transactionDetails',
        userId,
        userType,
      );
    });

    const promiseCompareTransactions = new Promise((resolve, reject) => {
      fetchTransactionDetails(
        resolve,
        reject,
        restId,
        compareStartDate,
        compareEndDate,
        platform,
        'compareTransactionDetails',
        userId,
        userType,
      );
    });

    try {
      const response = await Promise.all([promiseTransactions, promiseCompareTransactions]);

      devLog('success', 'transaction details complete', response);

      dispatch({
        type: type.FETCH_TRANSACTION_DETAILS_SUCCESS,
        payload: {
          transactionDetails: response[0].transactionDetails ?? [],
          compareTransactionDetails: response[1].compareTransactionDetails ?? [],
        },
      });
    } catch (error) {
      devLog('error', 'analytic bookings', error);
      dispatch({
        type: type.FETCH_TRANSACTION_DETAILS_FAILURE,
        payload: `Unable to retrieve transaction details: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'TRANSACTION_DETAILS',
      });
    }
  };

/**
 * Fetch the transaction details for the past year
 *
 * @param restId
 * @param startDate
 * @param endDate
 * @param compareStartDate
 * @param compareEndDate
 * @param platform
 * @param userId
 * @param userType
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchPastYearTransactionDetailsAction =
  (restId, startDate, endDate, compareStartDate, compareEndDate, platform, userId, userType) =>
  async (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'PAST_YEAR_TRANSACTION_DETAILS',
    });

    const today = dayjs().format('YYYY-MM-DD');
    const lastYearToday = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    const promisePastYearTransactions = new Promise((resolve, reject) => {
      fetchTransactionDetails(
        resolve,
        reject,
        restId,
        lastYearToday,
        today,
        platform,
        'pastYearTransactionDetails',
        userId,
        userType,
      );
    });

    try {
      const response = await Promise.all([promisePastYearTransactions]);

      devLog('success', 'past year transaction details complete', response);

      dispatch({
        type: type.FETCH_PAST_YEAR_TRANSACTION_DETAILS_SUCCESS,
        payload: {
          pastYearTransactionDetails: response[0].pastYearTransactionDetails ?? [],
        },
      });
    } catch (error) {
      devLog('error', 'past year transaction details', error);
      dispatch({
        type: type.FETCH_TRANSACTION_DETAILS_FAILURE,
        payload: `Unable to retrieve past year transaction details: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'PAST_YEAR_TRANSACTION_DETAILS',
      });
    }
  };

/**
 * Fetch the historical offers
 *
 * @param startDate
 * @param endDate
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchHistoricalOffersAction = (startDate, endDate) => async (dispatch) => {
  const action = 'FETCH_HISTORICAL_OFFERS';
  const variables = { startDate, endDate };

  await dispatch(
    makeApiAction(
      action,
      historicalOffers,
      variables,
      null,
      'historicalOffers',
      true,
      dispatch,
      null,
      {},
      true,
      null,
      true,
    ),
  );
};
