import dayjs from 'dayjs';

/**
 * Sort Transactions by Created Date, and Arrival Time
 *
 * @param transaction1 first transaction
 * @param transaction2 next transaction
 */

export const sortTransactionsByDate = (transaction1, transaction2, direction = 'asc') => {
  const time1 = dayjs(transaction1.arrivalTime.toLowerCase(), 'h:mm a');
  const time2 = dayjs(transaction2.arrivalTime.toLowerCase(), 'h:mm a');

  const date1 = dayjs(transaction1.created)
    .startOf('day')
    .add(time1.hour(), 'hours')
    .add(time1.minute(), 'minutes');

  const date2 = dayjs(transaction2.created)
    .startOf('day')
    .add(time2.hour(), 'hours')
    .add(time2.minute(), 'minutes');

  if (date1.isBefore(date2)) {
    return direction === 'asc' ? -1 : 1;
  }
  if (date1.isAfter(date2)) {
    return direction === 'asc' ? 1 : -1;
  }
  return 0;
};
