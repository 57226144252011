import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { inAppOrderById } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchOrderAction = (orderId, region, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'ORDER',
  });
  dispatch({
    type: type.FETCH_ORDER_PENDING,
  });

  const getData = async () => {
    try {
      // fetch order
      const response = await API.graphql(
        {
          query: inAppOrderById,
          variables: {
            orderId,
            region,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const {
        data: { inAppOrderById: orders },
      } = response;

      devLog('success', 'orders', orders);

      dispatch({
        type: type.FETCH_ORDER_SUCCESS,
        payload: orders,
      });
    } catch (error) {
      devLog('error', 'orders', error);

      dispatch({
        type: type.FETCH_ORDER_FAILURE,
        payload: `Unable to retrieve order: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ORDER',
      });
    }
  };
  getData();
};

export const clearOrderAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_ORDER,
  });
};
