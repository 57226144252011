import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatCurrency } from '../../utils/helpers';
import useStyles from './RevenueGraphStyles';

const CustomTooltip = ({ payload }) => {
  const theme = useTheme();
  const classes = useStyles();

  const label = `${payload?.[0]?.payload?.groupedDateLabel?.tooltipLabel}`;
  const closedForDay = payload?.[0]?.payload?.closedForDay;

  const revenuePayload = payload[0];
  const predictionPayload = payload[1];

  if (payload.length >= 2) {
    // If we're showing two different values, we're showing a prediction
    if (revenuePayload?.value !== predictionPayload?.value) {
      return (
        <div className={classes.tooltipContainer}>
          <div className={classes.tooltipDate}>{label}</div>

          {/* If there's no revenue (such as for future days), emphasise the prediction */}
          {revenuePayload.value > 0 ? (
            <>
              <div>
                <span style={{ fontWeight: theme.fontWeights.medium, fontSize: '18px' }}>
                  {formatCurrency(revenuePayload.value, false)}
                </span>
              </div>

              <div>
                <span style={{ fontSize: '12px' }}>
                  {formatCurrency(predictionPayload.value, false)}
                  <br />
                  Forecasted
                </span>
              </div>
            </>
          ) : (
            <div>
              <span style={{ fontWeight: theme.fontWeights.medium, fontSize: '18px' }}>
                {formatCurrency(predictionPayload.value, false)}
              </span>
              <br />
              Forecasted
            </div>
          )}
        </div>
      );
    }
  }

  // Show something different on days that are closed?
  if (closedForDay) {
    return (
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipDate}>{label}</div>
        <div>Closed</div>
      </div>
    );
  }

  // // Show something different on days that are closed?
  // if (payload[0]?.value === 0) {
  //   return (
  //     <div className={classes.tooltipContainer}>
  //       <div className={classes.tooltipDate}>{label}</div>
  //       <div>No revenue data available</div>
  //     </div>
  //   );
  // }

  // Otherwise just show the revenue
  return (
    <div className={classes.tooltipContainer}>
      <div className={classes.tooltipDate}>{label}</div>

      <div style={{ margin: '8px 0' }}>
        <span style={{ fontWeight: theme.fontWeights.medium, fontSize: '18px' }}>
          {formatCurrency(payload?.[0]?.value, false)}
        </span>
      </div>
    </div>
  );
};

CustomTooltip.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        groupedDateLabel: PropTypes.shape({
          primary: PropTypes.string,
          secondary: PropTypes.string,
          tooltipLabel: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomTooltip);
