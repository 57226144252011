import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import { fetchTransactionBookingsAction } from '../../actions/transactionsAction';
import { fetchWhiteLabelOrdersAction } from '../../actions/whiteLabelOrdersAction';
import { usePageViews } from '../../utils/analytics';
import BookingsTable from './BookingsTable';
import Error from '../error/Error';
import BookingsOrder from './BookingsOrder';
import { subEventsAction, unsubEventsAction } from '../../actions/subEventsAction';
import { devLog, sortTransactionsByDate, titleCaseFromCamel } from '../../utils';
import { setDateRangeAction, setDatesAction } from '../../actions/datePickerAction';

// Transactions.
const Bookings = ({
  dates,
  fetchTransactionBookings,
  fetchWhiteLabelOrders,
  region,
  restId,
  subEvents,
  transactions,
  whiteLabelOrders,
  unsubEvents,
  userId,
  userType,
  selectedPlatform,
}) => {
  const { startDate, endDate } = dates;
  usePageViews();

  // Subscription to new bookings
  useEffect(() => {
    const today = dayjs();

    // NOTE: subscribe only if today is visible during datepicker dates
    const shouldSubscribe = today.isBetween(startDate, endDate, 'day', '[]');

    if (!shouldSubscribe) {
      return;
    }

    subEvents(restId, userId);

    // eslint-disable-next-line consistent-return
    return () => {
      unsubEvents();
    };
  }, [restId, endDate, subEvents, unsubEvents]);

  useEffect(() => {
    // Inclusive of StartDate, and exclusive of EndDate
    const formatStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formatEndDate = dayjs(endDate).format('YYYY-MM-DD');

    fetchTransactionBookings(restId, region, formatStartDate, formatEndDate, userId, userType);
    fetchWhiteLabelOrders(region, formatStartDate, formatEndDate);
  }, [
    restId,
    region,
    endDate,
    fetchTransactionBookings,
    fetchWhiteLabelOrders,
    startDate,
    userId,
    userType,
  ]);

  // format the bookings whether white label or eatclub
  const formatBookings = (bookingsData) => {
    if (bookingsData.fetching || bookingsData.error) {
      return [];
    }

    const getType = (
      inAppOrder,
      transactionId,
      status,
      takeaway,
      tableOrders,
      platform,
      tableNum,
    ) => {
      if (platform === 'eatclub') {
        // Eatclub Orders
        if (tableOrders) {
          return 'tableOrder';
        }

        if (inAppOrder && transactionId !== null) {
          return 'inAppOrder';
        }
        if (takeaway) {
          return 'takeAway';
        }
        return 'dineIn';
      }

      if (platform === 'whitelabel') {
        // WhiteLabel Orders
        if (tableNum) {
          return 'tableOrder';
        }
        return 'takeAway';
      }

      devLog('error', 'order has no platform.');
      return '';
    };

    const bookingObject = bookingsData.data
      .map((booking) => {
        const {
          arrivalTime,
          created,
          discount,
          inAppOrder,
          issueStatus,
          name,
          objectId,
          orderId,
          partySize,
          platform,
          promoTitle,
          refundAmount,
          refundType,
          status,
          tableNum,
          tableOrders,
          takeaway,
          total,
          transactionId,
        } = booking;

        const bookingReference = orderId ? orderId.slice(-6) : objectId.slice(-6);
        const date = dayjs(created).format(import.meta.env.VITE_DATE_FORMAT);
        const arrivalDateTime = `${date}, ${arrivalTime.toLowerCase()}`;
        const orderType = getType(
          inAppOrder,
          transactionId,
          status,
          takeaway,
          tableOrders,
          platform,
          tableNum,
        );

        const getStatus = () => {
          if (inAppOrder && !status) {
            return 'Completed';
          }

          if (!issueStatus && !status) {
            return null;
          }

          const text = [];

          if (issueStatus) {
            if (issueStatus === 'partySizeReported') {
              text.push('Party Size Changed');
            } else {
              text.push(titleCaseFromCamel(issueStatus));
            }
          }

          if (status) {
            text.push(titleCaseFromCamel(status));
          }

          return text.join(', ');
        };

        const getOrderDetail = () => {
          if (platform === 'whitelabel' || (platform === 'eatclub' && orderType === 'inAppOrder')) {
            return <BookingsOrder orderId={orderId} region={region} />;
          }

          return '';
        };

        const object = {
          created,
          arrivalTime,
          arrivalDateTime,
          bookingId: objectId,
          bookingReference,
          deal: discount,
          issueStatus,
          new: booking._new,
          objectId: orderId || objectId,
          order: getOrderDetail(),
          orderId,
          partySize,
          platform,
          promoDeal: promoTitle || '',
          refundAmount,
          refundType,
          reservationName: name,
          status: getStatus(),
          tableNum,
          tableOrders,
          total: total ? parseFloat(total) : null,
          type: orderType,
        };

        return object;
      })
      .filter(
        (bookingRow) => transactions.filter === 'all' || bookingRow?.type === transactions.filter,
      );

    return bookingObject;
  };

  // const [bookings, setBookings] = useState([]);
  const bookings = useMemo(() => {
    return formatBookings(transactions);
  }, [
    region,
    formatBookings,
    transactions.data,
    transactions.error,
    transactions.fetching,
    transactions.filter,
  ]);

  const whiteLabelBookings = useMemo(() => {
    return formatBookings(whiteLabelOrders);
  }, [
    region,
    formatBookings,
    whiteLabelOrders.data,
    whiteLabelOrders.error,
    whiteLabelOrders.fetching,
    transactions.filter,
  ]);

  return (
    <>
      {transactions.error && (
        <Error error={transactions.error} message={transactions.errorMessage} />
      )}

      {selectedPlatform === 'whitelabel' ? (
        <BookingsTable bookings={whiteLabelBookings} isLoading={whiteLabelOrders.fetching} />
      ) : (
        <BookingsTable bookings={bookings} isLoading={transactions.fetching} />
      )}
    </>
  );
};

Bookings.propTypes = {
  dates: PropTypes.shape({
    endDate: PropTypes.instanceOf(dayjs),
    startDate: PropTypes.instanceOf(dayjs),
  }).isRequired,
  fetchTransactionBookings: PropTypes.func.isRequired,
  fetchWhiteLabelOrders: PropTypes.func.isRequired,
  subEvents: PropTypes.func.isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
  }).isRequired,
  whiteLabelOrders: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
  }).isRequired,
  unsubEvents: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  selectedPlatform: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { data } = state.transactions;
  const sortedData = data.sort((a, b) => sortTransactionsByDate(a, b, 'desc'));
  const sortedWhiteLabelData = state.whiteLabelOrders.data.sort((a, b) =>
    sortTransactionsByDate(a, b, 'desc'),
  );

  return {
    transactions: { data: sortedData, ...state.transactions },
    whiteLabelOrders: { data: sortedWhiteLabelData, ...state.whiteLabelOrders },
    dates: state.dates,
    restId: state.restaurantActive.restaurant.objectId,
    region: state.restaurantActive.restaurant.region,
    userId: state.user.userInfo.objectId,
    userType: state.user.userInfo.userType,
    activeRestaurant: state.restaurantActive.restaurant,
    selectedPlatform: state.platform.platform,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTransactionBookings: fetchTransactionBookingsAction,
      fetchWhiteLabelOrders: fetchWhiteLabelOrdersAction,
      subEvents: subEventsAction,
      unsubEvents: unsubEventsAction,
      setDates: setDatesAction,
      setDateRange: setDateRangeAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
