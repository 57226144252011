import { API } from 'aws-amplify';
import { getAppVersion, isVoid } from '../utils/helpers';
import { devLog } from '../utils';
import store from '../store';
import * as type from '../actions/types';

/**
 *
 * `EXPERIMENTAL` This is a copy of makeApiAction that is async to use with the
 * useDispatch react-redux hook which will allow .then(), .catch(), .finally() on the call
 *
 * @deprecated TODO The use-cases for this should already be covered by makeApiAction in actions.js
 *
 * */
export const createAsyncAction =
  (
    action, // the action type (e.g. 'CREATE_DEAL')
    query, // the api query/mutation
    variables, // the api variables
    model, // the api query/mutation name (e.g. 'createDeal')
    successMessage = undefined, // success message to toast
    toastErrorMessage = false, // whether to toast the error
    includeRestId = false, // whether to include the restId in the list of variables
  ) =>
  async (dispatch) => {
    try {
      const state = store.getState();
      const restId = state.restaurantActive.restaurant.objectId;
      const userId = state.user.userInfo.objectId;
      const { userType } = state.user.userInfo;

      if (includeRestId && isVoid(restId)) {
        devLog('error', model, 'restId is not set');
        throw new Error('Sorry, an unexpected error occurred.');
      }

      const newVariables = { ...(includeRestId ? { restId } : {}), ...variables };

      dispatch({
        type: type.SET_ID_APP_LOADING,
        payload: action,
      });

      dispatch({
        type: `${action}_PENDING`,
        payload: {
          variables: newVariables,
        },
      });

      const response = await API.graphql(
        {
          query,
          variables: newVariables,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw error.errors[0].message;
      });

      const data = response.data[model];

      devLog('success', model, data);

      dispatch({
        type: `${action}_SUCCESS`,
        payload: { data, variables: newVariables },
      });

      if (successMessage) {
        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `${action}_SUCCESS_${new Date().getTime()}`,
            message: successMessage,
            severity: 'success',
          },
        });
      }

      return { data, variables: newVariables }; // pass it back in the .then() statement
    } catch (errorMessage) {
      devLog('error', model, errorMessage);

      dispatch({
        type: `${action}_FAILURE`,
        payload: errorMessage,
      });

      if (toastErrorMessage) {
        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `${action}_ERROR_${new Date().getTime()}`,
            message: `${errorMessage}`,
            severity: 'error',
          },
        });
      }

      throw errorMessage;
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: action,
      });
    }
  };
