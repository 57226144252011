import React from 'react';
import PropTypes from 'prop-types';
import useStyles, { INSIGHT_TAB } from './PillarModalStyles';

export const PillarModalRowItem = ({
  label,
  value,
  variant = 'outlined',
  color = INSIGHT_TAB.IMPRESSIONS,
  smallText = null,
}) => {
  const classes = useStyles({ variant, tab: color });
  return (
    <div className={classes.rowItemContainer}>
      <div className={classes.rowItemInnerContainer}>
        <div>{label}</div>
        <div className={classes.rowItemInnerContainerRight}>
          <div>{value}</div>
          {smallText && <div className={classes.smallText}>{smallText}</div>}
        </div>
      </div>
    </div>
  );
};

PillarModalRowItem.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['outlined', 'filled']),
  color: PropTypes.oneOf([
    INSIGHT_TAB.NET_REVENUE,
    INSIGHT_TAB.AOV,
    INSIGHT_TAB.CUSTOMERS,
    INSIGHT_TAB.IMPRESSIONS,
  ]),
  smallText: PropTypes.string,
};
