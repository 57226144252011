import { makeStyles } from '@mui/styles';
import { BORDER_RADII, COLORS } from '../../EatClubTheme';

const getThemeForType = (type) => {
  const themes = {
    green: {
      backgroundColor: '#E7F0E6',
      color: '#467741',
    },
    red: {
      color: COLORS.PRIMARY_RED_MATTE,
      backgroundColor: COLORS.GRAPEFRUIT,
    },
    gray: {
      color: COLORS.CONFIRMED_GREEN_TEXT,
      backgroundColor: COLORS.CLOUD,
    },
    white: {
      color: '#313131',
      backgroundColor: COLORS.WHITE,
      border: '1px solid #E4E4E4',
      borderRadius: '4px',
    },
    yellow: {
      backgroundColor: '#F8F0E3',
      color: COLORS.CHARCOAL,
    },
    blue: {
      backgroundColor: '#DEE8E6',
      color: COLORS.CHARCOAL,
    },
    olive: {
      backgroundColor: COLORS.OLIVE_2,
      color: COLORS.WHITE,
    },
    orange: {
      backgroundColor: '#f5efd2',
      color: '#816f14',
    },
    linen: {
      backgroundColor: '#EFEADE',
    },
  };

  if (!Object.keys(themes).includes(type)) {
    return themes.gray;
  }

  return themes[type];
};

const useStyles = makeStyles(() => ({
  label: (props) => ({
    padding: '3px 9px',
    borderRadius: props?.rounded ? '50px' : BORDER_RADII.M,
    ...getThemeForType(props?.type),
    fontSize: '12px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    ...props?.style,
  }),
}));

export default useStyles;
