import * as type from './types';
import { predictionsForOffers } from '../graphql/queries';
import { makeApiAction } from './actions';
// import { makeApiAction } from './liveBookings/actions';

/**
 * Fetch a list of predictions for create an offer
 * @param {*} restId
 * @param {*} offers (e.g. [10, 20, 30, 40, 50])
 * @param {*} lightning
 * @param {*} dineIn
 * @param {*} takeaway
 * @param {*} drinksOnly
 * @param {*} startTime
 * @param {*} endTime
 * @param {*} startDate
 * @param {*} recurringDays
 * @returns
 */
export const fetchPredictionsForOffersAction =
  (
    restId,
    offers,
    lightning,
    dineIn,
    takeaway,
    drinksOnly,
    startTime,
    endTime,
    startDate,
    recurringDays,
  ) =>
  (dispatch) => {
    console.log('fetching predictions for offer');
    const action = 'FETCH_PREDICTIONS_FOR_OFFER';

    const variables = {
      restId,
      offers,
      lightning,
      dineIn,
      takeaway,
      drinksOnly,
      startTime,
      endTime,
      startDate,
      recurringDays,
    };

    dispatch(
      makeApiAction(
        action,
        predictionsForOffers,
        variables,
        {},
        'predictionsForOffers',
        false,
        dispatch,
        null,
        null,
        false,
        'Unable to predict offer',
        true,
      ),
    );
  };
