import { makeStyles } from '@mui/styles';
import { COLORS } from '../EatClubTheme';

import { drawerWidth } from '../constants';

const useStyles = makeStyles(
  (theme) => ({
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        overflowX: 'hidden',
        flexShrink: 0,
      },
      '@media print': {
        visibility: 'hidden', // hide side nav for runsheet print
      },
    },
    drawerPaper: {
      width: drawerWidth,
      overflowX: 'hidden',
      background: COLORS.LINEN,
      borderRight: 'none',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      gap: '12px',
    },
    label: {
      color: '#313131',
      backgroundColor: '#FCFCCF',
      padding: '4px 8px',
      border: '1px solid #313131',
      fontSize: '12px',
      borderRadius: '12px',
      letterSpacing: 2,
      fontWeight: 500,
      textTransform: 'uppercase',
      boxShadow: '0px 4px 4px 0px #CFCFCF40',
      lineHeight: 'normal',
    },
  }),
  { name: 'AppLayout' },
);

export default useStyles;
