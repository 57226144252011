import dayjs from 'dayjs';
import { calculateRevenueOnly } from '../calculateRevenue';
import {
  flatten,
  formatDate,
  formatIntFromTime,
  formatTimeFromInt,
  getDayOfWeekWithMondayFirst,
  groupByProperty,
  groupObjects,
  numberArray,
  roundToNearest,
  safeDivision,
  safeParse,
  uniqueObjectsByProperty,
  isEmpty,
  empty,
  findObjectByProperty,
} from '../helpers';
import { sortAlphaNumeric, sortListByProperty } from '../listHelpers';

/**
 * Calculates and returns the total revenue for the bookings provided
 *
 * TODO make more efficient as the "calculateRevenue" function is pretty heavy
 * NOTE: Does not currently support compare data
 *
 * @param bookings
 * @param averageOrderValue
 * @param averageBookingValue
 * @returns {number}
 */
export const getRevenue = (bookings, averageOrderValue, averageBookingValue) => {
  const calculatedRevenue = calculateRevenueOnly(
    bookings ?? [],
    [],
    averageOrderValue,
    averageBookingValue,
  );

  const revenueForSession = roundToNearest(calculatedRevenue);

  return revenueForSession;
};

// Default times to fall back to if times are not set
export const defaultTimeslots = {
  breakfast: {
    earlyBird: {
      start: 0,
      end: 510, // 8:30am
    },
    peak: { start: 510, end: 600 },
    lateSession: {
      start: 600,
      end: 690,
    },
  },
  lunch: {
    earlyBird: {
      start: 690, // 11:30
      end: 720, // 12
    },
    peak: { start: 720, end: 840 },
    lateSession: {
      start: 840, // 2
      end: 990,
    },
  },
  dinner: {
    earlyBird: {
      start: 990, // 4:30
      end: 1110,
    },
    peak: { start: 1110, end: 1230 }, // 7 to 7:30
    lateSession: {
      start: 1230,
      end: 1440,
    },
  },
};

/**
 * Convert the timeslot object into an array that's compatible with the API
 * @param timeslots
 */
export const convertTimeslotsToArray = (timeslots) => {
  const createTimeslotObject = (category, type) => ({
    objectId: null,
    enabled: true,
    category,
    type,
    startTime: timeslots[category][type].start,
    endTime: timeslots[category][type].end,
  });

  return [
    createTimeslotObject('breakfast', 'earlyBird'),
    createTimeslotObject('breakfast', 'peak'),
    createTimeslotObject('breakfast', 'lateSession'),
    createTimeslotObject('lunch', 'earlyBird'),
    createTimeslotObject('lunch', 'peak'),
    createTimeslotObject('lunch', 'lateSession'),
    createTimeslotObject('dinner', 'earlyBird'),
    createTimeslotObject('dinner', 'peak'),
    createTimeslotObject('dinner', 'lateSession'),
  ];
};

export const dateRangeOptions = [
  { name: 'Last 7 Days', value: 7 },
  { name: 'Last 14 Days', value: 14 },
  // { name: 'Last 28 Days', value: 28 },
  // { name: 'Last 90 Days', value: 90 },
  { name: 'Last Month', value: '1_month' },
  { name: 'Last 3 Months', value: '3_months' },
  { name: 'Last Year', value: 'last_year' },
  { name: 'All Time', value: 'all_time' },
  { name: 'Custom', value: 0 },
];

export const getDateRangeLabel = (dateRange) => {
  // Support months as well
  const optionIsCustomValue = dateRangeOptions.some((option) => option.value === dateRange?.value);

  // Check if option is in the list, otherwise it's a custom value
  const optionIsInList =
    (dayjs().isSame(dateRange?.endDate, 'day') &&
      dateRangeOptions.some((option) => option.value === dateRange?.dateRange)) ||
    optionIsCustomValue;

  if (optionIsInList) {
    return findObjectByProperty(dateRangeOptions, dateRange?.value, 'value')?.name;
  }

  return `${formatDate(dateRange.startDate, 'Do MMMM')} -
            ${formatDate(dateRange.endDate, 'Do MMMM')}`;
};

/**
 * Gets the timeslots for this venue for the day. Comes from the fetched data
 *
 * Returns the following structure
 * {
 *  breakfast: {
 *   earlyBird: {start: 0, end: 0},
 *   peak: {start: 0, end: 0},
 *   lateSession: {start: 0, end: 0},
 *   },
 *  lunch: {
 *   earlyBird: {start: 0, end: 0},
 *   peak: {start: 0, end: 0},
 *   lateSession: {start: 0, end: 0},
 *   },
 *  dinner: {
 *   earlyBird: {start: 0, end: 0},
 *   peak: {start: 0, end: 0},
 *   lateSession: {start: 0, end: 0},
 *   }
 * }
 *
 * @returns {{lunch: {}, breakfast: {}, dinner: {}}}
 * @param timePeriods
 */
export const getTimeslots = (timePeriods) => {
  const timePeriodsAsObject = safeParse(JSON.stringify(defaultTimeslots));

  // Convert the list of times into a structured object
  timePeriods.forEach((timePeriod) => {
    timePeriodsAsObject[timePeriod.category][timePeriod.type] = {
      start: timePeriod.startTime,
      end: timePeriod.endTime,
    };
  });

  return timePeriodsAsObject;
};

const getSessionForBooking = (booking, timeslots) => {
  // Comes back as "6:45 PM"
  const time = formatIntFromTime(dayjs.utc(booking?.time.toLowerCase(), 'h:mm a'));

  const breakfastStart = timeslots.breakfast.earlyBird.start;
  const lunchStart = timeslots.lunch.earlyBird.start;
  const dinnerStart = timeslots.dinner.earlyBird.start;

  if (time >= breakfastStart && time < timeslots.breakfast.lateSession.end) {
    return 'breakfast';
  }

  if (time >= lunchStart && time < timeslots.lunch.lateSession.end) {
    return 'lunch';
  }

  if (time >= dinnerStart && time < timeslots.dinner.lateSession.end) {
    return 'dinner';
  }

  return 'other';
};

export const getTimeslotForBooking = (booking, timeslots, session = null) => {
  // Comes back as "6:45 PM"
  const time = formatIntFromTime(dayjs.utc(booking?.time.toLowerCase(), 'h:mm a'));

  let bookingSession = session;
  if (!session) {
    bookingSession = getSessionForBooking(booking, timeslots);
  }

  if (
    time >= timeslots?.[bookingSession]?.lateSession?.start &&
    time < timeslots?.[bookingSession]?.lateSession?.end
  ) {
    return 'lateSession';
  }

  if (
    time >= timeslots?.[bookingSession]?.peak?.start &&
    time < timeslots?.[bookingSession]?.peak?.end
  ) {
    return 'peak';
  }

  if (
    time >= timeslots?.[bookingSession]?.earlyBird?.start &&
    time < timeslots?.[bookingSession]?.earlyBird?.end
  ) {
    return 'earlyBird';
  }

  // If we get here, it did not fit into a defined timeslot
  return 'other';
};

const getRevenueForSession = (
  bookingsWithSession,
  session,
  averageOrderValue,
  averageBookingValue,
) => {
  const bookingsForSession = bookingsWithSession.filter((booking) => booking?.session === session);

  return getRevenue(bookingsForSession, averageOrderValue, averageBookingValue);
};

const getRevenueForTimeslot = (
  bookingsWithSession,
  session,
  timeslot,
  averageOrderValue,
  averageBookingValue,
) => {
  const bookingsForSession = bookingsWithSession.filter(
    (booking) => booking?.timeslot === timeslot,
  );

  return getRevenue(bookingsForSession, averageOrderValue, averageBookingValue);
};

const getRevenueForSessionAndTimeslot = (
  bookingsWithSession,
  session,
  timeslot,
  averageOrderValue,
  averageBookingValue,
) => {
  const bookingsForSession = bookingsWithSession.filter(
    (booking) => booking?.session === session && booking?.timeslot === timeslot,
  );

  return getRevenue(bookingsForSession, averageOrderValue, averageBookingValue);
};

export const calculateRevenueBySession = (
  bookingsForGroup,
  averageOrderValue,
  averageBookingValue,
  timeslots,
) => {
  const bookingsWithSession = bookingsForGroup.map((booking) => ({
    ...booking,
    session: getSessionForBooking(booking, timeslots),
    timeslot: getTimeslotForBooking(booking, timeslots),
  }));

  // TODO warning - this is a lot of calculating and may take a while to process. See if it can be optimised
  return {
    breakfast: {
      total: getRevenueForSession(
        bookingsWithSession,
        'breakfast',
        averageOrderValue,
        averageBookingValue,
      ),
      earlyBird: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'breakfast',
        'earlyBird',
        averageOrderValue,
        averageBookingValue,
      ),
      peak: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'breakfast',
        'peak',
        averageOrderValue,
        averageBookingValue,
      ),
      lateSession: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'breakfast',
        'lateSession',
        averageOrderValue,
        averageBookingValue,
      ),
    },
    lunch: {
      total: getRevenueForSession(
        bookingsWithSession,
        'lunch',
        averageOrderValue,
        averageBookingValue,
      ),
      earlyBird: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'lunch',
        'earlyBird',
        averageOrderValue,
        averageBookingValue,
      ),
      peak: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'lunch',
        'peak',
        averageOrderValue,
        averageBookingValue,
      ),
      lateSession: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'lunch',
        'lateSession',
        averageOrderValue,
        averageBookingValue,
      ),
    },
    dinner: {
      total: getRevenueForSession(
        bookingsWithSession,
        'dinner',
        averageOrderValue,
        averageBookingValue,
      ),
      earlyBird: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'dinner',
        'earlyBird',
        averageOrderValue,
        averageBookingValue,
      ),
      peak: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'dinner',
        'peak',
        averageOrderValue,
        averageBookingValue,
      ),
      lateSession: getRevenueForSessionAndTimeslot(
        bookingsWithSession,
        'dinner',
        'lateSession',
        averageOrderValue,
        averageBookingValue,
      ),
    },
    other: {
      bookings: bookingsWithSession,
      total: getRevenueForSession(
        bookingsWithSession,
        'other',
        averageOrderValue,
        averageBookingValue,
      ),
    },
  };
};

export const getTimePeriodMessage = (startDate, endDate) => {
  const endDateIsToday = dayjs(endDate).isSame(dayjs(), 'day');
  // If end date is current date, return  "since X"
  if (endDateIsToday) {
    return `Since ${startDate.format('D MMM YYYY')}`;
  }

  // If end date is not current date, return "Between X and Y"
  return `Between ${startDate.format('D MMM YYYY')} and ${endDate.format('D MMM YYYY')}`;
};

export const formatDateForView = (dateToFormat, view) => {
  switch (view) {
    case 'year':
      return dayjs(dateToFormat).format('YYYY');
    case 'month':
      return dayjs(dateToFormat).format('YYYY-MM');
    case 'week':
      // Offset by 1 day so that it goes Monday - Sunday instead of Sunday - Saturday
      return dayjs(dateToFormat).subtract(1, 'day').startOf('week').format('YYYY-MM-DD');
    case 'day':
    default:
      return dayjs(dateToFormat).format('YYYY-MM-DD');
  }
};

// Return the last day of week/month/year from the date object provided
export const getGroupEndDate = (groupDate, group = 'day') => {
  switch (group) {
    case 'year':
      return groupDate.endOf('year');
    case 'month':
      return groupDate.endOf('month');
    case 'week':
      // Offset by 1 day so that it goes Monday - Sunday instead of Sunday - Saturday
      return groupDate.endOf('week').add(1, 'day');
    case 'day':
    default:
      return groupDate;
  }
};

export const getGroupDayLabel = (dateToFormat, view) => {
  const dayjsDate = dayjs(dateToFormat);
  switch (view) {
    case 'year':
      return {
        primary: dayjsDate.format('YYYY'),
        secondary: null,
        tooltipLabel: dayjsDate.format('YYYY'),
      };
    case 'month':
      return {
        primary: dayjsDate.format('MMM'),
        secondary: dayjsDate.format('YYYY').toUpperCase(),
        tooltipLabel: dayjsDate.format('MMM YYYY'),
      };
    case 'week':
      // Add 1 day so that it goes Monday - Sunday instead of Sunday - Saturday
      const startDate = dayjsDate.subtract(1, 'day').startOf('week').add(1, 'day');

      const endDate = getGroupEndDate(dayjsDate.subtract(1, 'day'), 'week');
      const isDifferentMonth = !startDate.isSame(endDate, 'month');

      return {
        primary: `${startDate.format('D')}-${endDate.format('D')}`,
        secondary:
          startDate.format('MMM').toUpperCase() +
          (isDifferentMonth ? `-${endDate.format('MMM').toUpperCase()}` : ''),
        // primary: `${endDate.format('D')}`,
        // secondary: endDate.format('MMM').toUpperCase(),
        tooltipLabel: `${startDate.format('ddd DD/MM/YYYY')} - ${endDate.format('ddd DD/MM/YYYY')}`,
      };
    case 'day':
    default:
      return {
        primary: dayjsDate.format('D'),
        secondary: dayjsDate.format('MMM').toUpperCase(),
        preLabel: dayjsDate.format('ddd'),
        tooltipLabel: dayjsDate.format('ddd DD/MM/YYYY'),
      };
  }
};

// Get a list of dates between two values
export const getSimpleDatesArray = (start, end) => {
  const datesArray = [];
  const diff = Math.abs(start.diff(end, 'days'));

  for (let i = 0; i <= diff; i += 1) {
    const nextDay = start.add(i, 'day');
    datesArray.push({
      date: nextDay.format('YYYY-MM-DD'),
      dayOfWeek: getDayOfWeekWithMondayFirst(nextDay),
    });
  }

  return datesArray;
};

/**
 * Get a list of dates for the range. Also includes the day/week/month for that date so they can be grouped
 * @param startDate
 * @param endDate
 * @param group
 * @returns {*[]}
 */
export const getDatesArray = (startDate, endDate, group = 'day') => {
  const datesArray = [];

  const diff = Math.abs(startDate.diff(endDate, 'days'));

  for (let i = 0; i <= diff; i += 1) {
    const nextDay = startDate.add(i, 'day');
    datesArray.push({
      date: nextDay.format('YYYY-MM-DD'),
      groupedDate: formatDateForView(nextDay, group),
      groupedDateLabel: getGroupDayLabel(nextDay, group),
    });
  }

  return datesArray;
};

/**
 * Calculate the revenue for each grouped period (day/week/month/year)
 * Data should already be grouped before this step
 *
 * Note: This runs for every day in order to get accurate prediction data
 *
 * @param datesArray
 * @param bookingsByGroup
 * @param closedDays
 * @param compareBookingsByDay
 * @param averageOrderValue
 * @param averageBookingValue
 * @returns {*}
 */
export const getTotalsByGroup = (
  datesArray,
  bookingsByGroup,
  closedDays,
  compareBookingsByDay = [],
  averageOrderValue = 0,
  averageBookingValue = 0,
) =>
  datesArray.map((date) => {
    const bookingsForGroup = bookingsByGroup?.[date.groupedDate] ?? [];
    const compareBookingsData = compareBookingsByDay?.[date.groupedDate] ?? [];

    const netRevenue = calculateRevenueOnly(
      bookingsForGroup,
      compareBookingsData,
      averageOrderValue,
      averageBookingValue,
    );

    const revenueForDay = roundToNearest(netRevenue);

    // So we can show a custom message if a venue is closed. HOWEVER, if the venue made money that day then it wasn't actually closed
    const dayjsDate = dayjs(date.date);
    const closedForDay = revenueForDay === 0 && closedDays.includes(dayjsDate.format('ddd'));

    return {
      ...date,
      dayjsDate,
      revenueForPreviousPeriod: netRevenue,
      revenueWithoutClosed: closedForDay ? null : revenueForDay,
      revenue: netRevenue,
      closedForDay,
      weekOfYear: dayjsDate.subtract(1, 'day').startOf('week').format('YYYY-MM-DD'), // Subtract 1 day since we start on monday but dayjs starts on sunday
    };
  });

/**
 * Find the slope and intercept of the data
 * returns values for a function like y=mx+c where m is the slope and c is the intercept
 *
 * @param y
 * @param x
 * @returns {{}}
 */
const linearRegression = (y, x) => {
  const lr = {};
  const n = y.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  // const sumYY = 0;

  for (let i = 0; i < y.length; i += 1) {
    sumX += x[i];
    sumY += y[i];
    sumXY += x[i] * y[i];
    sumXX += x[i] * x[i];
    // sumYY += y[i] * y[i];
  }

  lr.slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  lr.intercept = (sumY - lr.slope * sumX) / n;
  // lr.r2 =
  //   ((n * sumXY - sumX * sumY) /
  //     Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY))) **
  //   2;

  return lr;
};

/**
 * Calculates the revenue for every day for the last 3 months. Then calculates the seasonal index of each day, and returns the slope
 *
 * @param data
 * @param dates
 * @param closedDays
 * @returns {{endDate: (dayjs.Dayjs|*), regression: {}, daySeasonals: (number|*)[], startDate: dayjs.Dayjs, seasonalisedData: (*&{regressionIndex: *})[]}}
 */
export const getSeasonalisedPredictionData = (data, dates, closedDays) => {
  // First clean the data. Remove any future dates and only use the last 3 months or so of data

  const earliestBooking = sortListByProperty(data, 'created')?.[0];

  // Get the ideal end date, so that the data isn't skewed by future $0 values
  // Use start of weeks to only get clean Monday to Sunday data. Should give approx 14 complete weeks of data
  const yesterdayDate = dayjs().subtract(1, 'day').startOf('week');
  const rangeEndDate = dates.endDate.startOf('week');
  const endDate = yesterdayDate?.isBefore(rangeEndDate) ? yesterdayDate : rangeEndDate;

  // If venue is new, don't use months of zero data in estimates and instead go from the first booking
  const getBestStartDate = () => {
    const threeMonthsAgo = endDate.subtract(3, 'months');

    if (!isEmpty(earliestBooking)) {
      const earliestBookingDayjs = dayjs(earliestBooking?.created);
      return earliestBookingDayjs.isAfter(threeMonthsAgo) ? earliestBookingDayjs : threeMonthsAgo;
    }

    return threeMonthsAgo;
  };

  const bestStartDate = getBestStartDate();
  const startDate = bestStartDate.subtract(1, 'day').startOf('week').add(1, 'day');

  // Generate a list of every day in the range, so they can be grouped by that date
  const datesArray = uniqueObjectsByProperty(
    getDatesArray(startDate, endDate, 'day'),
    'groupedDate',
  );

  // Group the data by the time period (day, week, etc.)
  const bookingsByGroup = groupObjects(
    data.map((booking) => ({
      ...booking,
      groupedDate: formatDateForView(booking?.created),
      date: formatDate(booking?.created, 'YYYY-MM-DD', '-'),
    })),
    'groupedDate',
  );

  // Calculate the total revenue of each day
  const totalsByDay = getTotalsByGroup(datesArray, bookingsByGroup, closedDays);

  const addSeasonalIndices = () => {
    const dataByWeek = groupByProperty(totalsByDay, 'weekOfYear', 'weekOfYear');
    const dataWithWeekAverages = dataByWeek.map((week) => {
      const openDays = week.items.filter((day) => !day?.closedForDay);

      const totalRevenueForWeek = openDays.reduce((total, day) => total + day.revenue, 0);
      const averageOpenDayRevenueForWeek = safeDivision(totalRevenueForWeek, openDays.length);

      const daysWithSeasonalIndices = week.items.map((day) => ({
        ...day,
        seasonalIndex: safeDivision(day.revenue, averageOpenDayRevenueForWeek),
      }));

      return {
        ...week,
        items: daysWithSeasonalIndices,
        netRevenue: totalRevenueForWeek,
        average: averageOpenDayRevenueForWeek,
      };
    });

    return dataWithWeekAverages;
  };

  let dataWithIndices = addSeasonalIndices();

  // Get the average seasonal index for each weekday. That way we know how well that weekday usually performs compared to the week average
  const getAverageIndexForEachDay = () => {
    const averagePerDay = numberArray(7).map((index) => {
      const daysForIndex = dataWithIndices.map((week) => week.items?.[index]);
      const totalForWeekday = daysForIndex.reduce((accum, day) => accum + day?.seasonalIndex, 0);
      const openDays = daysForIndex.filter((day) => !day?.closedForDay);
      return safeDivision(totalForWeekday, openDays.length);
    });

    return averagePerDay;
  };

  // Note: 0 is monday, 6 is sunday
  const averagePerDay = getAverageIndexForEachDay();

  // Get the normalised value for each day
  dataWithIndices = dataWithIndices.map((week) => ({
    ...week,
    items: week.items.map((day, index) => ({
      ...day,
      deseasonalisedRevenue: safeDivision(day.revenue, averagePerDay?.[index]),
      daySeasonalAverage: averagePerDay?.[index],
    })),
  }));

  // Do the linear regression step to get the slope and the intercept, which gives us y = bx + a
  // Get it back to every individual day
  // NOTE: x=0 is the start day
  const regressionData = flatten(dataWithIndices, 'items')
    .flat()
    .map((day, index) => ({ ...day, regressionIndex: index }));
  const regression = linearRegression(
    regressionData.map((day) => day?.deseasonalisedRevenue),
    regressionData.map((day) => day?.regressionIndex),
  );

  // devLog('debug', 'seasonalisedData', {
  //   regression,
  //   regressionData,
  //   averagePerDay,
  //   startDate,
  //   dataWithIndices,
  //   totalsByDay,
  // });

  // TODO check that closed days weren't included in the seasonal calculations
  return {
    regression,
    seasonalisedData: regressionData,
    daySeasonals: averagePerDay,
    startDate,
    // endDate,
  };
};

/**
 * Get a smart label for the start and end times of a period
 *
 * e.g. has smart things like "Open - 8am"
 *
 * @returns {string}
 * @param timePeriod
 */
export const getTimePeriodLabel = (timePeriod) => {
  const startTime = timePeriod.start === 0 ? 'Open' : formatTimeFromInt(timePeriod.start, null, '');
  const endTime = timePeriod.end === 1440 ? 'Close' : formatTimeFromInt(timePeriod.end);

  if (timePeriod.start === timePeriod.end) {
    return endTime;
  }

  return `${startTime}-${endTime}`;
};

/**
 * Returns the earliest time, as an int, that the restaurant is open during the week
 */
export const getRestaurantEarliestOpenTime = (restaurant) => {
  const startTimes = [
    restaurant?.hoursParsed?.monOpen,
    restaurant?.hoursParsed?.tueOpen,
    restaurant?.hoursParsed?.wedOpen,
    restaurant?.hoursParsed?.thuOpen,
    restaurant?.hoursParsed?.friOpen,
    restaurant?.hoursParsed?.satOpen,
    restaurant?.hoursParsed?.sunOpen,
  ];
  return Math.min(...startTimes.filter((time) => time > -1));
};

/**
 * Returns the latest time, as an int, that the restaurant is open during the week
 */
export const getRestaurantLatestCloseTime = (restaurant) => {
  const startTimes = [
    restaurant?.hoursParsed?.monClose,
    restaurant?.hoursParsed?.tueClose,
    restaurant?.hoursParsed?.wedClose,
    restaurant?.hoursParsed?.thuClose,
    restaurant?.hoursParsed?.friClose,
    restaurant?.hoursParsed?.satClose,
    restaurant?.hoursParsed?.sunClose,
  ];
  return Math.max(...startTimes.filter((time) => time > -1));
};

/**
 * Calculate the percentile of the data that the value falls into
 * @param value
 * @param allValues
 */
export const calculatePercentile = (value, allValues) => {
  if (isEmpty(allValues)) {
    return 0;
  }

  const percentagePerPoint = safeDivision(100, allValues.length);
  let percentile = 100;

  for (let i = allValues.length - 1; i >= 0; i -= 1) {
    if (allValues[i] > value) {
      percentile -= percentagePerPoint;
    } else {
      break;
    }
  }

  return percentile;
};

export const getComparisonMessage = (total, comparisonData) => {
  if (empty(comparisonData?.dataset)) {
    return '';
  }

  const percentile = calculatePercentile(total, comparisonData?.dataset);

  // // Display to the nearest 10%. e.g. if 89th percentile, top 20. if 90th, top 10%
  // Also don't show "bottom 0%", so clamp to top/bottom 10%
  const increment = 1;
  const displayPercentile =
    percentile >= 50
      ? Math.min(
          100 - increment, // Limit to 90%
          Math.max(increment, roundToNearest(percentile, increment, 'floor')),
        )
      : // For bottom percentiles, round up, not down
        Math.max(
          increment, // limit to 10%
          Math.max(increment, roundToNearest(percentile, increment, 'ceil')),
        );

  const message = `You're in the ${percentile >= 50 ? 'top' : 'bottom'} ${percentile >= 50 ? 100 - displayPercentile : displayPercentile}%. `;

  if (percentile < 25) {
    return `${message} Not to worry, speak to your account manager to optimise your account to unlock more value.`;
    // return `You're in the bottom 25%. Not to worry, speak to your account manager to optimise your account to unlock more value.`;
  }

  if (percentile < 50) {
    return `${message} Speak to your account manager to optimise your offers to reach the next level.`;
    // return `You're just below the average. Speak to your account manager to optimise your offers to reach the next level.`;
  }

  if (percentile < 75) {
    return `${message} Speak to your account manager to optimise your offers to reach the next level.`;
    // return `You're just above the average. Speak to your account manager to optimise your offers to reach the next level.`;
  }

  if (percentile >= 75) {
    return `${message} Congratulations!`;
    // return `Congratulations, you’re in the top 25% of venues.`;
  }

  return ``;
};

/**
 * Formats the data by frequency, grouped into buckets, to generate the bell curve
 * @param dataset
 * @param lowCutoffPercentage
 * @param highCutoffPercentage
 * @param bucketAmount
 * @returns {[...{value: unknown, key: number}[],{value: number, key: *}]}
 */
export const getCurveData = (
  dataset,
  lowCutoffPercentage = 0,
  highCutoffPercentage = 100,
  bucketAmount = 5,
) => {
  const sortedData = [...(dataset ?? [])].sort(sortAlphaNumeric);

  const lowCutoff = sortedData?.[Math.floor((sortedData.length * lowCutoffPercentage) / 100)];
  const highCutoff = sortedData?.[Math.floor((sortedData.length * highCutoffPercentage) / 100)];

  const filteredData = sortedData.filter((value) => value >= lowCutoff && value <= highCutoff);

  // Sort into buckets. Imagine it like a bar chart with 50 bars
  const bucketSize = (highCutoff - lowCutoff) / bucketAmount;
  const dataByFrequency = {};
  filteredData.forEach((value) => {
    const bucketForValue = Math.floor(safeDivision(value, bucketSize));

    if (!dataByFrequency?.[bucketForValue]) {
      dataByFrequency[bucketForValue] = 0;
    }

    dataByFrequency[bucketForValue] += 1;
  });

  return {
    data: [
      ...Object.entries(dataByFrequency).map((item, index) => {
        return { key: Math.floor(bucketSize * index + lowCutoff), value: item[1] };
      }),
      { key: highCutoff, value: 1 },
    ],
    lowCutoff,
    highCutoff,
  };
};
