import { makeStyles } from '@mui/styles';

// Note: A lot of the paddings are to precisely line up the graph with the table. Be careful changing them
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      borderRadius: '32px',
      padding: '24px 24px 48px 24px',
      gap: '16px',
      justifyContent: 'space-between',
      // flexWrap: 'wrap',
      // flexShrink: 0,
      // flexGrow: 1,

      backgroundColor: theme.colors.revenueTimeChartBackground,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: '40px',
      },
    },
    graphContainer: {
      paddingTop: '16px',
      paddingRight: '10px',
      height: '204px',
      '& .recharts-surface': {
        overflow: 'visible',
      },
      // margin: '20px -10px',
    },
    graphHeader: {
      display: 'flex',
      flexDirection: 'column',
      gap: '48px',
      flexBasis: '50%',
      flexShrink: 0,
      flexGrow: 1,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    title: {
      fontSize: '22px',
      color: '#000000',
      opacity: 0.75, // Lets it blend in to the yellow and looks much nicer than just a grey
      fontWeight: theme.fontWeights.medium,
    },
  }),
  { name: 'RevenueDistribution' },
);

export default useStyles;
