/* eslint-disable radix */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { contactBdmAction } from '../../actions/contactBdmAction';
import useStyles from './SupportStyles';
import { trackEvent } from '../../utils/analytics';

const useQuery = () => new URLSearchParams(useLocation().search);

const SupportFormAccountManager = ({ contact, contactBdm, restId, userId, userType }) => {
  const classes = useStyles();
  const query = useQuery();

  const reasonOptions = [
    { name: 'Update Opening Hours', value: 1 },
    { name: 'Update Menus or Photos', value: 2 },
    { name: 'Update Deals', value: 3 },
    { name: '(Other)', value: 4 },
  ];

  const getInitialReason = () => {
    const selectedReason = query.get('discussion');
    if (selectedReason === null) {
      return 1;
    }

    const num = parseInt(selectedReason);
    if (num > reasonOptions.length - 1) {
      return 1;
    }

    return num;
  };

  const [reason, setReason] = useState(getInitialReason());
  const [message, setMessage] = useState('');

  const handleBdmSubmit = (event) => {
    event.preventDefault();
    trackEvent('button_click: contact_support_bdm');

    if (!event.target.checkValidity()) {
      return;
    }

    const reasonName = reasonOptions.find((option) => option.value === reason).name;

    contactBdm(reasonName, message, restId, userId, userType);
    setReason(getInitialReason());
    setMessage('');
  };

  return (
    <form noValidate autoComplete='off' onSubmit={handleBdmSubmit}>
      <FormGroup>
        <FormControl className={`${classes.formControl} ${classes.fixOutline}`} variant='outlined'>
          <InputLabel id='support-select-label'>Select Subject</InputLabel>
          <Select
            labelId='support-select-label'
            id='support-select'
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            required
          >
            {reasonOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
      <FormGroup className={classes.mb2}>
        <TextField
          label=''
          multiline
          minRows={5}
          maxRows={20}
          margin='normal'
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          required
          placeholder='Your Message'
        />
      </FormGroup>

      <Button type='submit' disabled={contact.pending} size='large'>
        Send
        {contact.pending && (
          <CircularProgress
            color='inherit'
            thickness={5}
            size={18}
            style={{ marginLeft: '10px' }}
          />
        )}
      </Button>
    </form>
  );
};

SupportFormAccountManager.propTypes = {
  contact: PropTypes.shape({
    pending: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  contactBdm: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  contact: state.contactBdm,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      contactBdm: contactBdmAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SupportFormAccountManager);
