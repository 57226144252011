import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { postOppo } from '../graphql/mutations';
import { devLog } from '../utils';

export const postDailyOppoAction =
  (oppoId, discount, origin, restId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'POST_DAILY_OPPO',
    });
    dispatch({
      type: type.POST_DAILY_OPPO_PENDING,
      payload: {
        oppoId,
      },
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: postOppo,
            variables: { oppoId, discount, origin },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'post daily oppos', response.data.postOppo);

        dispatch({
          type: type.POST_DAILY_OPPO_SUCCESS,
          payload: { oppoId },
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `POST_DAILY_OPPO_SUCCESS_${new Date().getTime()}`,
            message: `Success, the daily opportunity has been posted`,
            severity: 'success',
          },
        });
      } catch (error) {
        devLog('error', 'post daily oppos', error);

        dispatch({
          type: type.POST_DAILY_OPPO_FAILURE,
          payload: `${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'POST_DAILY_OPPO',
        });
      }
    })();
  };

export const clearPostDailyOppoAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_POST_DAILY_OPPO,
  });
};
