import * as type from './types';

export const setDatesDayjsAction = (startDate, endDate, dateRange) => (dispatch) => {
  dispatch({
    type: type.SET_SEARCH_DATES_DAYJS,
    payload: { startDate, endDate, dateRange },
  });
};

export const setDatesAction = (startDate, endDate) => (dispatch) => {
  dispatch({
    type: type.SET_SEARCH_DATES,
    payload: { startDate, endDate },
  });
};

export const setDateRangeAction = (dateRange) => (dispatch) => {
  dispatch({
    type: type.SET_DATE_RANGE,
    payload: dateRange,
  });
};
