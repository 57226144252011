import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Spacer, Divider } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateRestaurantTimePeriodsAction } from '../../../actions/restaurantPeriodsAction';
import MoonSVG from '../../../assets/icons/moon.svg';
import SunSVG from '../../../assets/icons/sun.svg';
import SunriseSVG from '../../../assets/icons/sunrise.svg';
import { Modal } from '../../../components/Modal';
import { trackEvent } from '../../../utils/analytics';
import { formatTimeFromInt, safeParse, isEmpty } from '../../../utils/helpers';
import {
  convertTimeslotsToArray,
  getRestaurantEarliestOpenTime,
  getRestaurantLatestCloseTime,
  getTimePeriodLabel,
  getTimeslots,
} from '../../../utils/insights/insightHelpers';
import { times } from '../../../utils/valueLists';
import OpeningHourDropdown from './OpeningHourDropdown';
import useStyles, { modalInputStyle, modalStyle } from './OpeningHoursEditModalStyles';

const OpeningHoursEditModal = ({
  isOpen,
  onClose,
  updateRestaurantTimePeriods,
  activeRestaurant,
  restaurantPeriods,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const defaultTimeslots = getTimeslots(restaurantPeriods.restaurantTimePeriods);
  const [timeslots, setTimeslots] = useState(defaultTimeslots);
  const [formErrors, setFormErrors] = useState([]);

  // TODO filter time options to start at open and end at close. Open should still be 0 and close 1439
  const earliestOpen = getRestaurantEarliestOpenTime(activeRestaurant);
  const latestClose = getRestaurantLatestCloseTime(activeRestaurant);
  const restaurantTimes = times.filter(
    (time) => time.value >= earliestOpen && time.value <= latestClose,
  );

  // Get all of the available times for the restaurant, to show in the dropdowns
  const labelledTimes = [
    { label: `Open (${formatTimeFromInt(earliestOpen)})`, value: 0 },
    ...restaurantTimes,
    { label: `Close (${formatTimeFromInt(latestClose)})`, value: 1440 },
  ];

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: edit_offer_modal');
      setTimeslots(getTimeslots(restaurantPeriods.restaurantTimePeriods));
    }
  }, [isOpen]);

  /** Validate the data before submitting
   *
   * @returns {*|boolean}
   */
  const validate = useCallback(() => {
    // Check all times are in sequence
    const allTimes = convertTimeslotsToArray(timeslots);

    let timeToCheck = 0;
    const errors = allTimes
      .map((time) => {
        if (time.startTime < timeToCheck) {
          return {
            category: time?.category,
            type: time?.type,
            point: 'start',
            error: 'Timeslot cannot start before the previous timeslot ends',
          };
        }
        timeToCheck = time.startTime;

        if (time.endTime < timeToCheck) {
          return {
            category: time?.category,
            type: time?.type,
            point: 'end',
            error: 'End time cannot be before start time',
          };
        }
        timeToCheck = time.endTime;

        return null;
      })
      .filter((item) => item);

    setFormErrors(errors);

    return isEmpty(errors);
  }, [timeslots]);

  const confirm = () => {
    trackEvent('button_click: confirm_change_timeslots');

    const valid = validate();

    if (!valid) {
      return;
    }

    updateRestaurantTimePeriods(timeslots);

    // TODO should we wait for success before closing?
    onClose();
  };

  const updateTimeslot = (category, type, point, newValue) => {
    const newTimeslots = safeParse(JSON.stringify(timeslots)); // Guarantees a copy of the object

    // Remove the old error
    const errorIndexForTimeslot = formErrors.findIndex(
      (formError) =>
        formError?.category === category && formError?.type === type && formError?.point === point,
    );

    if (errorIndexForTimeslot > -1) {
      setFormErrors(formErrors.filter((error, index) => index !== errorIndexForTimeslot));
    }

    // Limit to the restaurant hours
    const newTime = newValue;
    newTimeslots[category][type][point] = newTime;

    // Update future times to not be before this time
    // NOTE: Originally this would update ALL future times, but it seemed to easy for users to accidentally wipe their data
    // So instead we'll just do the next 1 time
    if (point === 'start') {
      // If start time was updated, simply check the end time and update it if it's before
      const nextTime = newTimeslots[category][type].end;
      if (nextTime < newTime) {
        newTimeslots[category][type].end = newTime;
      }
    } else {
      // If we changed the end time, find the next timeslot. Easiest to do by converting it to an array
      const newTimeslotsArray = convertTimeslotsToArray(newTimeslots);
      const indexOfChangedTimeslot = newTimeslotsArray.findIndex(
        (timeslot) => timeslot?.category === category && timeslot?.type === type,
      );

      // If there's a later timeslot, and its start time is before this, change its start time
      if (indexOfChangedTimeslot < newTimeslotsArray.length - 1) {
        const nextTimeslot = newTimeslotsArray[indexOfChangedTimeslot + 1];
        const nextStartTime = nextTimeslot.startTime;

        if (nextStartTime < newTime) {
          // Note we're updating the object, not the array
          newTimeslots[nextTimeslot.category][nextTimeslot.type].start = newTime;
        }
      }
    }

    setTimeslots(newTimeslots);
  };

  const tableData = useMemo(() => {
    return [
      {
        id: 'breakfast',
        Icon: <SunriseSVG style={{ color: theme.colors.sunriseRed }} />,
        name: 'Breakfast',
        timeslots: timeslots.breakfast,
      },
      {
        id: 'lunch',
        Icon: <SunSVG style={{ color: theme.colors.sunOrange }} />,
        name: 'Lunch',
        timeslots: timeslots.lunch,
      },
      {
        id: 'dinner',
        Icon: <MoonSVG style={{ color: theme.colors.moonPurple }} />,
        name: 'Dinner',
        timeslots: timeslots.dinner,
      },
    ];
  }, [timeslots]);

  return (
    <Modal
      confirmLabel='Save changes'
      heading='Opening hour labels'
      isLoading={restaurantPeriods.fetching}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirm}
      style={modalStyle}
      inputStyle={modalInputStyle}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Box className={classes.inlineText}>This will only change the labels on the insights</Box>

        <Box>
          <Box style={{ margin: '0 -20px' }}>
            <Divider color='#ECECEC' />
          </Box>

          <Box className={classes.tableContainer}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Times</th>
                  <th>Start time</th>
                  <th>End time</th>
                </tr>
              </thead>

              <tbody>
                {tableData.map((item) => (
                  <Fragment key={item.id}>
                    <tr className={classes.tableRow}>
                      <td
                        className={`${classes.tableItem} ${classes.tableItemLabel}`}
                        style={{ fontWeight: 500 }}
                        colSpan={3}
                      >
                        <Spacer className={classes.tableItemLabelInner} gap={12}>
                          {item?.Icon && (
                            <Box style={{ width: '37px', flexShrink: 0 }}>{item.Icon}</Box>
                          )}
                          <Box>{item.name}</Box>
                          {item.timeslots && (
                            <Box className={classes.sessionSubLabel}>
                              {getTimePeriodLabel({
                                start: item?.timeslots?.earlyBird?.start,
                                end: item?.timeslots?.lateSession?.end,
                              })}
                            </Box>
                          )}
                        </Spacer>
                      </td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableCell}>Early bird</td>
                      <td>
                        <OpeningHourDropdown
                          onSelect={(newValue) =>
                            updateTimeslot(item.id, 'earlyBird', 'start', newValue)
                          }
                          error={formErrors.find(
                            (formError) =>
                              formError?.category === item.id &&
                              formError?.type === 'earlyBird' &&
                              formError?.point === 'start',
                          )}
                          items={labelledTimes}
                          value={item?.timeslots?.earlyBird?.start}
                        />
                      </td>
                      <td>
                        <OpeningHourDropdown
                          onSelect={(newValue) =>
                            updateTimeslot(item.id, 'earlyBird', 'end', newValue)
                          }
                          error={formErrors.find(
                            (formError) =>
                              formError?.category === item.id &&
                              formError?.type === 'earlyBird' &&
                              formError?.point === 'end',
                          )}
                          items={labelledTimes}
                          value={item?.timeslots?.earlyBird?.end}
                        />
                      </td>
                    </tr>

                    <tr className={classes.tableRow} style={{ height: '40px' }}>
                      <td>
                        <Box className={classes.tableCell}>Peak</Box>
                      </td>
                      <td>
                        <OpeningHourDropdown
                          onSelect={(newValue) =>
                            updateTimeslot(item.id, 'peak', 'start', newValue)
                          }
                          error={formErrors.find(
                            (formError) =>
                              formError?.category === item.id &&
                              formError?.type === 'peak' &&
                              formError?.point === 'start',
                          )}
                          items={labelledTimes}
                          value={item?.timeslots?.peak?.start}
                        />
                      </td>
                      <td>
                        <OpeningHourDropdown
                          onSelect={(newValue) => updateTimeslot(item.id, 'peak', 'end', newValue)}
                          error={formErrors.find(
                            (formError) =>
                              formError?.category === item.id &&
                              formError?.type === 'peak' &&
                              formError?.point === 'end',
                          )}
                          items={labelledTimes}
                          value={item?.timeslots?.peak?.end}
                        />
                      </td>
                    </tr>

                    <tr className={classes.tableRow} style={{ height: '40px' }}>
                      <td className={classes.tableCell}>Late session</td>
                      <td>
                        <OpeningHourDropdown
                          onSelect={(newValue) =>
                            updateTimeslot(item.id, 'lateSession', 'start', newValue)
                          }
                          error={formErrors.find(
                            (formError) =>
                              formError?.category === item.id &&
                              formError?.type === 'lateSession' &&
                              formError?.point === 'start',
                          )}
                          items={labelledTimes}
                          value={item?.timeslots?.lateSession?.start}
                        />
                      </td>
                      <td>
                        <OpeningHourDropdown
                          onSelect={(newValue) =>
                            updateTimeslot(item.id, 'lateSession', 'end', newValue)
                          }
                          error={formErrors.find(
                            (formError) =>
                              formError?.category === item.id &&
                              formError?.type === 'lateSession' &&
                              formError?.point === 'end',
                          )}
                          items={labelledTimes}
                          value={item?.timeslots?.lateSession?.end}
                        />
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  restaurantPeriods: state.restaurantPeriods,
  activeRestaurant: state.restaurantActive.restaurant,
});

OpeningHoursEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateRestaurantTimePeriods: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateRestaurantTimePeriods: updateRestaurantTimePeriodsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpeningHoursEditModal);
