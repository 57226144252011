import { makeStyles } from '@mui/styles';
import { FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  indexLabel: {
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    lineHeight: '25px',
    border: '1px solid #313131',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#313131',
    boxSizing: 'content-box',
  },

  // Prediction bar
  predictionBar: {
    alignItems: 'center',
    background: '#EDCC3E',
    bottom: '0',
    boxShadow: '0px -2px 7px rgba(212, 212, 212, 0.25)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px 30px',
    left: '313px',
    padding: '10px 24px',
    position: 'fixed',
    right: '0',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      left: '0',
      borderRadius: '8px 8px 0 0',
    },
  },
  offerChangePrediction: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px 30px',
    flexWrap: 'wrap',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  predictedResultsText: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#4F4F4F',
    letterSpacing: '1.5px',
  },
  predictedResultsContainer: {
    display: 'flex',
    gap: '16px 30px',
  },
  predictedResult: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '12px',
    alignItems: 'center',
  },
  predictedResultLabel: {
    color: '#4F4F4F',
    fontSize: '14px',
    fontWeight: 500,
  },
  predictedResultValue: {
    display: 'flex',
    background: '#F8F1D3',
    height: '26px',
    width: '76px',
    borderRadius: '100px',
    boxShadow: '0px 2px 4px #DFB91C',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: '#4F4F4F',
    fontSize: '14px',
  },

  stepLabel: {
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: '16px',
    lineHeight: '27px',
  },
  stepDescription: {
    fontSize: '14px',
    lineHeight: '23px',
    color: '#6E6E6D',
  },
  stepContent: {
    marginLeft: '43px', // note: `stepIndex width + gap` to line it up with stepLabel
    width: '100%',
    maxWidth: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
    },
  },

  // Offer amount
  cardContainer: ({ checked }) => ({
    padding: '12px',
    border: '1px solid #aeaeae',
    borderRadius: '6px',
    maxWidth: '350px',
    alignItems: 'flex-start',
    borderColor: checked ? '#313131' : '#aeaeae',
    transition: 'border-color 0.1s',
  }),
  cardHeading: {
    fontWeight: 500,
    fontSize: '16px',
  },
  cardDescription: {
    color: '#6E6E6D',
    fontSize: '15px',
  },

  //
}));

export default useStyles;
