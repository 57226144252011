import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent } from '../../../utils/analytics';
import DayTab from './DayTab';
import PredictedOfferCard from '../OfferCard/PredictedOfferCard';
import useStyles from './OffersByDayStyles';

const PredictedOffersByDay = ({
  offers,
  deleteOffer,
  editOffer,
  bottomBuffer = true,
  notificationMessage = '',
  repeatDays = {},
}) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = useState(null);
  const [offersByDay, setOffersByDay] = useState([]);

  const dayMap = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  useEffect(() => {
    let newOffersByDay =
      offers.reduce(
        (days, item) => {
          const newDays = { ...days };
          const day = newDays[dayMap[item?.dayOfWeek - 1]] || [];
          day.push(item);
          newDays[dayMap[item?.dayOfWeek - 1]] = day;
          return newDays;
        },
        {
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: [],
          Sunday: [],
        },
      ) || {};

    // Switch back to all if no offers for that day
    if (selectedDay && newOffersByDay[selectedDay].length === 0) {
      setSelectedDay(null);
    }

    const sortByStartTime = (offersToSort) => {
      const currentlyActiveOffers = offersToSort
        .filter((offer) => offer?.status === 'active')
        .sort((a, b) => (a?.startTime >= b?.startTime ? 1 : -1));

      const offersForDay = offersToSort
        .filter((offer) => offer?.status !== 'active')
        .sort((a, b) => (a?.startTime >= b?.startTime ? 1 : -1));

      return [...currentlyActiveOffers, ...offersForDay];
    };

    // Sort each day by start time
    newOffersByDay = {
      Monday: sortByStartTime(newOffersByDay.Monday),
      Tuesday: sortByStartTime(newOffersByDay.Tuesday),
      Wednesday: sortByStartTime(newOffersByDay.Wednesday),
      Thursday: sortByStartTime(newOffersByDay.Thursday),
      Friday: sortByStartTime(newOffersByDay.Friday),
      Saturday: sortByStartTime(newOffersByDay.Saturday),
      Sunday: sortByStartTime(newOffersByDay.Sunday),
    };

    setOffersByDay(newOffersByDay);
  }, [offers]);

  return (
    <Box
      className={classes.container}
      style={{ minHeight: bottomBuffer ? `${Math.min(900, offers.length * 150)}px` : 0 }}
    >
      <Box className={classes.tabBar}>
        <DayTab
          key='All'
          day='All'
          selected={selectedDay === null}
          onClick={() => {
            trackEvent('button_click: starting_offers_day_set_all');
            setSelectedDay(null);
          }}
          itemAmount={offers.length}
        />
        {Object.keys(offersByDay)
          .filter((day) => offersByDay[day].length > 0)
          .map((day) => (
            <DayTab
              key={day}
              day={day}
              selected={selectedDay === day}
              onClick={() => {
                trackEvent(`button_click: starting_offers_day_set_${day}`);
                setSelectedDay(day);
              }}
              itemAmount={offersByDay[day].length}
            />
          ))}
      </Box>
      {notificationMessage && (
        <Box className={classes.notificationMessage}>{notificationMessage}</Box>
      )}
      <Box>
        {Object.keys(offersByDay)
          .filter((day) => (!selectedDay || day === selectedDay) && offersByDay[day].length > 0)
          .map((day) => (
            <Box key={day} className={classes.offerDay}>
              <Box className={classes.dayName}>{day}</Box>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#fff',
                  boxShadow: '0px 4px 4px 0px #DADADA40',
                  borderRadius: '24px',
                  overflow: 'hidden',
                }}
              >
                {offersByDay[day].map((offer, i) => (
                  <Box key={offer?.tempId + offer?.objectId}>
                    <PredictedOfferCard
                      offer={offer}
                      deleteOffer={deleteOffer}
                      editOffer={editOffer}
                      occurrences={repeatDays?.[offer?.dayOfWeek]}
                    />

                    {i < offersByDay[day].length - 1 && (
                      <Divider style={{ borderColor: '#EDEDED' }} />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
PredictedOffersByDay.propTypes = {
  offers: PropTypes.arrayOf(offerPropTypes).isRequired,
  deleteOffer: PropTypes.func.isRequired,
  editOffer: PropTypes.func.isRequired,
  bottomBuffer: PropTypes.bool,
  notificationMessage: PropTypes.string, // An optional message that can appear above the offers
  repeatDays: PropTypes.shape({}), // An object of each day and how many times they repeat in the period
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PredictedOffersByDay);
