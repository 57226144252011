import * as type from '../actions/types';

const initialState = {
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SAVE_PAYMENT_DETAILS_PENDING: {
      return {
        ...state,
        error: '',
        pending: true,
        success: false,
      };
    }

    case type.SAVE_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        pending: false,
        success: true,
      };
    }

    case type.SAVE_PAYMENT_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
};
