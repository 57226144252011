import { CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from '../../../../utils/helpers';
// import { useHasLoaded } from '../../../../utils/hooks/useHasLoaded';
import { calculatePercentile } from '../../../../utils/insights/insightHelpers';
import { sortAlphaNumeric } from '../../../../utils/listHelpers';
import TextDropdown from '../Dropdown/TextDropdown';
import ComparisonBar from './ComparisonBar';
import useStyles from './MarketComparisonStyles';

const MarketComparisons = ({
  heading = 'Market comparisons',
  description,
  comparisonData,
  total = 0,
  colors,
  loading = false,
  format = (value) => value,
  filter,
  setFilter,
  categories,
  secondaryFilter = null,
  secondaryFilterValue = null,
  setSecondaryFilter = () => {},
}) => {
  const classes = useStyles();

  const sortedData = [...(comparisonData?.dataset ?? [])].sort(sortAlphaNumeric);
  comparisonData.median = sortedData?.[Math.floor(sortedData.length / 2)];
  const percentile = calculatePercentile(total, sortedData);

  // Keep track of whether this has already loaded
  // const hasLoaded = useHasLoaded(loading);

  const filters = [
    { label: 'All venues', value: 'all' },
    ...(categories ?? []).map((category) => ({
      label: category,
      value: category,
    })),
  ];

  return (
    <Box className={classes.marketComparisonSection}>
      <Spacer direction='vertical'>
        <Spacer style={{ justifyContent: 'space-between', width: '100%' }}>
          <Box className={classes.sectionHeading}>{heading}</Box>
          <TextDropdown items={filters} value={filter} onSelect={setFilter} />
        </Spacer>

        {!isEmpty(secondaryFilter) && (
          <Spacer direction='vertical' style={{ alignItems: 'flex-end' }}>
            <TextDropdown
              items={secondaryFilter}
              value={secondaryFilterValue}
              onSelect={setSecondaryFilter}
            />
          </Spacer>
        )}
      </Spacer>

      {loading ? (
        <Box className={classes.spinnerContainer}>
          <CircularProgress
            color='inherit'
            style={{
              transform: 'translateX(-50%)',
            }}
          />
        </Box>
      ) : isEmpty(comparisonData?.dataset) ? (
        <Box>Unable to load comparison data. Please try again later</Box>
      ) : (
        <Box
          style={{
            opacity: loading ? 0 : 1,
            transition: loading ? 'opacity 0s' : 'opacity 1s',
            gap: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>{description}</Box>
          <ComparisonBar
            comparisonData={comparisonData}
            value={total}
            percentile={percentile}
            colors={colors}
            format={format}
          />
        </Box>
      )}
    </Box>
  );
};

MarketComparisons.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string.isRequired,
  comparisonData: PropTypes.shape({
    low: PropTypes.number,
    mean: PropTypes.number,
    high: PropTypes.number,
  }).isRequired,
  total: PropTypes.number,
  colors: PropTypes.shape({
    light: PropTypes.string,
    dark: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  format: PropTypes.func,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.restaurantActive.restaurant.categories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketComparisons);
